import Bugsnag from "@bugsnag/browser";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";
import includes from "lodash-es/includes";
import isNil from "lodash-es/isNil";
import React from "react";
import ReactDOM from "react-dom";

import authStore from "./js/stores/auth/authStore";
import uiStore from "./js/stores/uiStore";

import "./js/common/i18n";

import App from "./js/App";
import Button from "./js/components/Button";

/**
 * Add global error reporting for Bugsnag, adds additional context to the report before sending
 */
Bugsnag.start({
   apiKey: process.env.BUGSNAG_ID ?? "",
   plugins: [new BugsnagPluginReact()],
   releaseStage: process.env.BUGSNAG_STAGE,
   enabledBreadcrumbTypes: ["error", "log", "manual", "navigation", "process", "request", "state"],
   onError: (event) => {
      console.log("Decorating error report for Bugsnag...", event.originalError);
      if (authStore.isLoggedIn() && !isNil(authStore.user) && !isNil(authStore.currentCompany)) {
         event.setUser(authStore.currentCompany, authStore.user.email, authStore.getCurrentCompany()?.name);
      }

      // Add data to identify context for data used
      event.addMetadata("session", {
         loggedIn: authStore.isLoggedIn(),
         dataUsed: uiStore.dataVersion
      });

      // Add request data to error report if error contains such data
      if (!isNil(event.originalError?.response)) {
         const resp = event.originalError.response;
         console.log("Error was related to a request, adding request context for " + resp.status + " response");
         event.addMetadata("fetch", {
            url: resp.url,
            status: resp.status,
            statusText: resp.statusText,
            success: resp.ok,
            body: event.originalError.text
         });
      }
   }
});

const bugSnagReactPlugin = Bugsnag.getPlugin("react");
if (isNil(bugSnagReactPlugin)) {
   throw new Error("Unabe to ");
}
const BugsnagErrorBoundary = bugSnagReactPlugin.createErrorBoundary(React);

const UpgradeHapppened = () => (
   <>
      <h2>
         <CheckCircleIcon className="text-success" /> En ny versjon av TINE Handel er tilgjengelig!
      </h2>
      <p>Trykk på knappen under for å laste inn den nye versjonen og fortsette handleturen.</p>
      <Button className="tine-primary-button" onClick={() => window.location.reload()}>
         Fortsett
      </Button>
   </>
);

const UnexpectedError = () => (
   <>
      <h2>
         <ExclamationCircleIcon className="text-danger" /> Det har oppstått en uforutsett feil!
      </h2>
      <p>Du kan prøve å trykke på knappen under for å starte om websiden.</p>
      <p>
         Feilen har blitt automatisk rapportert inn til TINE, så vi kommer til å undersøke problemet nærmere for å se om vi kan
         unngå at dette skjer i fremtiden.
      </p>
      <Button variant="primary" onClick={() => window.location.reload()}>
         Start om siden
      </Button>
   </>
);

type SuggestedActionProps = {
   error: Error | null | undefined;
};

const SuggestedAction = ({ error }: SuggestedActionProps) => (
   <div className="d-flex align-items-center justify-content-center m-5">
      <div className="bg-white p-5 shadow">
         {includes(error?.message, "Failed to fetch dynamically imported module") ? <UpgradeHapppened /> : <UnexpectedError />}
      </div>
   </div>
);

const el = document.getElementById("root");
ReactDOM.render(
   <BugsnagErrorBoundary FallbackComponent={SuggestedAction}>
      <App />
   </BugsnagErrorBoundary>,
   el
);
