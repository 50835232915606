import { view } from "@risingstack/react-easy-state";
import { Row } from "react-bootstrap";
import { Link } from "wouter";

import { CmsContentSearchResults, SEARCH_TYPE, SearchComponentProps } from "../../common/types/searchTypes";
import useSupportedLocale from "../../common/utils/useSupportedLocale";

import searchStore from "../../stores/searchStore";

import CmsContentHit from "./CmsContentHit";

const hide = () => searchStore.clear(SEARCH_TYPE.QuickSearch, true);

const HitsForCmsContent = ({ searchDomain, maxHits = Number.MAX_VALUE, isNarrow = false }: SearchComponentProps) => {
   const results: CmsContentSearchResults | undefined = searchStore[SEARCH_TYPE.QuickSearch].results.find(
      (result) => result.name === searchDomain.name
   ) as CmsContentSearchResults | undefined;
   const hits = (results?.hits || []).slice(0, maxHits);
   const locale = useSupportedLocale();

   if (results && hits.length) {
      const { queryID } = results;
      return (
         <>
            <Row>
               <h3 className="pb-2">{searchDomain.displayName(locale)}</h3>
            </Row>
            <Row xs={1} lg={isNarrow ? 1 : 3}>
               {hits.map((hit, i) => (
                  <CmsContentHit
                     key={hit.objectID}
                     hit={hit}
                     position={i + 1}
                     locale={locale}
                     searchDomain={searchDomain}
                     queryID={queryID}
                  />
               ))}
            </Row>
            <Row>
               <Link
                  className="d-inline-block my-2 p-2"
                  onClick={() => hide()}
                  to={`/search/${searchDomain.name}?q=${results.query}`}
               >{`Se alle ${searchDomain.displayName(locale)} med '${results.query}'`}</Link>
            </Row>
         </>
      );
   }
   return <></>;
};

export default view(HitsForCmsContent);
