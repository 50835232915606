import { view } from "@risingstack/react-easy-state";
import { Link } from "wouter";

import { translateDayLabel, translateOrderType } from "../../common/utils";
import { formatDateTime, formatDeliveryDate } from "../../common/utils/dateUtils";

import cartStore from "../../stores/cartStore";
import deliveryDatesStore from "../../stores/deliveryDates/deliveryDatesStore";

import Button from "../../components/Button";
import InfoLine from "../../components/InfoLine";

const MiniCartDates = () => (
   <>
      <div className="d-flex flex-row flex-wrap justify-content-between align-items-center px-1 px-lg-2 mb-2 mb-lg-3">
         <h2>{translateOrderType(cartStore.orderType)}</h2>
         <div>
            <Link
               to={"/checkout/cart"}
               onClick={() => {
                  cartStore.hideMiniCart();
               }}
            >
               <Button>Endre ordre</Button>
            </Link>
         </div>
      </div>
      <div className="px-1 px-lg-2">
         <InfoLine
            margin
            label="Bestillingsfrist"
            value={formatDateTime(deliveryDatesStore.getCurrentDelivery()?.deadline, true, true)}
         />
         <InfoLine
            margin
            label={translateDayLabel(cartStore.orderType)}
            value={formatDeliveryDate(
               deliveryDatesStore.getCurrentDelivery()?.date,
               cartStore.orderType,
               deliveryDatesStore.getCurrentDelivery()?.interval
            )}
         />
      </div>
   </>
);

export default view(MiniCartDates);
