import { toNumber } from "lodash-es";

import { AmountPickerControlled } from "../../AmountPicker";

type IngredientsPortionsControlProps = {
   portions: number;
   onChange: (portions: number) => void;
};

const IngredientsPortionsControl = ({ portions, onChange }: IngredientsPortionsControlProps) => {
   const handleChange = (qty: string | number, inc: boolean) => {
      let value = toNumber(qty);
      if (value) {
         if (inc) {
            value += portions;
         }
         if (value) {
            onChange(value);
         }
      }
   };
   return (
      <div className="d-inline-block mb-3">
         <AmountPickerControlled qty={portions} changeFn={handleChange} />
      </div>
   );
};

export default IngredientsPortionsControl;
