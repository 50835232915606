import { ClockIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { view } from "@risingstack/react-easy-state";
import isNil from "lodash-es/isNil";
import { Col, Row } from "react-bootstrap";
import { Link, useLocation } from "wouter";

import { ORDERTYPE } from "../common/types/productOrderTypes";
import { formatDate, formatDateTime, parseM3Date } from "../common/utils/dateUtils";

import cartStore from "../stores/cartStore";

import Button from "../components/Button";
import InfoLine from "../components/InfoLine";

const SubscriptionEditingDeliveryInfo = view(() => {
   const [_, redirectTo] = useLocation();
   if (isNil(cartStore.editing)) {
      return null;
   }

   const label = cartStore.editing.instanceDate
      ? "planlagt levering (" + formatDate(parseM3Date(cartStore.editing.instanceDate)) + "), abo. "
      : "abonnement";

   return (
      <>
         <Col className="p-0 d-flex flex-column flex-md-row align-items-center justify-content-md-between gap-2">
            <Link to="/checkout/cart" className="selected-delivery-editing d-flex  align-items-end">
               <strong>
                  Endrer {label} #{cartStore.editing.orderNumber}
               </strong>
            </Link>
            <Button
               onClick={() => {
                  cartStore.stopEditOrderMode().then((orderNumber) => redirectTo(`/customer/subscriptions/${orderNumber}`));
               }}
            >
               Avbryt endring
            </Button>
            <small className="w-fit text-center text-md-end">
               Endringer gjøres direkte i handlekurven. Oppdater abonnement for å lagre endringer.
            </small>
         </Col>
      </>
   );
});

const DefaultEditingDeliveryInfo = view(({ abortEdit }) => {
   if (isNil(cartStore.editing)) {
      return null;
   }

   return (
      <div className="p-0 d-flex flex-row flex-wrap justify-content-between align-items-center gap-4">
         <div className="px-0 d-flex">
            <ClockIcon className="me-1" />
            <InfoLine label="Endringsfrist" align="start" value={formatDateTime(cartStore.editing.deadline, true, true)} />
         </div>
         <div className="px-0">
            <Button className="h-fit" onClick={cartStore.stopEditOrderMode}>
               Avbryt endring
            </Button>
         </div>
         <div className="px-0">
            <div>
               <Link to="/checkout/cart" className="selected-delivery-editing d-flex align-items-center flex-wrap">
                  <PencilSquareIcon className="me-1" />
                  <small className="text-center">Endrer ordre #{cartStore.editing.orderNumber}</small>
               </Link>
            </div>
         </div>
         <div className="px-0 d-flex flex-grow-1 justify-content-center">
            <small className="text-start text-md-end">
               Endringene kan gjøres direkte i handlekurven. Gå til kassen og send inn ordren for å lagre endringene.
            </small>
         </div>
      </div>
   );
});

// Separate top bar when we are editing an order. TypeScript is a silly goose and doesn't understand that the existing check
// ensures the same thing as this added section section. To keep it easily humanly readable I am leaving both.
const ChangeOrderOrSubscription = () => {
   if (cartStore.isEditing() && !isNil(cartStore.editing)) {
      return (
         <div className="selected-delivery-editing w-100 shadow-b px-3 px-md-5">
            <Row className="content-selected-delivery-editing py-3 gap-5">
               {cartStore.editing.orderType === ORDERTYPE.WAS && <SubscriptionEditingDeliveryInfo />}
               {cartStore.editing.orderType !== ORDERTYPE.WAS && <DefaultEditingDeliveryInfo />}
            </Row>
         </div>
      );
   } else {
      return null;
   }
};

export default view(ChangeOrderOrSubscription);
