import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { view } from "@risingstack/react-easy-state";

const ResetSuccess = () => (
   <div className="d-flex mb-4">
      <CheckCircleIcon className="login-small-icon me-3" />
      <p className="mb-0">Passordet ditt har blitt oppdatert. Du kan nå prøve å logge inn med nytt passord.</p>
   </div>
);

export default view(ResetSuccess);
