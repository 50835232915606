import { HeartIcon } from "@heroicons/react/24/outline";
import { view } from "@risingstack/react-easy-state";
import size from "lodash-es/size";
import sortBy from "lodash-es/sortBy";
import values from "lodash-es/values";
import { useState } from "react";
import { Form, OverlayTrigger, Popover } from "react-bootstrap";

import authStore from "../stores/auth/authStore";
import favoriteStore from "../stores/favoriteStore";

import Button from "./Button";

type FavoriteProps = {
   sku: string;
};

const Favorite = ({ sku }: FavoriteProps) => {
   const [show, setShow] = useState<boolean>(false);
   const isFavorite = favoriteStore.isFavorite(sku);
   const hasMultipleLists = size(favoriteStore.favorites) > 1;

   if (!authStore.isLoggedIn()) {
      return (
         <div className="favorite">
            <HeartIcon />
         </div>
      );
   }

   if (hasMultipleLists) {
      const lists = sortBy(values(favoriteStore.favorites), "name");
      const popover = (
         <Popover id="popover-basic" className="shadow">
            <Popover.Header as="h3">Velg liste</Popover.Header>
            <Popover.Body>
               <div className="list favorite-list-selector">
                  {lists.map((list) => {
                     const checked = typeof list.items[sku] === "number" && list.items[sku] > 0;
                     return (
                        <div key={list.id}>
                           <Form.Check
                              type="checkbox"
                              checked={checked}
                              onChange={() =>
                                 checked ? favoriteStore.removeFavorite(sku, list.id) : favoriteStore.addFavorite(sku, list.id)
                              }
                              value={list.listName}
                              label={list.listName}
                           />
                        </div>
                     );
                  })}
               </div>
               <div className="d-flex justify-content-center mt-2">
                  <Button variant="primary" size="sm" onClick={() => setShow(false)}>
                     OK
                  </Button>
               </div>
            </Popover.Body>
         </Popover>
      );

      const className = isFavorite ? "favorite marked" : "favorite";
      return (
         <OverlayTrigger
            trigger="click"
            rootClose={true}
            placement="auto"
            overlay={popover}
            show={show}
            onToggle={() => setShow(!show)}
         >
            <div className={className}>
               <HeartIcon />
            </div>
         </OverlayTrigger>
      );
   }

   if (isFavorite) {
      return (
         <div className="favorite marked" onClick={() => favoriteStore.removeFavorite(sku)}>
            <HeartIcon />
         </div>
      );
   }

   return (
      <div className="favorite" onClick={() => favoriteStore.addFavorite(sku)}>
         <HeartIcon />
      </div>
   );
};

export default view(Favorite);
