import { store } from "@risingstack/react-easy-state";
import { addMonths } from "date-fns";
import isNil from "lodash-es/isNil";

import { apiClient } from "../../common/apiClient";
import { AvailabilityError, BackendIssueError } from "../../common/errors";
import { sendPurchase } from "../../common/tracking";
import { M3Order, M3OrderListed, OrderFilter, OrderPlacementResponse } from "../../common/types/m3Types";
import {
   AsyncData,
   initializeWithDefaultData,
   setAsDataAvailable,
   setAsErrorOccured,
   setAsWaitingForData
} from "../../common/utils/asyncDataUtils";
import { formatISODate } from "../../common/utils/dateUtils";

import theme from "../../themes/theme";
import authStore from "../auth/authStore";
import cartStore from "../cartStore";
import deliveryDatesStore from "../deliveryDates/deliveryDatesStore";
import toastStore from "../toastStore";
import { fetchOrderDetailsFromM3, fetchOrderListFromM3 } from "./orderUtilsAndApi";

type DatesTuple = [Date, Date];

const defaultStart = addMonths(new Date(), -1);
const defaultEnd = addMonths(new Date(), 3);

type OrderStore = {
   orderList: AsyncData<M3OrderListed[]>;
   orderDetails: Record<string, AsyncData<M3Order | null>>;
   filters: OrderFilter;

   filterAsPickerDates(): DatesTuple;
   updateDateFilter(dates: DatesTuple): void;
   ensureOrderDetailsKeyAvailable(orderNumber: string): void;
   getOrderDetails(orderNumber: string): AsyncData<M3Order | null>;
   fetchOrderDetails(orderNumber: string): Promise<void>;
   updateOrderListUsingFilters(): Promise<void>;
   deleteOrder(orderNumber: string): Promise<void>;
   submitNewOrder(): Promise<string[]>;
   submitOrderChanges(): Promise<string[]>;
};

const orderStore: OrderStore = store({
   orderDetails: {},
   orderList: initializeWithDefaultData([]),
   filters: {
      orderNumber: "",
      orderName: "",
      dateRange: {
         from: defaultStart,
         to: defaultEnd
      },
      status: ""
   },

   filterAsPickerDates: (): DatesTuple => {
      const res = [orderStore.filters.dateRange.from, orderStore.filters.dateRange.to];
      return res.filter((date): date is Date => date !== null) as DatesTuple;
   },

   updateDateFilter: (dates) => {
      orderStore.filters.dateRange.from = dates[0];
      if (dates.length === 2) {
         orderStore.filters.dateRange.to = dates[1];
      } else {
         orderStore.filters.dateRange.to = null;
      }
   },

   ensureOrderDetailsKeyAvailable: (orderNumber) => {
      if (!(orderNumber in orderStore.orderDetails) || isNil(orderStore.orderDetails[orderNumber])) {
         orderStore.orderDetails[orderNumber] = initializeWithDefaultData(null);
      }
   },

   getOrderDetails: (orderNumber) => {
      orderStore.ensureOrderDetailsKeyAvailable(orderNumber);
      return orderStore.orderDetails[orderNumber];
   },

   fetchOrderDetails: async (orderNumber) => {
      const customerNumber = authStore.currentCompany;
      if (!authStore.isLoggedIn() || isNil(orderNumber) || isNil(customerNumber)) {
         console.warn("Order Details before logged in: ", orderNumber);
         return;
      }

      orderStore.ensureOrderDetailsKeyAvailable(orderNumber);

      const orderEntry = orderStore.orderDetails[orderNumber];
      setAsWaitingForData(orderEntry);

      try {
         const orderDetails = await fetchOrderDetailsFromM3(theme.m3CompanyNumber, customerNumber, orderNumber);

         if ("orderNumber" in orderDetails) {
            console.log("Fetched order details for order nubmer " + orderNumber, orderDetails);
            setAsDataAvailable(orderEntry, orderDetails);
         } else if ("Error_Code" in orderDetails) {
            console.warn("An error occured while fetching order: ", orderDetails.Error_Code);
            setAsErrorOccured(orderEntry, orderDetails.Response);
         }
      } catch (err) {
         console.warn("An error occured while fetching order: ", err);
         setAsErrorOccured(orderEntry, "" + err);
      }
   },

   deleteOrder: async (orderNumber) => {
      if (!authStore.isLoggedIn() || isNil(orderNumber)) {
         console.warn("Unable to delete order, order number is missing");
         return;
      }

      return apiClient(`${process.env.API_HOST}/api/${theme.tipApiPrefix}tip/API/customerOrder`, authStore.getSessionToken())
         .query({
            companyNumber: theme.m3CompanyNumber,
            customerNumber: authStore.currentCompany,
            orderNumber,
            typeOfOrder: "K"
         })
         .delete()
         .json((res) => {
            if (res.status !== true) {
               throw res.Error_Code;
            }
            void orderStore.fetchOrderDetails(orderNumber);
         });
   },

   updateOrderListUsingFilters: async () => {
      const customerNumber = authStore.currentCompany;
      if (!authStore.isLoggedIn() || isNil(customerNumber)) {
         console.log("Searching before logged in");
         return;
      }

      console.log("Fetching orders for customer " + customerNumber);

      setAsWaitingForData(orderStore.orderList);

      try {
         const orders: M3OrderListed[] = await fetchOrderListFromM3(customerNumber, orderStore.filters);
         console.log("fetchOrderListWithFilters: ", orders);
         setAsDataAvailable(orderStore.orderList, orders);
      } catch (err) {
         setAsErrorOccured(orderStore.orderList, "" + err);
      }
   },

   submitNewOrder: async () => {
      cartStore.sendingOrder = true;
      let response: OrderPlacementResponse[];
      try {
         const isAvailable = await cartStore.checkAvailability();

         if (!isAvailable) {
            console.log("Aborting order placement to resolve availability issues");
            throw new AvailabilityError();
         }

         const currentDelivery = deliveryDatesStore.getCurrentDelivery();
         if (isNil(currentDelivery)) {
            throw new BackendIssueError("Delivery day not available");
         }

         const orderLines = [
            ...cartStore.items.map((i) => ({
               sku: i.sku,
               quantity: i.qty,
               orderLineUnit: i.unit
            })),
            ...cartStore.lostSales
         ];

         const order = {
            companyNumber: theme.m3CompanyNumber,
            customerNumber: authStore.currentCompany,
            orderType: cartStore.orderType,
            requestedDeliveryDate: formatISODate(currentDelivery.date),
            estimatedOrderAmount: 2500,
            ...cartStore.orderRefs,
            orderLines
         };

         console.log("Starting order placement...");
         response = await apiClient(
            `${process.env.API_HOST}/api/${theme.tipApiPrefix}tip/API/customerOrder`,
            authStore.getSessionToken()
         )
            .query({
               optimizeOrderLines: true
            })
            .content("application/json")
            .post(order)
            .json();

         sendPurchase(
            response.map((o) => o.orderNumber),
            [...cartStore.items]
         );
         cartStore.lostSales = [];
         cartStore.resetOrderRefs();
         console.log("Order submit response:", response);
      } catch (err) {
         if (!(err instanceof AvailabilityError)) {
            console.warn("An unexpected error occured during availability check", err);
         }
         throw err;
      } finally {
         cartStore.sendingOrder = false;
      }

      cartStore.emptyCart();
      deliveryDatesStore.selectNextDeliveryDateForOrderType(cartStore.orderType);

      return response.map((o) => o.orderNumber);
   },

   submitOrderChanges: async () => {
      if (isNil(cartStore.editing) || isNil(cartStore.editing.orderNumber)) {
         console.warn("Cannot send order change when editing is nil");
         toastStore.addError("Uforutsett feil oppsto", "Vi greier ikke å sende inn ordren din.");
         return Promise.reject();
      }
      const isAvailable = await cartStore.checkAvailability();

      if (!isAvailable) {
         console.log("Aborting order placement to resolve availability issues");
         throw new AvailabilityError();
      }

      cartStore.sendingOrder = true;

      const payload = cartStore.generateChangeOrderPayload();

      if (payload === null) {
         cartStore.sendingOrder = false;
         return [cartStore.editing.orderNumber];
      }

      let response: OrderPlacementResponse[];
      try {
         response = await apiClient(
            `${process.env.API_HOST}/api/${theme.tipApiPrefix}tip/API/customerOrder`,
            authStore.getSessionToken()
         )
            .query({
               optimizeOrderLines: true
            })
            .content("application/json")
            .put(payload)
            .json();

         cartStore.lostSales = [];
         // Remove the cached order details so we are able to see the changes load in on the confirmation page
         delete orderStore.orderDetails[cartStore.editing.orderNumber];
         void cartStore.stopEditOrderMode();
      } catch (err) {
         console.warn("An error occured during order update", err);
         throw err;
      } finally {
         cartStore.sendingOrder = false;
      }

      return response.map((o) => o.orderNumber);
   }
} satisfies OrderStore);

export default orderStore;
