import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/24/solid";
import { view } from "@risingstack/react-easy-state";
import { useLayoutEffect } from "react";
import { Toast, ToastContainer } from "react-bootstrap";

import { ToastTheme, ToastType } from "../common/types/toastTypes";

import toastStore from "../stores/toastStore";

const toastThemes: Record<string, ToastTheme> = {
   success: {
      icon: CheckIcon,
      color: "white",
      background: "success"
   },
   error: {
      icon: ExclamationCircleIcon,
      color: "white",
      background: "danger"
   }
};

type SingleToastProps = {
   toast: ToastType;
};

const SingleToast = view(({ toast }: SingleToastProps) => {
   const Icon = toastThemes[toast.type].icon;
   const color = toastThemes[toast.type].color;
   const bg = toastThemes[toast.type].background;

   useLayoutEffect(() => {
      toast.show = true;
   }, []);

   return (
      <ToastContainer className="toast-container">
         <Toast
            autohide={toast.delay > 0}
            delay={toast.delay}
            show={toast.show}
            onClick={toast.onClick}
            onClose={() => (toast.show = false)}
            className="mb-3"
         >
            <Toast.Header className={`text-${color} bg-${bg}`} closeVariant="white">
               <Icon strokeWidth={2} width={18} className={`text-${color} me-2`} />
               <strong className="me-auto">{toast.title}</strong>
            </Toast.Header>
            <Toast.Body>{toast.message}</Toast.Body>
         </Toast>
      </ToastContainer>
   );
});

const ToastDisplay = () => (
   <ToastContainer className="position-fixed bottom-0 start-0 p-3">
      {toastStore.toasts.map((toast) => (
         <SingleToast key={toast.id} toast={toast} />
      ))}
   </ToastContainer>
);

export default view(ToastDisplay);
