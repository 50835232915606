import Bugsnag from "@bugsnag/js";
import { autoEffect, batch, store } from "@risingstack/react-easy-state";
import { parseISO } from "date-fns";
import { truncate } from "lodash-es";
import debounce from "lodash-es/debounce";
import defaultTo from "lodash-es/defaultTo";
import every from "lodash-es/every";
import find from "lodash-es/find";
import isNil from "lodash-es/isNil";
import isUndefined from "lodash-es/isUndefined";
import keyBy from "lodash-es/keyBy";
import pick from "lodash-es/pick";
import remove from "lodash-es/remove";
import sumBy from "lodash-es/sumBy";

import { apiClient } from "../common/apiClient";
import { sendMiniCartVisible } from "../common/tracking";
import {
   M3Order,
   M3SubscriptionListed,
   M3SubscriptionOptimizedOrderLine,
   M3SubscriptionRaw,
   SubscriptionStoreOrderItem
} from "../common/types/m3Types";
import {
   ChangeOrderPayload,
   EditMode,
   ORDER_UNIT,
   OrderChangeAction,
   OrderState,
   ORDERTYPE
} from "../common/types/productOrderTypes";
import { CartItem, CartItemDelta, CartTotals, LostSaleItem, ProductAvailability } from "../common/types/productTypes";
import { createActionsFromCartChanges, createRandomHex } from "../common/utils";
import { formatM3Date, parseM3Date } from "../common/utils/dateUtils";
import {
   getEditModeFromStorage,
   getOrdertypeFromStorage,
   setEditmodeInStorage,
   setOrderTypeInStorage
} from "../common/utils/storageUtils";

import theme from "../themes/theme";
import authStore from "./auth/authStore";
import loginState from "./auth/loginState";
import deliveryDatesStore from "./deliveryDates/deliveryDatesStore";
import deliveryFeeStore from "./deliveryFeeStore";
import orderStore from "./orders/orderStore";
import productStore from "./product/productStore";
import subscriptionStore from "./subscriptionStore";
import toastStore from "./toastStore";

type CartStore = {
   items: CartItem[];
   orderType: ORDERTYPE;
   miniCartVisible: boolean;
   availability: ProductAvailability[] | null;
   sendingOrder: boolean;
   cancelled: string[];
   editing: EditMode | null;
   orderRefs: {
      orderName: string;
      customerReference: string;
      customerOrderNumber: string;
   };
   lostSales: LostSaleItem[];

   getCartItem(sku: string): CartItem | undefined;
   resetOrderRefs(): void;
   deliveryDateForPicker(): Date[];
   getCartTotal(): CartTotals;
   startEditOrderMode(m3Order: M3Order): Promise<void>;
   startEditSubscriptionMode(subscription: M3SubscriptionRaw, instanceDate?: string, existingInstance?: boolean): Promise<void>;
   getValidQtyRange(sku: string): { min: number; max: number };
   isEditing(): boolean;
   isEditingInstance(): boolean;
   isAddedDuringEdit(sku: string): boolean;
   hasQtyChangedDuringEdit(sku: string): boolean;
   createCartItemDeltas(): CartItemDelta[];
   generateChangeOrderPayload(): ChangeOrderPayload | null;
   stopEditOrderMode(): Promise<string>;
   changeOrderType(newOrderType: ORDERTYPE): void;
   findExisitingSubscription(): M3SubscriptionListed[];
   emptyCart(): void;
   addToCart(sku: string, qty: number, delay?: number): Promise<void>;
   loadCartFromServer(): Promise<void>;
   saveCartToServer(): void;
   removeFromCart(sku: string): void;
   checkAvailability(): Promise<boolean>;
   applyAvailabilityChanges(): void;
   submitOrder(): Promise<string[]>;
   showMiniCart(): void;
   hideMiniCart(): void;
};

const cartStore: CartStore = store({
   items: [],
   orderType: getOrdertypeFromStorage(),
   miniCartVisible: false,
   availability: null,
   cancelled: [],
   sendingOrder: false,
   editing: getEditModeFromStorage(),
   orderRefs: {
      orderName: "",
      customerReference: "",
      customerOrderNumber: ""
   },
   lostSales: [],

   getCartItem: (sku) => {
      return find(cartStore.items, { sku });
   },

   resetOrderRefs: () => {
      cartStore.orderRefs = {
         orderName: "",
         customerReference: "",
         customerOrderNumber: ""
      };
   },

   deliveryDateForPicker: () => {
      const currentDelivery = deliveryDatesStore.getCurrentDelivery();
      if (isNil(currentDelivery)) {
         return [];
      }
      return [currentDelivery.date];
   },

   getCartTotal: () => {
      const skusInCart = cartStore.items.map((e) => e.sku);
      const productsIncart = productStore.resolveSkus(skusInCart);
      const productsBySku = keyBy(productsIncart, "sku");
      const productPrice = (sku: string): number => {
         const product = productsBySku[sku];
         return !isNil(product) && !isNil(product.price) ? product.price : 0;
      };

      return {
         total: sumBy(cartStore.items, (i) => i.qty * productPrice(i.sku)),
         recyclingCharge: sumBy(cartStore.items, (i) => {
            if (isUndefined(productsBySku[i.sku])) {
               return 0;
            }
            const item = productsBySku[i.sku];
            return (i.unit === ORDER_UNIT.D ? item.conversionFactor * i.qty : i.qty) * item.recyclingChargeBaseUnit;
         })
      } satisfies CartTotals;
   },

   startEditOrderMode: async (m3Order) => {
      if (cartStore.editing !== null) {
         console.warn("Something unexpected happened, we are trying to start editing an order while editing");
      }

      // Extract the neccesary data from the m3 order objekt from TIP API
      const orderNumber = m3Order.orderNumber;
      const deadline = m3Order.deadline;
      const orderItems = m3Order.optimizedOrderLines
         .map((ol) => pick(ol, ["sku", "combinedOrderLineNumbers", "canDecrease", "canIncrease", "canDelete", "totalQuantity"]))
         .map((ol) => ({
            ...ol,
            qty: ol.totalQuantity,
            combinedLines: ol.combinedOrderLineNumbers
         }));

      // Set up cart contents with the items from the order we are editing
      cartStore.emptyCart();
      orderItems.forEach((oi) => {
         cartStore.addToCart(oi.sku, oi.qty, 0);
      });

      await deliveryDatesStore.changeDeliveryDate(parseISO(m3Order.requestedDeliveryDate), cartStore.orderType);

      // Filling in the editing key turns the store over to "edit mode"
      cartStore.editing = {
         orderNumber,
         deadline,
         orderType: m3Order.orderType,
         canAddItems: m3Order.canAddOrderline,
         oldState: orderItems
      };

      setEditmodeInStorage(cartStore.editing);
   },

   startEditSubscriptionMode: async (subscription, instanceDate, existingInstance = false) => {
      if (cartStore.editing !== null) {
         console.warn("Something unexpected happened, we are trying to start editing an order while editing");
      }

      cartStore.changeOrderType(ORDERTYPE.WAS);
      let orderDetails: M3SubscriptionRaw;
      let changeToDate: Date;

      if (isNil(instanceDate)) {
         console.log("Starting edit of main subscription " + subscription.orderNumber);
         const nextDeliveryDate = deliveryDatesStore.getNextMatchingDeliveryDate(subscription.deliveryWeekdayNumber);
         if (isNil(nextDeliveryDate)) {
            toastStore.addError(
               "Det oppsto en feil",
               "Dessverre greier vi ikke å starte redigering av dette abonnementet pga en utfordring med leveringsdagene."
            );
            return;
         }

         changeToDate = nextDeliveryDate.date;
         orderDetails = subscription;
      } else {
         changeToDate = parseM3Date(instanceDate);
         if (existingInstance) {
            console.log("Starting edit of existing " + instanceDate + " instance of subscription " + subscription.orderNumber);
            orderDetails = await subscriptionStore.fetchInstanceOrder(subscription.orderNumber, instanceDate);
         } else {
            console.log("Starting creation of new " + instanceDate + " instance of subscription " + subscription.orderNumber);
            orderDetails = subscription;
         }
      }

      await deliveryDatesStore.changeDeliveryDate(changeToDate, ORDERTYPE.WAS);
      const currentDelivery = deliveryDatesStore.getCurrentDelivery();
      if (isNil(currentDelivery)) {
         toastStore.addError("Det oppsto en feil", "Det oppsto en feil etter endring av leveringsdag.");
         return;
      }
      currentDelivery.interval = parseInt(subscription.deliveryIntervalDays);

      const orderItems = orderDetails.optimizedOrderLines
         .map(
            (ol: M3SubscriptionOptimizedOrderLine): SubscriptionStoreOrderItem =>
               pick(ol, ["sku", "combinedOrderLineNumbers", "canDecrease", "canIncrease", "canDelete", "totalQuantity"])
         )
         .map((ol) => ({
            ...ol,
            qty: ol.totalQuantity,
            combinedLines: ol.combinedOrderLineNumbers
         }));

      // Set up cart contents with the items from the order we are editing
      cartStore.emptyCart();
      orderItems.forEach((oi: SubscriptionStoreOrderItem) => {
         cartStore.addToCart(oi.sku, defaultTo(oi.qty, 1), 0);
      });

      // Filling in the editing key turns the store over to "edit mode"
      cartStore.editing = {
         orderNumber: orderDetails.orderNumber,
         orderType: ORDERTYPE.WAS,
         instanceDate,
         existingInstance,
         // The difference between these two is the s at the end, unfortuantely the two APIs behave differently.
         canAddItems: defaultTo(orderDetails.canAddOrderline, orderDetails.canAddOrderlines) ?? false,
         oldState: orderItems
      };
      setEditmodeInStorage(cartStore.editing);
   },

   getValidQtyRange: (sku) => {
      let min = 1;
      let max = Number.MAX_SAFE_INTEGER;
      if (!cartStore.isEditing()) {
         return { min, max };
      }

      const item = find(cartStore.editing!.oldState, { sku });
      if (isUndefined(item)) {
         return { min, max };
      }

      if (!item.canIncrease) {
         max = item.qty;
      }

      if (!item.canDecrease) {
         min = item.qty;
      }

      return { min, max };
   },

   isEditing: () => {
      return cartStore.editing !== null;
   },

   isEditingInstance: () => {
      return !isNil(cartStore.editing) && !isNil(cartStore.editing.instanceDate);
   },

   isAddedDuringEdit: (sku) => {
      if (isNil(cartStore.editing)) {
         return true;
      }
      const previousOrder = find(cartStore.editing.oldState, { sku });
      return isNil(previousOrder);
   },

   hasQtyChangedDuringEdit: (sku) => {
      if (isNil(cartStore.editing)) {
         return false;
      }

      const previousOrder = find(cartStore.editing.oldState, { sku });
      if (isNil(previousOrder)) {
         // Product was not in previous order at all, so mark this as not a change
         return false;
      }

      const currentCart = cartStore.getCartItem(sku);
      if (isNil(currentCart)) {
         return false;
      }

      // Only true if the cart qty has changed
      return currentCart.qty !== previousOrder.qty;
   },
   createCartItemDeltas: () => {
      if (cartStore.editing) {
         const oldState: OrderState[] = cartStore.editing.oldState;
         const newAndChanged: CartItemDelta[] = cartStore.items
            .map((item) => {
               const orderLine = oldState.find((orderLine) => orderLine.sku === item.sku);
               if (orderLine) {
                  return { ...item, qtyDelta: item.qty - orderLine.qty };
               } else {
                  return { ...item, qtyDelta: item.qty };
               }
            })
            .filter((cartItemDelta) => cartItemDelta.qtyDelta !== 0);
         const removed: CartItemDelta[] = oldState
            .filter((orderLine) => !cartStore.items.find((item) => item.sku === orderLine.sku))
            .map((orderLine) => ({
               sku: orderLine.sku,
               qty: 0,
               qtyDelta: -orderLine.qty,
               unit: productStore.resolveSku(orderLine.sku)?.unit || ORDER_UNIT.D
            }));
         return [...newAndChanged, ...removed];
      }
      return [];
   },

   generateChangeOrderPayload: () => {
      if (isNil(cartStore.editing) || isNil(authStore.currentCompany) || isNil(cartStore.editing.orderNumber)) {
         return null;
      }

      const changedLines = cartStore.items.filter((ci) => cartStore.hasQtyChangedDuringEdit(ci.sku));
      console.log("Changed lines: ", changedLines);

      const addedLines = cartStore.items.filter((ci) => cartStore.isAddedDuringEdit(ci.sku));
      console.log("Added lines: ", addedLines);

      const removedOrderLines = cartStore.editing.oldState.filter((os) => isUndefined(find(cartStore.items, { sku: os.sku })));
      console.log("Removed order lines: ", removedOrderLines);

      if (removedOrderLines.length === 0 && changedLines.length === 0 && addedLines.length === 0) {
         return null;
      }

      const actions: OrderChangeAction[] = createActionsFromCartChanges(
         addedLines,
         changedLines,
         removedOrderLines,
         cartStore.editing.oldState
      );

      cartStore.lostSales.forEach((lostSale) => {
         actions.push({
            action: "add",
            lostSalesUniqueId: createRandomHex(10),
            ...lostSale
         });
      });

      return {
         companyNumber: theme.m3CompanyNumber,
         customerNumber: authStore.currentCompany,
         orderNumber: cartStore.editing.orderNumber,
         estimatedOrderAmount: 2500,
         orderLines: actions
      } satisfies ChangeOrderPayload;
   },

   stopEditOrderMode: async () => {
      if (isNil(cartStore.editing) || isNil(cartStore.orderType)) {
         console.warn("Wrong state to stop edit mode, editing: " + cartStore.editing + ", orderType: " + cartStore.orderType);
         return Promise.reject("Wrong state to stop edit mode");
      }
      const orderNumber = cartStore.editing.orderNumber;
      if (isNil(orderNumber)) {
         return Promise.reject("Unable to determine what order number was being edited");
      }

      cartStore.emptyCart();
      cartStore.editing = null;
      setEditmodeInStorage(cartStore.editing);

      deliveryDatesStore.selectNextDeliveryDateForOrderType(cartStore.orderType);

      return Promise.resolve(orderNumber);
   },

   changeOrderType: (newOrderType) => {
      console.log("ChangeOrderType DEBUG: " + loginState.currentState);
      if (!loginState.is("DELIVERY_DATES_LOADING") || loginState.is("LOGGED_IN")) {
         console.warn("Unexpected order type change?");
      }

      batch(() => {
         // const currentDelivery = deliveryDatesStore.getCurrentDelivery();
         cartStore.orderType = newOrderType;
         setOrderTypeInStorage(newOrderType);

         const deliveryForType = deliveryDatesStore.getCurrentDelivery(newOrderType);
         if (deliveryForType) {
            void deliveryDatesStore.changeDeliveryDate(deliveryForType.date, newOrderType);
         } else {
            deliveryDatesStore.selectNextDeliveryDateForOrderType(newOrderType);
         }
         // Find the next possible delivery date for this order type

         if (newOrderType === ORDERTYPE.WAS) {
            if (deliveryForType) {
               deliveryForType.interval = 7;
            }
         }
      });

      console.log("Selected delivery", deliveryDatesStore.getCurrentDelivery());
   },

   findExisitingSubscription: () => {
      if (cartStore.orderType !== ORDERTYPE.WAS || cartStore.isEditing()) {
         return [];
      }

      const currentDelivery = deliveryDatesStore.getCurrentDelivery();
      if (isNil(currentDelivery)) {
         return [];
      }
      return subscriptionStore.subscriptionsList.data.filter(
         (subscription) =>
            currentDelivery.interval === parseInt(subscription.deliveryIntervalDays) &&
            currentDelivery.date.getDay() === parseInt(subscription.deliveryWeekdayNumber)
      );
   },

   emptyCart: () => {
      cartStore.items = [];
      if (authStore.isLoggedIn()) {
         cartStore.saveCartToServer();
         deliveryFeeStore.recalculateDeliveryCharge();
      }
   },

   addToCart: (sku, qty, delay = 800) => {
      const cartItem = cartStore.getCartItem(sku);
      const alreadyInCart = !isUndefined(cartItem);

      const product = productStore.resolveSku(sku);
      if (isUndefined(product)) {
         console.warn("Tried to add unavailable product to cart, sku " + sku);
         return Promise.resolve();
      }
      const unit = product.unit;

      if (!isNil(cartStore.editing) && !cartStore.editing.canAddItems) {
         toastStore.addError(
            "Ordren har begrensninger",
            "Ordren som du redigerer har begrensninger som gjør at du ikke kan legge til nye varelinjer."
         );
         return Promise.reject();
      }

      if (delay === 0) {
         if (alreadyInCart) {
            cartItem.qty += qty;
         } else {
            cartStore.items.push({
               sku,
               qty,
               unit
            });
         }
         cartStore.saveCartToServer();
         deliveryFeeStore.recalculateDeliveryCharge();
         return Promise.resolve();
      }

      // Delay adding the product to cart to match animation
      setTimeout(() => {
         if (alreadyInCart) {
            cartItem.qty += qty;
         } else {
            cartStore.items.push({
               sku,
               qty,
               unit
            });
         }

         cartStore.saveCartToServer();
         deliveryFeeStore.recalculateDeliveryCharge();
      }, delay);
      return Promise.resolve();
   },

   loadCartFromServer: () => {
      if (isNil(authStore.currentCompany)) {
         console.warn("Attempted to load cart without being logged in");
         return Promise.reject();
      }

      return apiClient(`${process.env.API_HOST}/api/carts/${authStore.currentCompany}`, authStore.getSessionToken())
         .get()
         .json((cart) => {
            console.log("Loaded cart from server: ", cart);
            cartStore.items = cart;
            deliveryFeeStore.recalculateDeliveryCharge();
         });
   },

   saveCartToServer: debounce(() => {
      if (!authStore.isLoggedIn()) {
         console.warn("Attempted to save cart without being logged in");
         return;
      }

      apiClient(`${process.env.API_HOST}/api/carts/${authStore.currentCompany}`, authStore.getSessionToken())
         .post(cartStore.items)
         .json((res) => {
            if (res) {
               console.log("Remote cart has been updated");
            } else {
               console.warn("Something went wrong when storing cart");
            }
         })
         .catch((err) => {
            console.warn("Unable to store cart remotely", err);
         });
   }, 1000),

   removeFromCart: (sku) => {
      const cartItem = find(cartStore.items, { sku });
      if (isUndefined(cartItem)) {
         console.warn("Attempting to remove SKU that is not in the cart, bug?");
         return;
      }

      remove(cartStore.items, { sku });
      cartStore.saveCartToServer();
      deliveryFeeStore.recalculateDeliveryCharge();
   },

   checkAvailability: async () => {
      if (!theme.productAvailability.enabled) {
         console.log("Skipping product availability check since its disabled for this store");
         return true;
      }

      console.log("Starting availability check for cart items");
      let items: string;
      if (cartStore.editing) {
         items = cartStore
            .createCartItemDeltas()
            .filter((delta) => delta.qtyDelta > 0)
            .map((item) => `${item.sku}*${item.qtyDelta}`)
            .join("|");
      } else {
         items = cartStore.items.map((i) => i.sku + "*" + i.qty).join("|");
      }

      if (!items) {
         return true;
      }

      const currentDelivery = deliveryDatesStore.getCurrentDelivery();

      if (isNil(currentDelivery)) {
         console.warn("Unable to determine the current delivery date, failing out");
         return false;
      }

      const params = {
         customerNumber: authStore.currentCompany,
         m3OrderType: cartStore.orderType,
         requestedDeliveryDate: formatM3Date(currentDelivery.date),
         products: items
      };

      try {
         const resp: ProductAvailability[] = await apiClient(
            `${process.env.API_HOST}/api/${theme.tipApiPrefix}tip/API/productAvailability`,
            authStore.getSessionToken()
         )
            .query(params)
            .get()
            .json();

         console.log("Availability check returned", resp);

         if (!every(resp, (i) => i.available)) {
            cartStore.availability = resp
               .filter((i) => !i.available)
               .flatMap((a) => {
                  const cartItem = cartStore.getCartItem(a.productNumber);
                  if (isNil(cartItem)) {
                     // The use of .flatMap() allows us to skip an item by returning an empty array until .map()
                     return [] as ProductAvailability[];
                  }
                  return {
                     ...a,
                     selectedQuantity: a.availableQuantity,
                     requestedQuantity: cartItem.qty
                  } satisfies ProductAvailability;
               });

            cartStore.availability.forEach((availability) => {
               const product = productStore.resolveSku(availability.productNumber);
               if (product) {
                  const quantity = availability.partialAvailable
                     ? availability.requestedQuantity - availability.availableQuantity
                     : availability.requestedQuantity;
                  const existingLostSales = cartStore.lostSales.find((lostSale) => lostSale.sku === availability.productNumber);
                  if (existingLostSales) {
                     existingLostSales.quantity += quantity;
                  } else {
                     cartStore.lostSales.push({
                        quantity,
                        sku: availability.productNumber,
                        isLostSales: true as const,
                        lostSalesWareHouse: availability.supplyingWarehouse || availability.warehouse || "",
                        orderLineUnit: product.salesUnit
                     });
                  }
               }
            });

            if (cartStore.editing) {
               cartStore.availability.forEach((availability) => {
                  const oldState = cartStore.editing?.oldState.find((old) => old.sku === availability.productNumber);
                  if (oldState) {
                     availability.selectedQuantity += oldState.qty;
                     availability.availableQuantity += oldState.qty;
                     if (availability.availableQuantity) {
                        availability.partialAvailable = true;
                     }
                  }
               });
            }

            return false;
         }
      } catch (err) {
         console.warn("Unable to check product availablity, assuming its available...", err);
         return true;
      }

      cartStore.availability = null;
      return true;
   },

   applyAvailabilityChanges: () => {
      if (isNil(cartStore.availability)) {
         console.warn("Unable to apply availability, it's already null...");
         return;
      }

      cartStore.availability.forEach((item) => {
         const cartItem = cartStore.getCartItem(item.productNumber);

         if (item.selectedQuantity === 0 || isNil(cartItem)) {
            cartStore.removeFromCart(item.productNumber);
         } else {
            cartItem.qty = item.selectedQuantity;
         }
      });

      cartStore.availability = null;
   },

   submitOrder: async () => {
      // Subscription
      if (cartStore.orderType === "WAS") {
         // Changing one planned delivery of a subscription (subscription instance)
         if (cartStore.isEditingInstance()) {
            if (!isNil(cartStore.editing) && cartStore.editing.existingInstance) {
               return subscriptionStore.submitInstanceChanges();
            } else {
               return subscriptionStore.submitNewInstanceOrder();
            }
         }

         // Normal subscription
         if (cartStore.isEditing()) {
            return subscriptionStore.submitSubscriptionChanges();
         } else {
            return subscriptionStore.submitNewSubscription();
         }
      }

      // One-time order
      if (cartStore.orderType === ORDERTYPE.WEB || cartStore.orderType === ORDERTYPE.HPN) {
         return cartStore.isEditing() ? orderStore.submitOrderChanges() : orderStore.submitNewOrder();
      }

      toastStore.addError(
         "Ukjent ordretype aktiv",
         "Ordretypen som er valgt (" + cartStore.orderType + ") er ikke kjent, og vi får ikke plassert ordren."
      );
      Bugsnag.notify(new Error("Unknown order type [" + cartStore.orderType + "]"));
      return Promise.reject();
   },

   showMiniCart: () => {
      sendMiniCartVisible();
      cartStore.miniCartVisible = true;
   },

   hideMiniCart: () => {
      cartStore.miniCartVisible = false;
   }
});

autoEffect(() => {
   cartStore.orderRefs.customerOrderNumber = truncate(cartStore.orderRefs.customerOrderNumber, {
      length: 20,
      omission: ""
   });
   cartStore.orderRefs.customerReference = truncate(cartStore.orderRefs.customerReference, {
      length: 30,
      omission: ""
   });
   cartStore.orderRefs.orderName = truncate(cartStore.orderRefs.orderName, { length: 36, omission: "" });
});

export default cartStore;
