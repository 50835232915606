import { view } from "@risingstack/react-easy-state";
import isNil from "lodash-es/isNil";

import { Badge, BadgeKey, PageType } from "../common/types/productTypes";

type BadgeSizeAndFormat = "24_webp" | "24_jpg" | "40_webp" | "40_jpg";
type BadgesData = Record<BadgeKey, Record<BadgeSizeAndFormat, URL>>;

// These are hardcoded because it's neccesary for Parcel to be able to parse the image urls at build time and generate optimal images
const badgeData: BadgesData = {
   nyt_norge: {
      "24_webp": new URL("../../img/badges/nyt_norge.jpg?as=webp&width=24", import.meta.url),
      "24_jpg": new URL("../../img/badges/nyt_norge.jpg?as=jpg&width=24", import.meta.url),
      "40_webp": new URL("../../img/badges/nyt_norge.jpg?as=webp&width=40", import.meta.url),
      "40_jpg": new URL("../../img/badges/nyt_norge.jpg?as=jpg&width=40", import.meta.url)
   },
   nokkelhullet: {
      "24_webp": new URL("../../img/badges/nokkelhullet.png?as=webp&width=24", import.meta.url),
      "24_jpg": new URL("../../img/badges/nokkelhullet.png?as=jpg&width=24", import.meta.url),
      "40_webp": new URL("../../img/badges/nokkelhullet.png?as=webp&width=40", import.meta.url),
      "40_jpg": new URL("../../img/badges/nokkelhullet.png?as=jpg&width=40", import.meta.url)
   },
   pant_2kr: {
      "24_webp": new URL("../../img/badges/pant_2kr.jpg?as=webp&width=24", import.meta.url),
      "24_jpg": new URL("../../img/badges/pant_2kr.jpg?as=jpg&width=24", import.meta.url),
      "40_webp": new URL("../../img/badges/pant_2kr.jpg?as=webp&width=40", import.meta.url),
      "40_jpg": new URL("../../img/badges/pant_2kr.jpg?as=jpg&width=40", import.meta.url)
   },
   pant_3kr: {
      "24_webp": new URL("../../img/badges/pant_3kr.jpg?as=webp&width=24", import.meta.url),
      "24_jpg": new URL("../../img/badges/pant_3kr.jpg?as=jpg&width=24", import.meta.url),
      "40_webp": new URL("../../img/badges/pant_3kr.jpg?as=webp&width=40", import.meta.url),
      "40_jpg": new URL("../../img/badges/pant_3kr.jpg?as=jpg&width=40", import.meta.url)
   }
};

type BadgesProps = {
   badges: Badge[];
   page: PageType;
};

const Badges = ({ badges, page }: BadgesProps) => {
   if (isNil(badges) || badges.length === 0) {
      return null;
   }

   const size = page === PageType.ProductDetailsPage ? 40 : 24;
   const visibleBadges = badges.slice(0, 2); // Show max 2 badges

   return (
      <div className="badges">
         {visibleBadges.map((b, i) => {
            const badge = badgeData[b.key];
            return (
               <picture key={b.key}>
                  <source srcSet={badge[`${size}_webp`].toString()} type="image/webp" />
                  <source srcSet={badge[`${size}_jpg`].toString()} type="image/jpeg" />
                  <img className="w-100 mb-2" src={badge[`${size}_jpg`].toString()} width={size} alt={b.name} />
               </picture>
            );
         })}
      </div>
   );
};

export default view(Badges);
