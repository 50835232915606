import { XCircleIcon } from "@heroicons/react/24/outline";
import { view } from "@risingstack/react-easy-state";
import { get } from "lodash-es";
import { Modal } from "react-bootstrap";

import useMediaQuery from "../../common/useMediaQuery";
import useQuery from "../../common/useQuery";
import { mobileBreakpoint } from "../../common/utils";

import loginState from "../../stores/auth/loginState";
import uiStore, { LOGIN_MODE } from "../../stores/uiStore";

import ButtonPlain from "../ButtonPlain";
import LoginSms from "./LoginSms";
import LoginUsername from "./LoginUsername";
import ResetPassword from "./reset-password/ResetPassword";

const LoginModal = () => {
   const isMobile = useMediaQuery(mobileBreakpoint);
   const queryParams = useQuery();

   const isNewAccount = get(queryParams, "newaccount", "") === "true";
   const showModal = loginState.is("LOGIN_MODAL") || loginState.is("CHECKING_LOGIN");

   const hideModal = () => {
      uiStore.hideLoginModal();
   };

   return (
      <Modal
         contentClassName="login-container"
         centered
         show={showModal}
         onHide={hideModal}
         fullscreen={isMobile ? true : undefined}
      >
         <Modal.Body className="d-flex flex-column align-items-end">
            <ButtonPlain className="w-fit ms-auto" onClick={hideModal}>
               <XCircleIcon />
            </ButtonPlain>
            {uiStore.loginMode === LOGIN_MODE.USERNAME && <LoginUsername />}
            {uiStore.loginMode === LOGIN_MODE.SMS && <LoginSms />}
            {(uiStore.loginMode === LOGIN_MODE.RESET_PASSWORD_EMAIL ||
               uiStore.loginMode === LOGIN_MODE.RESET_PASSWORD_OTP ||
               uiStore.loginMode === LOGIN_MODE.RESET_PASSWORD_SUCCESS) && (
               <ResetPassword title={isNewAccount ? "Sett passord" : "Glemt passord?"} showBackToLogInText={!isNewAccount} />
            )}
         </Modal.Body>
      </Modal>
   );
};

export default view(LoginModal);
