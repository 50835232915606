import { XMarkIcon } from "@heroicons/react/24/outline";
import { view } from "@risingstack/react-easy-state";
import debounce from "lodash-es/debounce";
import { ChangeEventHandler, KeyboardEventHandler, useEffect, useRef } from "react";
import { Col, Overlay, Popover, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useLocation } from "wouter";

import { SupportedLocaleType } from "../../common/types/languageTypes";
import { SEARCH_TYPE } from "../../common/types/searchTypes";
import useMediaQuery from "../../common/useMediaQuery";
import useOutsideClick from "../../common/useOutsideClick";
import { bootstrapMediaQueries, focusableElementCssSelector } from "../../common/utils";
import { getMaxHitsForSearchDomain } from "../../common/utils/searchUtils";
import useSupportedLocale from "../../common/utils/useSupportedLocale";

import searchStore from "../../stores/searchStore";

import theme from "../../themes/theme";
import HitsForCmsContent from "./HitsForCmsContent";
import HitsForProduct from "./HitsForProduct";

const hide = () => searchStore.clear(SEARCH_TYPE.QuickSearch, true);

const performQuickSearch = debounce(
   (locale: SupportedLocaleType) => searchStore.performSearches(SEARCH_TYPE.QuickSearch, locale),
   85
);

const searchDomains = theme.searchConfig.searchDomains;

const SearchField = () => {
   const [location, redirectTo] = useLocation();
   const quickSearchRef = useRef<HTMLDivElement>(null);
   const searchFieldRef = useRef<HTMLInputElement>(null);
   const locale = useSupportedLocale();
   const isXl = useMediaQuery(bootstrapMediaQueries.xl);
   const isLg = useMediaQuery(bootstrapMediaQueries.lg);

   const results = searchStore[SEARCH_TYPE.QuickSearch].results;

   const totalHits: number = results.reduce((total, result) => total + result.hits.length, 0);

   const hasHits: boolean = !!totalHits;

   const resultsWithHits = results.filter((result) => result.hits.length);

   const hasHitsForMultipleNonProductDomains =
      resultsWithHits.length > 2 ||
      (resultsWithHits.length === 2 && !resultsWithHits.find((result) => result.searchDomainType === "product"));

   // Pressing Enter in the search field takes you to the search result page
   const handleKeys: KeyboardEventHandler = (e) => {
      if (e.key === "Enter") {
         redirectTo("/search?q=" + searchStore.query);
         hide();
      }
   };

   const handleBlur = (e: React.FocusEvent<HTMLElement>) => {
      const { relatedTarget } = e;
      const quickSearchElement = quickSearchRef.current;
      const searchFieldElement = searchFieldRef.current;
      if (quickSearchElement && searchFieldElement && relatedTarget) {
         if (!quickSearchElement.contains(relatedTarget)) {
            searchFieldRef.current.focus();
         }
      }
   };

   const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.code === "Tab" && !e.shiftKey) {
         const quickSearchElement = quickSearchRef.current;
         if (quickSearchElement && !quickSearchElement.classList.contains("invisible")) {
            {
               const firstLink = quickSearchElement.querySelector(focusableElementCssSelector) as HTMLElement;
               if (firstLink) {
                  e.preventDefault();
                  firstLink.focus();
               }
            }
         }
      }
   };

   // Update the quick search as long as you are not on the search result page
   const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      searchStore.query = e.target.value;
      performQuickSearch(locale);
   };

   // Clear the search field if you navigate to a new page, except for the search result page
   useEffect(() => {
      if (location !== "/search") {
         hide();
      }
   }, [location]);

   useOutsideClick([searchFieldRef, quickSearchRef], () => {
      hide();
   });

   return (
      <div>
         <Form.Control
            className="w-100"
            value={searchStore.query}
            ref={searchFieldRef}
            placeholder={"Søk i hele butikken"}
            name="search"
            autoComplete="off"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onKeyPress={handleKeys}
         />
         <Overlay target={searchFieldRef.current} show={hasHits} placement="bottom">
            <Popover className="quick-search">
               <div
                  id="quick-search-results"
                  ref={quickSearchRef}
                  onBlur={handleBlur}
                  className={`d-flex flex-column gap-5 ${!hasHits ? "invisible" : ""}`}
               >
                  <button
                     onClick={() => hide()}
                     className="btn quick-search__close-btn"
                     aria-label="lukk"
                     aria-controls="quick-search-results"
                  >
                     <XMarkIcon />
                  </button>
                  <Row className="g-md-3">
                     {resultsWithHits.map((results) => {
                        const searchDomain = searchDomains.find((searchDomain) => searchDomain.type === results.searchDomainType);
                        if (searchDomain) {
                           if (results.searchDomainType === "product") {
                              return (
                                 <Col md={12} key={searchDomain.name}>
                                    <HitsForProduct
                                       searchDomain={searchDomain}
                                       maxHits={getMaxHitsForSearchDomain(results.searchDomainType, resultsWithHits, isLg, isXl)}
                                    />
                                 </Col>
                              );
                           }
                           return (
                              <Col lg={hasHitsForMultipleNonProductDomains ? 6 : 12} key={searchDomain.name}>
                                 <HitsForCmsContent
                                    searchDomain={searchDomain}
                                    maxHits={getMaxHitsForSearchDomain(results.searchDomainType, resultsWithHits, isLg, isXl)}
                                    isNarrow={hasHitsForMultipleNonProductDomains}
                                 />
                              </Col>
                           );
                        }
                     })}
                  </Row>
               </div>
            </Popover>
         </Overlay>
      </div>
   );
};

export default view(SearchField);
