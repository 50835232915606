import { Children, ReactNode } from "react";
import { Row } from "react-bootstrap";

import useMediaQuery from "../../common/useMediaQuery";
import { bootstrapMediaQueries } from "../../common/utils";

type TeaserListLayoutProps = {
   children: ReactNode;
   id: string;
};

type TeaserListLayoutRowProps = {
   nodes: ReactNode[];
};

const TeaserListLayoutRow = ({ nodes }: TeaserListLayoutRowProps) => (
   <Row className="mb-3" xs={1} sm={2} md={nodes.length < 2 ? 2 : nodes.length}>
      {nodes}
   </Row>
);

const rowLength = (count: number, isSm: boolean, isMd: boolean, isLg: boolean) => {
   if (!isSm) {
      return 1;
   }
   if (!isMd) {
      return 2;
   }
   if (!isLg) {
      return 3;
   }
   return count % 3 || !(count % 4) ? 4 : 3;
};

const TeaserListLayout = ({ children, id }: TeaserListLayoutProps) => {
   const isSm = useMediaQuery(bootstrapMediaQueries.sm);
   const isMd = useMediaQuery(bootstrapMediaQueries.md);
   const isLg = useMediaQuery(bootstrapMediaQueries.lg);
   const nodes = Children.map(children, (child) => child);
   const count = nodes?.length || 0;
   const defaultRowLength = rowLength(count, isSm, isMd, isLg);

   let currentRow: ReactNode[] = [];
   const nodeRows: ReactNode[][] = [];
   if (defaultRowLength > 2) {
      nodes?.reverse().forEach((node) => {
         if (currentRow.length === defaultRowLength) {
            nodeRows.unshift(currentRow);
            currentRow = [];
         }
         currentRow.unshift(node);
      });
      if (currentRow.length) {
         nodeRows.unshift(currentRow);
      }
   } else {
      nodes?.forEach((node) => {
         if (currentRow.length === defaultRowLength) {
            nodeRows.push(currentRow);
            currentRow = [];
         }
         currentRow.push(node);
      });
      if (currentRow.length) {
         nodeRows.push(currentRow);
      }
   }

   if (defaultRowLength > 2 && nodeRows.length > 1 && nodeRows[0].length === 1) {
      nodeRows[0].push(nodeRows[1].shift());
   }

   return (
      <>
         {nodeRows.map((nodeRow, i) => (
            <TeaserListLayoutRow nodes={nodeRow} key={`teaser-list-layout-row-${id}-${i}`} />
         ))}
      </>
   );
};

export default TeaserListLayout;
