import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { view } from "@risingstack/react-easy-state";
import { Entry } from "contentful";
import { Row } from "react-bootstrap";

import { TypeCallToActionListSkeleton, TypeRecipeSkeleton, TypeV2_articleSkeleton } from "../../common/types/contentful";

import segmentStore from "../../stores/cms/segmentStore";

import CallToAction from "./CallToAction";
import RecipeTeaser from "./recipe/RecipeTeaser";
import TeaserListLayout from "./TeaserListLayout";

type TeaserListProps = {
   content: Entry<TypeCallToActionListSkeleton>;
};

const TeaserList = ({ content }: TeaserListProps) => {
   if (content.fields.contentList) {
      const contentList = content.fields.contentList as Entry<TypeV2_articleSkeleton | TypeRecipeSkeleton, undefined, string>[];
      const filteredContentList = contentList.filter((content) => {
         if (content.sys.contentType.sys.id === "v2_article") {
            return segmentStore.isArticleVisible(content.sys.id);
         }
         return true;
      });
      const title = content.fields.title as string | undefined;
      return (
         <div>
            {title && (
               <Row className="mb-3">
                  <h2>{title}</h2>
               </Row>
            )}
            <TeaserListLayout id={content.sys.id}>
               {filteredContentList.map((callToAction) => {
                  if (callToAction.sys.contentType.sys.id === "v2_article") {
                     return (
                        <CallToAction
                           key={"cta-" + callToAction.sys.id}
                           content={callToAction as Entry<TypeV2_articleSkeleton, undefined, string>}
                        />
                     );
                  } else if (callToAction.sys.contentType.sys.id === "recipe") {
                     return (
                        <RecipeTeaser
                           key={`recipe-teaser-${callToAction.sys.id}`}
                           content={callToAction as Entry<TypeRecipeSkeleton, undefined, string>}
                        />
                     );
                  } else {
                     return null;
                  }
               })}
            </TeaserListLayout>
         </div>
      );
   } else {
      return (
         <>
            <InformationCircleIcon /> Innholder dukker opp når alle feltene er fylt ut
         </>
      );
   }
};

export default view(TeaserList);
