import { UserIcon } from "@heroicons/react/24/outline";
import { view } from "@risingstack/react-easy-state";
import { useState } from "react";
import { useLocation } from "wouter";

import authStore from "../../../stores/auth/authStore";
import uiStore, { LOGIN_MODE } from "../../../stores/uiStore";

import ButtonPlain from "../../ButtonPlain";
import InputEmail from "./InputEmail";
import InputOtp from "./InputOtp";
import ResetSuccess from "./ResetSuccess";

const ResetPassword = ({ title, showBackToLogInText = true }: { title: string; showBackToLogInText?: boolean }) => {
   const [_, redirectTo] = useLocation();
   const [email, setEmail] = useState<string>("");

   const abortResetPassword = () => {
      uiStore.switchLoginMode(LOGIN_MODE.USERNAME);
      authStore.clearResetPassword();
      redirectTo("/");
   };

   const handleEmail = (email: string) => {
      setEmail(email);
   };

   return (
      <div className="login-content d-flex flex-column">
         <h1 className="h2 text-center mx-5 mb-4">
            <b>{title}</b>
         </h1>
         {uiStore.loginMode === LOGIN_MODE.RESET_PASSWORD_EMAIL && <InputEmail onSuccess={handleEmail} />}
         {uiStore.loginMode === LOGIN_MODE.RESET_PASSWORD_OTP && <InputOtp email={email} />}
         {uiStore.loginMode === LOGIN_MODE.RESET_PASSWORD_SUCCESS && <ResetSuccess />}
         {(showBackToLogInText || uiStore.loginMode === LOGIN_MODE.RESET_PASSWORD_SUCCESS) && (
            <ButtonPlain className="d-flex align-items-center align-self-center mb-4" onClick={abortResetPassword}>
               <UserIcon />
               <p className="mb-0 small">
                  <b>Tilbake til innlogging</b>
               </p>
            </ButtonPlain>
         )}
      </div>
   );
};

export default view(ResetPassword);
