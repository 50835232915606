import { view } from "@risingstack/react-easy-state";

import uiStore from "../../stores/uiStore";

import Button from "../Button";
import TranslationText from "../TranslationText";

const LoginButton = () => {
   return (
      <Button className="py-2 me-3 tine-primary-button" onClick={uiStore.showLoginModal}>
         <TranslationText textKey="Login.LoginButton" defaultText="Logg inn" />
      </Button>
   );
};

export default view(LoginButton);
