import { view } from "@risingstack/react-easy-state";
import cn from "classnames";
import isNil from "lodash-es/isNil";
import { FormEvent, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import isEmail from "validator/lib/isEmail";

import { isErrorOccured, isWaitingForData } from "../../../common/utils/asyncDataUtils";

import authStore from "../../../stores/auth/authStore";
import uiStore, { LOGIN_MODE } from "../../../stores/uiStore";

import Button from "../../Button";
import ErrorMessage from "../../ErrorMessage";
import Loading from "../../Loading";

type InputEmailProps = {
   onSuccess: { (email: string): void };
};

const InputEmail = ({ onSuccess }: InputEmailProps) => {
   const [email, setEmail] = useState<string>("");
   const [invalidEmail, setInvalidEmail] = useState<boolean>(false);
   const codeSent = !isNil(authStore.resetPasswordId.data);

   useEffect(() => {
      if (codeSent) {
         uiStore.switchLoginMode(LOGIN_MODE.RESET_PASSWORD_OTP);
      }
   }, [codeSent]);

   const onSubmit = async (event: FormEvent) => {
      event.preventDefault();
      setInvalidEmail(false);
      if (email.length === 0 || !isEmail(email)) {
         setInvalidEmail(true);
      } else {
         onSuccess(email);
         await authStore.requestResetPasswordOtp(email);
      }
   };

   return (
      <>
         <p className="text-center mb-3">Fyll inn e-postadresse for å motta engangskode over e-post.</p>
         <Form noValidate onSubmit={onSubmit} className="d-flex flex-column">
            <Form.Group className="mb-2">
               <Form.Label className="small">E-postadresse</Form.Label>
               <Form.Control
                  className={cn("tine-input-field", { "is-invalid": invalidEmail })}
                  type="email"
                  value={email}
                  onChange={(event) => {
                     setEmail(event.target.value);
                  }}
                  autoFocus
                  isInvalid={invalidEmail}
                  disabled={isWaitingForData(authStore.resetPasswordId)}
               />
               <Form.Control.Feedback type="invalid">E-postadressen er ikke gyldig.</Form.Control.Feedback>
            </Form.Group>
            {isErrorOccured(authStore.resetPasswordId) && !invalidEmail && (
               <ErrorMessage className="mb-2 small" message="Vi fant ingen bruker med denne e-postadressen" />
            )}
            <Button
               variant="primary"
               size="sm"
               className="tine-primary-button mb-4 mt-3 align-self-center w-fit"
               type="submit"
               disabled={isWaitingForData(authStore.resetPasswordId)}
            >
               {isWaitingForData(authStore.resetPasswordId) ? <Loading visible simple className="fw-100" /> : "Send engangskode"}
            </Button>
         </Form>
      </>
   );
};

export default view(InputEmail);
