import { store } from "@risingstack/react-easy-state";
import { Entry } from "contentful";

import { Company } from "../../common/types/companyTypes";
import { TypeV2_articleSkeleton } from "../../common/types/contentful";
import { convertEntriesToSegment, getEntryVisibility, keepIdOnly } from "../../common/utils/segmentUtils";

import authStore from "../auth/authStore";

export type CustomerSegment = {
   customerNumber: string[];
   chains: string[];
   customerGroups: string[];
};
export type ContentVisibility = "visible" | "requiresLogin" | "hidden";

type SegmentStore = {
   currentSegment: CustomerSegment;
   articleSegments: {
      [id: string]: {
         include?: CustomerSegment;
         exclude?: CustomerSegment;
      };
   };
   isArticleVisible: (articleId: string) => boolean;
   getArticleVisibility: (articleId: string) => ContentVisibility;
   updateCurrentSegment: (company: Company) => void;
   setArticleSegments: (articles: Entry<TypeV2_articleSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>[]) => void;
   clearCurrentSegment: () => void;
};

const segmentStore: SegmentStore = store({
   currentSegment: {
      customerNumber: [],
      chains: [],
      customerGroups: []
   },
   articleSegments: {},

   isArticleVisible: (articleId: string) => {
      const currentSegment = segmentStore.currentSegment;
      const article = segmentStore.articleSegments[articleId];
      if (!article) {
         return false;
      }
      const isLoggedIn = authStore.isLoggedIn();
      return getEntryVisibility(currentSegment, article, isLoggedIn) === "visible";
   },
   getArticleVisibility: (articleId: string): ContentVisibility => {
      const currentSegment = segmentStore.currentSegment;
      const article = segmentStore.articleSegments[articleId];
      if (!article) {
         return "hidden";
      }
      const isLoggedIn = authStore.isLoggedIn();
      return getEntryVisibility(currentSegment, article, isLoggedIn);
   },

   updateCurrentSegment: (company) => {
      segmentStore.currentSegment = {
         customerNumber: [company.customerNumber],
         chains: [
            keepIdOnly(company.businessLevels.level1),
            keepIdOnly(company.businessLevels.level2),
            keepIdOnly(company.businessLevels.level3),
            keepIdOnly(company.businessLevels.level4)
         ],
         customerGroups: [keepIdOnly(company.customerGroup)]
      };
      console.log("Current segment is: ", segmentStore.currentSegment);
   },

   setArticleSegments: (articles) => {
      articles.forEach((article) => {
         const include = article.fields.includeSegments && convertEntriesToSegment(article.fields.includeSegments);
         const exclude = article.fields.excludeSegments && convertEntriesToSegment(article.fields.excludeSegments);
         segmentStore.articleSegments[article.sys.id] = { include, exclude };
      });
   },
   clearCurrentSegment: () => {
      segmentStore.currentSegment = {
         customerNumber: [],
         chains: [],
         customerGroups: []
      };
   }
});

export default segmentStore;
