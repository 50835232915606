import { view } from "@risingstack/react-easy-state";
import groupBy from "lodash-es/groupBy";
import includes from "lodash-es/includes";
import isNil from "lodash-es/isNil";
import map from "lodash-es/map";
import size from "lodash-es/size";

import { Label, PageType } from "../common/types/productTypes";

const LabelComponent = ({ label }: { label: Label }) => {
   const Icon = label.icon as React.ElementType;
   return (
      <div className={`label label-${label.variant}`}>
         {!isNil(label.icon) ? <Icon className="nudge-up" /> : null} {label.text}
      </div>
   );
};

interface Props {
   labels: Label[];
   sku: string;
   page: PageType;
   className?: string;
}

const Labels = ({ labels, page, className = "", sku }: Props) => {
   //TODO: Create labels based on voucher/campaigns
   const voucherLabels: Label[] = [];
   labels = [...labels, ...voucherLabels];
   const relevantLabels = groupBy(
      labels.filter((l) => includes(l.page, page)),
      "position"
   );

   if (size(relevantLabels) === 0) {
      return null;
   }

   return (
      <>
         {map(relevantLabels, (labels, position) => (
            <div key={position} className={`labels d-flex flex-column ${position} ${className}`}>
               {labels.map((label, index) => (
                  <LabelComponent key={index} label={label} />
               ))}
            </div>
         ))}
      </>
   );
};

export default view(Labels);
