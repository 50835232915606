import { ArrowPathIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import { view } from "@risingstack/react-easy-state";
import isNil from "lodash-es/isNil";
import { Modal } from "react-bootstrap";

import { formatDate } from "../common/utils/dateUtils";

import loginState from "../stores/auth/loginState";
import cartStore from "../stores/cartStore";
import deliveryDatesStore from "../stores/deliveryDates/deliveryDatesStore";

type IconIndicatorProps = {
   target: number;
   progress: number;
};

const IconIndicator = view(({ target, progress }: IconIndicatorProps) => {
   if (progress === target) {
      return <ArrowPathIcon className="me-2 spin" />;
   } else if (progress > target) {
      return <CheckCircleIcon className="me-2" color="green" />;
   } else {
      return <div className="icon-placeholder">&nbsp;</div>;
   }
});

type ProgressLineProps = {
   step: number;
   label: string;
   progress: number;
};

const ProgressLine = view(({ step, label, progress }: ProgressLineProps) => {
   return (
      <div className="d-flex align-items-center mb-1">
         <div>
            <IconIndicator target={step} progress={progress} />
         </div>
         <div>{label}</div>
      </div>
   );
});

const LoginProgress = () => {
   let assortmentText = "Henter deres produkter og priser";
   if (!isNil(deliveryDatesStore.getCurrentDelivery()) && !isNil(cartStore.orderType)) {
      assortmentText += " for ";
      assortmentText += formatDate(deliveryDatesStore.getCurrentDelivery()?.date, true);
   }

   let progress = 0;
   switch (loginState.currentState) {
      case "VERIFY_SESSION":
         progress = 1;
         break;
      case "LOGGING_IN":
         progress = 2;
         break;
      case "COMPANY_SELECTED":
         progress = 2;
         break;
      case "FEATURES_LOADING":
         progress = 3;
         break;
      case "DELIVERY_DATES_LOADING":
         progress = 4;
         break;
      case "ASSORTMENT_LOADING":
         progress = 4;
         break;
      case "NOTIFICATIONS_LOADING":
         progress = 5;
         break;
   }

   return (
      <Modal className="login-progress" show={progress != 0} backdrop="static" size="lg" keyboard={false}>
         <Modal.Header>Gjør klar butikken...</Modal.Header>
         <Modal.Body>
            <ProgressLine step={1} progress={progress} label="Verifiserer innlogging" />
            <ProgressLine step={2} progress={progress} label="Henter info om kundeforholdet" />
            <ProgressLine step={3} progress={progress} label="Skrur på korrekte ordretyper" />
            <ProgressLine step={4} progress={progress} label={assortmentText} />
            <ProgressLine step={5} progress={progress} label="Henter eventuelle varslinger" />
         </Modal.Body>
      </Modal>
   );
};

export default view(LoginProgress);
