function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
import { EntityStore } from './EntityStore';
export var PostMessageMethods;
(function(PostMessageMethods) {
    PostMessageMethods["REQUEST_ENTITIES"] = "REQUEST_ENTITIES";
    PostMessageMethods["REQUESTED_ENTITIES"] = "REQUESTED_ENTITIES";
})(PostMessageMethods || (PostMessageMethods = {}));
/**
 * EntityStore which resolves entries and assets from the editor
 * over the sendMessage and subscribe functions.
 */ export class EditorEntityStore extends EntityStore {
    cleanupPromise(referenceId) {
        setTimeout(()=>{
            this.requestCache.delete(referenceId);
        }, 300);
    }
    getCacheId(id) {
        return id.length === 1 ? id[0] : id.join(this.cacheIdSeperator);
    }
    async fetchEntity(type, ids) {
        const { missing, resolved } = this.getEntitiesFromMap(type, ids);
        if (missing.length === 0) {
            // everything is already in cache
            return resolved;
        }
        const cacheId = this.getCacheId(missing);
        const openRequest = this.requestCache.get(cacheId);
        if (openRequest) {
            return openRequest;
        }
        const newPromise = new Promise((resolve, reject)=>{
            const unsubscribe = this.subscribe("REQUESTED_ENTITIES", (message)=>{
                if (missing.every((id)=>message.entities.find((entity)=>entity.sys.id === id))) {
                    clearTimeout(timeout);
                    resolve(message.entities);
                    this.cleanupPromise(cacheId);
                    ids.forEach((id)=>this.cleanupPromise(id));
                    unsubscribe();
                }
            });
            const timeout = setTimeout(()=>{
                reject(new Error('Request for entities timed out'));
                unsubscribe();
            }, this.timeoutDuration);
            this.sendMessage("REQUEST_ENTITIES", {
                entityIds: missing,
                entityType: type,
                locale: this.locale
            });
        });
        this.requestCache.set(cacheId, newPromise);
        ids.forEach((cid)=>{
            this.requestCache.set(cid, newPromise);
        });
        const result = await newPromise;
        result.forEach((value)=>{
            this.addEntity(value);
        });
        return this.getEntitiesFromMap(type, ids).resolved;
    }
    async fetchAsset(id) {
        try {
            return (await this.fetchAssets([
                id
            ]))[0];
        } catch (err) {
            // TODO: move to debug utils once it is extracted
            console.warn(`Failed to request asset ${id}`);
            return undefined;
        }
    }
    fetchAssets(ids) {
        return this.fetchEntity('Asset', ids);
    }
    async fetchEntry(id) {
        try {
            return (await this.fetchEntries([
                id
            ]))[0];
        } catch (err) {
            // TODO: move to debug utils once it is extracted
            console.warn(`Failed to request entry ${id}`, err);
            return undefined;
        }
    }
    fetchEntries(ids) {
        return this.fetchEntity('Entry', ids);
    }
    constructor({ entities, locale, sendMessage, subscribe, timeoutDuration = 3000 }){
        super({
            entities,
            locale
        });
        _define_property(this, "requestCache", new Map());
        _define_property(this, "sendMessage", void 0);
        _define_property(this, "subscribe", void 0);
        _define_property(this, "timeoutDuration", void 0);
        _define_property(this, "cacheIdSeperator", ',');
        this.sendMessage = sendMessage;
        this.subscribe = subscribe;
        this.timeoutDuration = timeoutDuration;
    }
}
