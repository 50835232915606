import { defaultTo, find, isArray, isNaN, isNil, split, trim } from "lodash-es";
import isString from "lodash-es/isString";

import { NextGenUser } from "./types/accessTypes";
import { BootstrapBreakpointName, BootstrapBreakpointValues } from "./types/bootstrapTypes";
import { ORDER_UNIT, OrderChangeAction, OrderState, ORDERTYPE } from "./types/productOrderTypes";
import { CartItem } from "./types/productTypes";
import { generateSubscriptionName } from "./utils/dateUtils";

export const productImageUrl = (sku: string, size: number = 320): string =>
   `https://res.cloudinary.com/tine-products/image/upload/e_bgremoval/cs_no_cmyk,f_auto,h_${size},w_${size}/product/${sku}.png`;

/** Attempts to generate a generic search term from a product name, by removing numbers from the product name */
export const makeGenericSearch = (searchString: string): string => {
   return split(searchString, " ")
      .filter((word) => !isFinite(parseInt(word[0])))
      .join(" ");
};

/** Ensures that the value is an array */
export const forceArray = <T>(val: T | T[]): T[] => (isArray(val) ? val : [val]);

const priceFormat = new Intl.NumberFormat("no-NO", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

/** Formats the OrderUnit into readable string */
export const formatUnit = (unit: ORDER_UNIT | string, short: boolean = true): string => {
   switch (unit) {
      case ORDER_UNIT.D:
         return short ? "D-pak" : "per D-pak (eks mva)";
      case ORDER_UNIT.STK:
         return short ? "STK" : "per stk (eks mva)";
      case ORDER_UNIT.L:
         return short ? "Liter" : "per liter (eks mva)";
      case ORDER_UNIT.P:
         return short ? "Pall" : "per pall (eks mva)";
      case ORDER_UNIT.K:
         return short ? "Kartong" : "per kartong (eks mva)";
      case ORDER_UNIT.KG:
         return short ? "Kg" : "per Kg (eks mva)";
      case ORDER_UNIT.CS:
         return short ? "Kasse" : "per kasse (eks mva)";
      default:
         return `Ukjent enhet: ${unit}`;
   }
};

export const formatPrice = (price?: number | null, simplify: boolean = false) => {
   if (isNil(price) || isNaN(price)) {
      return "-";
   }

   const priceStr = priceFormat.format(price);

   if (simplify) {
      const decimalIndex = priceStr.lastIndexOf(",");
      return priceStr.substring(0, decimalIndex + 1) + "-";
   }
   return `kr ${priceStr}`;
};

export const formatPhoneNumber = (phoneNumber: string): string => {
   if (!isString(phoneNumber)) {
      return phoneNumber;
   }

   return phoneNumber.replace(/[^+\d]/g, "");

   /*
   if (cleanedPhoneNumber.length < 4) {
      return cleanedPhoneNumber;
   }

   if (cleanedPhoneNumber.length < 6) {
      return `${cleanedPhoneNumber.slice(0, 3)} ${cleanedPhoneNumber.slice(3)}`;
   }

   return `${cleanedPhoneNumber.slice(0, 3)} ${cleanedPhoneNumber.slice(3, 5)} ${cleanedPhoneNumber.slice(5, 8)}`;
   */
};

export const formatOtp = (otp: string, prevOtp: string): string => {
   const cleanedOtp = otp.replace(/\D/g, "");

   return otp.length <= 6 ? cleanedOtp : prevOtp;
};

export const stripPossibleHtmlExtension = (path: string) => path.replace(".html", "");

export const removeWhiteSpace = (valueWithWhiteSpace: string): string => valueWithWhiteSpace.replace(/\s/g, "");

export const generateSubscriptionObjectName = (
   sub:
      | {
           deliveryWeekdayNumber: number | string;
           deliveryIntervalDays: number | string;
        }
      | null
      | undefined
): string => {
   if (isNil(sub)) {
      return "";
   }
   return generateSubscriptionName(sub.deliveryWeekdayNumber, sub.deliveryIntervalDays);
};

/** Build an array of actions for TIP to perform (add, del, upd) */
export const createActionsFromCartChanges = (
   addedLines: CartItem[],
   changedLines: CartItem[],
   removedOrderLines: OrderState[],
   oldState: OrderState[]
): OrderChangeAction[] => {
   const actions: OrderChangeAction[] = [];

   addedLines.forEach((al) => {
      actions.push({
         action: "add",
         sku: al.sku,
         quantity: al.qty,
         orderLineUnit: al.unit
      });
   });
   /**
    *
    * Why are we generating payloads with both orderLineNumber and orderLineNumbers?
    *
    * Because the order API and the subscription API was not created with the same orderLineNumber(s) property name,
    * we add both so that we can reuse this function for both. This means we are always sending one field that the API
    * will ignore, but we get to avoid duplicating code.
    *
    */
   changedLines.forEach((cl) => {
      const oldStateLine = find(oldState, { sku: cl.sku });
      actions.push({
         action: "upd",
         orderLineNumber: oldStateLine && oldStateLine.combinedLines,
         orderLineNumbers: oldStateLine && oldStateLine.combinedLines,
         sku: cl.sku,
         quantity: cl.qty,
         orderLineUnit: cl.unit
      });
   });

   removedOrderLines.forEach((rl) => {
      actions.push({
         action: "del",
         sku: rl.sku,
         orderLineNumber: rl.combinedLines,
         orderLineNumbers: rl.combinedLines
      });
   });

   return actions;
};

export const formatEan = (ean: string): string => {
   if (!isString(ean)) {
      console.error(`Invalid EAN: ${ean}. Expected a string.`);
      return "";
   }

   if (ean.length !== 13) {
      console.warn(`Unexpected EAN length: ${ean.length}. Expected 13.`);
      return ean;
   }

   return ean.substring(0, 1) + " " + ean.substring(1, 7) + " " + ean.substring(7);
};

export const translateOrderType = (orderType: ORDERTYPE): string => {
   const orderTypeToHumanString: Record<ORDERTYPE, string> = {
      WEB: "Engangsordre",
      STD: "Opprettet av TINE",
      WAS: "Abonnement",
      EXP: "Ekspressordre",
      EXL: "Ekstralevering",
      HPN: "Henteordre",
      KOE: "Konsulentordre",
      RLG: "Reklameartikler",
      TUP: "Kundeprogram",
      XML: "EDI-ordre",
      ETT: "Etterregistrering"
   };
   return defaultTo(orderTypeToHumanString[orderType], orderType);
};

export const translateDayLabel = (orderType: ORDERTYPE): string => {
   switch (orderType) {
      case ORDERTYPE.WAS:
         return "Levering";
      case ORDERTYPE.HPN:
         return "Hentes etter";
      default:
         return "Valgt levering";
   }
};

export const translateStatus = (status: string): string => {
   const statusToHumanString: Record<string, string> = {
      "20": "bestilling mottatt av TINE",
      "22": "bestilling mottatt av TINE",
      "33": "bestilling mottatt av TINE",
      "44": "under plukking",
      "66": "ferdig pakket",
      "77": "pakket og fakturert",
      "90": "slettet",
      "99": "kan ikke leveres"
   };
   return defaultTo(statusToHumanString[status], `status ${status}`);
};

export const translateWeekday = (englishWeekday: string) => {
   const englishToNorwegianWeekdays: Record<string, string> = {
      Monday: "Mandag",
      Tuesday: "Tirsdag",
      Wednesday: "Onsdag",
      Thursday: "Torsdag",
      Friday: "Fredag",
      Saturday: "Lørdag",
      Sunday: "Søndag"
   };
   return defaultTo(englishToNorwegianWeekdays[englishWeekday], englishWeekday);
};

export const makeUserLabel = (user: NextGenUser) => {
   if (user.firstName && user.lastName) {
      return trim(defaultTo(user.firstName, " ").substring(0, 1) + defaultTo(user.lastName, " ").substring(0, 1));
   } else if (user.firstName) {
      return user.firstName.substring(0, 2);
   } else if (user.lastName) {
      return user.lastName.substring(0, 2);
   } else {
      const emailsParts = user.email.split("@")[0].split(".");
      if (emailsParts.length > 1) {
         return emailsParts[0].substring(0, 1) + emailsParts[1].substring(0, 1);
      } else {
         return emailsParts[0].substring(0, 2);
      }
   }
};

const stringToColor = (string: string, saturation: number = 75, lightness: number = 75): string => {
   let hash = 0;
   for (let i = 0; i < string.length; i++) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash;
   }
   return `hsl(${hash % 360}, ${saturation}%, ${lightness}%)`;
};

export const makeUserColor = (user: NextGenUser): string => stringToColor(makeUserLabel(user));

export const sleep = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay));

export const desktopBreakpoint = "(min-width: 821px)";
export const mediumBreakpoint = "(min-width: 767px)";
export const mobileBreakpoint = "(max-width: 576px)";

export const inRangeInclusive = (num: number, validRange: { min: number; max: number }): boolean =>
   num >= validRange.min && num <= validRange.max;

export const weekDayNames = ["Sø.", "Ma.", "Ti.", "On.", "To.", "Fr.", "Lø."];

export const monthNames = [
   "Januar",
   "Februar",
   "Mars",
   "April",
   "Mai",
   "Juni",
   "Juli",
   "August",
   "September",
   "Oktober",
   "November",
   "Desember"
];

export const bootstrapBreakpoints: { [name in BootstrapBreakpointName]: BootstrapBreakpointValues } = {
   xs: "",
   sm: "576px",
   md: "768px",
   lg: "992px",
   xl: "1200px",
   xxl: "1400px"
};
export const bootstrapMediaQueries: { [name in BootstrapBreakpointName]: string } = {
   xs: "",
   sm: `(min-width: ${bootstrapBreakpoints.sm})`,
   md: `(min-width: ${bootstrapBreakpoints.md})`,
   lg: `(min-width: ${bootstrapBreakpoints.lg})`,
   xl: `(min-width: ${bootstrapBreakpoints.xl})`,
   xxl: `(min-width: ${bootstrapBreakpoints.xxl})`
};

export const focusableElementCssSelector = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

export const getYoutubeVideoIdFromURL = (url: string): string | undefined => {
   const [preQuery, postQuery] = url.split("?");
   if (/youtube\.com/i.test(preQuery) && postQuery) {
      return postQuery
         .split("&")
         .find((param) => param.startsWith("v="))
         ?.substring(2);
   }
};

export const titleCase = (str: string): string => {
   const lower = str.toLowerCase();
   return lower.charAt(0).toUpperCase() + lower.slice(1);
};

export const createRandomHex = (size: number) => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join("");
