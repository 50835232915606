import { BoltIcon, DevicePhoneMobileIcon, UserIcon } from "@heroicons/react/24/outline";
import { autoEffect, store, view } from "@risingstack/react-easy-state";
import cn from "classnames";
import isNil from "lodash-es/isNil";
import { KeyboardEventHandler } from "react";
import { Form } from "react-bootstrap";
import { useLocation } from "wouter";

import {
   BackendIssueError,
   backendIssueMessagesByLocale,
   genericLoginErrorByLocale,
   getCredentialsErrorMessage,
   networkErrorMessageByLocale,
   ServerNotFoundError,
   WrongUsernameOrPasswordError
} from "../../common/errors";
import { getUserNameFromStorage, setUserNameInStorage } from "../../common/utils/storageUtils";
import useSupportedLocale from "../../common/utils/useSupportedLocale";

import authStore from "../../stores/auth/authStore";
import loginState from "../../stores/auth/loginState";
import uiStore, { LOGIN_MODE } from "../../stores/uiStore";

import Button from "../Button";
import ButtonPlain from "../ButtonPlain";
import LoadingSmall from "../LoadingSmall";

type LocalState = {
   username: string;
   password: string;
   error: WrongUsernameOrPasswordError | BackendIssueError | ServerNotFoundError | null;
};

const LoginUsername = () => {
   const [_, redirectTo] = useLocation();

   const formState: LocalState = store({
      username: getUserNameFromStorage(),
      password: "",
      error: null
   });
   const locale = useSupportedLocale();

   autoEffect(() => setUserNameInStorage(formState.username));

   const attemptLogin = () => {
      formState.error = null;
      authStore.login(formState.username, formState.password).catch((error) => {
         console.warn("Login failed", error);
         formState.error = error;
      });
   };

   const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = (event) => {
      if (event.key === "Enter") {
         attemptLogin();
      }
   };

   const error = formState.error;
   let errorMessage = genericLoginErrorByLocale[locale];
   if (error instanceof BackendIssueError) {
      switch (error.message) {
         case "Unable to load assortment, error from backend":
         case "Unable to load delivery dates / pickup":
         case "Unable to load favourites, error from backend":
         case "Missing delivery dates":
            errorMessage = backendIssueMessagesByLocale[locale][error.message];
      }
   }
   if (error instanceof WrongUsernameOrPasswordError) {
      errorMessage = getCredentialsErrorMessage(formState.username, locale);
   }
   if (error instanceof ServerNotFoundError) {
      errorMessage = networkErrorMessageByLocale[locale];
   }
   return (
      <div className="login-content d-flex flex-column">
         <div className="position-relative pt-3 pb-4">
            <UserIcon className="login-user-icon position-absolute" />
            <h1 className="h2 text-center mx-5">
               <b>Logg inn</b>
            </h1>
         </div>
         {uiStore.accessLevel === 2 ? (
            <div className="py-4 fw-bold text-danger">
               <BoltIcon /> Du har nå KS-tilgang. Last siden på nytt (F5) for å komme tilbake til normal tilgang.
            </div>
         ) : (
            <>
               <p className="text-center pt-2 pb-3">Skriv inn ditt kundenummer eller din e-postadresse for å logge inn.</p>
               <div className="pb-2">
                  <Form.Label className="small">E-postadresse/ kundenummer</Form.Label>
                  <Form.Control
                     className={cn("tine-input-field", { "is-invalid": !isNil(formState.error) })}
                     type="text"
                     value={formState.username}
                     autoFocus
                     onChange={(e) => {
                        formState.username = e.target.value;
                     }}
                     onKeyDown={handleKeyPress}
                  />
               </div>
               <div className="pb-2">
                  <Form.Label className="small">Passord</Form.Label>
                  <Form.Control
                     className="tine-input-field"
                     type="password"
                     value={formState.password}
                     onChange={(e) => {
                        formState.password = e.target.value;
                     }}
                     onKeyDown={handleKeyPress}
                  />
                  <ButtonPlain
                     onClick={() => uiStore.switchLoginMode(LOGIN_MODE.RESET_PASSWORD_EMAIL)}
                     className="pt-1 small underline-before-hover float-end"
                  >
                     Glemt passord?
                  </ButtonPlain>
               </div>
               <>{!!error && <span className="text-danger small pb-2">{errorMessage}</span>}</>
               <Button
                  variant="primary"
                  size="sm"
                  className="tine-primary-button mt-2 mb-4 align-self-center w-fit"
                  onClick={attemptLogin}
                  disabled={loginState.is("CHECKING_LOGIN")}
               >
                  {loginState.is("LOGIN_MODAL") && <>Logg inn</>}
                  {loginState.is("CHECKING_LOGIN") && <LoadingSmall />}
               </Button>
               <p className="text-center m-0">Eller</p>
               <ButtonPlain
                  className="d-flex align-items-center align-self-center mb-4"
                  onClick={() => uiStore.switchLoginMode(LOGIN_MODE.SMS)}
               >
                  <DevicePhoneMobileIcon />
                  <p className="mb-0 small">
                     <b>Logg inn med mobilnummer</b>
                  </p>
               </ButtonPlain>
            </>
         )}
      </div>
   );
};

export default view(LoginUsername);
