import { view } from "@risingstack/react-easy-state";
import { Link } from "wouter";

import { formatPrice } from "../../common/utils";

import cartStore from "../../stores/cartStore";
import deliveryFeeStore from "../../stores/deliveryFeeStore";

import Button from "../../components/Button";
import theme from "../../themes/theme";

const MiniCartGoToCart = () => {
   const cartInfo = cartStore.getCartTotal();
   const deliveryCharge = deliveryFeeStore.currentDeliveryCharge();

   return (
      <div className="w-100 px-1 px-lg-3 border-top mb-2">
         <div className="d-flex justify-content-between p-2">
            <div>Total sum</div>
            <div className="fw-bolder">
               {formatPrice(cartInfo.total + cartInfo.recyclingCharge + (deliveryCharge?.currentFee?.fee ?? 0))}
            </div>
         </div>
         {cartInfo.recyclingCharge > 0 && (
            <div className="d-flex justify-content-between px-2 smaller">
               <div>Inkludert pant</div>
               <div>{formatPrice(cartInfo.recyclingCharge)}</div>
            </div>
         )}
         {theme.deliveryFee.enabled && (
            <div className="d-flex justify-content-between mt-1 mb-3 px-2 smaller">
               <div>Inkludert ordregebyr</div>
               <div>{formatPrice(deliveryCharge?.currentFee?.fee)}</div>
            </div>
         )}
         <div className="d-flex justify-content-center p-2">
            <Link to="/checkout/cart" onClick={cartStore.hideMiniCart}>
               <Button className="w-75" variant="primary" size="lg">
                  Gå til kassen
               </Button>
            </Link>
         </div>
      </div>
   );
};

export default view(MiniCartGoToCart);
