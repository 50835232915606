import { store } from "@risingstack/react-easy-state";
import { orderBy } from "lodash-es";
import isNil from "lodash-es/isNil";

import { apiClient } from "../../common/apiClient";
import { Notification, NotificationsResponse } from "../../common/types/notificationTypes";
import {
   AsyncData,
   initializeWithDefaultData,
   setAsDataAvailable,
   setAsErrorOccured,
   setAsWaitingForData
} from "../../common/utils/asyncDataUtils";

import authStore from "../auth/authStore";
import { filterOldNotifications } from "./notificationsUtils";

type NotificationsStore = {
   notifications: AsyncData<Notification[]>;
   oldNotifications: AsyncData<Notification[]>;
   fetchNotifications(): Promise<void>;
   fetchOldNotifications(): Promise<void>;
   clearNotifications(): void;
};

const notificationsStore: NotificationsStore = store({
   notifications: initializeWithDefaultData([]),
   oldNotifications: initializeWithDefaultData([]),

   fetchNotifications: async () => {
      const transitionedSuccessfully = setAsWaitingForData(notificationsStore.notifications);

      if (!transitionedSuccessfully || isNil(authStore.currentCompany)) {
         return Promise.reject();
      }

      return apiClient(`${process.env.API_HOST}/api/notifications/${authStore.currentCompany}`, authStore.getSessionToken())
         .get()
         .json()
         .then((res) => {
            const sortedResult = orderBy((res as NotificationsResponse).payload, ["added"], ["desc"]);
            setAsDataAvailable(notificationsStore.notifications, sortedResult);
         })
         .catch((errorObject) => {
            setAsErrorOccured(notificationsStore.notifications, "" + errorObject);
         });
   },

   fetchOldNotifications: async () => {
      const transitionedSuccessfully = setAsWaitingForData(notificationsStore.oldNotifications);

      if (!transitionedSuccessfully || isNil(authStore.currentCompany)) {
         return Promise.reject();
      }

      return apiClient(
         `${process.env.API_HOST}/api/notifications/${authStore.currentCompany}/archive`,
         authStore.getSessionToken()
      )
         .get()
         .json()
         .then((res) => {
            const result = filterOldNotifications((res as NotificationsResponse).payload, notificationsStore.notifications.data);
            const sortedResult = orderBy(result, ["added"], ["desc"]);
            setAsDataAvailable(notificationsStore.oldNotifications, sortedResult);
         })
         .catch((errorObject) => {
            setAsErrorOccured(notificationsStore.oldNotifications, "" + errorObject);
         });
   },

   clearNotifications: () => {
      notificationsStore.notifications = initializeWithDefaultData([]);
      notificationsStore.oldNotifications = initializeWithDefaultData([]);
   }
});

export default notificationsStore;
