import { RecipeType } from "../types/recipeTypes";

const tineRecipeUrl = (path: string, name: string) => `https://www.tine.no/oppskrifter/${path}/${name}`;

export const canonicalRecipeUrl = (recipe: RecipeType): string => {
   const { name, categories, publicUrls } = recipe;

   // If categories, componse a recipe url from category levels available
   if (name && categories) {
      const { level1, level2 } = categories;
      if (level2) {
         return tineRecipeUrl(level2.slug, name);
      } else {
         return tineRecipeUrl(level1.slug, name);
      }
   }

   // If no categories, use the first public url
   if (publicUrls && publicUrls.length > 0) {
      return publicUrls[0].value;
   }

   return "";
};
