import { view } from "@risingstack/react-easy-state";
import { Entry } from "contentful";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

import { TypeRecipeSkeleton } from "../../common/types/contentful";
import { RecipeType } from "../../common/types/recipeTypes";
import { canonicalRecipeUrl } from "../../common/utils/recipeUtils";

import theme from "../../themes/theme";
import IngredientsAndInstructions from "./recipe/IngredientsAndInstructions";
import RecipeHeaderAndImage from "./recipe/RecipeHeaderAndImage";
import RecipeProductList from "./recipe/RecipeProductList";

type RecipeProps = {
   id: string;
   recipeEntry: Entry<TypeRecipeSkeleton, undefined, "nb_no">;
};

const Recipe = ({ recipeEntry }: RecipeProps) => {
   const dataFromContentful = recipeEntry.fields.recipeData;
   const recipe: RecipeType | undefined =
      dataFromContentful && !Array.isArray(dataFromContentful) ? (dataFromContentful as RecipeType) : undefined;

   useEffect(() => {}, [recipe?.recipeId]);
   if (recipe) {
      const canonical = canonicalRecipeUrl(recipe);
      return (
         <div className="recipe">
            <Helmet>
               <title>{`${recipe.displayName} - ${theme.siteName}`}</title>
               {!!canonical && <link rel="canonical" href={canonical} />}
            </Helmet>
            <RecipeHeaderAndImage recipe={recipe} />
            <IngredientsAndInstructions recipe={recipe} />
            <RecipeProductList recipe={recipe} />
         </div>
      );
   }
   return <></>;
};

export default view(Recipe);
