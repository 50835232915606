import { view } from "@risingstack/react-easy-state";
import { ComponentProps } from "react";

import { Product, ProductBasics, SimplifiedProduct } from "../common/types/productTypes";
import { productImageUrl } from "../common/utils";

interface ProductImageProps extends ComponentProps<"img"> {
   product: ProductBasics;
   size: number;
}

const ProductImage = ({ product, size, ...rest }: ProductImageProps) => {
   return (
      <img
         alt={`Produktbilde av ${product.name}`}
         loading="lazy"
         width={size}
         src={productImageUrl(product.sku, size)}
         {...rest}
      />
   );
};

export default view(ProductImage);
