import Bugsnag from "@bugsnag/js";
import { autoEffect, batch, store } from "@risingstack/react-easy-state";
import {
   addMilliseconds,
   differenceInMilliseconds,
   isPast,
   minutesToMilliseconds,
   parseJSON,
   secondsToMilliseconds
} from "date-fns";
import { first, matches, omit } from "lodash-es";
import find from "lodash-es/find";
import isNil from "lodash-es/isNil";
import isUndefined from "lodash-es/isUndefined";
import isEmpty from "lodash/isEmpty";

import { apiClient, ensureWretchError } from "../../common/apiClient";
import { BackendIssueError, ServerNotFoundError, WrongCodeError, WrongUsernameOrPasswordError } from "../../common/errors";
import { Company, LoginData, NextGenProfileResponse, Session, User } from "../../common/types/companyTypes";
import {
   AsyncData,
   initializeWithDefaultData,
   setAsDataAvailable,
   setAsErrorOccured,
   setAsWaitingForData
} from "../../common/utils/asyncDataUtils";
import { formatDateTime } from "../../common/utils/dateUtils";
import {
   getCompanyFromStorage,
   getOrdertypeFromStorage,
   getUserFromStorage,
   setCompanyInStorage,
   setOrderTypeInStorage,
   setUserInStorage
} from "../../common/utils/storageUtils";

import theme from "../../themes/theme";
import accessStore from "../accessStore";
import cartStore from "../cartStore";
import claimsStore from "../claims/claimsStore";
import segmentStore from "../cms/segmentStore";
import deliveryDatesStore from "../deliveryDates/deliveryDatesStore";
import favoriteStore from "../favoriteStore";
import featuresStore from "../features/featuresStore";
import recipientsStore from "../notifications/legacyRecipientsStore";
import notificationsStore from "../notifications/notificationsStore";
import productStore from "../product/productStore";
import recommendationStore from "../recommendationStore";
import subscriptionStore from "../subscriptionStore";
import toastStore from "../toastStore";
import loginState from "./loginState";

/** Configuration settings for scheduled refresh of auth tokens */
// If there is less than 20 minutes left in the token expiry, we do an immidiate refresh after
const IMMEDIATE_REFRESH_LIMIT = minutesToMilliseconds(20);

// If we are withing the immidiate refresh period, we wait 30 seconds and then trigger refresh
const IMMEDIATE_REFRESH_WAIT = secondsToMilliseconds(30);

// If we are not refreshing right now, we set a timeout and trigger it when there is 20 minutes until expiry
const REFRESH_MARGIN = minutesToMilliseconds(20);

let refeshTimeoutId: ReturnType<typeof setInterval>;
let logoutTimeoutId: ReturnType<typeof setInterval>;

export type LoginRequirement =
   | "CART"
   | "ORDER_CONFIRMATION"
   | "PICKUP_CONFIRMATION"
   | "SEGMENTED_CONTENT"
   | "ACCOUNT_PAGES"
   | "NOT_REQUIRED";

type AuthStore = {
   user: User | null;
   companies: Company[] | null;
   currentCompany: string | null;
   currentlyRequiresLogin: LoginRequirement[];
   codeChallengeId: string | null;
   resetPasswordId: AsyncData<string | null>;
   resetPasswordProgress: AsyncData<boolean>;
   showForcedLogoutModal: boolean;
   startStep?: number;
   switchingCompany: boolean;
   username?: string;
   error?: BackendIssueError | null;

   isLoggedIn(): boolean;
   getSessionToken(): string | null;
   refreshToken(): Promise<void>;
   scheduleTokenRefresh(session: Session): void;
   abortTokenTimers(): void;
   useSessionFromQuery(): boolean;
   verifySession(session: Session | null): Promise<unknown>;
   verifyStillLoggedIn(visible: boolean): Promise<void>;
   changeCompany(newCompany: string): Promise<void>;
   getCurrentCompany(): Company | undefined;
   getCustomerData(token: string): Promise<LoginData>;
   updatePersonalContactInfo(email: string, firstName: string, lastName: string, mobile: string): Promise<void>;
   updateCompanyContactInfo(email: string, phone: string, phone2: string): Promise<void>;
   getAlgoliaUserToken(): string;
   changePassword(newPassword: string): Promise<string>;
   requestSmsLoginOtp(mobileNumber: string): Promise<void>;
   verifySmsLoginOtp(code: string): Promise<void>;
   requestResetPasswordOtp(email: string): Promise<void>;
   attemptResetPassword(resetCode: string, newPassword: string): Promise<void>;
   clearResetPassword(): void;
   login(username: string, password: string): Promise<void>;
   logout(): void;
};

const authStore: AuthStore = store({
   user: getUserFromStorage(),
   companies: [],
   currentCompany: null,
   currentlyRequiresLogin: [],
   codeChallengeId: null,
   resetPasswordId: initializeWithDefaultData(null),
   resetPasswordProgress: initializeWithDefaultData(false),
   showForcedLogoutModal: false,
   switchingCompany: false,

   isLoggedIn: () => {
      return loginState.is("LOGGED_IN");
   },

   getCustomerData: async (token: string) => {
      const customerDataEndpoint = `${process.env.API_HOST}/api/nextgen/${theme.storeId}/me`;
      const customer: NextGenProfileResponse = await apiClient(customerDataEndpoint, token).get().json();

      const user: Omit<User, "session"> = omit(customer, "companies");
      const companies: Company[] = customer.companies.map((company) => {
         const { businessChainLevel1, businessChainLevel2, businessChainLevel3, businessChainLevel4, ...rest } = company;
         return {
            ...rest,
            businessLevels: {
               level1: businessChainLevel1 || "",
               level2: businessChainLevel2 || "",
               level3: businessChainLevel3 || "",
               level4: businessChainLevel4 || ""
            },
            minimumOrderValue: company.minimumOrderValue || 0
         };
      });
      const currentCompany = first(companies)?.customerNumber;

      if (isNil(currentCompany)) {
         throw new BackendIssueError("Unable to determine default customer number");
      }

      return { user, companies, currentCompany };
   },

   getSessionToken: () => {
      const token = authStore.user?.session?.accessToken;
      if (isUndefined(token)) {
         return null;
      }
      return token;
   },

   refreshToken: async () => {
      console.log("Refreshing access token using existing access token");

      if (isNil(authStore.user) || isNil(authStore.user?.session?.expiry)) {
         console.warn("The session is no longer defined in the store, aborting refresh...");
         return Promise.resolve();
      }

      const expiryTime = parseJSON(authStore.user.session.expiry);
      if (isPast(expiryTime)) {
         console.warn("Token past expiry time (" + expiryTime + "), logout user out...");
         toastStore.addError(
            "Logget ut pga inaktivitet",
            "Du har blitt logget ut på grunn av inaktivitet. Logg inn igjen for å fortsette handleturen."
         );

         loginState.transitionToIfPossible("NOT_LOGGED_IN");
         return Promise.resolve();
      }

      return apiClient(`${process.env.API_HOST}/api/refresh`, authStore.getSessionToken())
         .post()
         .json((newSession) => {
            authStore.scheduleTokenRefresh(newSession);
            authStore.user = {
               ...authStore.user,
               session: newSession
            };
         })
         .catch((err) => {
            console.warn("Unable to refresh access token, will automatically log user out when token expires...", err);
            Bugsnag.notify(err, (e) => {
               e.context = "Token refresh";
            });
         });
   },

   scheduleTokenRefresh: (session) => {
      // Schedule a refresh of the access token so we don't time out
      const expiryTime = parseJSON(session.expiry);
      const expiryIn = differenceInMilliseconds(expiryTime, new Date());
      // console.log("Expiry is " + session.expiry + " (" + expiryTime + ") which is in " + expiryIn + " ms");

      if (logoutTimeoutId) {
         clearTimeout(logoutTimeoutId);
      }
      //console.log("Scheduling logout in " + expiryIn + " ms unless token gets refreshed");
      logoutTimeoutId = setTimeout(() => loginState.transitionToIfPossible("NOT_LOGGED_IN"), expiryIn);

      // If there is less than 10 minutes until expiry, refresh the token in 30 seconds.
      const refreshIn = expiryIn < IMMEDIATE_REFRESH_LIMIT ? IMMEDIATE_REFRESH_WAIT : expiryIn - REFRESH_MARGIN;
      const refreshAt = addMilliseconds(new Date(), refreshIn);
      console.log("Scheduling refresh of access token in " + refreshIn + " ms (" + formatDateTime(refreshAt) + ")");

      refeshTimeoutId = setTimeout(authStore.refreshToken, refreshIn);
   },

   abortTokenTimers: () => {
      if (refeshTimeoutId) {
         clearTimeout(refeshTimeoutId);
      }
      if (logoutTimeoutId) {
         clearTimeout(logoutTimeoutId);
      }
   },

   useSessionFromQuery: () => {
      const params = new URLSearchParams(window.location.search);
      const accessToken = params.get("token");
      const expiry = params.get("expiry");

      if (isNil(accessToken) || isNil(expiry)) {
         return false;
      }

      if (isEmpty(accessToken) || isEmpty(expiry)) {
         return false;
      }

      console.log("Creating session data from query string");
      authStore.user = {
         ...authStore.user,
         session: {
            accessToken,
            expiry
         }
      };

      return true;
   },

   verifySession: async (session = null): Promise<void> => {
      const { username } = authStore;
      let usableSession = session === null && !!authStore.user?.session ? authStore.user.session : session;

      if (usableSession === null) {
         loginState.transitionTo("NOT_LOGGED_IN");
         return;
      }

      loginState.transitionTo("VERIFY_SESSION");

      let customerResult;
      try {
         customerResult = await authStore.getCustomerData(usableSession.accessToken);
      } catch (err) {
         const wErr = ensureWretchError(err);
         if (!isNil(wErr)) {
            console.log("Token is likely expired. Response: ", wErr.status);
         }
         loginState.transitionTo("NOT_LOGGED_IN");
         return;
      }

      try {
         console.log("Customer Data verified:", customerResult);

         loginState.transitionTo("LOGGING_IN");
         authStore.user = {
            session: usableSession,
            ...customerResult.user
         };
         authStore.companies = customerResult.companies;

         const { companies, currentCompany } = authStore;
         const usernameCompany = companies.find((company) => company.customerNumber === username)?.customerNumber;
         const sessionCompany = getCompanyFromStorage();
         const currentCompanyNumber = currentCompany || usernameCompany || sessionCompany;

         if (currentCompanyNumber) {
            await authStore.changeCompany(currentCompanyNumber);
         } else {
            let company;
            if (companies?.length === 1) {
               console.log("COMPANY SELECTION: Only one company available, selecting it automatically");
               company = companies[0];
            } else {
               console.log(`COMPANY SELECTION: Multiple companies available, checking if username ${username} matches`);
               company = companies?.find((company) => username === company.customerNumber);
            }

            if (company) {
               console.log(`COMPANY SELECTION: Company found ${company.customerNumber}, proceeding with changeCompany...`);
               await authStore.changeCompany(company.customerNumber);
            } else {
               console.log("COMPANY SELECTION: Multiple companies available, showing selection screen");
               loginState.transitionTo("COMPANY_SELECTION");
            }
         }

         authStore.scheduleTokenRefresh(usableSession);
      } catch (err) {
         debugger;
         authStore.logout();
         throw err;
      }
   },

   verifyStillLoggedIn: async (visible) => {
      if (!authStore.isLoggedIn() || !authStore.user || !visible) {
         return;
      }

      try {
         const usableSession = authStore.user.session;
         await authStore.getCustomerData(usableSession.accessToken);
         console.log("Session is verified as still active!");
      } catch (err) {
         console.log("Session is not longer valid! Logging out...");
         authStore.showForcedLogoutModal = true;
         loginState.transitionTo("NOT_LOGGED_IN");
      }
   },

   changeCompany: async (newCompany) => {
      console.log("Switching to company " + newCompany);
      loginState.transitionTo("COMPANY_SELECTED");

      delete authStore.error;
      try {
         accessStore.reset();
         authStore.currentCompany = newCompany;
         setCompanyInStorage(newCompany);

         loginState.transitionTo("FEATURES_LOADING");

         await featuresStore.fetchEnabledCustomerFeatures(authStore.currentCompany);
         await featuresStore.fetchEnabledConfigCatFeatures(authStore.currentCompany);

         loginState.transitionTo("DELIVERY_DATES_LOADING");

         await deliveryDatesStore.fetchDeliveryDates(authStore.currentCompany);

         const availableOrderTypes = deliveryDatesStore.getAvailableOrderTypes();
         const orderType = getOrdertypeFromStorage(); // Defaults to WEB

         if (availableOrderTypes === null || availableOrderTypes.length === 0) {
            cartStore.changeOrderType(orderType); // Should be handeled another way as they don't have ordertype
         } else if (availableOrderTypes.includes(orderType)) {
            cartStore.changeOrderType(orderType);
         } else {
            cartStore.changeOrderType(availableOrderTypes[0]);
         }

         const currentDelivery = deliveryDatesStore.getCurrentDelivery();
         if (currentDelivery === null) {
            authStore.error = new BackendIssueError("Missing delivery dates");
            loginState.transitionTo("LOGGED_IN");
            return;
         }

         loginState.transitionTo("ASSORTMENT_LOADING");

         try {
            await productStore.getAssortment(authStore.currentCompany, currentDelivery.date, cartStore.orderType);
         } catch (err) {
            if (err instanceof BackendIssueError) {
               authStore.error = err;
            } else {
               authStore.error = new BackendIssueError("Unable to load assortment, error from backend");
            }

            loginState.transitionTo("LOGGED_IN");
            return;
         }

         loginState.transitionTo("NOTIFICATIONS_LOADING");
         void favoriteStore.loadFavorites();
         void cartStore.loadCartFromServer();
         void subscriptionStore.fetchSubscriptionList();

         if (featuresStore.notificationsEnabled) {
            await notificationsStore.fetchNotifications();
         }

         const company = authStore.getCurrentCompany();
         if (company === undefined) {
            throw new BackendIssueError("Unable to find selected company");
         }

         segmentStore.updateCurrentSegment(company);

         loginState.transitionTo("LOGGED_IN");
      } catch (err) {
         debugger;
         console.warn("Error occured during login", err);

         throw err;
      }
   },

   getCurrentCompany: () => {
      return find(authStore.companies, matches({ customerNumber: authStore.currentCompany }));
   },

   getAlgoliaUserToken: () => "company-" + authStore.currentCompany,

   changePassword: async (newPassword): Promise<string> => {
      const endpoint = `${process.env.API_HOST}/api/nextgen/${theme.storeId}/me/password`;
      try {
         await apiClient(endpoint, authStore.getSessionToken()).content("application/json").post({ newPassword }).text();
         return "";
      } catch (error) {
         console.log({ error, where: "authStore.changePassword" });
         return error && typeof error === "object" && "text" in error ? (error.text as string) : "unknown error cause";
      }
   },

   updateCompanyContactInfo: async (email, phone1, phone2) => {
      if (!authStore.isLoggedIn() || isNil(authStore.user)) {
         toastStore.addError("Det oppsto en feil", "Prøv å logge ut og inn igjen for å oppdatere profilen.");
         return;
      }
      const currentCompany = authStore.currentCompany;
      if (isNil(currentCompany)) {
         return;
      }

      const res: Company = await apiClient(
         `${process.env.API_HOST}/api/nextgen/${theme.storeId}/company/${currentCompany}`,
         authStore.getSessionToken()
      )
         .post({
            email,
            phone1,
            phone2
         })
         .json();

      const infoIsUpdated: boolean = res.email === email && res.phone1 === phone1 && res.phone2 === phone2;
      if (!infoIsUpdated) {
         throw "Unable to save new contact info";
      }

      console.log("Updated email, phone and phone2 successfully");

      const companyIndex = authStore.companies?.findIndex((company) => company.customerNumber === currentCompany);
      if (!isNil(authStore.companies) && !isNil(companyIndex)) {
         authStore.companies[companyIndex].email = email;
         authStore.companies[companyIndex].phone1 = phone1;
         authStore.companies[companyIndex].phone2 = phone2;
      }
   },

   updatePersonalContactInfo: async (email, firstName, lastName, mobile) => {
      if (!authStore.isLoggedIn() || isNil(authStore.user)) {
         toastStore.addError("Det oppsto en feil", "Prøv å logge ut og inn igjen for å oppdatere profilen.");
         return;
      }

      const currentCompany = authStore.currentCompany;
      if (isNil(currentCompany)) {
         return;
      }

      const res: User = await apiClient(`${process.env.API_HOST}/api/nextgen/${theme.storeId}/me`, authStore.getSessionToken())
         .post({
            id: authStore.user.id,
            email,
            firstName,
            lastName,
            mobile
         })
         .json();

      console.log("Updated firstName, lastName, email and mobile successfully");

      authStore.user.email = res.email;
      authStore.user.firstName = res.firstName;
      authStore.user.lastName = res.lastName;
      authStore.user.mobile = res.mobile;
   },

   requestSmsLoginOtp: async (mobileNumber) => {
      authStore.codeChallengeId = await apiClient(`${process.env.API_HOST}/api/nextgen/${theme.storeId}/sms/requestcode`)
         .query({
            mobileNumber
         })
         .post()
         .text();
   },

   verifySmsLoginOtp: async (code) => {
      loginState.transitionTo("CHECKING_LOGIN");

      try {
         const session: Session = await apiClient(`${process.env.API_HOST}/api/nextgen/${theme.storeId}/sms/verify`)
            .query({
               challengeId: authStore.codeChallengeId,
               code
            })
            .post()
            .json();

         console.log("Verifying OneTimeCode session", session);
         await authStore.verifySession(session);

         // Clear the codeChallengeId once the progress dialog has disappeared
         setTimeout(() => {
            authStore.codeChallengeId = null;
         }, 500);
      } catch (err) {
         loginState.transitionTo("LOGIN_MODAL");

         const wErr = ensureWretchError(err);
         if (wErr?.response?.status === 403) {
            throw new WrongCodeError();
         } else {
            console.log("Got error during OneTimeCode login: ", err);
            throw new BackendIssueError("Unable to verify OneTimeCode");
         }
      }
   },

   requestResetPasswordOtp: async (email: string) => {
      const transitionedSuccessfully = setAsWaitingForData(authStore.resetPasswordId);

      if (!transitionedSuccessfully) {
         return Promise.reject();
      }

      return apiClient(`${process.env.API_HOST}/api/nextgen/tinehandel/forgottenpassword`)
         .query({
            email
         })
         .post()
         .text((resetId) => {
            setAsDataAvailable(authStore.resetPasswordId, resetId);
         })
         .catch((errorObject) => {
            setAsErrorOccured(authStore.resetPasswordId, "" + errorObject);
         });
   },

   attemptResetPassword: async (code: string, newPassword: string) => {
      const transitionedSuccessfully = setAsWaitingForData(authStore.resetPasswordProgress);

      if (!transitionedSuccessfully) {
         return Promise.reject();
      }

      return apiClient(`${process.env.API_HOST}/api/nextgen/tinehandel/resetpassword`)
         .query({ resetId: authStore.resetPasswordId.data, code, newPassword })
         .post()
         .res((res) => {
            setAsDataAvailable(authStore.resetPasswordProgress, res.ok);
         })
         .catch((errorObject) => {
            setAsErrorOccured(authStore.resetPasswordProgress, "" + errorObject);
         });
   },

   clearResetPassword: () => {
      authStore.resetPasswordId = initializeWithDefaultData(null);
      authStore.resetPasswordProgress = initializeWithDefaultData(false);
   },

   login: async (username, password) => {
      loginState.transitionTo("CHECKING_LOGIN");
      console.log("Starting login process using host " + process.env.API_HOST);
      // Need to set it to a initial value, because typescript will not understand that the catch-block guarantees a
      // value being set before next try/catch block
      let session: Session = { expiry: "", accessToken: "" };

      try {
         // Attempting to get session with access token for user
         session = await apiClient(`${process.env.API_HOST}/api/nextgen/${theme.storeId}/login`)
            .query({ username, password, migrate: true })
            .post()
            .json();
      } catch (err: unknown) {
         loginState.transitionTo("LOGIN_MODAL");
         const wErr = ensureWretchError(err);
         if (wErr !== null) {
            switch (wErr.response.status) {
               case 400:
               case 401:
                  if (wErr.text === "StoreId not found") {
                     throw new BackendIssueError("StoreId not found");
                  }
                  throw new WrongUsernameOrPasswordError();

               case 500:
                  throw new BackendIssueError();
            }
         } else {
            throw new ServerNotFoundError();
         }
         // Default to throwing a backend issue error if we don't know what happened
         console.log({ nonWretchError: err });
         throw new BackendIssueError();
      }

      try {
         console.log("Verifying session", session);
         authStore.username = username;
         await authStore.verifySession(session);
      } catch (err: unknown) {
         console.log("Got error during login: ", err);
         loginState.transitionTo("NOT_LOGGED_IN");

         if (err instanceof Error) {
            Bugsnag.notify(err, (e) => {
               e.context = "Session Verification";
            });
         }
         if (err instanceof BackendIssueError) {
            throw err;
         }
         throw new BackendIssueError();
      }
   },

   logout: () => {
      batch(() => {
         authStore.abortTokenTimers();
         authStore.user = null;
         authStore.companies = [];
         authStore.currentCompany = null;
         setCompanyInStorage(null);

         deliveryDatesStore.clearDeliveryDates();
         setOrderTypeInStorage(undefined);

         segmentStore.clearCurrentSegment();
         favoriteStore.clearFavorites();
         claimsStore.clearClaims();
         notificationsStore.clearNotifications();
         recipientsStore.clearRecipients();
         productStore.loadGuestAssortment();
         recommendationStore.clearRecommendations();
         accessStore.reset();

         // Reset all feature availability
         void featuresStore.fetchEnabledConfigCatFeatures();
         void featuresStore.clearCustomerFeatures();

         if (cartStore.isEditing()) {
            void cartStore.stopEditOrderMode();
         } else {
            cartStore.emptyCart();
         }
         cartStore.lostSales = [];
      });
   }
} satisfies AuthStore);

// Save user info to session storage whenever it changes
autoEffect(() => setUserInStorage(authStore.user));

// Make customer info available for Google Analytics and other software outside React
autoEffect(() => {
   window.isUserLoggedIn = authStore.isLoggedIn();
   window.companyInfo = authStore.getCurrentCompany();
   window.customerInfo = authStore.user;
});

export default authStore;
