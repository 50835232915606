import { view } from "@risingstack/react-easy-state";
import { Row } from "react-bootstrap";
import { Link } from "wouter";

import { Product } from "../../common/types/productTypes";
import { AlgoliaProductHit, ProductSearchResults, SEARCH_TYPE, SearchComponentProps } from "../../common/types/searchTypes";
import useSupportedLocale from "../../common/utils/useSupportedLocale";

import searchStore from "../../stores/searchStore";

import ProductHit from "./ProductHit";

const hide = () => searchStore.clear(SEARCH_TYPE.QuickSearch, true);

const HitsForProduct = ({ searchDomain, maxHits = Number.MAX_VALUE }: SearchComponentProps) => {
   const locale = useSupportedLocale();

   const productResults: ProductSearchResults | undefined = searchDomain
      ? (searchStore[SEARCH_TYPE.QuickSearch].results.find(
           (result) => result.name === searchDomain.name && searchDomain.type === "product"
        ) as ProductSearchResults | undefined)
      : undefined;

   if (!productResults) {
      return null;
   }

   const queryID = productResults.queryID;
   const products = (productResults.products || []).slice(0, maxHits);
   const hasProducts = !!products?.length;

   return (
      <>
         {hasProducts && (
            <div className="d-flex flex-column gap-2 w-100">
               <Row>
                  <h3 className="pb-2">{searchDomain.displayName(locale)}</h3>
               </Row>
               <Row className="row-cols-1 row-cols-lg-3">
                  {products.map((product: Product, i: number) => {
                     const hit = productResults.hits.find((h) => h.objectID === product.sku) as AlgoliaProductHit;
                     return (
                        <ProductHit
                           key={product.sku}
                           hit={hit}
                           product={product}
                           position={i + 1}
                           algoliaIndex={searchDomain?.indexName || ""}
                           queryID={queryID}
                        />
                     );
                  })}
               </Row>
               <Row>
                  <Link
                     className="d-inline-block my-2 p-2"
                     onClick={() => hide()}
                     to={`/search/${searchDomain.name}?q=${productResults.query}`}
                  >{`Se alle produkter med '${productResults.query}'`}</Link>
               </Row>
            </div>
         )}
      </>
   );
};

export default view(HitsForProduct);
