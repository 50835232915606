import includes from "lodash-es/includes";
import intersection from "lodash-es/intersection";

import { CategoryInfo, CategoryQuery } from "../../common/types/categoryTypes";

import productStore from "../product/productStore";

export const setHiddenStatus = (cat: CategoryInfo) => {
   cat.visible = intersection(cat.skus, productStore.assortment).length > 0;
   cat.children.forEach((subcat) => setHiddenStatus(subcat));
};

/**
 * Recusively interates over the category tree and builds objects for
 * each matching category that can be used with findCategoryByUrlKey
 */
export const searchCategoryRescursive = (sku: string, cat: CategoryInfo, level: number, obj?: CategoryQuery): CategoryQuery[] => {
   if (!includes(cat.skus, sku)) {
      return [];
   }

   const newObj = {
      ...(obj ? obj : {}),
      ["cat" + level]: cat.url_key
   };

   if (cat.children.length === 0) {
      return [newObj];
   }

   return cat.children.flatMap((subcat) => searchCategoryRescursive(sku, subcat, level + 1, newObj));
};
