import { Entry } from "contentful";
import { Col } from "react-bootstrap";
import { Link } from "wouter";

import { TypeRecipeSkeleton } from "../../../common/types/contentful";
import { difficultyTexts, getRecipeForRecipeEntry } from "../../../common/utils/cmsStoreUtils";
import useSupportedLocale from "../../../common/utils/useSupportedLocale";

import contentStore from "../../../stores/cms/contentStore";

import RecipeImage from "./RecipeImage";

type RecipeTeaserProps = {
   content: Entry<TypeRecipeSkeleton, undefined, string>;
};

const RecipeTeaser = ({ content }: RecipeTeaserProps) => {
   const locale = useSupportedLocale();
   const urlData = contentStore.urlData.data.find((urlData) => urlData.id === content.sys.id);
   const recipe = getRecipeForRecipeEntry(content);
   if (recipe) {
      const titleText = recipe.displayName || content.fields.title || "Uten tittel";
      const workTimeText = recipe?.workTime ? `${recipe.workTime} min` : "";
      const difficultyText = difficultyTexts[locale][recipe.difficulty];
      const { image } = recipe;
      return (
         <Col>
            <Link to={"/" + urlData?.fullUrl}>
               <div
                  className="recipe-teaser shadow-sm rounded-1 w-100 "
                  role="link"
                  aria-label={`Gå til artikkelen ${titleText}`}
               >
                  {!!image?.url && (
                     <RecipeImage
                        url={image.url}
                        width={600}
                        ratio="16x9"
                        className="rounded-top"
                        placement="belowFold"
                        title={image.title}
                     />
                  )}
                  <div className="recipe-teaser-info p-3">
                     <div className="recipe-teaser-title">{titleText}</div>
                     <div className="d-flex gap-2">
                        <div className="rounded-4 px-3 py-1 recipe-teaser-tag">{workTimeText}</div>
                        <div className="rounded-4 px-3 py-1 recipe-teaser-tag">{difficultyText}</div>
                     </div>
                  </div>
               </div>
            </Link>
         </Col>
      );
   }
   return <></>;
};

export default RecipeTeaser;
