import debounce from "lodash-es/debounce";
import first from "lodash-es/first";
import isArray from "lodash-es/isArray";
import isNil from "lodash-es/isNil";
import isNumber from "lodash-es/isNumber";
import isString from "lodash-es/isString";
import isUndefined from "lodash-es/isUndefined";
import keyBy from "lodash-es/keyBy";

import cartStore from "../stores/cartStore";
import categoryStore from "../stores/categories/categoryStore";
import productStore from "../stores/product/productStore";

import { CartItem, Product } from "./types/productTypes";

const productToGA4 = (product?: Product): GAItemProduct => {
   if (isUndefined(product)) {
      return {};
   }

   const categories = categoryStore.findCategoryKeysBySku(product.sku);
   const category = categories.length > 0 ? first(categories) : { cat1: null, cat2: null, cat3: null };

   return {
      item_id: product.sku,
      item_name: product.name,
      item_brand: product.brand,
      item_category: category?.cat1,
      item_category2: category?.cat2,
      item_category3: category?.cat3,
      price: product.price
   };
};

const buildListInfo = (listName?: string, position?: number): GAItemListInfo => {
   const listInfo: GAItemListInfo = {};
   // If we added to cart from a list, we want to report which list and which position the product had in that list.
   if (!isNil(listName)) {
      listInfo.item_list_name = listName;
      listInfo.item_list_id = listName.toLowerCase().replace(" ", "-");
      listInfo.index = position;
   }
   return listInfo;
};

const debouncedSendSearch = (searchTerm: string) => {
   sendEvent("ga4_search", {
      searchTerm
   });
};

// Debounce the search event so that we dont get separate events for each new term (l, le, lett, lettm, lettme, etc)
export const sendSearch = debounce(debouncedSendSearch, 1000);

export const sendLoginSuccess = () => {
   sendEvent("ga4_login");
};

export const sendLoginFailed = () => {
   sendEvent("ga4_login_failed");
};

export const sendButtonClickTracking = (event: string) => sendEvent(event);

export const sendAddToCart = (product: Product | string, qty: number, listName?: string, position?: number) => {
   let fullProductInfo: Product | undefined;
   if (isString(product)) {
      // string (sku)
      fullProductInfo = productStore.resolveSku(product);
   } else if ("price" in product) {
      // Product
      fullProductInfo = product;
   }
   if (isUndefined(fullProductInfo)) {
      console.warn("Unable to find product when sending add to cart event", product);
      return;
   }
   console.log("Adding product to cart: ", fullProductInfo);

   sendEvent("ga4_add_to_cart", {
      ecommerce: {
         currency: "NOK",
         value: isNumber(fullProductInfo.price) ? fullProductInfo.price * qty : undefined,
         items: [
            {
               ...productToGA4(fullProductInfo),
               ...buildListInfo(listName, position),
               quantity: qty
            }
         ]
      }
   });
};

export const sendRemoveFromCart = (product: Product, qty: number) => {
   sendEvent("ga4_remove_from_cart", {
      ecommerce: {
         currency: "NOK",
         value: isNumber(product.price) ? product.price * qty : undefined,
         items: [
            {
               ...productToGA4(product),
               quantity: qty
            }
         ]
      }
   });
};

export const sendProductClick = (product: Product, list?: string, position?: number) => {
   sendEvent("g4_select_item", {
      ecommerce: {
         items: [
            {
               ...productToGA4(product),
               ...buildListInfo(list, position)
            }
         ]
      }
   });
};

export const sendMiniCartVisible = () => {
   sendViewCart("ga4_view_cart");
};

export const sendBeginCheckout = () => {
   sendViewCart("ga4_begin_checkout");
};

/**
 * Sends the cart contents to Google Analytics, with a specific event name.
 */
const sendViewCart = (eventName: "ga4_view_cart" | "ga4_begin_checkout") => {
   const cartTotal = cartStore.getCartTotal();
   const products = keyBy(productStore.resolveSkus(cartStore.items.map((cartItem) => cartItem.sku)), "sku");

   const itemPayload = cartStore.items.map((cartItem) => ({
      ...productToGA4(products[cartItem.sku]),
      quantity: cartItem.qty
   }));

   sendEvent(eventName, {
      ecommerce: {
         currency: "NOK",
         value: cartTotal.total,
         items: itemPayload
      }
   });
};

export const sendProductDetails = (product: Product) => {
   sendEvent("ga4_view_item", {
      ecommerce: {
         currency: "NOK",
         value: product.price,
         items: [
            {
               ...productToGA4(product)
            }
         ]
      }
   });
};

export const sendViewPromotion = (promotionId: string, promotionName: string) => {
   sendEvent("ga4_view_promotion", {
      promotion_id: promotionId,
      promotion_name: promotionName,
      items: []
   });
};

export const sendViewCampaign = (campaignId: string, campaignName: string) => sendViewPromotion(campaignId, campaignName);

export const sendPurchase = (orderNumber: string | string[], cartItems: CartItem[]) => {
   const useOrderNumber = isArray(orderNumber) ? orderNumber[0] : orderNumber;
   const cartCalculations = cartStore.getCartTotal();

   const products = cartItems.map((cartItem) => {
      const productFromCartItem = productStore.resolveSku(cartItem.sku);
      return {
         ...productToGA4(productFromCartItem),
         quantity: cartItem.qty
      };
   });

   sendEvent("ga4_purchase", {
      ecommerce: {
         currency: "NOK",
         coupon: null,
         transaction_id: useOrderNumber,
         value: cartCalculations.total,
         items: products
      }
   });
};

// Keep track of queued impressions
const impressions: GAItem[] = [];
let impressionTimeout: number | null = null;

export const queueImpression = (product: Product, list?: string, position?: number) => {
   impressions.push({
      ...productToGA4(product),
      ...buildListInfo(list, position)
   });

   if (impressions.length > 25) {
      sendQueuedImpressions();
   }

   if (impressionTimeout === null) {
      impressionTimeout = window.setTimeout(sendQueuedImpressions, 30_000);
   }
};

const sendQueuedImpressions = () => {
   if (impressions.length > 0) {
      // Clone and truncate array immediately to minimize likelihood of concurrency issues
      const itemPayload = [...impressions];
      impressions.length = 0;

      sendEvent("ga4_view_item_list", {
         ecommerce: {
            items: itemPayload
         }
      });
   }

   if (impressionTimeout !== null) {
      clearTimeout(impressionTimeout);
      impressionTimeout = null;
   }
};

const sendEvent = (event: string, additionalData?: GAAddtionalData) => {
   window.dataLayer.push({ ecommerce: null });
   const eventPayload = {
      event,
      ...additionalData
   };
   console.log("GA Event executed: ", eventPayload);
   window.dataLayer.push(eventPayload);
};
