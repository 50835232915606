import { view } from "@risingstack/react-easy-state";
import intersection from "lodash-es/intersection";
import isNil from "lodash-es/isNil";
import { useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { ProductTileCallbacks } from "../common/types/productTypes";
import { isDefaultData } from "../common/utils/asyncDataUtils";

import authStore from "../stores/auth/authStore";
import featuresStore from "../stores/features/featuresStore";
import productStore from "../stores/product/productStore";
import recommendationStore from "../stores/recommendationStore";

import ProductList from "./ProductList";

type RecommendationsProps = {
   heading: string | null;
   count?: number;
   skus?: string | string[] | null;
};

const Recommendations = ({ heading = null, count = 6 }: RecommendationsProps) => {
   const isLoggedIn = authStore.isLoggedIn();

   useEffect(() => {
      if (isLoggedIn) {
         if (isDefaultData(recommendationStore.recommendations)) {
            void recommendationStore.getRecommendations();
         }
      }
   }, [isLoggedIn]);

   if (!authStore.isLoggedIn() || recommendationStore.recommendations.data === null) {
      // This components should only be displayed when customer is logged in
      return null;
   }

   const recommendationToUse = featuresStore.recommendationType;
   const recommended = recommendationStore.recommendations.data[recommendationToUse];
   if (isNil(recommended)) {
      // If we don't have any recommendations available, we can just hide the component
      return null;
   }

   const skusInRecommendation = recommended.skus.map((suggestion) => suggestion.sku);

   const inAssortment = intersection(skusInRecommendation, productStore.assortment);
   if (inAssortment.length === 0) {
      console.log("None of the recommendation are in the assortment. Hiding module...");
      return null;
   }

   const callbacks: ProductTileCallbacks = {
      addToCart: (product) => {
         void recommendationStore.sendRecommendationFeedback(recommended.producedBy, product.sku, "ADD_TO_CART");
      },
      click: (product) => {
         void recommendationStore.sendRecommendationFeedback(recommended.producedBy, product.sku, "VIEW_DETAILS");
      },
      impression: (product) => {
         void recommendationStore.sendRecommendationFeedback(recommended.producedBy, product.sku, "IMPRESSION");
      }
   };

   return (
      <div className="recommendation-container" id={`recommendation_${recommended.producedBy}`.toLowerCase()}>
         {heading !== null && (
            <Row className="mb-lg-3">
               <Col>
                  <h3>{heading}</h3>
               </Col>
            </Row>
         )}
         <Row>
            <Col>
               <p dangerouslySetInnerHTML={{ __html: recommended.reason }} />
            </Col>
         </Row>
         <Row>
            <Col>
               <ProductList
                  skus={inAssortment}
                  count={count}
                  maxCol={6}
                  callbacks={callbacks}
                  listName={`Produktanbefalinger (${recommendationToUse})`}
               />
            </Col>
         </Row>
      </div>
   );
};

export default view(Recommendations);
