import { BoltIcon } from "@heroicons/react/24/outline";
import { view } from "@risingstack/react-easy-state";
import { Link } from "wouter";

import authStore from "../../stores/auth/authStore";
import uiStore from "../../stores/uiStore";

import BarcodeScannerButton from "../../components/barcodes/BarcodeScannerButton";
import LoginButton from "../../components/login/LoginButton";
import NewCustomerButton from "../../components/login/NewCustomerButton";
import SearchField from "../../components/search/SearchField";
import theme from "../../themes/theme";
import MiniCart from "../minicart/MiniCart";
import UserMenu from "./UserMenu";

const DesktopSearchBar = () => (
   <div className="logo-row bg-white px-sm-3 px-md-5">
      <div className="me-auto d-flex align-items-center">
         <Link to="/">
            <img
               role="button"
               alt={"Logo"}
               width={67}
               height={Math.round(67 * theme.logoRatio)}
               className="logo"
               src={theme.desktopLogoUrl}
               tabIndex={0}
            />
         </Link>
         {uiStore.accessLevel === 2 && (
            <div className="fw-bold text-danger">
               <BoltIcon /> Du har nå KS-tilgang. Last på nytt (F5) for å avslutte.
            </div>
         )}
      </div>
      <div className="px-3">
         <BarcodeScannerButton />
      </div>
      <div className="px-3 search-bar">
         <SearchField />
      </div>
      {authStore.isLoggedIn() ? (
         <>
            <UserMenu />
            <div className="flex-shrink-1 px-3">
               <MiniCart />
            </div>
         </>
      ) : (
         <>
            <LoginButton />
            <NewCustomerButton />
         </>
      )}
   </div>
);

export default view(DesktopSearchBar);
