import { Link } from "wouter";

type TagLinkProps = {
   url: string;
   label: string;
   isActive?: boolean;
};

const TagLink = ({ url, label, isActive = false }: TagLinkProps) => (
   <Link to={url}>
      <div className={`tag-link py-2 px-3 small ${isActive ? "color-active" : ""} `} role="link">
         {label}
      </div>
   </Link>
);

export default TagLink;
