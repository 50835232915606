import { useEffect, useState } from "react";

function useMediaQuery(query: string) {
   const getMatches = (query: string) => {
      // Prevents SSR issues
      if (typeof window !== "undefined") {
         return window.matchMedia(query).matches;
      }
      return false;
   };

   const [matches, setMatches] = useState(getMatches(query));

   function handleChange() {
      setMatches(getMatches(query));
   }

   useEffect(() => {
      const matchMedia = window.matchMedia(query);

      // Triggered at the first client-side load and if query changes
      handleChange();

      // Listen matchMedia
      if (matchMedia.addEventListener) {
         matchMedia.addEventListener("change", handleChange);
      } else if (matchMedia.addListener) {
         matchMedia.addListener(handleChange);
      }

      return () => {
         if (matchMedia.removeEventListener) {
            matchMedia.removeEventListener("change", handleChange);
         } else if (matchMedia.removeListener) {
            matchMedia.removeListener(handleChange);
         }
      };
   }, [query]);

   return matches;
}

export default useMediaQuery;
