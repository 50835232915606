import { useContentfulInspectorMode } from "@contentful/live-preview/dist/react";
import { Entry } from "contentful";

import { TypeImageWithFocusSkeleton, TypeV2_textAndImageSkeleton } from "../../common/types/contentful";

import ImageWithFocus from "./ImageWithFocus";
import RichTextRenderer from "./RichTextRenderer";

type TextAndImageProps = {
   content: Entry<TypeV2_textAndImageSkeleton, undefined, "nb_NO">;
};

const TextAndImage = ({ content }: TextAndImageProps) => {
   console.log("Text and image content: ", content);
   const inspectorProps = useContentfulInspectorMode();

   if (!content.fields) {
      return <>Fant ikke innhold</>;
   }

   const entryId = content.sys.id;
   const image = content.fields.image as Entry<TypeImageWithFocusSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>;
   const imagePosition = content.fields.imagePosition;
   const imageOrder = imagePosition === "Left" ? "order-lg-1" : "order-lg-2";
   const textOrder = imagePosition === "Left" ? "order-lg-2" : "order-lg-1";

   return (
      <div className="textAndImage gap-4">
         <div
            className={`${imageOrder} order-1 imageBox`}
            {...inspectorProps({
               entryId: entryId,
               fieldId: "image"
            })}
         >
            {image && (
               <ImageWithFocus
                  width={800}
                  ratio="16x9"
                  entryId={image.sys.id}
                  imageFromParent={image}
                  className="rounded"
                  placement="belowFold"
               />
            )}
         </div>
         <div
            className={`${textOrder} order-2 textBox`}
            {...inspectorProps({
               entryId: entryId,
               fieldId: "text"
            })}
         >
            <RichTextRenderer document={content.fields.text} />
         </div>
      </div>
   );
};

export default TextAndImage;
