import { view } from "@risingstack/react-easy-state";
import { useEffect } from "react";

const RedirectPunchout = ({ target }: { target: "esales" | "magento" }) => {
   useEffect(() => {
      if (target === "esales") {
         window.location.href = process.env.PUNCHOUT_ESALES_URL + window.location.pathname + window.location.search;
      } else {
         window.location.href = process.env.PUNCHOUT_MAGENTO_URL + window.location.pathname + window.location.search;
      }
   }, []);

   return <p>Vennligst vent mens du blir videresendt...</p>;
};

export default view(RedirectPunchout);
