import { view } from "@risingstack/react-easy-state";
import { Entry } from "contentful";
import range from "lodash-es/range";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";

import { TypeRecipeSkeleton, TypeV2_articleSkeleton, TypeV2_sectionSkeleton } from "../../common/types/contentful";
import { initializeWithDefaultData, isDataAvailable, isDefaultData, isWaitingForData } from "../../common/utils/asyncDataUtils";

import contentStore from "../../stores/cms/contentStore";

import theme from "../../themes/theme";
import CallToAction from "./CallToAction";
import RecipeTeaser from "./recipe/RecipeTeaser";
import SectionNavigator from "./SectionNavigator";

type SectionProps = {
   id: string;
   section: Entry<TypeV2_sectionSkeleton, undefined, "nb_NO">;
};
const Section = ({ id, section }: SectionProps) => {
   console.log("Displaying section", section);

   if (!contentStore.sectionContent[id]) {
      contentStore.sectionContent[id] = initializeWithDefaultData([]);
   }
   const sectionContent = contentStore.sectionContent[id];

   if (isDefaultData(sectionContent)) {
      void contentStore.fetchSectionContentForId(id);
   }
   console.log("Section content", sectionContent);

   return (
      <>
         {!!section.fields.title && (
            <Helmet>
               <title>{`${section.fields.title} - ${theme.siteName}`}</title>
            </Helmet>
         )}
         <SectionNavigator />
         {isDataAvailable(sectionContent) && (
            <Row xs={1} sm={2} md={3} lg={4} className="g-3">
               {sectionContent.data.map((object) => {
                  if (object.sys.contentType.sys.id === "recipe") {
                     return (
                        <RecipeTeaser
                           key={`recipe-teaser-${object.sys.id}`}
                           content={object as Entry<TypeRecipeSkeleton, undefined, string>}
                        />
                     );
                  } else {
                     return (
                        <CallToAction
                           key={"cta-" + object.sys.id}
                           content={object as Entry<TypeV2_articleSkeleton, undefined, string>}
                        />
                     );
                  }
               })}
            </Row>
         )}
         {(isDefaultData(sectionContent) || isWaitingForData(sectionContent)) && (
            <Row>
               {range(6).map((x) => (
                  <Col key={"skeleton" + x} sm={12} lg={6}>
                     <Skeleton width={600} height={487} />
                  </Col>
               ))}
            </Row>
         )}
      </>
   );
};

export default view(Section);
