import { Col, Row } from "react-bootstrap";

import { IngredientLineType } from "../../../common/types/recipeTypes";
import useSupportedLocale from "../../../common/utils/useSupportedLocale";

type IngredientLineProps = {
   ingredientLine: IngredientLineType;
   multiplier: number;
};

const IngredientLine = ({ ingredientLine, multiplier }: IngredientLineProps) => {
   const { amount, ingredient, unit, preText, postText } = ingredientLine;
   const locale = useSupportedLocale();
   const formatter = new Intl.NumberFormat(locale, { maximumFractionDigits: 2 });
   const adjustedAmount = amount * multiplier;
   const formattedAmount = formatter.format(adjustedAmount);

   const unitName = formattedAmount === "1" ? unit.singular : unit.plural;
   const ingredientName = formattedAmount === "1" ? ingredient.singular : ingredient.plural || ingredient.singular;
   const preTextSpaced = preText ? `${preText} `.replace("  ", " ") : "";
   const postTextSpaced = postText ? (/\w/.test(postText.charAt(0)) ? ` ${postText}` : postText) : "";
   const text = `${preTextSpaced}${ingredientName}${postTextSpaced}`;

   return (
      <tr className="ingredient-line">
         <td className="ingredient-line--quantity py-1">
            <>{formattedAmount}</>&nbsp;<>{unitName}</>
         </td>
         <td className="ingredient-line--name p-1">{text}</td>
      </tr>
   );
};

export default IngredientLine;
