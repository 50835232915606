import { view } from "@risingstack/react-easy-state";
import isNil from "lodash-es/isNil";
import isNumber from "lodash-es/isNumber";

import { SimplifiedProduct } from "../common/types/productTypes";
import { formatPrice } from "../common/utils";

type PricePerItemProps = {
   product: SimplifiedProduct;
   className?: string;
};

const PricePerItem = ({ product, className = "" }: PricePerItemProps) => {
   const stk =
      product.unit === "STK" || isNil(product?.conversionFactor) || !isNumber(product.conversionFactor)
         ? 1
         : product.conversionFactor;

   return (
      <div className={className}>
         {stk.toFixed(0)} stk á {formatPrice(!isNil(product.price) ? product.price / stk : null)}
      </div>
   );
};

export default view(PricePerItem);
