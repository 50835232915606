import { PlusSmallIcon } from "@heroicons/react/24/outline";
import { view } from "@risingstack/react-easy-state";
import { chunk } from "lodash-es";
import { useState } from "react";
import { Collapse } from "react-bootstrap";

import Button from "./Button";

type ExpandableListProps = {
   items: React.ReactElement[];
   cutoff?: number;
};

const ExpandableList = ({ items, cutoff = 3 }: ExpandableListProps) => {
   const [lastVisibleListIndex, setLastVisibleListIndex] = useState(0);
   const chunkList = chunk(items, cutoff);
   return (
      <>
         {chunkList.map((list, index) => {
            return (
               <div key={`favoritelists-list-${index}`}>
                  <Collapse in={index <= lastVisibleListIndex}>
                     <div id={`visible-list-${index}`} className="d-flex flex-column">
                        {index <= lastVisibleListIndex && list.map((element) => element)}
                     </div>
                  </Collapse>
                  {index - 1 === lastVisibleListIndex && (
                     <Button
                        variant="secondary"
                        className="main p-1 my-2 h-fit w-fit"
                        onClick={() => setLastVisibleListIndex(lastVisibleListIndex + 1)}
                        aria-controls={`visible-list-${index}`}
                     >
                        Vis flere
                        <PlusSmallIcon className="small-icon me-1 w-1" />
                     </Button>
                  )}
               </div>
            );
         })}
      </>
   );
};

export default view(ExpandableList);
