import { ClockIcon } from "@heroicons/react/24/outline";
import { isInteger } from "lodash-es";

type RecipePreparationTimeProps = {
   workTime?: number | null;
   workTimeRange?: number | null;
};

const RecipePreparationTime = ({ workTime, workTimeRange }: RecipePreparationTimeProps) => {
   if (typeof workTime === "number" && Number.isInteger(workTime)) {
      const text =
         typeof workTimeRange === "number" && isInteger(workTimeRange) && workTimeRange > workTime
            ? `${workTime} - ${workTimeRange} min`
            : `${workTime} min`;
      return (
         <div className="recipe-preparation-time d-flex align-items-center">
            <ClockIcon />
            <div>{text}</div>
         </div>
      );
   }
   return <></>;
};

export default RecipePreparationTime;
