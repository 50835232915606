import { view } from "@risingstack/react-easy-state";

import Categories from "./Categories";
import DeliveryInfo from "./DeliveryInfo";
import DesktopSearchBar from "./DesktopSearchBar";

const DesktopHeader = () => (
   <>
      <DesktopSearchBar />
      <Categories />
      <DeliveryInfo />
   </>
);

export default view(DesktopHeader);
