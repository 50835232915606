import { ConfigFile, Setting } from "./ProjectConfig";
export var isUndefined = function (comp) { return comp === void 0; };
export function delay(delayMs, obtainCancel) {
    var timerId;
    var promise = new Promise(function (resolve) { return timerId = setTimeout(resolve, delayMs); });
    obtainCancel === null || obtainCancel === void 0 ? void 0 : obtainCancel(function () { return clearTimeout(timerId); });
    return promise;
}
;
export function getSettingsFromConfig(json) {
    return Object.fromEntries(Object.entries(json[ConfigFile.FeatureFlags]).map(function (_a) {
        var key = _a[0], value = _a[1];
        return [key, Setting.fromJson(value)];
    }));
}
export function getTimestampAsDate(projectConfig) {
    return projectConfig ? new Date(projectConfig.Timestamp) : void 0;
}
export function errorToString(err, includeStackTrace) {
    if (includeStackTrace === void 0) { includeStackTrace = false; }
    return err instanceof Error
        ? includeStackTrace && err.stack ? err.stack : err.toString()
        : err + "";
}
