import { ArrowLeftOnRectangleIcon, UserIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { view } from "@risingstack/react-easy-state";
import { useRef, useState } from "react";
import { Overlay, Popover } from "react-bootstrap";
import { Link, useLocation } from "wouter";

import { FEATURE_NAME } from "../../common/types/featureTypes";

import authStore from "../../stores/auth/authStore";
import loginState from "../../stores/auth/loginState";
import featuresStore from "../../stores/features/featuresStore";

import NotificationsBadge from "../../account/notifications/NotificationsBadge";

const UserMenu = () => {
   const [_, redirectTo] = useLocation();
   const [show, setShow] = useState(false);
   const buttonRef = useRef<HTMLButtonElement>(null);

   const activateSwitchCompany = (e: React.MouseEvent) => {
      loginState.transitionTo("COMPANY_SELECTION");
      e.preventDefault();
   };

   const showSwitchCompany = authStore.companies && authStore.companies.length > 1;

   const logoutAndRedirect = () => {
      redirectTo("/");
      loginState.transitionTo("NOT_LOGGED_IN");
   };

   const handleClick = () => {
      setShow(!show);
   };

   const handleFirstFocusableKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
      if (show && e.key === "Tab" && e.shiftKey) {
         const buttonElement = buttonRef.current;
         if (buttonElement) {
            buttonElement?.focus();
            e.preventDefault();
         }
      }
   };

   const handleLastFocusableKeyDown = (e: React.KeyboardEvent<HTMLLIElement>) => {
      if (show && e.key === "Tab" && !e.shiftKey) {
         const buttonElement = buttonRef.current;
         if (buttonElement) {
            buttonElement?.focus();
            e.preventDefault();
         }
      }
   };

   const handleButtonKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
      if (show && e.key === "Tab" && !e.shiftKey) {
         const firstLink = document?.querySelector("#user-menu ul") as HTMLAnchorElement;
         console.log({ firstLink });
         firstLink?.focus();
      }
   };

   const popover = (
      <Popover className="user-drop-down" id="user-menu">
         <ul
            className="list-unstyled"
            tabIndex={0}
            onClick={() => {
               buttonRef.current?.focus();
               setShow(false);
            }}
         >
            {showSwitchCompany && (
               <li className="border-bottom">
                  <a href="#" onClick={activateSwitchCompany}>
                     Bytt firma
                  </a>
               </li>
            )}
            <li>
               <Link to={"/customer/account"} onKeyDown={handleFirstFocusableKeyDown}>
                  Brukere og innlogging
               </Link>
            </li>
            <li>
               <Link to={"/customer/deliveries"}>Leveringsdager</Link>
            </li>
            <li>
               <Link to={"/customer/orders"}>Ordrer</Link>
            </li>
            <li>
               <Link to={"/customer/favorites"}>Favorittlister</Link>
            </li>
            {featuresStore.hasCustomerFeature(FEATURE_NAME.subscriptionOrderAvailable) && (
               <li>
                  <Link to={"/customer/subscriptions"}>Abonnement</Link>
               </li>
            )}
            {featuresStore.hasCustomerFeature(FEATURE_NAME.invoicePaymentAvailable) && (
               <li>
                  <Link to={"/customer/invoices"}>Fakturaer</Link>
               </li>
            )}
            {featuresStore.claimsEnabled && (
               <li>
                  <Link to={"/customer/claims"}>Reklamasjoner</Link>
               </li>
            )}
            {featuresStore.notificationsEnabled && (
               <li>
                  <NotificationsBadge className="float-right" />
                  <Link to={"/customer/notifications"}>Varslinger</Link>
               </li>
            )}
            <li>
               <Link to={"/customer/companyInfo"}>Bedriftsinformasjon</Link>
            </li>
            <li role="button" onClick={logoutAndRedirect} tabIndex={0} onKeyDown={handleLastFocusableKeyDown}>
               <ArrowLeftOnRectangleIcon /> Logg ut
            </li>
         </ul>
      </Popover>
   );

   return (
      <div>
         <button
            className="user-menu-button user-select-none d-flex"
            onClick={handleClick}
            onKeyDown={handleButtonKeyDown}
            aria-haspopup="true"
            aria-controls="user-menu"
            aria-expanded={show}
            ref={buttonRef}
         >
            <div className="align-self-center flex-shrink-1 position-relative">
               {featuresStore.notificationsEnabled && (
                  <div className="notifications-user-icon-position">
                     <NotificationsBadge />
                  </div>
               )}
               <UserIcon className="user-icon" />
            </div>
            <div>
               <div>
                  Min konto <ChevronDownIcon className="menu-icon" />
               </div>
               <div className="customer-number">{authStore.currentCompany}</div>
            </div>
         </button>
         <Overlay target={buttonRef.current} rootClose={true} show={show} onHide={() => setShow(false)} placement="bottom">
            {popover}
         </Overlay>
      </div>
   );
};

export default view(UserMenu);
