import { __assign, __awaiter, __generator } from "tslib";
import { AutoPollConfigService } from "./AutoPollConfigService";
import { AutoPollOptions, LazyLoadOptions, ManualPollOptions, PollingMode } from "./ConfigCatClientOptions";
import { RefreshResult } from "./ConfigServiceBase";
import { OverrideBehaviour } from "./FlagOverrides";
import { LazyLoadConfigService } from "./LazyLoadConfigService";
import { ManualPollConfigService } from "./ManualPollConfigService";
import { ConfigFile } from "./ProjectConfig";
import { checkSettingsAvailable, ensureAllowedDefaultValue, evaluate, evaluateAll, evaluateAllVariationIds, evaluateVariationId, evaluationDetailsFromDefaultValue, evaluationDetailsFromDefaultVariationId, RolloutEvaluator } from "./RolloutEvaluator";
import { errorToString, getSettingsFromConfig, getTimestampAsDate } from "./Utils";
var ConfigCatClientCache = /** @class */ (function () {
    function ConfigCatClientCache() {
        this.instances = {};
    }
    ConfigCatClientCache.prototype.getOrCreate = function (options, configCatKernel) {
        var instance;
        var cachedInstance = this.instances[options.apiKey];
        if (cachedInstance) {
            var weakRef = cachedInstance[0];
            instance = weakRef.deref();
            if (instance) {
                return [instance, true];
            }
        }
        var token = {};
        instance = new ConfigCatClient(options, configCatKernel, token);
        this.instances[options.apiKey] = [new WeakRef(instance), token];
        return [instance, false];
    };
    ConfigCatClientCache.prototype.remove = function (sdkKey, cacheToken) {
        var cachedInstance = this.instances[sdkKey];
        if (cachedInstance) {
            var weakRef = cachedInstance[0], token = cachedInstance[1];
            var instanceIsAvailable = !!weakRef.deref();
            if (!instanceIsAvailable || token === cacheToken) {
                delete this.instances[sdkKey];
                return instanceIsAvailable;
            }
        }
        return false;
    };
    ConfigCatClientCache.prototype.clear = function () {
        var removedInstances = [];
        for (var _i = 0, _a = Object.entries(this.instances); _i < _a.length; _i++) {
            var _b = _a[_i], sdkKey = _b[0], weakRef = _b[1][0];
            var instance = weakRef.deref();
            if (instance) {
                removedInstances.push(instance);
            }
            delete this.instances[sdkKey];
        }
        return removedInstances;
    };
    return ConfigCatClientCache;
}());
export { ConfigCatClientCache };
var clientInstanceCache = new ConfigCatClientCache();
var ConfigCatClient = /** @class */ (function () {
    function ConfigCatClient(options, configCatKernel, cacheToken) {
        var _a;
        this.cacheToken = cacheToken;
        /** @inheritdoc */
        this.addListener = this.on;
        /** @inheritdoc */
        this.off = this.removeListener;
        if (!options) {
            throw new Error("Invalid 'options' value");
        }
        this.options = options;
        this.options.logger.debug('Initializing ConfigCatClient. Options: ' + JSON.stringify(this.options));
        if (!configCatKernel) {
            throw new Error("Invalid 'configCatKernel' value");
        }
        if (!configCatKernel.configFetcher) {
            throw new Error("Invalid 'configCatKernel.configFetcher' value");
        }
        if (options.defaultUser) {
            this.setDefaultUser(options.defaultUser);
        }
        this.evaluator = new RolloutEvaluator(options.logger);
        if (((_a = options.flagOverrides) === null || _a === void 0 ? void 0 : _a.behaviour) != OverrideBehaviour.LocalOnly) {
            var configServiceClass = options instanceof AutoPollOptions ? AutoPollConfigService :
                options instanceof ManualPollOptions ? ManualPollConfigService :
                    options instanceof LazyLoadOptions ? LazyLoadConfigService :
                        (function () { throw new Error("Invalid 'options' value"); })();
            this.configService = new configServiceClass(configCatKernel.configFetcher, options);
        }
        else {
            this.options.hooks.emit("clientReady");
        }
        this.suppressFinalize = registerForFinalization(this, { sdkKey: options.apiKey, cacheToken: cacheToken, configService: this.configService, logger: options.logger });
    }
    Object.defineProperty(ConfigCatClient, "instanceCache", {
        get: function () { return clientInstanceCache; },
        enumerable: false,
        configurable: true
    });
    ;
    ConfigCatClient.get = function (sdkKey, pollingMode, options, configCatKernel) {
        if (!sdkKey) {
            throw new Error("Invalid 'sdkKey' value");
        }
        var optionsClass = pollingMode === PollingMode.AutoPoll ? AutoPollOptions :
            pollingMode === PollingMode.ManualPoll ? ManualPollOptions :
                pollingMode === PollingMode.LazyLoad ? LazyLoadOptions :
                    (function () { throw new Error("Invalid 'pollingMode' value"); })();
        var actualOptions = new optionsClass(sdkKey, configCatKernel.sdkType, configCatKernel.sdkVersion, options, configCatKernel.cache, configCatKernel.eventEmitterFactory);
        var _a = clientInstanceCache.getOrCreate(actualOptions, configCatKernel), instance = _a[0], instanceAlreadyCreated = _a[1];
        if (instanceAlreadyCreated && options) {
            actualOptions.logger.warn("Client for SDK key '".concat(sdkKey, "' is already created and will be reused; configuration action is being ignored."));
        }
        return instance;
    };
    ConfigCatClient.finalize = function (data) {
        // Safeguard against situations where user forgets to dispose of the client instance.
        var _a;
        (_a = data.logger) === null || _a === void 0 ? void 0 : _a.debug("finalize() called");
        if (data.cacheToken) {
            clientInstanceCache.remove(data.sdkKey, data.cacheToken);
        }
        ConfigCatClient.close(data.configService, data.logger);
    };
    ConfigCatClient.close = function (configService, logger, hooks) {
        logger === null || logger === void 0 ? void 0 : logger.debug("close() called");
        hooks === null || hooks === void 0 ? void 0 : hooks.tryDisconnect();
        configService === null || configService === void 0 ? void 0 : configService.dispose();
    };
    ConfigCatClient.prototype.dispose = function () {
        var options = this.options;
        options.logger.debug("dispose() called");
        if (this.cacheToken) {
            clientInstanceCache.remove(options.apiKey, this.cacheToken);
        }
        ConfigCatClient.close(this.configService, options.logger, options.hooks);
        this.suppressFinalize();
    };
    ConfigCatClient.disposeAll = function () {
        var removedInstances = clientInstanceCache.clear();
        var errors;
        for (var _i = 0, removedInstances_1 = removedInstances; _i < removedInstances_1.length; _i++) {
            var instance = removedInstances_1[_i];
            try {
                ConfigCatClient.close(instance.configService, instance.options.logger, instance.options.hooks);
                instance.suppressFinalize();
            }
            catch (err) {
                errors !== null && errors !== void 0 ? errors : (errors = []);
                errors.push(err);
            }
        }
        if (errors) {
            throw typeof AggregateError !== "undefined" ? new AggregateError(errors) : errors.pop();
        }
    };
    ConfigCatClient.prototype.getValue = function (key, defaultValue, callback, user) {
        this.options.logger.debug("getValue() called.");
        this.getValueAsync(key, defaultValue, user).then(callback);
    };
    ConfigCatClient.prototype.getValueAsync = function (key, defaultValue, user) {
        return __awaiter(this, void 0, void 0, function () {
            var value, evaluationDetails, remoteConfig, settings, err_1;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.options.logger.debug("getValueAsync() called.");
                        remoteConfig = null;
                        user !== null && user !== void 0 ? user : (user = this.defaultUser);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        ensureAllowedDefaultValue(defaultValue);
                        settings = void 0;
                        return [4 /*yield*/, this.getSettingsAsync()];
                    case 2:
                        _a = _b.sent(), settings = _a[0], remoteConfig = _a[1];
                        evaluationDetails = evaluate(this.evaluator, settings, key, defaultValue, user, remoteConfig, this.options.logger);
                        value = evaluationDetails.value;
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _b.sent();
                        this.options.logger.error("Error occurred in getValueAsync().", err_1);
                        evaluationDetails = evaluationDetailsFromDefaultValue(key, defaultValue, getTimestampAsDate(remoteConfig), user, errorToString(err_1), err_1);
                        value = defaultValue;
                        return [3 /*break*/, 4];
                    case 4:
                        this.options.hooks.emit("flagEvaluated", evaluationDetails);
                        return [2 /*return*/, value];
                }
            });
        });
    };
    ConfigCatClient.prototype.getValueDetails = function (key, defaultValue, callback, user) {
        this.options.logger.debug("getValueDetails() called.");
        this.getValueDetailsAsync(key, defaultValue, user).then(callback);
    };
    ConfigCatClient.prototype.getValueDetailsAsync = function (key, defaultValue, user) {
        return __awaiter(this, void 0, void 0, function () {
            var evaluationDetails, remoteConfig, settings, err_2;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.options.logger.debug("getValueDetailsAsync() called.");
                        remoteConfig = null;
                        user !== null && user !== void 0 ? user : (user = this.defaultUser);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        ensureAllowedDefaultValue(defaultValue);
                        settings = void 0;
                        return [4 /*yield*/, this.getSettingsAsync()];
                    case 2:
                        _a = _b.sent(), settings = _a[0], remoteConfig = _a[1];
                        evaluationDetails = evaluate(this.evaluator, settings, key, defaultValue, user, remoteConfig, this.options.logger);
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _b.sent();
                        this.options.logger.error("Error occurred in getValueDetailsAsync().", err_2);
                        evaluationDetails = evaluationDetailsFromDefaultValue(key, defaultValue, getTimestampAsDate(remoteConfig), user, errorToString(err_2), err_2);
                        return [3 /*break*/, 4];
                    case 4:
                        this.options.hooks.emit("flagEvaluated", evaluationDetails);
                        return [2 /*return*/, evaluationDetails];
                }
            });
        });
    };
    ConfigCatClient.prototype.forceRefresh = function (callback) {
        this.options.logger.debug("forceRefresh() called.");
        this.forceRefreshAsync().then(callback);
    };
    ConfigCatClient.prototype.forceRefreshAsync = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result, err_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.options.logger.debug("forceRefreshAsync() called.");
                        if (!this.configService) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.configService.refreshConfigAsync()];
                    case 2:
                        result = (_a.sent())[0];
                        return [2 /*return*/, result];
                    case 3:
                        err_3 = _a.sent();
                        this.options.logger.error("Error occurred in forceRefreshAsync().", err_3);
                        return [2 /*return*/, RefreshResult.failure(errorToString(err_3), err_3)];
                    case 4: return [3 /*break*/, 6];
                    case 5: return [2 /*return*/, RefreshResult.failure("Client is configured to use the LocalOnly override behavior, which prevents making HTTP requests.")];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ConfigCatClient.prototype.getAllKeys = function (callback) {
        this.options.logger.debug("getAllKeys() called.");
        this.getAllKeysAsync().then(callback);
    };
    ConfigCatClient.prototype.getAllKeysAsync = function () {
        return __awaiter(this, void 0, void 0, function () {
            var settings, err_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.options.logger.debug("getAllKeysAsync() called.");
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.getSettingsAsync()];
                    case 2:
                        settings = (_a.sent())[0];
                        if (!checkSettingsAvailable(settings, this.options.logger, ", returning empty array")) {
                            return [2 /*return*/, []];
                        }
                        return [2 /*return*/, Object.keys(settings)];
                    case 3:
                        err_4 = _a.sent();
                        this.options.logger.error("Error occurred in getAllKeysAsync().", err_4);
                        return [2 /*return*/, []];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ConfigCatClient.prototype.getVariationId = function (key, defaultVariationId, callback, user) {
        this.options.logger.debug("getVariationId() called.");
        this.getVariationIdAsync(key, defaultVariationId, user).then(callback);
    };
    ConfigCatClient.prototype.getVariationIdAsync = function (key, defaultVariationId, user) {
        return __awaiter(this, void 0, void 0, function () {
            var variationId, evaluationDetails, remoteConfig, settings, err_5;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.options.logger.debug("getVariationIdAsync() called.");
                        remoteConfig = null;
                        user !== null && user !== void 0 ? user : (user = this.defaultUser);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        settings = void 0;
                        return [4 /*yield*/, this.getSettingsAsync()];
                    case 2:
                        _a = _b.sent(), settings = _a[0], remoteConfig = _a[1];
                        evaluationDetails = evaluateVariationId(this.evaluator, settings, key, defaultVariationId, user, remoteConfig, this.options.logger);
                        variationId = evaluationDetails.variationId;
                        return [3 /*break*/, 4];
                    case 3:
                        err_5 = _b.sent();
                        this.options.logger.error("Error occurred in getVariationIdAsync().", err_5);
                        evaluationDetails = evaluationDetailsFromDefaultVariationId(key, defaultVariationId, getTimestampAsDate(remoteConfig), user, errorToString(err_5), err_5);
                        variationId = defaultVariationId;
                        return [3 /*break*/, 4];
                    case 4:
                        this.options.hooks.emit("flagEvaluated", evaluationDetails);
                        return [2 /*return*/, variationId];
                }
            });
        });
    };
    ConfigCatClient.prototype.getAllVariationIds = function (callback, user) {
        this.options.logger.debug("getAllVariationIds() called.");
        this.getAllVariationIdsAsync(user).then(callback);
    };
    ConfigCatClient.prototype.getAllVariationIdsAsync = function (user) {
        return __awaiter(this, void 0, void 0, function () {
            var result, evaluationDetailsArray, _a, settings, remoteConfig, errors, err_6, _i, evaluationDetailsArray_1, evaluationDetail;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.options.logger.debug("getAllVariationIdsAsync() called.");
                        user !== null && user !== void 0 ? user : (user = this.defaultUser);
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.getSettingsAsync()];
                    case 2:
                        _a = _c.sent(), settings = _a[0], remoteConfig = _a[1];
                        errors = void 0;
                        _b = evaluateAllVariationIds(this.evaluator, settings, user, remoteConfig, this.options.logger), evaluationDetailsArray = _b[0], errors = _b[1];
                        if (errors === null || errors === void 0 ? void 0 : errors.length) {
                            throw typeof AggregateError !== "undefined" ? new AggregateError(errors) : errors.pop();
                        }
                        result = evaluationDetailsArray.filter(function (details) { return details !== null && details !== void 0; }).map(function (details) { return details.variationId; });
                        return [3 /*break*/, 4];
                    case 3:
                        err_6 = _c.sent();
                        this.options.logger.error("Error occurred in getAllVariationIdsAsync().", err_6);
                        evaluationDetailsArray !== null && evaluationDetailsArray !== void 0 ? evaluationDetailsArray : (evaluationDetailsArray = []);
                        result = [];
                        return [3 /*break*/, 4];
                    case 4:
                        for (_i = 0, evaluationDetailsArray_1 = evaluationDetailsArray; _i < evaluationDetailsArray_1.length; _i++) {
                            evaluationDetail = evaluationDetailsArray_1[_i];
                            this.options.hooks.emit("flagEvaluated", evaluationDetail);
                        }
                        return [2 /*return*/, result];
                }
            });
        });
    };
    ConfigCatClient.prototype.getKeyAndValue = function (variationId, callback) {
        this.options.logger.debug("getKeyAndValue() called.");
        this.getKeyAndValueAsync(variationId).then(callback);
    };
    ConfigCatClient.prototype.getKeyAndValueAsync = function (variationId) {
        return __awaiter(this, void 0, void 0, function () {
            var settings, _i, _a, _b, settingKey, setting, rolloutRules, i, rolloutRule, percentageItems, i, percentageItem, err_7;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.options.logger.debug("getKeyAndValueAsync() called.");
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.getSettingsAsync()];
                    case 2:
                        settings = (_c.sent())[0];
                        if (!checkSettingsAvailable(settings, this.options.logger, ", returning null")) {
                            return [2 /*return*/, null];
                        }
                        for (_i = 0, _a = Object.entries(settings); _i < _a.length; _i++) {
                            _b = _a[_i], settingKey = _b[0], setting = _b[1];
                            if (variationId === setting.variationId) {
                                return [2 /*return*/, new SettingKeyValue(settingKey, setting.value)];
                            }
                            rolloutRules = settings[settingKey].rolloutRules;
                            if (rolloutRules && rolloutRules.length > 0) {
                                for (i = 0; i < rolloutRules.length; i++) {
                                    rolloutRule = rolloutRules[i];
                                    if (variationId === rolloutRule.variationId) {
                                        return [2 /*return*/, new SettingKeyValue(settingKey, rolloutRule.value)];
                                    }
                                }
                            }
                            percentageItems = settings[settingKey].rolloutPercentageItems;
                            if (percentageItems && percentageItems.length > 0) {
                                for (i = 0; i < percentageItems.length; i++) {
                                    percentageItem = percentageItems[i];
                                    if (variationId === percentageItem.variationId) {
                                        return [2 /*return*/, new SettingKeyValue(settingKey, percentageItem.value)];
                                    }
                                }
                            }
                        }
                        this.options.logger.error("Could not find the setting for the given variation ID: " + variationId);
                        return [3 /*break*/, 4];
                    case 3:
                        err_7 = _c.sent();
                        this.options.logger.error("Error occurred in getKeyAndValueAsync().", err_7);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, null];
                }
            });
        });
    };
    ConfigCatClient.prototype.getAllValues = function (callback, user) {
        this.options.logger.debug("getAllValues() called.");
        this.getAllValuesAsync(user).then(callback);
    };
    ConfigCatClient.prototype.getAllValuesAsync = function (user) {
        return __awaiter(this, void 0, void 0, function () {
            var result, evaluationDetailsArray, _a, settings, remoteConfig, errors, err_8, _i, evaluationDetailsArray_2, evaluationDetail;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.options.logger.debug("getAllValuesAsync() called.");
                        user !== null && user !== void 0 ? user : (user = this.defaultUser);
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.getSettingsAsync()];
                    case 2:
                        _a = _c.sent(), settings = _a[0], remoteConfig = _a[1];
                        errors = void 0;
                        _b = evaluateAll(this.evaluator, settings, user, remoteConfig, this.options.logger), evaluationDetailsArray = _b[0], errors = _b[1];
                        if (errors === null || errors === void 0 ? void 0 : errors.length) {
                            throw typeof AggregateError !== "undefined" ? new AggregateError(errors) : errors.pop();
                        }
                        result = evaluationDetailsArray.map(function (details) { return new SettingKeyValue(details.key, details.value); });
                        return [3 /*break*/, 4];
                    case 3:
                        err_8 = _c.sent();
                        this.options.logger.error("Error occurred in getAllValuesAsync().", err_8);
                        evaluationDetailsArray !== null && evaluationDetailsArray !== void 0 ? evaluationDetailsArray : (evaluationDetailsArray = []);
                        result = [];
                        return [3 /*break*/, 4];
                    case 4:
                        for (_i = 0, evaluationDetailsArray_2 = evaluationDetailsArray; _i < evaluationDetailsArray_2.length; _i++) {
                            evaluationDetail = evaluationDetailsArray_2[_i];
                            this.options.hooks.emit("flagEvaluated", evaluationDetail);
                        }
                        return [2 /*return*/, result];
                }
            });
        });
    };
    ConfigCatClient.prototype.getAllValueDetails = function (callback, user) {
        this.options.logger.debug("getAllValueDetails() called.");
        this.getAllValueDetailsAsync(user).then(callback);
    };
    ConfigCatClient.prototype.getAllValueDetailsAsync = function (user) {
        return __awaiter(this, void 0, void 0, function () {
            var evaluationDetailsArray, _a, settings, remoteConfig, errors, err_9, _i, evaluationDetailsArray_3, evaluationDetail;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.options.logger.debug("getAllValueDetailsAsync() called.");
                        user !== null && user !== void 0 ? user : (user = this.defaultUser);
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.getSettingsAsync()];
                    case 2:
                        _a = _c.sent(), settings = _a[0], remoteConfig = _a[1];
                        errors = void 0;
                        _b = evaluateAll(this.evaluator, settings, user, remoteConfig, this.options.logger), evaluationDetailsArray = _b[0], errors = _b[1];
                        if (errors === null || errors === void 0 ? void 0 : errors.length) {
                            throw typeof AggregateError !== "undefined" ? new AggregateError(errors) : errors.pop();
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        err_9 = _c.sent();
                        this.options.logger.error("Error occurred in getAllValueDetailsAsync().", err_9);
                        evaluationDetailsArray !== null && evaluationDetailsArray !== void 0 ? evaluationDetailsArray : (evaluationDetailsArray = []);
                        return [3 /*break*/, 4];
                    case 4:
                        for (_i = 0, evaluationDetailsArray_3 = evaluationDetailsArray; _i < evaluationDetailsArray_3.length; _i++) {
                            evaluationDetail = evaluationDetailsArray_3[_i];
                            this.options.hooks.emit("flagEvaluated", evaluationDetail);
                        }
                        return [2 /*return*/, evaluationDetailsArray];
                }
            });
        });
    };
    ConfigCatClient.prototype.setDefaultUser = function (defaultUser) {
        this.defaultUser = defaultUser;
    };
    ConfigCatClient.prototype.clearDefaultUser = function () {
        this.defaultUser = void 0;
    };
    Object.defineProperty(ConfigCatClient.prototype, "isOffline", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.configService) === null || _a === void 0 ? void 0 : _a.isOffline) !== null && _b !== void 0 ? _b : true;
        },
        enumerable: false,
        configurable: true
    });
    ConfigCatClient.prototype.setOnline = function () {
        if (this.configService) {
            this.configService.setOnline();
        }
        else {
            this.options.logger.warn("Client is configured to use the LocalOnly override behavior, thus SetOnline() has no effect.");
        }
    };
    ConfigCatClient.prototype.setOffline = function () {
        var _a;
        (_a = this.configService) === null || _a === void 0 ? void 0 : _a.setOffline();
    };
    ConfigCatClient.prototype.getSettingsAsync = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var getRemoteConfigAsync, flagOverrides, remoteSettings, remoteConfig, localSettings, _b;
            var _c, _d;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        this.options.logger.debug("getSettingsAsync() called.");
                        getRemoteConfigAsync = function () { return __awaiter(_this, void 0, void 0, function () {
                            var config, json, settings;
                            var _a;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0: return [4 /*yield*/, ((_a = this.configService) === null || _a === void 0 ? void 0 : _a.getConfig())];
                                    case 1:
                                        config = _b.sent();
                                        json = config === null || config === void 0 ? void 0 : config.ConfigJSON;
                                        settings = (json === null || json === void 0 ? void 0 : json[ConfigFile.FeatureFlags]) ? getSettingsFromConfig(json) : null;
                                        return [2 /*return*/, [settings, config !== null && config !== void 0 ? config : null]];
                                }
                            });
                        }); };
                        flagOverrides = (_a = this.options) === null || _a === void 0 ? void 0 : _a.flagOverrides;
                        if (!flagOverrides) return [3 /*break*/, 7];
                        remoteSettings = void 0;
                        remoteConfig = void 0;
                        return [4 /*yield*/, flagOverrides.dataSource.getOverrides()];
                    case 1:
                        localSettings = _e.sent();
                        _b = flagOverrides.behaviour;
                        switch (_b) {
                            case OverrideBehaviour.LocalOnly: return [3 /*break*/, 2];
                            case OverrideBehaviour.LocalOverRemote: return [3 /*break*/, 3];
                            case OverrideBehaviour.RemoteOverLocal: return [3 /*break*/, 5];
                        }
                        return [3 /*break*/, 7];
                    case 2: return [2 /*return*/, [localSettings, null]];
                    case 3: return [4 /*yield*/, getRemoteConfigAsync()];
                    case 4:
                        _c = _e.sent(), remoteSettings = _c[0], remoteConfig = _c[1];
                        return [2 /*return*/, [__assign(__assign({}, (remoteSettings !== null && remoteSettings !== void 0 ? remoteSettings : {})), localSettings), remoteConfig]];
                    case 5: return [4 /*yield*/, getRemoteConfigAsync()];
                    case 6:
                        _d = _e.sent(), remoteSettings = _d[0], remoteConfig = _d[1];
                        return [2 /*return*/, [__assign(__assign({}, localSettings), (remoteSettings !== null && remoteSettings !== void 0 ? remoteSettings : {})), remoteConfig]];
                    case 7: return [4 /*yield*/, getRemoteConfigAsync()];
                    case 8: return [2 /*return*/, _e.sent()];
                }
            });
        });
    };
    /** @inheritdoc */
    ConfigCatClient.prototype.on = function (eventName, listener) {
        this.options.hooks.on(eventName, listener);
        return this;
    };
    /** @inheritdoc */
    ConfigCatClient.prototype.once = function (eventName, listener) {
        this.options.hooks.once(eventName, listener);
        return this;
    };
    /** @inheritdoc */
    ConfigCatClient.prototype.removeListener = function (eventName, listener) {
        this.options.hooks.removeListener(eventName, listener);
        return this;
    };
    /** @inheritdoc */
    ConfigCatClient.prototype.removeAllListeners = function (eventName) {
        this.options.hooks.removeAllListeners(eventName);
        return this;
    };
    /** @inheritdoc */
    ConfigCatClient.prototype.listeners = function (eventName) {
        return this.options.hooks.listeners(eventName);
    };
    /** @inheritdoc */
    ConfigCatClient.prototype.listenerCount = function (eventName) {
        return this.options.hooks.listenerCount(eventName);
    };
    /** @inheritdoc */
    ConfigCatClient.prototype.eventNames = function () {
        return this.options.hooks.eventNames();
    };
    return ConfigCatClient;
}());
export { ConfigCatClient };
var SettingKeyValue = /** @class */ (function () {
    function SettingKeyValue(settingKey, settingValue) {
        this.settingKey = settingKey;
        this.settingValue = settingValue;
    }
    return SettingKeyValue;
}());
export { SettingKeyValue };
;
var registerForFinalization = function (client, data) {
    // Use FinalizationRegistry (finalization callbacks) if the runtime provides that feature.
    if (typeof FinalizationRegistry !== "undefined") {
        var finalizationRegistry_1 = new FinalizationRegistry(function (data) { return ConfigCatClient["finalize"](data); });
        registerForFinalization = function (client, data) {
            var unregisterToken = {};
            finalizationRegistry_1.register(client, data, unregisterToken);
            return function () { return finalizationRegistry_1.unregister(unregisterToken); };
        };
    }
    // If FinalizationRegistry is unavailable, we can't really track finalization.
    // (Although we could implement something which resembles finalization callbacks using a weak map + a timer,
    // since ConfigCatClientCache also needs to keep (weak) references to the created client instances,
    // this hypothetical approach wouldn't work without a complete WeakRef polyfill,
    // which is kind of impossible (for more details, see Polyfills.ts).
    else {
        registerForFinalization = function () { return function () { }; };
    }
    return registerForFinalization(client, data);
};
