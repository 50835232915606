import { ComponentPropsWithoutRef, PropsWithChildren } from "react";
import { Link, LinkProps, useRoute } from "wouter";

type NavLinkProps = {
   to: string;
};

const NavLink = ({ to, children, ...rest }: PropsWithChildren<NavLinkProps & LinkProps>) => {
   const [match] = useRoute(to);
   const className = match ? "active" : undefined;
   const ariaCurrent = match ? "page" : undefined;

   return (
      <Link aria-current={ariaCurrent} role="button" className={className} to={to} {...rest}>
         {children}
      </Link>
   );
};

export default NavLink;
