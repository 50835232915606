import { view } from "@risingstack/react-easy-state";
import { useRef } from "react";
import { Link } from "wouter";

import { Product, ProductTileCallbacks } from "../../common/types/productTypes";
import { AlgoliaProductHit, SEARCH_TYPE } from "../../common/types/searchTypes";
import { productImageUrl } from "../../common/utils";

import authStore from "../../stores/auth/authStore";
import productStore from "../../stores/product/productStore";
import searchStore from "../../stores/searchStore";

import AddToCartButton from "../AddToCartButton";

type ProductHitProps = {
   hit: AlgoliaProductHit;
   product?: Product;
   position: number;
   algoliaIndex: string;
   queryID: string;
};
const hide = () => searchStore.clear(SEARCH_TYPE.QuickSearch, true);

const ProductHit = ({ hit, position, algoliaIndex, queryID, product }: ProductHitProps) => {
   const imageRef = useRef<HTMLImageElement>(null);
   const productUrl = productStore.getUrl(hit);

   // Callbacks for Algolia event reporting (Personalization, A/B testing etc relies on this)
   const callbacks = {
      addToCart: (product) => {
         searchStore.reportAlgoliaAddToCart(product.sku, algoliaIndex, queryID, true, SEARCH_TYPE.QuickSearch);
      },
      click: (product, _listName, position) => {
         searchStore.reportAlgoliaClick(product.sku, algoliaIndex, queryID, position, true, SEARCH_TYPE.QuickSearch);
      }
   } satisfies ProductTileCallbacks;

   const hitClicked = () => {
      searchStore.reportAlgoliaClick(hit.sku, algoliaIndex, queryID, position, true, SEARCH_TYPE.QuickSearch);
      hide();
   };

   return (
      <div className="hit d-flex justify-content-between align-items-center py-2 px-1 pe-lg-4 quick-search-hit">
         <Link onClick={hitClicked} to={productUrl} className="d-flex align-items-center">
            <img
               alt={`Produktbilde av ${hit.name}`}
               loading="lazy"
               width={48}
               src={productImageUrl(hit.sku, 48)}
               className="p-0"
               ref={imageRef}
            />
            <div className="mx-2 flex-fill quick-search-text">{hit.name}</div>
         </Link>
         {authStore.isLoggedIn() && product && (
            <div className="fw-25">
               <AddToCartButton
                  product={product}
                  qty={1}
                  listName="Quick Search"
                  callbacks={callbacks}
                  position={position}
                  imageRefFn={() => imageRef}
               />
            </div>
         )}
      </div>
   );
};

export default view(ProductHit);
