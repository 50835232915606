import { store } from "@risingstack/react-easy-state";
import { getISODay, isAfter, isFuture, isSameDay, parseISO, parseJSON } from "date-fns";
import { has, isEmpty } from "lodash-es";
import defaultTo from "lodash-es/defaultTo";
import isNil from "lodash-es/isNil";
import isObject from "lodash-es/isObject";
import isString from "lodash-es/isString";
import isUndefined from "lodash-es/isUndefined";
import omit from "lodash-es/omit";
import some from "lodash-es/some";

import { apiClient } from "../../common/apiClient";
import { MissingDeliveryDatesError } from "../../common/errors";
import {
   CalendarClickEvent,
   CalendarStyle,
   DeliveryDate,
   DeliveryDateResponse,
   OrderTypeWithDeliveryDates,
   PickupData
} from "../../common/types/deliveryTypes";
import { FEATURE_NAME } from "../../common/types/featureTypes";
import { ORDERTYPE } from "../../common/types/productOrderTypes";
import {
   AsyncData,
   initializeWithDefaultData,
   setAsDataAvailable,
   setAsErrorOccured,
   setAsLoading,
   setAsWaitingForData
} from "../../common/utils/asyncDataUtils";
import { removeUnwantedDates } from "../../common/utils/dateUtils";

import theme from "../../themes/theme";
import authStore from "../auth/authStore";
import loginState from "../auth/loginState";
import cartStore from "../cartStore";
import featuresStore from "../features/featuresStore";
import productStore from "../product/productStore";
import uiStore from "../uiStore";

type DeliveryDatesByOrderType = {
   [type in ORDERTYPE]?: DeliveryDate;
};

type DeliveryDatesStore = {
   deliveryDates: AsyncData<OrderTypeWithDeliveryDates>;
   holidays: AsyncData<Date[]>;
   pickupLocation: AsyncData<Omit<PickupData, "pickupAt"> | null>;
   cancelledDates: Date[];
   currentDelivery: DeliveryDatesByOrderType;
   changingDeliveryDate: boolean;

   fetchDeliveryDates: (customerNumber: string) => Promise<void>;
   fetchHolidays: () => Promise<void>;
   clearDeliveryDates: () => void;

   getCurrentDelivery: (type?: ORDERTYPE) => DeliveryDate | null;
   getDeliveryForDate: (date: Date, orderType: ORDERTYPE) => DeliveryDate | undefined;
   changeDeliveryDate: (date: Date, orderType?: ORDERTYPE) => Promise<void>;

   getCalendarStyleForDate: (e: CalendarClickEvent) => CalendarStyle;

   getNextMatchingDeliveryDate: (requiredWeekDay: number | string | null, orderType?: ORDERTYPE) => DeliveryDate | undefined;
   getAllDeliveriesForOrderType: (orderType: ORDERTYPE) => DeliveryDate[];
   getNextDeliveryDateForOrderType: (orderType: ORDERTYPE) => DeliveryDate | null;
   selectNextDeliveryDateForOrderType: (orderType: ORDERTYPE) => void;
   getDeadlineForDelivery: (queryDate: Date, orderType: ORDERTYPE) => Date | undefined;

   isDeliveryDate: (date: Date, orderType: ORDERTYPE) => boolean;
   isCancelledDate: (date: Date) => boolean;
   isHoliday: (date: Date) => boolean;
   getAvailableOrderTypes: () => ORDERTYPE[] | null;
};

const parse2022DeliveryDates = (resp: DeliveryDateResponse) => {
   return resp.deliveryDates
      .filter((d) => isFuture(parseJSON(d.deadline)))
      .map((d) => ({
         date: parseISO(d.deliveryDate),
         deadline: parseJSON(d.deadline),
         price: 0
      }));
};
const parse2022DeliveryDatesForSubscription = (resp: DeliveryDateResponse) => {
   return resp.deliveryDates
      .filter((d) => d.subscriptionDeadline !== null && isFuture(parseJSON(d.subscriptionDeadline)))
      .map((d) => ({
         date: parseISO(d.deliveryDate),
         deadline: parseJSON(d.subscriptionDeadline),
         price: 0
      }));
};
const parse2022PickupDates = (pickupData: PickupData) => {
   return pickupData.pickupAt.map((pickupAlt) => ({
      date: parseJSON(pickupAlt.pickupAfter),
      deadline: parseJSON(pickupAlt.orderBefore),
      price: 0
   }));
};

const deliveryDatesStore: DeliveryDatesStore = store({
   deliveryDates: initializeWithDefaultData({}),
   holidays: initializeWithDefaultData([]),
   pickupLocation: initializeWithDefaultData(null),
   cancelledDates: [],
   currentDelivery: {},
   changingDeliveryDate: false,

   fetchDeliveryDates: async (customerNumber: string) => {
      if (!featuresStore.hasCustomerFeature(FEATURE_NAME.createOrderAvailable)) {
         return;
      }
      setAsWaitingForData(deliveryDatesStore.pickupLocation);
      setAsWaitingForData(deliveryDatesStore.deliveryDates);
      const token = authStore.getSessionToken();
      let deliveries: OrderTypeWithDeliveryDates = {};

      try {
         const resp: DeliveryDateResponse = await apiClient(
            `${process.env.API_HOST}/api/deliverydates_2022/${customerNumber}`,
            token
         )
            .query({ company: theme.m3CompanyNumber })
            .get()
            .json();

         if (has(resp, "deliveryDates")) {
            deliveries[ORDERTYPE.WEB] = parse2022DeliveryDates(resp);
            deliveries[ORDERTYPE.WAS] = parse2022DeliveryDatesForSubscription(resp);
         }
         deliveries = removeUnwantedDates(deliveries, [ORDERTYPE.WEB, ORDERTYPE.WAS]);
      } catch (err: unknown) {
         console.log("User has no delivery dates.", "" + err);
      }

      if (featuresStore.hasCustomerFeature(FEATURE_NAME.pickupOrderAvailable)) {
         try {
            const pickupData: PickupData = await apiClient(
               `${process.env.API_HOST}/api/deliverydates_2022/pickupdates/${theme.m3CompanyNumber}/${customerNumber}`,
               token
            )
               .get()
               .json();

            if (!isNil(pickupData)) {
               deliveries[ORDERTYPE.HPN] = parse2022PickupDates(pickupData);
            }

            setAsDataAvailable(deliveryDatesStore.pickupLocation, omit(pickupData, "pickupAt"));
         } catch (err: unknown) {
            setAsErrorOccured(deliveryDatesStore.pickupLocation, "" + err);
            console.log("User has no pick up dates.", "" + err);
         }
      } else {
         setAsErrorOccured(
            deliveryDatesStore.pickupLocation,
            "No pickup location when user don't have 'pickupOrderAvailable'-feature"
         );
      }

      if (isEmpty(deliveryDatesStore.deliveryDates)) {
         setAsErrorOccured(deliveryDatesStore.deliveryDates, "User has neither delivery dates nor pickup dates.");
      } else {
         setAsDataAvailable(deliveryDatesStore.deliveryDates, deliveries);
      }
   },

   fetchHolidays: async () => {
      setAsLoading(deliveryDatesStore.holidays);

      const holidayUrl = `${process.env.STATICDATA_HOST}/holidays.${process.env.ENV_NAME}_${uiStore.dataVersion}.json`;
      console.log("Fetching holidays from " + holidayUrl);

      return apiClient(holidayUrl)
         .get()
         .json((holidays) => {
            setAsDataAvailable(deliveryDatesStore.holidays, holidays.map(parseISO));
         })
         .catch((err) => {
            setAsErrorOccured(deliveryDatesStore.holidays, err);
         });
   },

   getCurrentDelivery: (type?: ORDERTYPE) => {
      const orderType = type || cartStore.orderType;
      return deliveryDatesStore.currentDelivery?.[orderType] || null;
   },

   clearDeliveryDates: () => {
      deliveryDatesStore.deliveryDates.data = {};
   },

   /**
    * Find first delivery date for the ordertype where delivery date and order deadline is in the future.
    * If weekday is given, make sure that the delivery date also matches the given day of week.
    *
    * @param {string | number | null} requiredWeekDay - Weekday number (1-7) or null
    * @param {ORDERTYPE} orderType - Order type to find delivery date for
    */
   getNextMatchingDeliveryDate: (requiredWeekDay = null, orderType) => {
      const deliveryDates = deliveryDatesStore.getAllDeliveriesForOrderType(defaultTo(orderType, cartStore.orderType));
      const now = new Date();
      const weekdayNumber = isString(requiredWeekDay) ? parseInt(requiredWeekDay) : requiredWeekDay;

      return deliveryDates.find(
         (dd) =>
            isAfter(dd.date, now) &&
            isAfter(dd.deadline, now) &&
            (requiredWeekDay === null || getISODay(dd.date) === weekdayNumber)
      );
   },

   getAllDeliveriesForOrderType: (orderType: ORDERTYPE) => {
      return defaultTo(deliveryDatesStore.deliveryDates.data[orderType], []);
   },

   getNextDeliveryDateForOrderType: (orderType: ORDERTYPE) => {
      const deliveriesForOrderType = deliveryDatesStore.getAllDeliveriesForOrderType(orderType);
      if (deliveriesForOrderType.length === 0) {
         return null;
      }

      // Set current delivery info to first possible delivery date for the new order type
      return deliveriesForOrderType[0];
   },

   selectNextDeliveryDateForOrderType: (orderType: ORDERTYPE) => {
      const nextDelivery = deliveryDatesStore.getNextDeliveryDateForOrderType(orderType);
      const newDelivery = isObject(nextDelivery) ? { ...nextDelivery } : null;
      if (newDelivery) {
         if (authStore.isLoggedIn()) {
            deliveryDatesStore.changeDeliveryDate(newDelivery.date, orderType);
         } else {
            deliveryDatesStore.currentDelivery[orderType] = newDelivery;
         }
      }
   },

   changeDeliveryDate: async (newDate, type: ORDERTYPE = cartStore.orderType) => {
      console.log("Changing delivery date to " + newDate.toString());

      deliveryDatesStore.changingDeliveryDate = true;
      const foundDate = deliveryDatesStore.getDeliveryForDate(newDate, type);

      if (isUndefined(foundDate)) {
         console.warn("Unable to find date selected in calendar, bug?");
         throw "Unable to find date selected in calendar";
      }

      const previousInterval = defaultTo(deliveryDatesStore.getCurrentDelivery(type)?.interval, 7);

      deliveryDatesStore.currentDelivery[type] = {
         ...foundDate
      };

      if (type === ORDERTYPE.WAS) {
         const currentWASDelivery = deliveryDatesStore.currentDelivery[ORDERTYPE.WAS];
         if (currentWASDelivery) {
            currentWASDelivery.interval = previousInterval;
         }
      }

      const currentDelivery = deliveryDatesStore.getCurrentDelivery(type);
      console.log("Selected delivery", currentDelivery);

      if (isNil(currentDelivery)) {
         throw new MissingDeliveryDatesError();
      }

      if (loginState.is("LOGGED_IN")) {
         await productStore.getAssortment(authStore.currentCompany, currentDelivery.date, cartStore.orderType);
      }
      cartStore.lostSales = [];
      deliveryDatesStore.changingDeliveryDate = false;
   },

   getDeliveryForDate: (date, orderType) => {
      const deliveries = deliveryDatesStore.getAllDeliveriesForOrderType(orderType);
      return deliveries.find((delivery) => isSameDay(delivery.date, date));
   },

   getDeadlineForDelivery: (queryDate, orderType) => {
      const delivery = deliveryDatesStore.getDeliveryForDate(queryDate, orderType);
      return delivery?.deadline;
   },

   getCalendarStyleForDate: (event) => {
      const date = event.date.toDate();

      const isDeliveryDate = deliveryDatesStore.isDeliveryDate(date, cartStore.orderType);
      const isHoliday = deliveryDatesStore.isHoliday(date);
      const isCancelled = deliveryDatesStore.isCancelledDate(date);

      if (isCancelled) {
         return {
            disabled: true,
            style: {
               backgroundColor: "#e91c24",
               color: "White"
            }
         };
      }

      const res: CalendarStyle = {
         className: (isDeliveryDate ? "delivery-date " : "") + (isHoliday ? "holiday" : "")
      };

      if (!isDeliveryDate) {
         res.disabled = true;

         if (isHoliday) {
            res.style = { color: "#e91c24" };
         }
      }

      return res;
   },

   isDeliveryDate: (queryDate, orderType) => {
      const deliveries = deliveryDatesStore.getAllDeliveriesForOrderType(orderType);
      return some(deliveries, (delivery) => isSameDay(delivery.date, queryDate));
   },

   isCancelledDate: (queryDate) => {
      return some(deliveryDatesStore.cancelledDates, (cancelledDate) => isSameDay(cancelledDate, queryDate));
   },

   isHoliday: (queryDate) => {
      return some(deliveryDatesStore.holidays.data, (holiday) => isSameDay(holiday, queryDate));
   },
   getAvailableOrderTypes: () => {
      if (!loginState.is("DELIVERY_DATES_LOADING")) {
         return null;
      }

      return Object.keys(deliveryDatesStore.deliveryDates.data) as ORDERTYPE[];
   }
} satisfies DeliveryDatesStore);

export default deliveryDatesStore;
