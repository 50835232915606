import { createClient, Entry, EntryCollection, EntrySkeletonType } from "contentful";
import isUndefined from "lodash-es/isUndefined";

import {
   TypeFooterSkeleton,
   TypeRecipeSkeleton,
   TypeV2_articleSkeleton,
   TypeV2_sectionSkeleton
} from "../../common/types/contentful";

export const PREVIEW_BASE_PATH = "_preview";

export type UrlDataWithoutFullUrl = {
   id: string;
   title: string;
   slug: string;
   type: EntryTypesWithUrl;
   parentId?: string;
   icon?: string;
};
export type UrlData = { fullUrl: string } & UrlDataWithoutFullUrl;

export type ImageWithFocusData = {
   entryId: string;
   altText: string;
   focalPoint: {
      x: number;
      y: number;
   };
   originalAssetUrl: string | undefined;
};

export type FocalPointData = {
   focalPoint: {
      x: number;
      y: number;
   };
};

type ContentfulEntry = Entry<EntrySkeletonType, undefined, string>;

export type EntryTypesWithUrl = "v2_article" | "v2_section" | "recipe";

export type ContentEntryType =
   | EntryTypesWithUrl
   | "productList"
   | "automaticGeneratedProductList"
   | "callToActionList"
   | "v2_textAndImage"
   | "warning"
   | "imageWithFocus";

export type TypeContentfulArticle = Entry<TypeV2_articleSkeleton, undefined, string>;
export type TypeContentfulSection = Entry<TypeV2_sectionSkeleton, undefined, string>;
export type TypeContentfulRecipe = Entry<TypeRecipeSkeleton, undefined, string>;

export type MenuItem = {
   title: string;
   id: string;
   url: string;
};

export const contentfulClient = (isPreviewMode: boolean) =>
   isPreviewMode
      ? createClient({
           host: "preview.contentful.com",
           space: process.env.CONTENTFUL_SPACE ?? "",
           accessToken: process.env.CONTENTFUL_PREVIEWTOKEN ?? "",
           environment: process.env.CONTENTFUL_ENV
        })
      : createClient({
           space: process.env.CONTENTFUL_SPACE ?? "",
           accessToken: process.env.CONTENTFUL_READTOKEN ?? "",
           environment: process.env.CONTENTFUL_ENV
        });

export const getArticleSlugsAndParents = (isPreviewMode: boolean) =>
   contentfulClient(isPreviewMode)
      .withoutUnresolvableLinks.getEntries<TypeV2_articleSkeleton>({
         content_type: "v2_article",
         select: [
            "sys.contentType",
            "metadata.tags",
            "fields.title",
            "fields.slug",
            "fields.parentId",
            "fields.excludeSegments",
            "fields.includeSegments"
         ],
         include: 1
      })
      .then((response) => response.items);

export const getRecipeSlugsAndParents = (isPreviewMode: boolean) =>
   contentfulClient(isPreviewMode)
      .withoutUnresolvableLinks.getEntries<TypeRecipeSkeleton>({
         content_type: "recipe",
         select: ["sys.contentType", "metadata.tags", "fields.title", "fields.slug", "fields.parentId"],
         include: 1
      })
      .then((response) => response.items);

export const getArticlesInSection = (sectionId: string, isPreviewMode: boolean) =>
   contentfulClient(isPreviewMode)
      .getEntries<TypeV2_articleSkeleton>({
         content_type: "v2_article",
         "fields.visibleInSections": sectionId,
         select: ["fields.title", "fields.callToAction", "fields.headerImage", "sys.contentType"],
         include: 0
      })
      .then((response) => response.items);

export const getRecipesInSection = (sectionId: string, isPreviewMode: boolean) =>
   contentfulClient(isPreviewMode)
      .getEntries<TypeRecipeSkeleton>({
         content_type: "recipe",
         "fields.visibleInSections": sectionId,
         select: ["fields.title", "fields.recipeData", "sys.contentType"],
         include: 0
      })
      .then((response) => response.items);

export const getFooterContent = (isPreviewMode: boolean) =>
   contentfulClient(isPreviewMode)
      .getEntries<TypeFooterSkeleton>({
         content_type: "footer",
         select: ["fields.contactNumber", "fields.openingHours", "fields.linksRichText"],
         include: 0,
         limit: 1
      })
      .then((response) => response.items);

export const getSectionsSlugsAndParents = (isPreviewMode: boolean) =>
   contentfulClient(isPreviewMode)
      .getEntries<TypeV2_sectionSkeleton>({
         content_type: "v2_section",
         select: [
            "sys.contentType",
            "metadata.tags",
            "fields.title",
            "fields.slug",
            "fields.parentId",
            "fields.description",
            "fields.icon"
         ],
         include: 0
      })
      .then((response) => response.items);

const buildFullUrlFromParent = (page: ContentfulEntry): string => {
   const parent = page.fields.parent as ContentfulEntry | null;
   const slug = page.fields.slug as string;
   return parent ? `${buildFullUrlFromParent(parent)}/${slug}` : slug;
};

export const getFullContent = async (id: string, isPreviewMode: boolean) => {
   //TODO: Examine if this returns the approriate levels
   return await contentfulClient(isPreviewMode).getEntry<TypeV2_articleSkeleton | TypeV2_sectionSkeleton | TypeRecipeSkeleton>(
      id,
      {
         include: 2
      }
   );
};

export const getFullArticleContentByIds = async (ids: string[], isPreviewMode: boolean): Promise<TypeContentfulArticle[]> => {
   const entries = await contentfulClient(isPreviewMode).getEntries<TypeV2_articleSkeleton>({
      content_type: "v2_article",
      "sys.id[in]": ids
   });
   return entries.items;
};

export const getFullRecipeContentByIds = async (ids: string[], isPreviewMode: boolean): Promise<TypeContentfulRecipe[]> => {
   const entries = await contentfulClient(isPreviewMode).getEntries<TypeRecipeSkeleton>({
      content_type: "recipe",
      "sys.id[in]": ids
   });
   return entries.items;
};

export const getUnprocessedUrlDataFromContent = (
   content: Entry<TypeV2_sectionSkeleton | TypeV2_articleSkeleton | TypeRecipeSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "nb_NO">
): UrlDataWithoutFullUrl => ({
   id: content.sys.id,
   title: content.fields.title ?? "Uten tittel",
   slug: content.fields.slug ?? content.sys.id,
   type: content.sys.contentType.sys.id,
   parentId: content.fields.parentId
});

export const buildFullUrlRecursively = (
   builtUrl: string,
   parentId: string | undefined,
   allPages: UrlDataWithoutFullUrl[]
): string => {
   if (isUndefined(parentId)) {
      return builtUrl;
   }
   const parent = allPages.find((possibleParent) => possibleParent.id === parentId);
   if (isUndefined(parent)) {
      return builtUrl;
   }
   return buildFullUrlRecursively(`${parent.slug}/${builtUrl}`, parent.parentId, allPages);
};

export const generateFullUrl = (page: UrlDataWithoutFullUrl, allPages: UrlDataWithoutFullUrl[]): UrlData => {
   const fullUrl = buildFullUrlRecursively(page.slug, page.parentId, allPages);
   return {
      ...page,
      fullUrl
   };
};

export const getAllWarnings = (isPreviewMode: boolean) =>
   contentfulClient(isPreviewMode)
      .getEntries({
         content_type: "warning"
      })
      .then((response) => response.items);

export type SectionRecipeOrArticleContent =
   | Entry<TypeV2_articleSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>
   | Entry<TypeV2_sectionSkeleton, undefined, string>
   | Entry<TypeRecipeSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>;

/**
 * Returns true if the content is tagged with the appriate tag to indicate that the content
 * should be featured in the main menu
 */
export const hasMainMenuTag = (content: SectionRecipeOrArticleContent) => {
   const MAIN_MENU_TAG = "visibleInMainMenu";
   return content.metadata.tags.filter((tag) => tag.sys.id === MAIN_MENU_TAG).length > 0;
};

export const createMenuItem = (content: SectionRecipeOrArticleContent, allUrlData: UrlData[]): MenuItem[] => {
   // Is this content even tagged to be featured in the main menu?
   if (!hasMainMenuTag(content)) {
      return [];
   }

   const urlForMenuItem = allUrlData.find((urlData) => urlData.id === content.sys.id)?.fullUrl;
   // If we are unable to generate a proper URL for the content, we skip it
   if (isUndefined(urlForMenuItem)) {
      return [];
   }

   return [
      {
         title: content.fields.title,
         id: content.sys.id,
         url: urlForMenuItem.startsWith("/") ? urlForMenuItem : `/${urlForMenuItem}`
      }
   ];
};
