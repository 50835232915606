import { CalendarIcon, ClockIcon, PencilSquareIcon, TruckIcon } from "@heroicons/react/24/outline";
import { view } from "@risingstack/react-easy-state";
import isNil from "lodash-es/isNil";
import { KeyboardEvent } from "react";

import { translateDayLabel } from "../../common/utils";
import { isLoading } from "../../common/utils/asyncDataUtils";
import { formatDate, formatDateTime, formatDeliveryDate, parseM3Date } from "../../common/utils/dateUtils";

import authStore from "../../stores/auth/authStore";
import cartStore from "../../stores/cartStore";
import deliveryDatesStore from "../../stores/deliveryDates/deliveryDatesStore";

import DeliveryDateButton from "../../components/DeliveryDateButton";
import Loading from "../../components/Loading";
import ChangeOrderOrSubscription from "../ChangeOrderOrSubscription";

const DeliveryInfo = () => {
   if (!authStore.isLoggedIn() || authStore.error) {
      return null;
   }

   if (isLoading(deliveryDatesStore.deliveryDates)) {
      return <Loading label="Laster leveringsdager..." />;
   }

   const nextDelivery = deliveryDatesStore.getAllDeliveriesForOrderType(cartStore.orderType)[0].date;

   if (cartStore.isEditing() && !isNil(cartStore.editing)) {
      return <ChangeOrderOrSubscription />;
   }

   const dayLabel = translateDayLabel(cartStore.orderType);
   const currentDelivery = deliveryDatesStore.getCurrentDelivery();
   if (currentDelivery === null) {
      console.log("No current delivery found");
      return null;
   }

   const renderDeliveryDateButton = (_value: string, openCalendar: () => void) => {
      const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
         if (e.key === "Enter") {
            openCalendar();
         }
      };
      return (
         <div role="button" onClick={openCalendar} className="me-1" tabIndex={0} onKeyDown={handleKeyDown}>
            <TruckIcon />
         </div>
      );
   };
   return (
      <div className="d-flex justify-content-center w-100 py-2 px-4 shadow-b">
         <div className="selected-delivery">
            <div className="delivery-col">
               <div className="delivery-icon-and-label">
                  <ClockIcon className="me-1" /> <p>Bestillingsfrist</p>
               </div>
               <b> {formatDateTime(currentDelivery.deadline, true, true)}</b>
            </div>
            <div className="delivery-col">
               <div className="delivery-icon-and-label">
                  <DeliveryDateButton render={renderDeliveryDateButton} />
                  <p>{dayLabel}</p>
               </div>
               <b> {formatDeliveryDate(currentDelivery.date, cartStore.orderType, currentDelivery.interval)}</b>
            </div>
            <div className="delivery-col">
               <div className="delivery-icon-and-label">
                  <CalendarIcon className="me-1" />
                  <p>Første mulige levering</p>
               </div>
               <b> {formatDate(nextDelivery, true, false, true)}</b>
            </div>
         </div>
      </div>
   );
};

export default view(DeliveryInfo);
