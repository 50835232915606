import { Entry } from "contentful";
import { Col } from "react-bootstrap";
import { Link } from "wouter";

import { TypeV2_articleSkeleton } from "../../common/types/contentful";

import contentStore from "../../stores/cms/contentStore";
import segmentStore from "../../stores/cms/segmentStore";

import ImageWithFocus from "./ImageWithFocus";

type CallToActionProps = {
   content: Entry<TypeV2_articleSkeleton, undefined, string>;
};

const CallToAction = ({ content }: CallToActionProps) => {
   if (!segmentStore.isArticleVisible(content.sys.id)) {
      return <></>;
   }
   const urlData = contentStore.urlData.data.find((urlData) => urlData.id === content.sys.id);
   const ctaTitle = content.fields.callToAction ?? content.fields.title;
   const imageId = content.fields.headerImage?.sys?.id;

   return (
      <Col>
         <div className="cta-container shadow-sm rounded-1" role="link" aria-label={`Gå til artikkelen ${ctaTitle}`}>
            <Link to={"/" + urlData?.fullUrl}>
               {imageId && (
                  <ImageWithFocus entryId={imageId} width={600} ratio="16x9" className="rounded-1" placement="belowFold" />
               )}

               <div className="cta-shading rounded-1">
                  <h2 className="cta-title px-3 py-1">{ctaTitle ?? "Uten tittel"}</h2>
               </div>
            </Link>
         </div>
      </Col>
   );
};

export default CallToAction;
