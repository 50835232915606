import * as configcat from "configcat-js";
import { PollingMode } from "configcat-js";

import { ConfigCatUserData } from "./types/featureTypes";

// All the keys we use in ConfigCat
export const enum ConfigCatKeys {
   BARCODESCANNER_AVAILABLE = "barcodeScannerAvailable",
   CLAIMS_AVAILABLE = "claimsAvailable",
   PICKUP_ORDER_AVAILABLE = "pickupOrderAvailable",
   NOTIFICATIONS_AVAILABLE = "notificationsAvailable",
   RECOMMENDATION_TYPE = "recommendationType"
}

// Define a mapping type that associates keys with return types to allow TypeScript to
// infer the correct return type based on the key.
type ConfigCatKeyReturnType = {
   [ConfigCatKeys.BARCODESCANNER_AVAILABLE]: boolean;
   [ConfigCatKeys.CLAIMS_AVAILABLE]: boolean;
   [ConfigCatKeys.PICKUP_ORDER_AVAILABLE]: boolean;
   [ConfigCatKeys.NOTIFICATIONS_AVAILABLE]: boolean;
   [ConfigCatKeys.RECOMMENDATION_TYPE]: "AI1" | "AI2";
};

// Define a mapping object with default values for each key now that we can no longer default to false
const defaultValueReturnValues = {
   [ConfigCatKeys.BARCODESCANNER_AVAILABLE]: false,
   [ConfigCatKeys.CLAIMS_AVAILABLE]: false,
   [ConfigCatKeys.PICKUP_ORDER_AVAILABLE]: false,
   [ConfigCatKeys.NOTIFICATIONS_AVAILABLE]: false,
   [ConfigCatKeys.RECOMMENDATION_TYPE]: "AI2"
};

declare var process: {
   env: {
      CONFIG_CAT_KEY: string;
   };
};

/**
 * Set up ConfigCat client so we can do true feature toggles
 */
const configCatClient = configcat.getClient(process.env.CONFIG_CAT_KEY, PollingMode.AutoPoll, {
   pollIntervalSeconds: 60 * 20,
   logger: configcat.createConsoleLogger(3)
});

export function getConfigCatValue<K extends ConfigCatKeys>(
   key: K,
   userData?: ConfigCatUserData
): Promise<ConfigCatKeyReturnType[K]>;

/** Fetches a value from ConfigCat, with a fallback value to false if it's not available. */
export async function getConfigCatValue<K extends ConfigCatKeys>(
   key: K,
   userData?: ConfigCatUserData
): Promise<ConfigCatKeyReturnType[K]> {
   const value = await configCatClient.getValueAsync(key, defaultValueReturnValues[key], userData);
   if (key == ConfigCatKeys.RECOMMENDATION_TYPE) {
      return value as any;
   }
   return Boolean(value) as any;
}
