import { view } from "@risingstack/react-easy-state";
import { PropsWithChildren } from "react";
import BootstrapButton, { ButtonProps } from "react-bootstrap/Button";

import { sendButtonClickTracking } from "../common/tracking";

const Button = ({
   variant = "secondary",
   size = "sm",
   className = "",
   onClick,
   trackingEvent,
   children,
   ...rest
}: PropsWithChildren<ButtonProps> & { trackingEvent?: string }) => {
   return (
      <BootstrapButton
         variant={variant}
         size={size}
         className={`rounded-pill px-3 ${className}`}
         onClick={(e) => {
            !!trackingEvent && sendButtonClickTracking(trackingEvent);
            onClick && onClick(e);
         }}
         {...rest}
      >
         {children}
      </BootstrapButton>
   );
};

export default view(Button);
