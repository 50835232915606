import { defaultTo } from "lodash-es";

import { TypeContentfulRecipe } from "../../stores/cms/contentUtils";

import {
   ArticleDetails,
   ArticleMeta,
   ArticleWithoutDetails,
   Block,
   BlockBase,
   CampaignBase,
   CampaignMainContent,
   PageLayout,
   TextAndImage,
   WarningCms
} from "../types/cmsTypes";
import { SupportedLocaleType } from "../types/languageTypes";
import { RecipeDifficultyType, RecipeType } from "../types/recipeTypes";

export const fromJsonToWarningCms = (warning: any): WarningCms => {
   return {
      id: warning.sys.id,
      message: warning.message.json,
      theme: warning.theme,
      visibleFor: warning.visibleFor
   };
};

export const fromJsonToPageLayout = (pageLayout: any): PageLayout => {
   return {
      urlKey: pageLayout.urlKey,
      pageTitle: pageLayout.pageTitle,
      content: pageLayout.content.json,
      tags: pageLayout.contentfulMetadata.tags.map((tag: { id: string }) => tag.id)
   };
};

export const fromJsonToArticleMeta = (article: any): ArticleMeta => {
   return {
      id: article.sys.id,
      urlKey: article.urlKey,
      typeName: "Article"
   };
};

export const fromJsonToArticleWihoutDetails = (article: any): ArticleWithoutDetails => {
   return {
      id: article.sys.id,
      urlKey: article.urlKey,
      heading: article.heading,
      topImage: article.topImage.url,
      preface: article.leadText.json,
      tags: article.contentfulMetadata.tags.map((tag: { id: string }) => tag.id),
      callToAction: article.callToAction,
      detailsAvailable: false,
      detailsLoading: false,
      typeName: "Article"
   };
};

export const fromJsonToArticleDetails = (article: any): ArticleDetails => {
   return {
      body: article.bodyText.json,
      author: article.author,
      writtenAt: article.writtenAt,
      relatedProducts: article.relatedProducts
   };
};

const fromJsonToBlockBase = (block: any): BlockBase => ({
   id: block.sys.id,
   typeName: "Block",
   type: block.blockType
});

export const fromJsonToBlock = (block: any): Block => {
   return {
      id: block.sys.id,
      heading: block.displayNameInStore?.length > 0 ? block.displayNameInStore : null,
      type: block.blockType,
      typeName: "Block",
      count: block.count,
      skus: !!block.skus ? block.skus.split(",") : [],
      tags: block.contentfulMetadata.tags.map((tag: { id: string }) => tag.id),
      relatedContent: block.relatedContentCollection.items.map((article: any) => fromJsonToArticleMeta(article))
   };
};

export const fromJsonToTextAndImage = (textAndImage: any): TextAndImage => {
   return {
      id: textAndImage.sys.id,
      heading: textAndImage.heading,
      typeName: "TextAndImage",
      image: {
         description: textAndImage.image.description,
         url: textAndImage.image.url
      },
      imageAlignment: textAndImage.imageAlignment,
      description: textAndImage.text.json
   };
};

export const fromJsonToCampaignBase = (campaign: any): CampaignBase => {
   return {
      id: campaign.sys.id,
      segmentSpecification: {
         include: {
            grNumbers: defaultTo(campaign.includeGrNumbers, []),
            customerGroups: defaultTo(campaign.includeCustomerGroups, []),
            customerNumbers: defaultTo(campaign.includeCustomerNumbers, [])
         },
         exclude: {
            grNumbers: defaultTo(campaign.excludeGrNumbers, []),
            customerGroups: defaultTo(campaign.excludeCustomerGroups, []),
            customerNumbers: defaultTo(campaign.excludeCustomerNumbers, [])
         }
      },
      skus: campaign.skus !== null ? campaign.skus.split(",") : [],
      productLabel: campaign.productLabel
   };
};

export const fromJsonToCampaignMainContent = (item: any): CampaignMainContent | undefined => {
   switch (item.__typename) {
      case "Block":
         return fromJsonToBlockBase(item);
      case "TextAndImage":
         return fromJsonToTextAndImage(item);
      default:
         return undefined;
   }
};

export const generateFakeWarningContent = (text: string): WarningCms => ({
   id: "-1",
   message: {
      content: [
         {
            content: [
               {
                  data: {},
                  marks: [],
                  value: text,
                  nodeType: "text"
               }
            ],
            nodeType: "paragraph"
         }
      ],
      nodeType: "document"
   },
   theme: "Important",
   visibleFor: "everyone"
});

export const getRecipeForRecipeEntry = (entry: TypeContentfulRecipe): RecipeType | undefined => {
   const jsonRecipe = entry.fields.recipeData;
   if (jsonRecipe && !Array.isArray(jsonRecipe)) {
      if ("name" in jsonRecipe && "description" in jsonRecipe && "displayName" in jsonRecipe && "difficulty" in jsonRecipe) {
         return jsonRecipe as RecipeType;
      }
   }
};
export const difficultyTexts: { [locale in SupportedLocaleType]: { [difficulty in RecipeDifficultyType]: string } } = {
   "nb-NO": {
      easy: "Enkel",
      medium: "Medium",
      difficult: "Utfordrende"
   },
   "en-US": {
      easy: "Easy",
      medium: "Medium",
      difficult: "Challenging"
   }
};
