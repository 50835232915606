import { RecipeType } from "../../../common/types/recipeTypes";

import RecipeDifficulty from "./RecipeDifficulty";
import RecipePreparationTime from "./RecipePreparationTime";

type RecipeHeaderProps = {
   recipe: RecipeType;
};

const RecipeHeader = ({ recipe }: RecipeHeaderProps) => {
   const { displayName, description, difficulty, workTime, workTimeRange } = recipe;

   const displayDescription = description.replace(/&nbsp;/i, " ");

   return (
      <header className="recipe-header d-flex flex-column justify-content-center h-100">
         <h1 className="recipe-header--display-name mb-3">{displayName}</h1>
         <div className="d-flex recipe-header--info">
            <RecipePreparationTime workTime={workTime} workTimeRange={workTimeRange} />
            <RecipeDifficulty difficulty={difficulty} />
         </div>

         <p className="preface mt-4">{displayDescription}</p>
      </header>
   );
};

export default RecipeHeader;
