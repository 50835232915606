function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
import { get } from './utils';
/**
 * Base Store for entities
 * Can be extened for the different loading behaviours (editor, production, ..)
 */ export class EntityStore {
    get entities() {
        return [
            ...this.entryMap.values(),
            ...this.assetMap.values()
        ];
    }
    updateEntity(entity) {
        this.addEntity(entity);
    }
    getValue(entityLink, path) {
        entityLink.sys.type;
        const entity = this.getEntity(entityLink.sys.linkType, entityLink.sys.id);
        if (!entity) {
            // TODO: move to `debug` utils once it is extracted
            console.warn(`Unresolved entity reference: ${entityLink}`);
            return;
        }
        return get(entity, path);
    }
    getEntitiesFromMap(type, ids) {
        const resolved = [];
        const missing = [];
        for (const id of ids){
            const entity = this.getEntity(type, id);
            if (entity) {
                resolved.push(entity);
            } else {
                missing.push(id);
            }
        }
        return {
            resolved,
            missing
        };
    }
    addEntity(entity) {
        if (this.isAsset(entity)) {
            this.assetMap.set(entity.sys.id, entity);
        } else {
            this.entryMap.set(entity.sys.id, entity);
        }
    }
    async fetchAsset(id) {
        const { resolved, missing } = this.getEntitiesFromMap('Asset', [
            id
        ]);
        if (missing.length) {
            // TODO: move to `debug` utils once it is extracted
            console.warn(`Asset "${id}" is not in the store`);
            return undefined;
        }
        return resolved[0];
    }
    async fetchAssets(ids) {
        const { resolved, missing } = this.getEntitiesFromMap('Asset', ids);
        if (missing.length) {
            throw new Error(`Missing assets in the store (${missing.join(',')})`);
        }
        return resolved;
    }
    async fetchEntry(id) {
        const { resolved, missing } = this.getEntitiesFromMap('Entry', [
            id
        ]);
        if (missing.length) {
            // TODO: move to `debug` utils once it is extracted
            console.warn(`Entry "${id}" is not in the store`);
            return undefined;
        }
        return resolved[0];
    }
    async fetchEntries(ids) {
        const { resolved, missing } = this.getEntitiesFromMap('Entry', ids);
        if (missing.length) {
            throw new Error(`Missing assets in the store (${missing.join(',')})`);
        }
        return resolved;
    }
    isAsset(entity) {
        return entity.sys.type === 'Asset';
    }
    getEntity(type, id) {
        if (type === 'Asset') {
            return this.assetMap.get(id);
        }
        return this.entryMap.get(id);
    }
    constructor({ entities, locale }){
        _define_property(this, "locale", void 0);
        _define_property(this, "entryMap", new Map());
        _define_property(this, "assetMap", new Map());
        this.locale = locale;
        for (const entity of entities){
            this.addEntity(entity);
        }
    }
}
