import { view } from "@risingstack/react-easy-state";
import { KeyboardEvent, useRef, useState } from "react";
import { Overlay, Popover } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import { Link } from "wouter";

import { CategoryInfo } from "../../common/types/categoryTypes";
import { titleCase } from "../../common/utils";

import authStore from "../../stores/auth/authStore";
import categoryStore from "../../stores/categories/categoryStore";
import contentStore from "../../stores/cms/contentStore";

import PreprodInfo from "../../components/PreprodInfo";
import theme from "../../themes/theme";
import MegaMenuCol from "./MegaMenuCol";

type CategoryProps = {
   cat: CategoryInfo;
};

const Category = view(({ cat }: CategoryProps) => {
   const [show, setShow] = useState(false);
   const target = useRef(null);
   const parentRef = useRef<HTMLDivElement>(null);

   const productsKeydownHandler = (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === "Enter") {
         setShow(!show);
      }
   };

   return (
      <div ref={parentRef}>
         <Nav.Item>
            <div
               className="nav-link ps-0"
               role="button"
               tabIndex={0}
               onClick={() => setShow(!show)}
               onKeyDown={productsKeydownHandler}
            >
               {titleCase(cat.name)}
            </div>
         </Nav.Item>
         <Overlay
            target={parentRef}
            show={show}
            placement="bottom-start"
            container={parentRef}
            rootClose={true}
            onHide={() => setShow(false)}
         >
            <Popover className=" categories-overlay popover" ref={target}>
               <Row className="row-cols-12 pt-4">
                  {cat.children.map((subcat, index) => (
                     <MegaMenuCol
                        columnIndex={index}
                        key={subcat.id}
                        cat={subcat}
                        productsUrl={cat.fullUrl}
                        onClick={() => setShow(false)}
                     />
                  ))}
               </Row>
            </Popover>
         </Overlay>
      </div>
   );
});

const Categories = () => {
   const { categories } = categoryStore;
   const { productDropdownEnabled } = theme;
   const categoryTree = productDropdownEnabled ? categories : categories[0]?.children || categories;

   // TODO: Fetch articles and sections from contentStore that is tagged with "Visible in main menu"

   return (
      <Row className="categories w-100">
         <Navbar className="d-flex flex-row align-items-center px-sm-3 px-md-5">
            <Navbar.Collapse>
               <Nav className="w-100">
                  {productDropdownEnabled && categoryTree.map((cat) => <Category key={cat.id} cat={cat} />)}
                  {!productDropdownEnabled &&
                     categoryTree.map((cat) => (
                        <Nav.Item key={cat.id}>
                           <Link className="nav-link" to={cat.fullUrl}>
                              {cat.name}
                           </Link>
                        </Nav.Item>
                     ))}
                  {contentStore.mainMenuItems.map((menuItem) => (
                     <Nav.Item key={menuItem.id}>
                        <Link className="nav-link" to={menuItem.url}>
                           {titleCase(menuItem.title)}
                        </Link>
                     </Nav.Item>
                  ))}
                  {authStore.isLoggedIn() && (
                     <Nav.Item key="favorites" className="ms-auto">
                        <Link className="nav-link" to="/customer/favorites">
                           Favorittlister
                        </Link>
                     </Nav.Item>
                  )}
               </Nav>
               {process.env.ENV_NAME == "preprod24" && <PreprodInfo />}
            </Navbar.Collapse>
         </Navbar>
      </Row>
   );
};

export default view(Categories);
