import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { view } from "@risingstack/react-easy-state";
import { keyBy, mapValues, sortBy, toLower, values } from "lodash-es";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";

import cartStore from "../../stores/cartStore";
import favoriteStore, { FavoriteList } from "../../stores/favoriteStore";
import toastStore from "../../stores/toastStore";

import Button from "../../components/Button";
import ExpandableList from "../../components/ExpandableList";

type Props = {
   isOpen: boolean;
   onCloseModal: () => void;
   favoriteListAdded: () => void;
};

const AddToFavoriteModal = ({ isOpen = false, onCloseModal, favoriteListAdded }: Props) => {
   const [mode, setMode] = useState<"newList" | "existingList">("existingList");
   const favoriteLists = sortBy(values(favoriteStore.favorites), (l) => toLower(l.listName));

   const onClose = () => {
      onCloseModal();
      setMode("existingList");
   };

   return (
      <Modal show={isOpen} onHide={onClose}>
         {mode === "existingList" ? (
            <AddToExistingList
               favoriteLists={favoriteLists}
               onClose={onClose}
               onAddedToList={favoriteListAdded}
               setMode={setMode}
            />
         ) : (
            <AddNewList onClose={onClose} onNewListAdded={favoriteListAdded} />
         )}
      </Modal>
   );
};

const AddNewList = view(({ onClose, onNewListAdded }: { onClose: () => void; onNewListAdded: () => void }) => {
   const cartItems = cartStore.items;
   const [listName, setListName] = useState<undefined | string>();
   const favoriteItems: Record<string, number> = mapValues(keyBy(cartItems, "sku"), "qty");
   const skus = cartItems.map(({ sku }) => sku);
   const qtySum = cartItems.reduce((prev, item) => prev + item.qty, 0);

   const handleSubmit = () => {
      if (listName !== undefined) {
         favoriteStore.createNewListInStorage(listName).then((list) => {
            toastStore.addSuccess("Opprettet", "Favorittlisten har blitt opprettet.");
            if (typeof list === "object") {
               const listId = favoriteStore.getFavoriteListByName(listName)?.id;
               listId && favoriteStore.addMultipleFavorites(skus, list.id, favoriteItems);
            }
            onNewListAdded();
            onClose();
         });
      }
   };

   return (
      <>
         <Modal.Header closeButton className="border-0">
            <h1 className="text-center w-100 font-weight-normal">Lagre ny favorittliste </h1>
         </Modal.Header>
         <Modal.Body className="pb-2 pt-0 px-3 px-sm-4">
            <Form.Group className="pb-3">
               <Form.Label>Velg navn på listen: </Form.Label>
               <Form.Control
                  autoFocus
                  required
                  type="name"
                  placeholder="Ny favorittliste"
                  onChange={(event) => setListName(event.target.value)}
               />
               <Form.Text>
                  Du ønsker å legge til {qtySum} produkter – {skus.length} varelinje(r) – i denne favorittlisten.
               </Form.Text>
               <Form.Control.Feedback type="invalid">Skriv inn et navn på listen</Form.Control.Feedback>
            </Form.Group>
         </Modal.Body>
         <Modal.Footer className="border-0">
            <Button
               type="submit"
               trackingEvent="favorite_modal_add_new_list"
               disabled={listName === undefined}
               variant="primary"
               onClick={handleSubmit}
            >
               OK
            </Button>
            <Button onClick={onClose}>Avbryt</Button>
         </Modal.Footer>
      </>
   );
});

const AddToExistingList = view(
   ({
      favoriteLists,
      onClose,
      onAddedToList,
      setMode
   }: {
      favoriteLists: FavoriteList[];
      onClose: () => void;
      onAddedToList: () => void;
      setMode: (newMode: "newList" | "existingList") => void;
   }) => {
      const cartItems = cartStore.items;
      const skus = cartItems.map(({ sku }) => sku);
      const favoriteItems: Record<string, number> = mapValues(keyBy(cartItems, "sku"), "qty");
      const [checkedLists, setCheckedLists] = useState<string[]>([]);

      const onCheckList = (listId: string) => {
         setCheckedLists([...checkedLists, listId]);
      };
      const onUncheckList = (listId: string) => {
         const newList = checkedLists.filter((id) => id !== listId);
         setCheckedLists(newList);
      };

      const handleSubmit = () => {
         console.log(checkedLists);
         checkedLists.map((listId) => {
            favoriteStore.addMultipleFavorites(skus, listId, favoriteItems);
         });
         onAddedToList();
         onClose();
         toastStore.addSuccess("Lagret", "Favorittlisten har blitt oppdatert med nye produkter.");
      };

      return (
         <>
            <Modal.Header closeButton className="border-0">
               <h1 className="text-center w-100">Lagre som favorittliste </h1>
            </Modal.Header>
            <Modal.Body className="px-4">
               <button className="new-favorite-list-button p-0 gap-2 mb-4 px-md-3 px-0 py-2 " onClick={() => setMode("newList")}>
                  <div className="d-inline-flex gap-2 justify-content-center">
                     <PlusCircleIcon />
                     <h2>Lag ny liste</h2>
                  </div>
                  <p>Opprett en ny liste med de valgte varene</p>
               </button>
               {favoriteLists.length > 0 && (
                  <div className="px-md-3 px-0 py-2">
                     <h2>Legg til i eksisterende liste</h2>
                     <p>Velg listen du ønsker å legge varene i.</p>
                     <Form className=" d-flex flex-column m-0 p-0 overflow-auto h-50">
                        <ExpandableList
                           cutoff={5}
                           items={favoriteLists.map((list) => (
                              <div key={`add-to-favorite-list-${list.id}`}>
                                 <Form.Check
                                    type="checkbox"
                                    id={"favorite-list-" + list.id}
                                    label={list.listName}
                                    onChange={(event) => (event.target.checked ? onCheckList(list.id) : onUncheckList(list.id))}
                                 />
                              </div>
                           ))}
                        />
                     </Form>
                  </div>
               )}
            </Modal.Body>
            <Modal.Footer className="border-0">
               <Button
                  trackingEvent={`favorite_modal_add_to_multiple_lists_${checkedLists.length}`}
                  type="submit"
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={checkedLists.length === 0}
               >
                  Lagre
               </Button>
               <Button onClick={onClose}>Avbryt</Button>
            </Modal.Footer>
         </>
      );
   }
);

export default view(AddToFavoriteModal);
