import { __awaiter, __generator } from "tslib";
import { FetchError, FetchResult, FetchStatus } from "./ConfigFetcher";
import { ConfigFile, Preferences, ProjectConfig } from "./ProjectConfig";
var RefreshResult = /** @class */ (function () {
    function RefreshResult(errorMessage, errorException) {
        this.errorMessage = errorMessage;
        this.errorException = errorException;
    }
    Object.defineProperty(RefreshResult.prototype, "isSuccess", {
        get: function () { return this.errorMessage === null; },
        enumerable: false,
        configurable: true
    });
    RefreshResult.from = function (fetchResult) {
        return fetchResult.status !== FetchStatus.Errored
            ? RefreshResult.success()
            : RefreshResult.failure(fetchResult.errorMessage, fetchResult.errorException);
    };
    RefreshResult.success = function () {
        return new RefreshResult(null);
    };
    RefreshResult.failure = function (errorMessage, errorException) {
        return new RefreshResult(errorMessage, errorException);
    };
    return RefreshResult;
}());
export { RefreshResult };
var ConfigServiceStatus;
(function (ConfigServiceStatus) {
    ConfigServiceStatus[ConfigServiceStatus["Online"] = 0] = "Online";
    ConfigServiceStatus[ConfigServiceStatus["Offline"] = 1] = "Offline";
    ConfigServiceStatus[ConfigServiceStatus["Disposed"] = 2] = "Disposed";
})(ConfigServiceStatus || (ConfigServiceStatus = {}));
var ConfigServiceBase = /** @class */ (function () {
    function ConfigServiceBase(configFetcher, options) {
        this.pendingFetch = null;
        this.configFetcher = configFetcher;
        this.options = options;
        this.status = options.offline ? ConfigServiceStatus.Offline : ConfigServiceStatus.Online;
    }
    ConfigServiceBase.prototype.dispose = function () {
        this.status = ConfigServiceStatus.Disposed;
    };
    Object.defineProperty(ConfigServiceBase.prototype, "disposed", {
        get: function () { return this.status === ConfigServiceStatus.Disposed; },
        enumerable: false,
        configurable: true
    });
    ConfigServiceBase.prototype.refreshConfigAsync = function () {
        return __awaiter(this, void 0, void 0, function () {
            var latestConfig, _a, fetchResult, config;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.options.cache.get(this.options.getCacheKey())];
                    case 1:
                        latestConfig = _b.sent();
                        if (!!this.isOffline) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.refreshConfigCoreAsync(latestConfig)];
                    case 2:
                        _a = _b.sent(), fetchResult = _a[0], config = _a[1];
                        return [2 /*return*/, [RefreshResult.from(fetchResult), config]];
                    case 3:
                        this.logOfflineModeWarning();
                        return [2 /*return*/, [RefreshResult.failure("Client is in offline mode, it can't initiate HTTP calls."), latestConfig]];
                }
            });
        });
    };
    ConfigServiceBase.prototype.refreshConfigCoreAsync = function (latestConfig) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, fetchResult, newConfig, configContentHasChanged;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.fetchAsync(latestConfig)];
                    case 1:
                        _a = _b.sent(), fetchResult = _a[0], newConfig = _a[1];
                        configContentHasChanged = !ProjectConfig.equals(latestConfig, newConfig);
                        if (!(newConfig && (configContentHasChanged || newConfig.Timestamp > latestConfig.Timestamp))) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.options.cache.set(this.options.getCacheKey(), newConfig)];
                    case 2:
                        _b.sent();
                        this.onConfigUpdated(newConfig);
                        if (configContentHasChanged) {
                            this.onConfigChanged(newConfig);
                        }
                        return [2 /*return*/, [fetchResult, newConfig]];
                    case 3: return [2 /*return*/, [fetchResult, latestConfig]];
                }
            });
        });
    };
    ConfigServiceBase.prototype.onConfigUpdated = function (newConfig) { };
    ConfigServiceBase.prototype.onConfigChanged = function (newConfig) {
        this.options.logger.debug("config changed");
        this.options.hooks.emit("configChanged", newConfig);
    };
    ConfigServiceBase.prototype.fetchAsync = function (lastConfig) {
        var _this = this;
        var _a;
        return (_a = this.pendingFetch) !== null && _a !== void 0 ? _a : (this.pendingFetch = (function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 2, 3]);
                        return [4 /*yield*/, this.fetchLogicAsync(lastConfig)];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        this.pendingFetch = null;
                        return [7 /*endfinally*/];
                    case 3: return [2 /*return*/];
                }
            });
        }); })());
    };
    ConfigServiceBase.prototype.fetchLogicAsync = function (lastConfig) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var options, errorMessage, _b, response, configJson, err_1, errorMessage_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        options = this.options;
                        options.logger.debug("ConfigServiceBase.fetchLogicAsync() - called.");
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.fetchRequestAsync((_a = lastConfig === null || lastConfig === void 0 ? void 0 : lastConfig.HttpETag) !== null && _a !== void 0 ? _a : null)];
                    case 2:
                        _b = _c.sent(), response = _b[0], configJson = _b[1];
                        switch (response.statusCode) {
                            case 200: // OK
                                if (!configJson) {
                                    errorMessage = "Fetch was successful but HTTP response was invalid";
                                    options.logger.debug("ConfigServiceBase.fetchLogicAsync(): ".concat(errorMessage.charAt(0).toLowerCase()).concat(errorMessage.slice(1), ". Returning null."));
                                    return [2 /*return*/, [FetchResult.error(errorMessage), null]];
                                }
                                options.logger.debug("ConfigServiceBase.fetchLogicAsync(): fetch was successful. Returning new config.");
                                return [2 /*return*/, [FetchResult.success(response.body, response.eTag), new ProjectConfig(new Date().getTime(), configJson, response.eTag)]];
                            case 304: // Not Modified
                                if (!lastConfig) {
                                    errorMessage = "HTTP response ".concat(response.statusCode, " ").concat(response.reasonPhrase, " was received when no config is cached locally");
                                    options.logger.debug("ConfigServiceBase.fetchLogicAsync(): ".concat(errorMessage.charAt(0).toLowerCase()).concat(errorMessage.slice(1), ". Returning null."));
                                    return [2 /*return*/, [FetchResult.error(errorMessage), null]];
                                }
                                options.logger.debug("ConfigServiceBase.fetchLogicAsync(): content was not modified. Returning last config with updated timestamp.");
                                return [2 /*return*/, [FetchResult.notModified(), new ProjectConfig(new Date().getTime(), lastConfig.ConfigJSON, lastConfig.HttpETag)]];
                            case 403: // Forbidden
                            case 404: // Not Found
                                errorMessage = "Double-check your SDK Key at https://app.configcat.com/sdkkey";
                                options.logger.error(errorMessage);
                                options.logger.debug("ConfigServiceBase.fetchLogicAsync(): fetch was unsuccessful. Returning last config (if any) with updated timestamp.");
                                return [2 /*return*/, [FetchResult.error(errorMessage), lastConfig ? new ProjectConfig(new Date().getTime(), lastConfig.ConfigJSON, lastConfig.HttpETag) : null]];
                            default:
                                errorMessage = "Unexpected HTTP response was received: ".concat(response.statusCode, " ").concat(response.reasonPhrase);
                                options.logger.error(errorMessage);
                                options.logger.debug("ConfigServiceBase.fetchLogicAsync(): fetch was unsuccessful. Returning null.");
                                return [2 /*return*/, [FetchResult.error(errorMessage), null]];
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _c.sent();
                        errorMessage_1 = err_1 instanceof FetchError
                            ? err_1.message
                            : "Unexpected error occurred during fetching.";
                        options.logger.error(errorMessage_1, err_1);
                        options.logger.debug("ConfigServiceBase.fetchLogicAsync(): fetch was unsuccessful. Returning null.");
                        return [2 /*return*/, [FetchResult.error(errorMessage_1, err_1), null]];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ConfigServiceBase.prototype.fetchRequestAsync = function (lastETag, maxRetryCount) {
        if (maxRetryCount === void 0) { maxRetryCount = 2; }
        return __awaiter(this, void 0, void 0, function () {
            var options, retryNumber, response, configJSON, preferences, baseUrl, redirect;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        options = this.options;
                        options.logger.debug("ConfigServiceBase.fetchRequestAsync() - called.");
                        retryNumber = 0;
                        _a.label = 1;
                    case 1:
                        options.logger.debug("ConfigServiceBase.fetchRequestAsync(): calling fetchLogic()".concat(retryNumber > 0 ? ", retry ".concat(retryNumber, "/").concat(maxRetryCount) : ""));
                        return [4 /*yield*/, this.configFetcher.fetchLogic(options, lastETag)];
                    case 2:
                        response = _a.sent();
                        if (response.statusCode !== 200) {
                            return [2 /*return*/, [response]];
                        }
                        if (!response.body) {
                            options.logger.debug("ConfigServiceBase.fetchRequestAsync(): no response body.");
                            return [2 /*return*/, [response]];
                        }
                        configJSON = void 0;
                        try {
                            configJSON = JSON.parse(response.body);
                        }
                        catch (_b) {
                            options.logger.debug("ConfigServiceBase.fetchRequestAsync(): invalid response body.");
                            return [2 /*return*/, [response]];
                        }
                        preferences = configJSON[ConfigFile.Preferences];
                        if (!preferences) {
                            options.logger.debug("ConfigServiceBase.fetchRequestAsync(): preferences is empty.");
                            return [2 /*return*/, [response, configJSON]];
                        }
                        baseUrl = preferences[Preferences.BaseUrl];
                        // If the base_url is the same as the last called one, just return the response.
                        if (!baseUrl || baseUrl == options.baseUrl) {
                            options.logger.debug("ConfigServiceBase.fetchRequestAsync(): baseUrl OK.");
                            return [2 /*return*/, [response, configJSON]];
                        }
                        redirect = preferences[Preferences.Redirect];
                        // If the base_url is overridden, and the redirect parameter is not 2 (force),
                        // the SDK should not redirect the calls and it just have to return the response.
                        if (options.baseUrlOverriden && redirect !== 2) {
                            options.logger.debug("ConfigServiceBase.fetchRequestAsync(): options.baseUrlOverriden && redirect !== 2.");
                            return [2 /*return*/, [response, configJSON]];
                        }
                        options.baseUrl = baseUrl;
                        if (redirect === 0) {
                            return [2 /*return*/, [response, configJSON]];
                        }
                        if (redirect === 1) {
                            options.logger.warn("Your dataGovernance parameter at ConfigCatClient initialization is not in sync " +
                                "with your preferences on the ConfigCat Dashboard: " +
                                "https://app.configcat.com/organization/data-governance. " +
                                "Only Organization Admins can access this preference.");
                        }
                        if (retryNumber >= maxRetryCount) {
                            options.logger.error("Redirect loop during config.json fetch. Please contact support@configcat.com.");
                            return [2 /*return*/, [response, configJSON]];
                        }
                        _a.label = 3;
                    case 3:
                        retryNumber++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(ConfigServiceBase.prototype, "isOfflineExactly", {
        get: function () {
            return this.status === ConfigServiceStatus.Offline;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ConfigServiceBase.prototype, "isOffline", {
        get: function () {
            return this.status !== ConfigServiceStatus.Online;
        },
        enumerable: false,
        configurable: true
    });
    ConfigServiceBase.prototype.setOnlineCore = function () { };
    ConfigServiceBase.prototype.setOnline = function () {
        if (this.status === ConfigServiceStatus.Offline) {
            this.setOnlineCore();
            this.status = ConfigServiceStatus.Online;
            this.logStatusChange(this.status);
        }
        else if (this.disposed) {
            this.logDisposedWarning("setOnline");
        }
    };
    ConfigServiceBase.prototype.setOfflineCore = function () { };
    ConfigServiceBase.prototype.setOffline = function () {
        if (this.status == ConfigServiceStatus.Online) {
            this.setOfflineCore();
            this.status = ConfigServiceStatus.Offline;
            this.logStatusChange(this.status);
        }
        else if (this.disposed) {
            this.logDisposedWarning("setOnline");
        }
    };
    ConfigServiceBase.prototype.logStatusChange = function (status) {
        var _a;
        this.options.logger.debug("Switched to ".concat((_a = ConfigServiceStatus[status]) === null || _a === void 0 ? void 0 : _a.toUpperCase(), " mode."));
    };
    ConfigServiceBase.prototype.logOfflineModeWarning = function () {
        this.options.logger.warn("Client is in offline mode, it can't initiate HTTP calls.");
    };
    ConfigServiceBase.prototype.logDisposedWarning = function (methodName) {
        this.options.logger.warn("Client has already been disposed, thus ".concat(methodName, "() has no effect."));
    };
    return ConfigServiceBase;
}());
export { ConfigServiceBase };
