import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { view } from "@risingstack/react-easy-state";

import ButtonPlain from "./ButtonPlain";

interface Props {
   message: string;
   onRetry?: () => void;
   className?: string;
}

const ErrorMessage = ({ message, onRetry, className = "" }: Props) => (
   <div className={`tine-error-text d-flex ${className}`}>
      <ExclamationCircleIcon className="me-2" />
      {message}
      {onRetry && (
         <ButtonPlain className="ms-1" onClick={onRetry}>
            <b className="tine-error-text"> Prøv på nytt?</b>
         </ButtonPlain>
      )}
   </div>
);

export default view(ErrorMessage);
