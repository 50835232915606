import { addDays, addMonths, format, formatISO, isBefore, isFuture, parse, startOfWeek } from "date-fns";
import nb from "date-fns/locale/nb/index";
import { isNil, upperFirst } from "lodash-es";
import isEmpty from "lodash-es/isEmpty";

import { OpeningHours, OpeningHourSummary, OrderTypeWithDeliveryDates } from "../types/deliveryTypes";
import { ORDERTYPE } from "../types/productOrderTypes";

/**
 * Removes expired entries from the delivery date object for the given order types
 */
export const removeUnwantedDates = (
   orderTypeWithDeliveryDates: OrderTypeWithDeliveryDates,
   orderTypesToProcess: ORDERTYPE[]
): OrderTypeWithDeliveryDates => {
   const cutoff = addMonths(new Date(), 3);

   orderTypesToProcess.forEach((key) => {
      if (key in orderTypeWithDeliveryDates) {
         orderTypeWithDeliveryDates[key] = orderTypeWithDeliveryDates[key]?.filter(
            (e) => isFuture(e.date) && isBefore(e.date, cutoff)
         );
      }
   });

   return orderTypeWithDeliveryDates;
};

/**
 * Takes a weekday index (1-7) and an interval and returns the string representation of the subscription name, ex: "Hver mandag" or "Hver tredje onsdag"
 */
export const generateSubscriptionName = (weekdayIndex: string | number, interval: string | number) => {
   if (isNil(interval)) {
      return "Missing parameter: interval";
   }

   const weekdayIndexNumber = typeof weekdayIndex === "string" ? parseInt(weekdayIndex) : weekdayIndex;

   let name = intervalToText(interval);
   name += " " + format(addDays(startOfWeek(new Date()), weekdayIndexNumber), "EEEE", { locale: nb, weekStartsOn: 1 });
   return name;
};

/**
 * Takes a Date object, an order type and an interval and returns the string representation of the delivery date
 */
export const formatDeliveryDate = (
   date: Date | undefined,
   orderType: ORDERTYPE,
   interval?: string | number,
   shortened = false
) => {
   if (isNil(date)) {
      return "-";
   }

   if (orderType === ORDERTYPE.WAS && !isNil(interval)) {
      const intervalDescription = intervalToText(interval);
      const dayOfWeek = format(date, "EEEE", { locale: nb });
      return `${intervalDescription} ${dayOfWeek}${shortened ? "" : ` f.o.m. ${formatDate(date)}`}`;
   }

   if (orderType === ORDERTYPE.HPN) {
      return formatDateTime(date, true, true);
   }

   return formatDate(date, true, false, true);
};

/**
 * Takes an interval string and returns the text representation of the prefix that should be used for that interval
 */
export const intervalToText = (interval: string | number | null | undefined) => {
   if (isNil(interval)) {
      return "-";
   }

   const parsedInterval = typeof interval === "string" ? parseInt(interval) : interval;
   if (parsedInterval === 7) {
      return "Hver";
   } else if (parsedInterval === 14) {
      return "Annenhver";
   } else if (parsedInterval === 21) {
      return "Hver tredje";
   } else if (parsedInterval === 28) {
      return "Hver fjerde";
   }

   return `Hver ${interval}.`;
};

/**
 * Parses a date from M3 in format yyyyMMdd (ex: 20231231) and returns the Date object representation
 */
export const parseM3Date = (date: string): Date => {
   return parse(date, "yyyyMMdd", new Date());
};

/**
 * Takes a Date object and returns the string representation for use in M3 with format yyyyMMdd (ex: 20231231)
 */
export const formatM3Date = (date: Date | null | undefined) => {
   if (isNil(date)) {
      console.log("formatM3Date attempted to format empty date");
      return "";
   }
   return format(date, "yyyyMMdd");
};

/**
 * Takes a Date object and returns the ISO representation for use in JSON responses etc with format yyyy-MM-dd (ex: 2023-12-31)
 */
export const formatISODate = (date: Date) => {
   return formatISO(date, { representation: "date" });
};

/**
 * Takes a string as input and returns the Date object representation of the same date. Supports the following formats: yyyyMMdd, yyyy-MM-dd, yyyy-MM-ddTHH:mm
 */
export const getDateFromString = (dateStr: string | null | undefined) => {
   if (isNil(dateStr)) {
      return undefined;
   }

   const isM3Format = dateStr.length === 8;
   const isISOFormat = dateStr.length >= 10 && dateStr.length < 16;
   const isISOWithTimeFormat = dateStr.length >= 16;
   if (isM3Format) {
      return parseM3Date(dateStr);
   }
   if (isISOFormat) {
      return parse(dateStr.substring(0, 10), "yyyy-MM-dd", new Date());
   }
   if (isISOWithTimeFormat) {
      return parse(dateStr.substring(0, 16), "yyyy-MM-dd'T'HH:mm", new Date());
   }
};

/**
 * Ensures the param is a Date-object, parsing datetime strings if needed
 */
const forceDate = (date: Date | string | null | undefined) => {
   if (date instanceof Date) {
      return date;
   }
   return getDateFromString(date);
};

const formatDateWithWeekday = (date: Date, monthYearFormat: string) => format(date, `EEEE ${monthYearFormat}`, { locale: nb });

/**
 * Format a date or string into a humanly readable format, based on the given parameters
 */
export const formatDate = (
   dateOrString: Date | string | null | undefined,
   showDayName = false,
   showFullMonthYear = false,
   capitalize = false
) => {
   const date = forceDate(dateOrString);
   if (!date) {
      return "Unknown date: " + dateOrString;
   }

   const monthYearFormat = showFullMonthYear ? "d. MMM yyyy" : "dd.MM.yy";
   if (showDayName) {
      const dateWithDayNameFormat = formatDateWithWeekday(date, monthYearFormat);
      return capitalize ? upperFirst(dateWithDayNameFormat) : dateWithDayNameFormat;
   }

   return format(date, `${monthYearFormat}`, { locale: nb });
};

export const formatDatesTuple = (dates: [Date, Date]) => {
   return `${formatDate(dates[0])} - ${formatDate(dates[1])}`;
};

export const formatDateTime = (
   dateOrString: Date | string | null | undefined,
   showDayName = true,
   capitalize = false,
   separator = "|"
) => {
   const date = forceDate(dateOrString);
   if (isNil(date)) {
      return "Unknown date: " + dateOrString;
   }

   if (showDayName) {
      const dateWithDayNameFormat = format(date, `EEEE dd.MM.yy '${separator}' HH.mm`, { locale: nb });
      return capitalize ? upperFirst(dateWithDayNameFormat) : dateWithDayNameFormat;
   }

   return format(date, "dd.MM.yy | HH.mm");
};

const combineDays = (first: string, last: string) => {
   if (last === "") {
      return first;
   }
   return first + " - " + last;
};

export const summarizeOpeningHours = (openingHours: OpeningHours[]): OpeningHourSummary[] => {
   if (isNil(openingHours) || isEmpty(openingHours)) {
      return [];
   }

   const openingHourSummary: OpeningHourSummary[] = [];
   let currentFirstDay = "";
   let currentLastDay = "";
   let currentOpeningWindow = "";

   openingHours.forEach(({ weekday, openingHour, closingHour }) => {
      const openingWindow = openingHour + " - " + closingHour;

      const hasNewOpeningWindow = currentOpeningWindow !== openingWindow;
      const hasValidData = currentOpeningWindow !== "";

      if (hasNewOpeningWindow) {
         if (hasValidData) {
            openingHourSummary.push({ days: combineDays(currentFirstDay, currentLastDay), open: currentOpeningWindow });
         }

         currentFirstDay = weekday;
         currentLastDay = "";
         currentOpeningWindow = openingWindow;
      } else {
         currentLastDay = weekday;
      }
   });

   openingHourSummary.push({ days: combineDays(currentFirstDay, currentLastDay), open: currentOpeningWindow });

   return openingHourSummary;
};
