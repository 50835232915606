import { TagIcon } from "@heroicons/react/24/outline";
import isFuture from "date-fns/isFuture";
import parse from "date-fns/parse";
import { groupBy, isNil, isUndefined, keys, mapValues } from "lodash-es";
import every from "lodash-es/every";
import includes from "lodash-es/includes";
import isArray from "lodash-es/isArray";
import some from "lodash-es/some";

import {
   Badge,
   BadgeKey,
   Filters,
   FilterSettings,
   hasKey,
   isFilterAttribute,
   Label,
   LegalFilterKey,
   PageType,
   Product
} from "../../common/types/productTypes";

/**
 * Takes a list of existing badges and adds another bedge if the product has the given attributes.
 */
const mapBadge = (
   existingBadges: Badge[],
   product: Product,
   certificationValue: string,
   badgeName: string,
   badgeKey: BadgeKey
) => {
   if (includes(product.certifications, certificationValue)) {
      existingBadges.push({ name: badgeName, key: badgeKey });
   }
};

/**
 * Inspects the attributes of the products and creates Badges for matching attributes.
 * @returns An array of badges.
 */
export const mapBadges = (product: Product) => {
   const badges: Badge[] = [];
   if (product.recyclingChargeBaseUnit === 2) {
      badges.push({ name: "Pant, kr 2", key: "pant_2kr" });
   }
   if (product.recyclingChargeBaseUnit === 3) {
      badges.push({ name: "Pant, kr 3", key: "pant_3kr" });
   }
   mapBadge(badges, product, "Nyt_Norge", "Nyt Norge", "nyt_norge");
   mapBadge(badges, product, "nyt_norge", "Nyt Norge", "nyt_norge");
   mapBadge(badges, product, "Nokkelhullet", "Nøkkelhull", "nokkelhullet");
   return badges;
};

/**
 * Inspects the attributes of the products and creates Labels for matching attributes.
 * @returns An array of labels.
 */
export const mapLabels = (product: Product) => {
   const labels: Label[] = [];

   // Label: Veievare - Prio: 1
   if (product.variableWeight) {
      labels.push({
         text: "Veievare",
         variant: "red",
         position: "bottom-right",
         page: [PageType.ProductDetailsPage, PageType.ProductListPage]
      });
   }

   // Label: Nyhet - Prio: 2 (#c40076)
   if (product.newUntilDate !== null && product.newUntilDate.length > 0) {
      const newUntil = parse(product.newUntilDate, "dd.MM.yyyy", new Date());
      if (isFuture(newUntil)) {
         labels.push({
            text: "Nyhet",
            variant: "red",
            position: "top-left",
            page: [PageType.ProductDetailsPage, PageType.ProductListPage]
         });
      }
   }

   // Label: Avtalesortiment - Prio: 3 (#554494 + tag icon)
   if (product.inAgreementAssortment) {
      labels.push({
         text: "Avtalesortiment",
         icon: TagIcon,
         variant: "blue",
         position: "bottom-left",
         page: [PageType.ProductListPage]
      });
   }

   // Label: Økologisk - Prio: 3 (#c40076)
   if (includes(product.certifications, "EU_okologi") || includes(product.certifications, "Debio")) {
      labels.push({
         text: "Økologisk",
         icon: TagIcon,
         variant: "red",
         position: "top-left",
         page: [PageType.ProductListPage, PageType.ProductDetailsPage]
      });
   }

   // Label: Egne frister (for minitanker) - Prio: 5 (#4d70bc)
   if (product.miniTank) {
      labels.push({
         text: "Egne frister",
         variant: "red",
         position: "bottom-left",
         page: [PageType.ProductListPage, PageType.ProductDetailsPage]
      });
   }

   const seasonalProducts = [
      "5778",
      "7110",
      "7106",
      "345",
      "102",
      "410",
      "805",
      "6896",
      "638",
      "8687",
      "8768",
      "6746",
      "6855",
      "6862",
      "6865",
      "6706",
      "6746",
      "2907",
      "2978",
      "8723",
      "8771",
      "8773",
      "8815",
      "8661",
      "5254",
      "8720"
   ];
   if (includes(seasonalProducts, product.sku)) {
      labels.push({
         text: "Sesong",
         variant: "red",
         position: "top-left",
         page: [PageType.ProductListPage, PageType.ProductDetailsPage]
      });
   }

   return labels;
};

/**
 * Returns a boolean indicating whether the product matches the active filters.
 */
export const matchesFilter = (product: Product, activeFilters: Filters): boolean => {
   return every(activeFilters, (selectedValues, field) => {
      // Type-guards to make sure field name sent to filter function are "filterable"
      if (!hasKey(product, field) || !isFilterAttribute(field)) {
         return false;
      }
      const productValue = product[field];
      return some(selectedValues, (selectedValue) =>
         isArray(productValue) ? includes(productValue, selectedValue) : productValue === selectedValue
      );
   });
};

/**
 * Creates a list of unique values for a specific product attribute given the name of the field and a list of products. Used to build one filter
 * for each filterable attribute.
 *
 * @returns Object with field key, field name and a object with one key for each avaialble value and a list of SKUs that have this value
 */
export const buildFilter = (products: Product[] | undefined, field: LegalFilterKey, name: string): FilterSettings | null => {
   if (isUndefined(products)) {
      return null;
   }

   // Remove products that dont have this field set or has an empty array for this field
   const validProductsForFilters = products.filter((p) => !isNil(p[field]) && (p[field]?.length ?? 0) > 0);

   // If a field given is a field that can have multiple values, we split the product into multiple products, one for each value
   // in array (for instance, one products with two certifications turns into two separate products in this chain)
   const skuAndFieldValues = validProductsForFilters.flatMap((p) =>
      field === "certifications" ? p[field].map((c) => ({ sku: p.sku, value: c })) : [{ sku: p.sku, value: p[field] }]
   );

   // Group all products based on their value in field
   const groupedByFieldValue: { [fieldValue: string]: string[] } = mapValues(groupBy(skuAndFieldValues, "value"), "sku");

   if (keys(groupedByFieldValue).length === 0) {
      return null;
   }

   const optional = field === "customerType" ? { radioDefault: "Isbar" } : {};

   return {
      field,
      name,
      options: groupedByFieldValue,
      ...optional
   };
};

export const getRelevantFilterSettings = (
   fullSettings: FilterSettings[],
   effectiveFilters: Filters,
   products: Product[]
): FilterSettings[] => {
   return fullSettings
      .map((settings) => {
         if (settings.radioDefault) {
            return settings;
         }
         let testFilters = { ...effectiveFilters };
         const filterForSettings = effectiveFilters[settings.field];
         if (filterForSettings) {
            const { [settings.field]: _, ...otherFilters } = effectiveFilters;
            testFilters = otherFilters;
         }
         const productsFilteredOnOtherFilters = products.filter((product) => matchesFilter(product, testFilters));
         return buildFilter(productsFilteredOnOtherFilters, settings.field, settings.name);
      })
      .filter((f) => f) as FilterSettings[];
};

export const hasDiscount = (product: Product): boolean => product.priceBeforeDiscount !== product.price;
