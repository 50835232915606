import { view } from "@risingstack/react-easy-state";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import authStore from "../stores/auth/authStore";
import favoriteStore from "../stores/favoriteStore";

import ProductList from "./ProductList";
import ShortcutBoxes from "./shortcuts/ShortcutBoxes";

type FavoritesAndSubscriptionsWidgetProps = {
   heading?: string | null;
};

const FavoritesAndSubscriptionsWidget = ({ heading }: FavoritesAndSubscriptionsWidgetProps) => {
   if (!authStore.isLoggedIn()) {
      return null;
   }

   return (
      <>
         <Row>
            <Col>
               <ShortcutBoxes />
            </Col>
         </Row>
         <Row>
            <Col className="mb-4">
               <ProductList
                  heading={heading}
                  count={4}
                  maxCol={4}
                  skus={favoriteStore.allSkus.slice(0, 4)}
                  buttonText={"Favorittlister"}
                  buttonUrl="/customer/favorites"
                  listName="Mine Favoritter (CMS Block)"
               />
            </Col>
         </Row>
      </>
   );
};

export default view(FavoritesAndSubscriptionsWidget);
