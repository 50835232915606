import { view } from "@risingstack/react-easy-state";
import { PropsWithChildren } from "react";

type ShortcutBoxProps = {
   className?: string;
};

const ShortcutBox = ({ className = "", children }: PropsWithChildren<ShortcutBoxProps>) => {
   return <div className={`bg-white m-1 p-3 flex-shrink-1 ${className}`}>{children}</div>;
};

export default view(ShortcutBox);
