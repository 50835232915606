import { view } from "@risingstack/react-easy-state";

import cartStore from "../../stores/cartStore";

import MiniCartOrderLines from "./MiniCartOrderLines";

const EmptyCart = view(() => (
   <div className={`d-flex justify-content-center w-100 h-100`}>
      <h4 className="align-self-center">Du har ingen varer i handlekurven</h4>
   </div>
));

const MiniCartContent = () => {
   const items = cartStore.items;
   const empty = items.length === 0;

   return !empty ? <MiniCartOrderLines items={items} /> : <EmptyCart />;
};

export default view(MiniCartContent);
