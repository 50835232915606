import { useContentfulInspectorMode } from "@contentful/live-preview/dist/react";
import { view } from "@risingstack/react-easy-state";
import { Entry } from "contentful";
import { useContext } from "react";

import { TypeProductListSkeleton } from "../../common/types/contentful";

import ProductList from "../ProductList";
import HomePageContext from "./HomePageContext";

type CmsManualProductListProps = {
   content: Entry<TypeProductListSkeleton, undefined, "nb_NO">;
};

const CmsManualProductList = ({ content }: CmsManualProductListProps) => {
   const isOnHomePage = useContext(HomePageContext);
   const inspectorProps = useContentfulInspectorMode();
   const skus = content.fields.skus ? [...content.fields.skus] : [];
   const title = content.fields.title;
   const entryId = content.sys.id;

   return (
      <div
         {...inspectorProps({
            entryId: entryId,
            fieldId: "entry"
         })}
      >
         <ProductList
            heading={title}
            listName={content.fields.title ?? "ProductListWithoutName-" + content.sys.id}
            count={content.fields.numberOfProducts}
            maxCol={isOnHomePage ? 6 : content.fields.numberOfProducts}
            skus={skus}
         />
      </div>
   );
};

export default view(CmsManualProductList);
