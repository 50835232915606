import { store } from "@risingstack/react-easy-state";
import algoliasearch, { SearchIndex } from "algoliasearch";
import { getUnixTime, subMinutes } from "date-fns";

import { apiClient } from "../common/apiClient";
import { DEFAULT_LOCALE, SupportedLocaleType } from "../common/types/languageTypes";
import {
   AlgoliaCmsContentHit,
   AlgoliaEvent,
   AlgoliaProductHit,
   AlgoliaRequestOptions,
   AllSearchTypes,
   RecipeSearchResults,
   SEARCH_TYPE,
   SearchData,
   SearchDomain,
   SearchResults,
   V2ArticleSearchResults
} from "../common/types/searchTypes";
import { forceArray } from "../common/utils";

import theme from "../themes/theme";
import authStore from "./auth/authStore";
import contentStore from "./cms/contentStore";
import { getFullArticleContentByIds, getFullRecipeContentByIds } from "./cms/contentUtils";
import segmentStore from "./cms/segmentStore";
import productStore from "./product/productStore";

type SearchDomainWithIndex = SearchDomain & { index: SearchIndex };

const searchClient = algoliasearch(theme.searchConfig.algoliaConfig.appId, theme.searchConfig.algoliaConfig.searchApiKey);

const searchDomains: SearchDomainWithIndex[] = theme.searchConfig.searchDomains.map((domain) => ({
   ...domain,
   index: searchClient.initIndex(domain.indexName)
}));

const createProductAlgoliaFilter = () => productStore.assortment.map((sku) => "sku:" + sku).join(" OR ");

const createArticleAlgoliaFilter = (domainName: string) => {
   return `fields.searchCollections.${DEFAULT_LOCALE}: ${domainName}`;
};

const createRestrictSearchableAttributes = (
   searchDomain: SearchDomain,
   locale: SupportedLocaleType = "nb-NO"
): string[] | undefined => {
   if (searchDomain.type === "v2_article") {
      return ["title", "preface", "body"].map((name) => `fields.${name}.${locale}`);
   }
   return undefined;
};

const createAlgoliaRequestOptions = (searchDomain: SearchDomain, searchType: AllSearchTypes, locale: SupportedLocaleType) => {
   const clickAnalytics = searchType !== "direct";
   const filters: string = (() => {
      switch (searchDomain.type) {
         case "product":
            return createProductAlgoliaFilter();
         case "v2_article":
            return createArticleAlgoliaFilter(searchDomain.name);
         case "recipe":
            return createArticleAlgoliaFilter("oppskrifter");
      }
      return "";
   })();
   const restrictSearchableAttributes = createRestrictSearchableAttributes(searchDomain, locale);

   const requestOptions: AlgoliaRequestOptions = {
      clickAnalytics,
      filters,
      hitsPerPage: searchType === SEARCH_TYPE.Full ? 50 : searchType === SEARCH_TYPE.QuickSearch ? 20 : 6
   };
   if (restrictSearchableAttributes) {
      requestOptions.restrictSearchableAttributes = restrictSearchableAttributes;
   }
   if (authStore.isLoggedIn()) {
      requestOptions.userToken = authStore.getAlgoliaUserToken();
   }
   return requestOptions;
};

const filterVisibleArticleHits = (hits: AlgoliaCmsContentHit[]) =>
   hits.filter((hit) => segmentStore.isArticleVisible(hit.objectID));

const searchInDomain = async (
   query: string,
   searchDomain: SearchDomainWithIndex,
   searchType: AllSearchTypes,
   locale: SupportedLocaleType = "nb-NO"
): Promise<SearchResults> => {
   const requestOptions = createAlgoliaRequestOptions(searchDomain, searchType, locale);
   if (searchDomain.type === "product") {
      const response = await searchDomain.index.search<AlgoliaProductHit>(query, requestOptions);

      const products = productStore.resolveSkus(response.hits.map((h) => h.sku));
      const hits = products.map((product) => response.hits.find((hit) => product.sku === hit.sku)) as AlgoliaProductHit[];
      return {
         name: searchDomain.name,
         searchDomainType: "product",
         hits,
         queryID: response.queryID || "",
         products,
         query
      };
   } else if (searchDomain.type === "v2_article") {
      const response = await searchDomain.index.search<AlgoliaCmsContentHit>(query, requestOptions);
      const hits = filterVisibleArticleHits(response.hits);
      const results: V2ArticleSearchResults = {
         name: searchDomain.name,
         searchDomainType: "v2_article",
         hits,
         queryID: response.queryID || "",
         query
      };
      if (results.hits.length) {
         results.content = await getFullArticleContentByIds(
            hits.map((hit) => hit.objectID),
            contentStore.isPreviewMode
         );
      } else {
         results.content = [];
      }
      return results;
   } else if (searchDomain.type === "recipe") {
      const response = await searchDomain.index.search<AlgoliaCmsContentHit>(query, requestOptions);
      const hits = [...response.hits];
      const results: RecipeSearchResults = {
         name: searchDomain.name,
         searchDomainType: "recipe",
         hits,
         queryID: response.queryID || "",
         query
      };
      if (results.hits.length) {
         results.content = await getFullRecipeContentByIds(
            hits.map((hit) => hit.objectID),
            contentStore.isPreviewMode
         );
      } else {
         results.content = [];
      }
      return results;
   }
   return {
      name: searchDomain.name,
      searchDomainType: searchDomain.type,
      hits: [],
      queryID: "",
      query
   };
};

let queueTimeout: number | undefined;

type SearchStore = Record<SEARCH_TYPE, SearchData> & {
   query: string;
   eventQueue: AlgoliaEvent[];

   clear(field: SEARCH_TYPE, includeQuery?: boolean): void;
   performSearches(type: SEARCH_TYPE, locale?: SupportedLocaleType): void;
   reportAlgoliaClick(
      id: string,
      index: string,
      queryID?: string,
      position?: number | null,
      fromSearch?: boolean,
      type?: AllSearchTypes
   ): void;
   reportAlgoliaAddToCart(sku: string, index: string, queryID?: string, fromSearch?: boolean, type?: AllSearchTypes): void;
   reportDetailsViewed(sku: string, index: string, queryID?: string): void;
   reportArticleViewed(id: string, index: string, queryID?: string): void;
   reportEvent(
      eventType: string,
      eventName: string,
      id: string,
      position: number | null,
      type: AllSearchTypes | null,
      index: string,
      queryID: string
   ): void;
   queueEvent(eventsToQueue: AlgoliaEvent | AlgoliaEvent[]): void;
   sendQueue(): void;
};

const searchStore: SearchStore = store({
   query: "",
   eventQueue: [],
   quick: {
      results: [],
      resultsFor: ""
   },
   full: {
      results: [],
      resultsFor: ""
   },
   async performSearches(type, locale) {
      const query = searchStore.query;
      const results = await Promise.all(searchDomains.map((searchDomain) => searchInDomain(query, searchDomain, type, locale)));
      if (query === searchStore.query) {
         searchStore[type].results = results;
         searchStore[type].resultsFor = query;
      }
   },
   clear: (field: SEARCH_TYPE = SEARCH_TYPE.QuickSearch, includeQuery = false) => {
      searchStore[field].results = [];
      if (includeQuery) {
         searchStore.query = "";
      }
   },

   /**
    * @param sku {string}
    * @param position {number | null}
    * @param fromSearch {boolean}
    * @param type {"quick" | "direct"}
    */
   reportAlgoliaClick: (id, index, queryID = "", position = null, fromSearch = false, type = "direct") => {
      const eventName = fromSearch ? "Clicked From Search" : "Clicked";
      searchStore.reportEvent("click", eventName, id, position, type, index, queryID);
   },

   reportAlgoliaAddToCart: (sku, index, queryID = "", fromSearch = false, type = "direct") => {
      const eventName = fromSearch ? "Added To Cart From Search" : "Added to cart";
      searchStore.reportEvent("conversion", eventName, sku, null, type, index, queryID);
   },

   reportDetailsViewed: (sku, index, queryID = "") => {
      searchStore.reportEvent("view", "Product Viewed", sku, null, null, index, queryID);
   },
   reportArticleViewed: (id, index, queryID = "") => {
      searchStore.reportEvent("view", "Article Viewed", id, null, null, index, queryID);
   },

   reportEvent: (eventType, eventName, id, position, type, index, queryID) => {
      if (!authStore.isLoggedIn()) {
         return;
      }

      const userToken = authStore.getAlgoliaUserToken();

      const event: AlgoliaEvent = {
         eventType,
         eventName,
         objectIDs: forceArray(id),
         index,
         queryID,
         userToken,
         timestamp: getUnixTime(subMinutes(new Date(), 5)) * 1000
      };

      if (position != null) {
         event.positions = [position];
      }

      searchStore.queueEvent(event);
   },

   queueEvent: (eventsToQueue) => {
      const eventArray = forceArray(eventsToQueue);
      eventArray.map((e) => searchStore.eventQueue.push(e));

      console.log("Algolia event(s) queued, current queue size: " + searchStore.eventQueue.length, eventsToQueue);

      if (searchStore.eventQueue.length > 25) {
         void searchStore.sendQueue();
      } else {
         if (queueTimeout != null) {
            clearTimeout(queueTimeout);
         }

         queueTimeout = window.setTimeout(searchStore.sendQueue, 30_000);
      }
   },

   sendQueue: () => {
      if (searchStore.eventQueue.length === 0) {
         return;
      }
      clearTimeout(queueTimeout);

      // Retrieve the queue contents current being processed, and empty the actual queue.
      // If the request fails we return theese events to the queue.
      const queueContents = [...searchStore.eventQueue];
      searchStore.eventQueue = [];

      console.log("Sending " + queueContents.length + " events to Aloglia");
      return apiClient("https://insights.algolia.io/1/events")
         .headers({
            "X-Algolia-Application-Id": theme.searchConfig.algoliaConfig.appId,
            "X-Algolia-API-Key": theme.searchConfig.algoliaConfig.searchApiKey
         })
         .post({ events: queueContents })
         .json(() => {
            console.log(queueContents.length + " events reported to Algolia");
         })
         .catch((err) => {
            console.warn(queueContents.length + " events where rejected by Algoia error!", err);
         });
   }
});

export default searchStore;
