export function get(obj, path) {
    if (!path.length) {
        return obj;
    }
    try {
        const [currentPath, ...nextPath] = path;
        return get(obj[currentPath], nextPath);
    } catch (err) {
        return undefined;
    }
}
