// NOTE: No instance methods should be added to this class!
// Flawed ICache implementations may erase the prototype information (when serialization is involved),
// which would lead to "... is not a function" errors in the case of instance methods.
// (As a matter of fact, this type should have been defined as an interface to prevent such complications but
// we can't really change this any more because this would be a too dangerous breaking change at this point.)
var ProjectConfig = /** @class */ (function () {
    function ProjectConfig(timeStamp, jsonConfig, httpETag) {
        this.Timestamp = timeStamp;
        this.ConfigJSON = typeof jsonConfig === "string" ? JSON.parse(jsonConfig) : jsonConfig;
        this.HttpETag = httpETag;
    }
    /**
     * Determines whether the specified ProjectConfig instances are considered equal.
     */
    ProjectConfig.equals = function (projectConfig1, projectConfig2) {
        if (!projectConfig1) {
            // If both configs are null, we consider them equal.
            return !projectConfig2;
        }
        if (!projectConfig2) {
            return false;
        }
        // When both ETags are available, we don't need to check the JSON content
        // (because of how HTTP ETags work - see https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/ETag).
        return !!projectConfig1.HttpETag && !!projectConfig2.HttpETag
            ? this.compareEtags(projectConfig1.HttpETag, projectConfig2.HttpETag)
            : JSON.stringify(projectConfig1.ConfigJSON) === JSON.stringify(projectConfig2.ConfigJSON);
    };
    ProjectConfig.compareEtags = function (etag1, etag2) {
        return this.ensureStrictEtag(etag1) === this.ensureStrictEtag(etag2);
    };
    ProjectConfig.ensureStrictEtag = function (etag) {
        if (!etag) {
            return '';
        }
        if (etag.length > 2 && etag.substr(0, 2).toLocaleUpperCase() === "W/") {
            return etag.substring(2);
        }
        return etag;
    };
    ProjectConfig.isExpired = function (projectConfig, expirationMs) {
        return !projectConfig || projectConfig.Timestamp + expirationMs < new Date().getTime();
    };
    return ProjectConfig;
}());
export { ProjectConfig };
var ConfigFile = /** @class */ (function () {
    function ConfigFile() {
    }
    ConfigFile.Preferences = "p";
    ConfigFile.FeatureFlags = "f";
    return ConfigFile;
}());
export { ConfigFile };
var Preferences = /** @class */ (function () {
    function Preferences() {
    }
    Preferences.BaseUrl = "u";
    Preferences.Redirect = "r";
    return Preferences;
}());
export { Preferences };
var Setting = /** @class */ (function () {
    function Setting(value, rolloutPercentageItems, rolloutRules, variationId) {
        this.value = value;
        this.rolloutPercentageItems = rolloutPercentageItems;
        this.rolloutRules = rolloutRules;
        this.variationId = variationId;
    }
    Setting.fromJson = function (json) {
        var _a, _b, _c, _d;
        return new Setting(json[this.Value], (_b = (_a = json[this.RolloutPercentageItems]) === null || _a === void 0 ? void 0 : _a.map(function (item) { return RolloutPercentageItem.fromJson(item); })) !== null && _b !== void 0 ? _b : [], (_d = (_c = json[this.RolloutRules]) === null || _c === void 0 ? void 0 : _c.map(function (item) { return RolloutRule.fromJson(item); })) !== null && _d !== void 0 ? _d : [], json[this.VariationId]);
    };
    Setting.Value = "v";
    Setting.SettingType = "t";
    Setting.RolloutPercentageItems = "p";
    Setting.RolloutRules = "r";
    Setting.VariationId = "i";
    return Setting;
}());
export { Setting };
var RolloutRule = /** @class */ (function () {
    function RolloutRule(comparisonAttribute, comparator, comparisonValue, value, variationId) {
        this.comparisonAttribute = comparisonAttribute;
        this.comparator = comparator;
        this.comparisonValue = comparisonValue;
        this.value = value;
        this.variationId = variationId;
    }
    RolloutRule.fromJson = function (json) {
        return new RolloutRule(json[this.ComparisonAttribute], json[this.Comparator], json[this.ComparisonValue], json[this.Value], json[this.VariationId]);
    };
    RolloutRule.Order = "o";
    RolloutRule.ComparisonAttribute = "a";
    RolloutRule.Comparator = "t";
    RolloutRule.ComparisonValue = "c";
    RolloutRule.Value = "v";
    RolloutRule.VariationId = "i";
    return RolloutRule;
}());
export { RolloutRule };
var RolloutPercentageItem = /** @class */ (function () {
    function RolloutPercentageItem(percentage, value, variationId) {
        this.percentage = percentage;
        this.value = value;
        this.variationId = variationId;
    }
    RolloutPercentageItem.fromJson = function (json) {
        return new RolloutPercentageItem(json[this.Percentage], json[this.Value], json[this.VariationId]);
    };
    RolloutPercentageItem.Order = "o";
    RolloutPercentageItem.Value = "v";
    RolloutPercentageItem.Percentage = "p";
    RolloutPercentageItem.VariationId = "i";
    return RolloutPercentageItem;
}());
export { RolloutPercentageItem };
