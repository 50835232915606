import { view } from "@risingstack/react-easy-state";
import isNil from "lodash-es/isNil";
import sortBy from "lodash-es/sortBy";
import values from "lodash-es/values";
import { Dropdown } from "react-bootstrap";
import { useLocation } from "wouter";

import cartStore from "../../stores/cartStore";
import favoriteStore from "../../stores/favoriteStore";
import productStore from "../../stores/product/productStore";
import toastStore from "../../stores/toastStore";

import ShortcutBox from "./ShortcutBox";

const ShortcutFavoritesToCart = () => {
   const [_, redirectTo] = useLocation();

   const addListToCart = (listId: string | null) => {
      if (isNil(listId)) {
         console.warn("Missing listId for change event");
         return;
      }
      const list = favoriteStore.getFavoriteListById(listId);
      if (!list) {
         console.warn("Unable to find favorite list when adding to cart", { list, listId, favorites: favoriteStore.favorites });
         toastStore.addError("En feil oppsto", "Finner ikke favorittlisten");
         return;
      }
      console.log("Adding favorite list to cart: ", list);

      const products = productStore.resolveSkus(list.sequence);
      if (products.length !== list.sequence.length) {
         toastStore.addError(
            "Sjekk listen for utilgjengelig produkter",
            "Favorittlisten inneholder produkter som ikke lengre er tilgjengelig. Fjern disse for å bruke funksjonen på forsiden."
         );
         redirectTo("/customer/favorites/" + listId);
         return;
      }

      const allAdds = Promise.all(list.sequence.map((sku) => cartStore.addToCart(sku, list.items[sku], 0)));

      allAdds.then(() => {
         toastStore.addSuccess(
            "Varer lagt i handlekurven",
            `Varene fra favorittlisten '${list.listName}' er lagt i handlekurven.`
         );
         redirectTo("/checkout/cart");
      });
   };

   const sortedFavoriteLists = sortBy(values(favoriteStore.favorites), "listName");

   return (
      <ShortcutBox>
         <h4>Legg favorittliste i handlekurven</h4>
         <p>Velg en favorittliste og den vil bli lagt direkte i handlekurven.</p>
         <Dropdown onSelect={addListToCart}>
            <Dropdown.Toggle className="px-3" size="sm" variant="secondary">
               Velg favorittliste
            </Dropdown.Toggle>
            <Dropdown.Menu>
               {sortedFavoriteLists.map((fl) => (
                  <Dropdown.Item eventKey={fl.id} key={fl.id}>
                     {fl.listName}
                  </Dropdown.Item>
               ))}
            </Dropdown.Menu>
         </Dropdown>
      </ShortcutBox>
   );
};

export default view(ShortcutFavoritesToCart);
