import { store } from "@risingstack/react-easy-state";
import find from "lodash-es/find";
import isNil from "lodash-es/isNil";
import isString from "lodash-es/isString";

import { apiClient } from "../../common/apiClient";
import { ensureError } from "../../common/errors";
import { Recipient, RECIPIENT_MODAL } from "../../common/types/recipientTypes";
import { forceArray } from "../../common/utils";
import {
   initializeWithDefaultData,
   isWaitingForData,
   setAsDataAvailable,
   setAsErrorOccured,
   setAsWaitingForData
} from "../../common/utils/asyncDataUtils";

import authStore from "../auth/authStore";
import { RecipientsStore } from "./recipientsStore";
import {
   initializeCurrentRecipient,
   initializeRecipientValidations,
   isInvalidRecipient,
   mapFromLegacyRecipient,
   mapToLegacyRecipient,
   validateRecipient
} from "./recipientsUtils";

type LegacyRecipientsStore = RecipientsStore & {
   saveInternalState(customerNumber: string, recipients: Recipient[]): Promise<void>;
};

const legacyRecipientsStore: LegacyRecipientsStore = store({
   recipients: initializeWithDefaultData([]),
   categories: initializeWithDefaultData([]),
   currentRecipient: initializeCurrentRecipient(),
   requestProgress: initializeWithDefaultData(false),
   validations: initializeRecipientValidations(),
   showModal: RECIPIENT_MODAL.NONE,

   fetchRecipients: async () => {
      // Directly set this as data available, since we don't have dynamic categories in the legacy system
      setAsWaitingForData(legacyRecipientsStore.categories);
      setAsDataAvailable(legacyRecipientsStore.categories, [
         { id: "ORDER_CONFIRMATION", name: "Ordrebekreftelse" },
         { id: "DELIVERY_CONFIRMATION", name: "Leveringsbekreftelse" },
         { id: "IMPORTANT_INFO_ABOUT_DELIVERY", name: "Viktig melding om levering" },
         { id: "ORDER_DEADLINE", name: "Påminnelse om ordrefrist" }
      ]);

      setAsWaitingForData(legacyRecipientsStore.recipients);

      return apiClient(
         `${process.env.API_HOST}/api/notification_contacts/${authStore.currentCompany}`,
         authStore.getSessionToken()
      )
         .get()
         .json((res) => {
            const recipients = res.payload.map(mapFromLegacyRecipient);
            setAsDataAvailable(legacyRecipientsStore.recipients, recipients);
         })
         .catch((errorObject) => {
            console.warn("Unable to fetch recipients", errorObject);
            setAsErrorOccured(legacyRecipientsStore.recipients, ensureError(errorObject).message);
         });
   },

   addRecipient: async () => {
      legacyRecipientsStore.validations = validateRecipient(legacyRecipientsStore.currentRecipient);
      if (isInvalidRecipient(legacyRecipientsStore.validations)) {
         return;
      }

      if (isNil(authStore.currentCompany)) {
         return Promise.reject();
      }

      const recipients = [...legacyRecipientsStore.recipients.data, legacyRecipientsStore.currentRecipient];
      const customerNumber = authStore.currentCompany;
      return legacyRecipientsStore.saveInternalState(customerNumber, recipients);
   },

   editRecipient: async () => {
      legacyRecipientsStore.validations = validateRecipient(legacyRecipientsStore.currentRecipient);
      if (isInvalidRecipient(legacyRecipientsStore.validations)) {
         return;
      }

      if (isNil(authStore.currentCompany)) {
         return Promise.reject();
      }
      const customerNumber = authStore.currentCompany;

      const recipients = legacyRecipientsStore.recipients.data.map((recipient) =>
         recipient.profileId === legacyRecipientsStore.currentRecipient.profileId
            ? legacyRecipientsStore.currentRecipient
            : recipient
      );

      return legacyRecipientsStore.saveInternalState(customerNumber, recipients);
   },

   deleteRecipient: async () => {
      if (isNil(authStore.currentCompany)) {
         return Promise.reject();
      }

      const customerNumber = authStore.currentCompany;
      const recipients = legacyRecipientsStore.recipients.data.filter(
         (recipient) => recipient.profileId !== legacyRecipientsStore.currentRecipient.profileId
      );

      return legacyRecipientsStore.saveInternalState(customerNumber, recipients);
   },

   saveInternalState: (customerNumber, recipients) => {
      const abortController = setAsWaitingForData(legacyRecipientsStore.requestProgress);

      const payload = recipients.map(mapToLegacyRecipient);
      return apiClient(`${process.env.API_HOST}/api/notification_contacts/${customerNumber}`, authStore.getSessionToken())
         .signal(abortController)
         .post(payload)
         .res((res) => {
            setAsDataAvailable(legacyRecipientsStore.requestProgress, res.success);
            void legacyRecipientsStore.fetchRecipients();
            legacyRecipientsStore.toggleModal(RECIPIENT_MODAL.NONE);
         })
         .catch((err) => {
            setAsErrorOccured(legacyRecipientsStore.requestProgress, ensureError(err).message);
         });
   },

   updateCurrentRecipient: (property, value) => {
      if (isNil(value)) {
         return;
      }

      if (property === "emailNotifications" || property === "smsNotifications") {
         legacyRecipientsStore.currentRecipient[property] = forceArray(value) ?? [];
      } else if (isString(value)) {
         legacyRecipientsStore.currentRecipient[property] = value ?? "";
      }
   },
   updateCurrentNotifications: (
      notificationId: string,
      notificationType: keyof Pick<Recipient, "emailNotifications" | "smsNotifications">,
      isRemove: boolean
   ) => {
      const oldNotificationsList =
         notificationType === "emailNotifications"
            ? legacyRecipientsStore.currentRecipient.emailNotifications
            : legacyRecipientsStore.currentRecipient.smsNotifications;

      const newNotificationsList = isRemove
         ? oldNotificationsList.filter((oldNotification) => oldNotification !== notificationId)
         : [...oldNotificationsList, notificationId];

      legacyRecipientsStore.updateCurrentRecipient(notificationType, newNotificationsList);
   },
   getNotificationCategoryName: (queryId) =>
      find(legacyRecipientsStore.categories.data, (category) => category.id === queryId)?.name,
   toggleModal: (modalType: RECIPIENT_MODAL = RECIPIENT_MODAL.NONE, recipientId = "") => {
      if (modalType === RECIPIENT_MODAL.NONE) {
         legacyRecipientsStore.currentRecipient = initializeCurrentRecipient();
         legacyRecipientsStore.requestProgress = initializeWithDefaultData(false);
         legacyRecipientsStore.validations = initializeRecipientValidations();
      }

      if (modalType === RECIPIENT_MODAL.EDIT || modalType === RECIPIENT_MODAL.DELETE) {
         legacyRecipientsStore.currentRecipient = legacyRecipientsStore.recipients.data.find(
            (recipient) => recipientId === recipient.profileId
         )!;
      }

      legacyRecipientsStore.showModal = modalType;
   },
   clearRecipients: () => {
      legacyRecipientsStore.recipients = initializeWithDefaultData([]);
      legacyRecipientsStore.categories = initializeWithDefaultData([]);
      legacyRecipientsStore.currentRecipient = initializeCurrentRecipient();
      legacyRecipientsStore.toggleModal();
   }
});

export default legacyRecipientsStore;
