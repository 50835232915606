import { UserIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon, InformationCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { autoEffect, store, view } from "@risingstack/react-easy-state";
import cn from "classnames";
import isNil from "lodash-es/isNil";
import { KeyboardEventHandler } from "react";
import { Form } from "react-bootstrap";

import { formatOtp, formatPhoneNumber } from "../../common/utils";
import { getPhoneNumberFromStorage, setPhoneNumberInStorage } from "../../common/utils/storageUtils";

import authStore from "../../stores/auth/authStore";
import uiStore, { LOGIN_MODE } from "../../stores/uiStore";

import Button from "../Button";
import ButtonPlain from "../ButtonPlain";
import Loading from "../Loading";

type FormType = {
   mobileNumber: string;
   code: string;
   error: "code" | "mobile" | null;
   loading: boolean;
};

const LoginSms = () => {
   const form: FormType = store({
      mobileNumber: getPhoneNumberFromStorage(),
      code: "",
      error: null,
      loading: false
   });

   autoEffect(() => setPhoneNumberInStorage(form.mobileNumber));

   const requestCode = () => {
      if (form.mobileNumber.length === 0) {
         form.error = "mobile";
         return;
      }

      if (form.loading) {
         return;
      }

      form.loading = true;
      authStore.requestSmsLoginOtp(form.mobileNumber).finally(() => {
         form.loading = false;
      });
   };

   const verifyCode = () => {
      if (form.code.length === 0) {
         form.error = "code";
         return;
      }

      authStore
         .verifySmsLoginOtp(form.code)
         .then(() => {
            form.code = "";
            form.mobileNumber = "";
         })
         .catch((err) => {
            form.error = err;
         });
   };

   const abortSMSLogin = () => {
      uiStore.switchLoginMode(LOGIN_MODE.USERNAME);
      authStore.codeChallengeId = null;
   };

   const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = (event) => {
      form.error = null;
      if (event.key === "Enter") {
         if (isNil(authStore.codeChallengeId)) {
            requestCode();
         } else {
            verifyCode();
         }
      }
   };

   const isCodeSent = !isNil(authStore.codeChallengeId);

   return (
      <div className="login-content d-flex flex-column">
         <h1 className="h2 text-center mx-5">
            <b>Logg inn med mobilnummer</b>
         </h1>
         <div className="d-flex pt-3 pb-2">
            {!isCodeSent ? (
               <p className="text-center">Fyll inn mobilnummer og motta engangskode på SMS.</p>
            ) : !form.error ? (
               <>
                  <CheckCircleIcon className="me-2 login-success-icon" />
                  <p className="m-0">En SMS med engangskode er sendt til mobilnummeret som er registrert på din konto.</p>
               </>
            ) : (
               <>
                  <XCircleIcon className="me-2 login-failed-icon" />
                  <p className="m-0">Koden som er angitt stemmer ikke. Vennligst prøv på nytt.</p>
               </>
            )}
         </div>
         <div className="pb-2">
            <Form.Label className="small">Mobilnummer</Form.Label>
            <Form.Control
               className={cn("tine-input-field", {
                  "is-invalid": !isNil(form.error) && !isCodeSent,
                  "login-disabled": isCodeSent
               })}
               type="tel"
               value={form.mobileNumber}
               onChange={(e) => {
                  form.mobileNumber = formatPhoneNumber(e.target.value);
               }}
               onKeyDown={handleKeyPress}
               autoFocus
               disabled={isCodeSent}
            />
         </div>
         <div className="pb-2">
            <Form.Label className="small">Engangskode fra SMS</Form.Label>
            <Form.Control
               className={cn("tine-input-field", {
                  "is-invalid": !isNil(form.error),
                  "login-disabled": !isCodeSent
               })}
               type="tel"
               value={form.code}
               onChange={(e) => {
                  // Last parameter used to be e.currentTarget.prev - TODO: Ask Fredrik how this worked
                  form.code = formatOtp(e.target.value, e.target.value);
               }}
               onKeyDown={handleKeyPress}
               key={isCodeSent ? "enabled-field" : "disabled-field"}
               autoFocus={isCodeSent}
               disabled={!isCodeSent}
            />
         </div>
         {isNil(authStore.codeChallengeId) ? (
            <Button
               variant="primary"
               size="sm"
               className="tine-primary-button mt-3 mb-4 align-self-center w-fit"
               onClick={requestCode}
            >
               {form.loading ? <Loading visible simple className="fw-100" /> : "Send engangskode"}
            </Button>
         ) : (
            <Button
               variant="primary"
               size="sm"
               className="tine-primary-button mt-2 mb-4 align-self-center w-fit"
               onClick={verifyCode}
            >
               Logg inn med kode
            </Button>
         )}
         {!isCodeSent ? (
            <p className="text-center m-0">Eller</p>
         ) : (
            <div className="d-flex mb-2">
               {!form.error ? (
                  <>
                     <InformationCircleIcon className="me-1 login-small-icon align-self-start" />
                     <p className="small">
                        Får du ingen SMS fra oss? Logg inn med kundenummer og passord for å sikre at korrekt mobilnummer er
                        registrert på din konto.
                     </p>
                  </>
               ) : (
                  <>
                     <InformationCircleIcon className="me-1 login-small-icon align-self-start" />
                     <p className="small">
                        Engangskoden innholder 6 siffer. Dersom du opplever problemer med SMS-pålogging, vennligst logg inn med
                        kundenummer/epost.
                     </p>
                  </>
               )}
            </div>
         )}
         <ButtonPlain className="d-flex align-items-center align-self-center mb-4" onClick={abortSMSLogin}>
            <UserIcon />
            <p className="mb-0 small">
               <b>Logg inn med kundenummer/e-post</b>
            </p>
         </ButtonPlain>
      </div>
   );
};

export default view(LoginSms);
