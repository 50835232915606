import { PlayCircleIcon } from "@heroicons/react/24/outline";
import { Entry } from "contentful";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";

import { TypeImageWithFocusSkeleton } from "../../../common/types/contentful";
import { RecipeType } from "../../../common/types/recipeTypes";
import { getYoutubeVideoIdFromURL } from "../../../common/utils";

import YouTubeVideo from "../../YouTubeVideo";
import RecipeHeader from "./RecipeHeader";
import RecipeImage from "./RecipeImage";

type RecipeHeaderAndImageProps = {
   recipe: RecipeType;
};

const RecipeHeaderAndImage = ({ recipe }: RecipeHeaderAndImageProps) => {
   const { video } = recipe;
   const [showVideo, setShowVideo] = useState(false);
   const youtubeId = getYoutubeVideoIdFromURL(video?.url || "");
   const youTubeTitle = video?.title || "";

   const containerClass = `recipe-header-image-container ${
      youtubeId && !showVideo ? "recipe-header-image-container--has-video" : ""
   }`;

   const handleClick = () => {
      if (youtubeId) {
         setShowVideo(true);
      }
   };
   const handleEnd = () => {
      setShowVideo(false);
   };

   return (
      <Row>
         <Col xs={12} md={4}>
            <RecipeHeader recipe={recipe} />
         </Col>
         <Col className={containerClass}>
            {!showVideo && (
               <>
                  <div className="play-circle-background" role="presentation" />
                  <PlayCircleIcon className="play-circle" role="presentation" onClick={handleClick} />
                  {!!recipe.image?.url && (
                     <RecipeImage
                        width={1250}
                        placement="aboveFold"
                        ratio="16x9"
                        title={recipe.image?.title}
                        url={recipe.image.url}
                        sourceWidth={recipe.image.sourceWidth || undefined}
                        sourceHeight={recipe.image.sourceHeight || undefined}
                        onClick={handleClick}
                     />
                  )}
               </>
            )}
            {showVideo && !!youtubeId && <YouTubeVideo id={youtubeId} title={youTubeTitle} autoPlay={true} onEnd={handleEnd} />}
         </Col>
      </Row>
   );
};

export default RecipeHeaderAndImage;
