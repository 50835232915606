import { view } from "@risingstack/react-easy-state";

import ChangeOrderOrSubscription from "../ChangeOrderOrSubscription";
import BurgerMenuAndCart from "./BurgerMenuAndCart";
import MobileSearchBar from "./MobileSearchBar";

const MobileHeader = () => {
   return (
      <>
         <BurgerMenuAndCart />
         <MobileSearchBar />
         <ChangeOrderOrSubscription />
      </>
   );
};

export default view(MobileHeader);
