import { DevicePhoneMobileIcon } from "@heroicons/react/24/outline";
import { view } from "@risingstack/react-easy-state";
import { getYear } from "date-fns";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
// @ts-ignore
import contantUsJpeg from "url:../../img/contact-us.png?as=jpeg&width=80&height=80";
// @ts-ignore
import contentUsWebp from "url:../../img/contact-us.png?as=webp&width=80&height=80";

import { getLangCodeFromLanguage, getLanguages } from "../common/i18n";
import { isDataAvailable } from "../common/utils/asyncDataUtils";
import { setLanguageInStorage } from "../common/utils/storageUtils";

import contentStore from "../stores/cms/contentStore";
import uiStore from "../stores/uiStore";

import RichTextRenderer from "../components/cms/RichTextRenderer";

type SoMeIconProps = {
   site: string;
   href: string;
};

const LanguageSwitcher = () => {
   const { i18n } = useTranslation();
   const languages = getLanguages();
   return (
      <div>
         <h1> Velg språk </h1>
         {languages.map((language) => {
            const langCode = getLangCodeFromLanguage(language);
            return (
               <button
                  key={`language-btn-${language.name}`}
                  onClick={() => {
                     i18n.changeLanguage(langCode);
                     setLanguageInStorage(langCode);
                  }}
                  disabled={langCode === i18n.resolvedLanguage}
               >
                  {language.localizedName}
               </button>
            );
         })}
      </div>
   );
};

const SoMeIcon = ({ site, href }: SoMeIconProps) => (
   <a href={href} target="_blank" rel="noopener noreferrer" className="mx-2">
      <picture>
         <source type="image/webp" srcSet={`https://www.tinehandel.no/media/wysiwyg/${site}.webp`} />
         <source type="image/png" srcSet={`https://www.tinehandel.no/media/wysiwyg/${site}.png `} />
         <img src={`https://www.tinehandel.no/media/wysiwyg/${site}.png`} alt={site} width="24" height="24" loading="lazy" />
      </picture>
   </a>
);

const OpeningHourKey = (openingHour: any) =>
   openingHour &&
   typeof openingHour === "object" &&
   "key" in openingHour &&
   typeof openingHour["key"] === "string" && <div>{openingHour.key}</div>;

const OpeningHourValue = (openingHour: any) =>
   openingHour &&
   typeof openingHour === "object" &&
   "value" in openingHour &&
   typeof openingHour["value"] === "string" && <div>{openingHour.value}</div>;

const Footer = () => {
   const footerContent = contentStore.footerContent.data;

   return (
      <>
         <div className="footer w-inner px-lg-64 mx-auto">
            <div className="mt-3 mb-4 border-bottom border-slight" />
            {uiStore.showLanguageSwitcher && <LanguageSwitcher />}
            <div className="footer-content pb-5 mb-3">
               <div className="d-flex gap-4 w-fit">
                  <div>
                     <picture>
                        <source srcSet={contentUsWebp} type="image/webp" />
                        <source srcSet={contantUsJpeg} type="image/jpeg" />
                        <img src={contantUsJpeg} height={80} width={80} alt="TINE Kundesenter" />
                     </picture>
                  </div>
                  {footerContent && (
                     <div className="footer-contact-info">
                        <h2 className="pb-8">Kontakt oss</h2>
                        <div className="d-flex my-4">
                           <DevicePhoneMobileIcon className="me-2" />
                           <div className="align-self-center">{footerContent.fields.contactNumber}</div>
                        </div>
                        <div className=" border-bottom border-slight">
                           {footerContent.fields.openingHours &&
                              Array.isArray(footerContent.fields.openingHours) &&
                              footerContent.fields.openingHours?.map((openingHour, index) => (
                                 <div
                                    key={`OpeningHourLine${index}`}
                                    className="d-flex justify-content-between smaller mb-2 gap-3"
                                 >
                                    {OpeningHourKey(openingHour)}
                                    {OpeningHourValue(openingHour)}
                                 </div>
                              ))}
                        </div>
                     </div>
                  )}
               </div>
               {isDataAvailable(contentStore.urlData) &&
                  isDataAvailable(contentStore.footerContent) &&
                  footerContent?.fields.linksRichText && (
                     <div className="footer-links">
                        <h2 className="show-mobile mb-0">Våre lenker</h2>
                        <RichTextRenderer
                           textColumnWidth="absoluteFull"
                           document={footerContent.fields.linksRichText}
                           key="nb_no"
                        />
                     </div>
                  )}
            </div>
         </div>
         <div className="bg-white py-5 d-flex justify-content-center">
            <div className="w-inner px-64 text-center smaller">
               <a
                  className="me-1"
                  href="https://www.tine.no/kontakt/artikler/ansvarserkl%C3%A6ring-personvern-og-debattregler-p%C3%A5-tines-nettsteder"
               >
                  Ansvar, personvern og debattregler
               </a>{" "}
               &mdash; &copy; {getYear(new Date())} TINE Handel
               <br />
               {uiStore.dataVersion}
            </div>
         </div>
      </>
   );
};

export default view(Footer);
