import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { view } from "@risingstack/react-easy-state";
import isNil from "lodash-es/isNil";
import { Modal } from "react-bootstrap";

import { formatDate } from "../common/utils/dateUtils";

import loginState from "../stores/auth/loginState";
import deliveryDatesStore from "../stores/deliveryDates/deliveryDatesStore";

const ChangeDeliveryDateModal = () => {
   const currentDelivery = deliveryDatesStore.getCurrentDelivery();
   if (isNil(currentDelivery?.date)) {
      return null;
   }

   return (
      <Modal
         show={deliveryDatesStore.changingDeliveryDate && !loginState.is("DELIVERY_DATES_LOADING")}
         backdrop={true}
         style={{ width: "100vw" }}
      >
         <Modal.Header>Oppdaterer produkter og priser...</Modal.Header>
         <Modal.Body>
            <p>
               Det hender at nye produkter er tilgjengelig eller andre priser gjelder når man bytter leveringsdag, derfor må vi
               hente et oppdatert produktutvalg og prisliste for {formatDate(currentDelivery?.date, true, true, false)}.
            </p>
            <div className="text-center">
               <ArrowPathIcon className="spin" width={50} />
            </div>
         </Modal.Body>
      </Modal>
   );
};

export default view(ChangeDeliveryDateModal);
