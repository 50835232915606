import { view } from "@risingstack/react-easy-state";
import { Entry } from "contentful";

import {
   TypeAutomaticGeneratedProductListSkeleton,
   TypeCallToActionListSkeleton,
   TypeImageWithFocusSkeleton,
   TypeProductListSkeleton,
   TypeRecipeSkeleton,
   TypeV2_articleSkeleton,
   TypeV2_sectionSkeleton,
   TypeV2_textAndImageSkeleton
} from "../../common/types/contentful";

import { ContentEntryType } from "../../stores/cms/contentUtils";

import Article from "./Article";
import CmsAutomaticProductList from "./CmsAutomaticProductList";
import CmsManualProductList from "./CmsManualProductList";
import ContentBreadcrumb from "./ContentBreadcrumb";
import ImageWithFocus from "./ImageWithFocus";
import Recipe from "./Recipe";
import Section from "./Section";
import TeaserList from "./TeaserList";
import TextAndImage from "./TextAndImage";

type CmsEmbeddedEntryProps = {
   content: Entry<
      | TypeImageWithFocusSkeleton
      | TypeV2_textAndImageSkeleton
      | TypeProductListSkeleton
      | TypeAutomaticGeneratedProductListSkeleton
      | TypeCallToActionListSkeleton
      | TypeV2_articleSkeleton
      | TypeRecipeSkeleton
      | TypeV2_sectionSkeleton,
      undefined,
      "nb_NO"
   >;
   type: ContentEntryType;
   entryId: string;
};

const CmsEmbeddedEntry = ({ content, type, entryId }: CmsEmbeddedEntryProps) => {
   switch (type) {
      case "imageWithFocus":
         const imageEntry = content as Entry<TypeImageWithFocusSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>;
         return <ImageWithFocus width={1250} ratio="16x9" entryId={entryId} imageFromParent={imageEntry} placement="belowFold" />;
      case "v2_textAndImage":
         const textAndImageEntry = content as Entry<TypeV2_textAndImageSkeleton, undefined, "nb_NO">;
         return <TextAndImage content={textAndImageEntry} />;
      case "productList":
         const productListEntry = content as Entry<TypeProductListSkeleton, undefined, "nb_NO">;
         return <CmsManualProductList content={productListEntry} />;
      case "automaticGeneratedProductList":
         const automaticProductListEntry = content as Entry<TypeAutomaticGeneratedProductListSkeleton, undefined, "nb_NO">;
         return <CmsAutomaticProductList content={automaticProductListEntry} />;
      case "callToActionList":
         const callToActionListEntry = content as Entry<TypeCallToActionListSkeleton, undefined, "nb_NO">;
         return <TeaserList content={callToActionListEntry} />;
      case "v2_article":
         const articleEntry = content as Entry<TypeV2_articleSkeleton, undefined, "nb_NO">;
         return (
            <>
               <ContentBreadcrumb />
               <Article id={entryId} article={articleEntry} />
            </>
         );
      case "v2_section":
         const sectionEntry = content as Entry<TypeV2_sectionSkeleton, undefined, "nb_NO">;
         return (
            <>
               <ContentBreadcrumb />
               <Section id={entryId} section={sectionEntry} />
            </>
         );
      case "recipe":
         const recipeEntry = content as Entry<TypeRecipeSkeleton, undefined, "nb-NO">;
         return (
            <>
               <ContentBreadcrumb />
               <Recipe id={entryId} recipeEntry={recipeEntry} />
            </>
         );

      default:
         return <div>Fant ikke visningen for innhold av typen {type}</div>;
   }
};

export default view(CmsEmbeddedEntry);
