import { UserGroupIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { view } from "@risingstack/react-easy-state";
import some from "lodash-es/some";
import { Col, Modal } from "react-bootstrap";
import { useLocation } from "wouter";

import authStore from "../stores/auth/authStore";
import loginState from "../stores/auth/loginState";

import Button from "./Button";

const pathsToRedirect = {
   "/checkout/confirmation": "/",
   "/customer/favorites/": "/customer/favorites",
   "/customer/subscriptions/": "/customer/subscriptions",
   "/customer/orders/": "/customer/orders"
};

const SwitchCompanyModal = () => {
   const [path, navigate] = useLocation();
   const companies = (authStore.companies || []).filter((company) => company.customerNumber !== authStore.currentCompany);

   const currentCompany = authStore.companies?.find((company) => company.customerNumber === authStore.currentCompany);
   const selectCompany = (customerNumber: string) => {
      if (customerNumber !== authStore.currentCompany) {
         void authStore.changeCompany(customerNumber);

         // Stops iterating when first path is found
         some(pathsToRedirect, (to, startsWithPath) => {
            if (path.startsWith(startsWithPath)) {
               navigate(to);
               return true;
            }
            return false;
         });
      }
   };

   const handleClose = () => {
      if (authStore.currentCompany) {
         loginState.transitionTo("LOGGED_IN");
      } else {
         loginState.transitionTo("NOT_LOGGED_IN");
      }
   };

   return (
      <Modal size="lg" fullscreen="md-down" centered show={loginState.is("COMPANY_SELECTION")} onHide={handleClose} scrollable>
         <Modal.Header closeButton={!!currentCompany}>
            <h3 className="tine-font-weight-600 mb-0">Velg firma</h3>
         </Modal.Header>
         <Modal.Body>
            <table className="table w-100">
               <thead>
                  {!!currentCompany && (
                     <tr>
                        <td valign="middle">
                           <Col>
                              <div className="tine-font-weight-600">{currentCompany?.name}</div>
                              <div>
                                 <small>{currentCompany?.customerNumber}</small>
                              </div>
                           </Col>
                        </td>
                        <td valign="middle" className="text-end fw-200">
                           <div className="d-flex justify-content-end">
                              <div className="me-2">Nåværende firma</div>
                              <CheckCircleIcon className="text-success" />
                           </div>
                        </td>
                     </tr>
                  )}
               </thead>
               <tbody>
                  {companies.map((company, index) => (
                     <tr key={`switch-company-${company.customerNumber}-${index}`}>
                        <td valign="middle">
                           <Col>
                              <div className="tine-font-weight-600">{company?.name}</div>
                              <div>
                                 <small>{company?.customerNumber}</small>
                              </div>
                           </Col>
                        </td>
                        <td valign="middle" className="text-end fw-180">
                           <div className="d-flex justify-content-end">
                              <Button
                                 size="sm"
                                 className="tine-secondary-button-outlined"
                                 onClick={() => selectCompany(company.customerNumber)}
                              >
                                 Velg firma
                                 <UserGroupIcon width={18} height={18} className="ms-1" />
                              </Button>
                           </div>
                        </td>
                     </tr>
                  ))}
               </tbody>
            </table>
         </Modal.Body>
      </Modal>
   );
};

export default view(SwitchCompanyModal);
