import { ProjectConfig } from "configcat-common";
var LocalStorageCache = /** @class */ (function () {
    function LocalStorageCache() {
        this.cache = {};
    }
    LocalStorageCache.prototype.set = function (key, config) {
        this.cache[key] = config;
        try {
            localStorage.setItem(key, btoa(JSON.stringify(config)));
        }
        catch (ex) {
            // local storage is unavailable
        }
    };
    LocalStorageCache.prototype.get = function (key) {
        var config = this.cache[key];
        if (config) {
            return config;
        }
        try {
            var configString = localStorage.getItem(key);
            if (configString) {
                var config_1 = JSON.parse(atob(configString));
                // JSON.parse creates a plain object instance, so we need to manually restore the prototype
                // (so we don't run into "... is not a function" errors).
                (Object.setPrototypeOf || (function (o, proto) { return o["__proto__"] = proto; }))(config_1, ProjectConfig.prototype);
                if (config_1) {
                    this.cache[key] = config_1;
                    return config_1;
                }
            }
        }
        catch (ex) {
            // local storage is unavailable or invalid cache value in localstorage
        }
        return null;
    };
    return LocalStorageCache;
}());
export { LocalStorageCache };
