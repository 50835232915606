export type ConfigCatUserData = {
   identifier: string;
};

export type CustomerFeature = {
   name: FEATURE_NAME;
   description: string;
};

export enum FEATURE_NAME {
   subscriptionOrderAvailable = "subscriptionOrderAvailable",
   invoicePaymentAvailable = "invoicePaymentAvailable",
   createOrderAvailable = "createOrderAvailable",
   pickupOrderAvailable = "pickupOrderAvailable",
   canBuySingleItems = "canBuySingleItems"
}
