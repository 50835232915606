import isNil from "lodash-es/isNil";
import isString from "lodash-es/isString";

import { BackendErrorTypes, ErrorMessagesByLocale, ErrorType } from "./types/errorTypes";
import { SupportedLocaleType } from "./types/languageTypes";

export class GenericError<T extends ErrorType> extends Error {
   constructor(message: T, errorOptions?: ErrorOptions) {
      errorOptions ? super(message, errorOptions) : super(message);
      this.message = message;
   }
   message: T;
}
export class WrongCodeError extends GenericError<"WRONG CODE"> {
   constructor() {
      super("WRONG CODE");
   }
}

export class WrongUsernameOrPasswordError extends GenericError<"USERNAME OR PASSWORD"> {
   constructor() {
      super("USERNAME OR PASSWORD");
   }
}

export class BackendIssueError extends GenericError<BackendErrorTypes> {
   constructor(message: BackendErrorTypes = "BACKEND ISSUE", errorOptions: ErrorOptions = {}) {
      super(message, errorOptions);
   }
}

export class ServerNotFoundError extends GenericError<"SERVER NOT FOUND"> {
   constructor() {
      super("SERVER NOT FOUND");
   }
}

export class MultiAccountError extends GenericError<"MULTI ACCOUNT"> {
   constructor() {
      super("MULTI ACCOUNT");
   }
}

export class TokenExpiryError extends GenericError<"TOKEN EXPIRY"> {
   constructor() {
      super("TOKEN EXPIRY");
   }
}

export class MissingDeliveryDatesError extends GenericError<"MISSING DELIVERY DATES"> {
   constructor() {
      super("MISSING DELIVERY DATES");
   }
}

export class AvailabilityError extends GenericError<"AVAILABILITY ERROR"> {
   constructor() {
      super("AVAILABILITY ERROR");
   }
}

export const ensureError = (err: unknown): Error => {
   if (isNil(err)) {
      return new Error("Null or undefined passed as error", { cause: err });
   }

   if (err instanceof Error) {
      return err;
   }

   if (isString(err)) {
      return new Error(err);
   }

   if (typeof err === "object" && err !== null && "message" in err) {
      return new Error("" + err.message, { cause: err });
   }

   return new Error("Unknown error", { cause: err });
};

type BackendIssueMessagesByLocale = { [locale in SupportedLocaleType]: { [key in BackendErrorTypes]: string } };

export const genericLoginErrorByLocale: { [locale in SupportedLocaleType]: string } = {
   "nb-NO": "Det oppsto en feil med et underliggende system ved innlogging.",
   "en-US": "There was an error with one of the supporting system during login."
};
export const backendIssueMessagesByLocale: BackendIssueMessagesByLocale = {
   "nb-NO": {
      "BACKEND ISSUE": genericLoginErrorByLocale["nb-NO"],
      "StoreId not found": genericLoginErrorByLocale["nb-NO"],
      "Missing delivery dates": "Det oppsto en feil ved henting av leveringsdager.",
      "Unable to load assortment, error from backend": "Det oppsto en feil ved henting av produktsortiment.",
      "Unable to load delivery dates / pickup": "Det oppsto en feil ved henting av leveringsdager.",
      "Unable to load favourites, error from backend": "Det oppsto en feil ved henting av favoritter.",
      "Unable to determine default customer number": "Kunne ikke finne foretrukket kundenummer.",
      "Unable to verify OneTimeCode": "Kunne ikke verifisere engangskoden.",
      "Delivery day not available": "Leveringsdagen er ikke tilgjengelig.",
      "Unable to find selected company": "Kunne ikke finne det valgte firma."
   },
   "en-US": {
      "BACKEND ISSUE": genericLoginErrorByLocale["en-US"],
      "StoreId not found": genericLoginErrorByLocale["en-US"],
      "Unable to load assortment, error from backend": "There was an error loading your product assortment.",
      "Unable to load delivery dates / pickup": "There was an error loading delivery dates.",
      "Missing delivery dates": "There was an error loading your delivery dates.",
      "Unable to load favourites, error from backend": "There was an error loading your favourites.",
      "Unable to determine default customer number": "Unable to determine default customer number.",
      "Unable to verify OneTimeCode": "Unable to verify OneTimeCode.",
      "Delivery day not available": "Delivery day not available.",
      "Unable to find selected company": "Unable to find selected company."
   }
};

export const getCredentialsErrorMessage = (username: string, locale: SupportedLocaleType) => {
   if (locale === "en-US") {
      const userType = username.includes("@") ? "email" : "customer number";
      return `Wrong ${userType} or password`;
   }
   const userType = username.includes("@") ? "e-post" : "kundenummer";
   return `Feil ${userType} eller passord`;
};

export const networkErrorMessageByLocale: { [local in SupportedLocaleType]: string } = {
   "en-US": "Cannot locate the login server. This may be caused by a network error or technical difficulties at TINE.",
   "nb-NO": "Fikk ikke kontakt med innloggingsserver. Årsaken kan være nettverksfeil eller tekniske utfordringer hos TINE."
};
