import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { ChangeEvent, CSSProperties, InputHTMLAttributes, useEffect, useState } from "react";
import { Form, FormControlProps } from "react-bootstrap";
import PasswordStrengthBar, { PasswordStrengthBarProps } from "react-password-strength-bar";

import { SupportedLocaleType } from "../common/types/languageTypes";

type PasswordFieldProps = FormControlProps &
   InputHTMLAttributes<HTMLInputElement> & {
      value: string;
      locale?: SupportedLocaleType;
      strengthBar?: boolean;
      allowClearText?: boolean;
      validator?: { (value: string): string };
   };
type StrengthBarPropsByLocale = {
   [L in SupportedLocaleType]: Omit<PasswordStrengthBarProps, "password">;
};

const strengthBarPropsByLocale: StrengthBarPropsByLocale = {
   "nb-NO": {
      scoreWords: ["veldig svakt", "svakt", "ok", "bra", "sterkt"],
      shortScoreWord: ["for kort"]
   },
   "en-US": {}
};

type EyeTogglerProps = { value: boolean; onToggle: { (): void } };

const eyeStyle: CSSProperties = {
   position: "absolute",
   cursor: "pointer",
   height: "70%",
   right: "3px",
   top: "15%"
};

const EyeToggler = ({ value, onToggle }: EyeTogglerProps) =>
   !value ? (
      <EyeIcon title="Vis passord" style={eyeStyle} onClick={() => onToggle()} />
   ) : (
      <EyeSlashIcon title="Skjul passord" style={eyeStyle} onClick={() => onToggle()} />
   );

const PasswordField = ({
   strengthBar = false,
   allowClearText = false,
   locale = "nb-NO",
   validator,
   onChange,
   value,
   min = 8,
   type = "password",
   className = "",
   isInvalid,
   ...rest
}: PasswordFieldProps) => {
   const [clearText, setClearText] = useState<boolean>(false);
   const [error, setError] = useState<string>("");

   const showClearTextToggle = type === "password" && allowClearText;
   const strengthBarProps = strengthBarPropsByLocale[locale];

   useEffect(() => {
      validator && setError(validator(value));
   }, [isInvalid]);

   const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(e);
      const error = (validator && validator(e.target.value)) || "";
      validator && setError(validator(e.target.value));
   };
   return (
      <div className={`${className} password-field`}>
         <div style={{ position: "relative" }}>
            <Form.Control
               {...rest}
               value={value}
               type={clearText ? "text" : type}
               onChange={onPasswordChange}
               isInvalid={isInvalid || !!error}
               style={showClearTextToggle ? { paddingRight: "1.8rem", backgroundImage: "none" } : {}}
            />
            {showClearTextToggle && <EyeToggler value={clearText} onToggle={() => setClearText(!clearText)} />}
         </div>
         {!!value && strengthBar && (
            <div className="mt-2">
               <PasswordStrengthBar {...strengthBarProps} password={value} minLength={Number(min)} />
            </div>
         )}
         {!!error && <p className="tine-error-text small">{error}</p>}
      </div>
   );
};

export default PasswordField;
