import { view } from "@risingstack/react-easy-state";
import isNil from "lodash-es/isNil";
import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useLocation } from "wouter";

import cartStore from "../../stores/cartStore";
import subscriptionStore from "../../stores/subscriptionStore";

import Button from "../Button";
import Loading from "../Loading";
import ShortcutBox from "./ShortcutBox";

const ShortcutChangeSubscription = () => {
   const [_, redirectTo] = useLocation();
   const [showModal, setShowModal] = useState(false);

   const startEdit = (orderNumber: string | null) => {
      if (isNil(orderNumber)) {
         console.warn("Missing order number when starting edit of subscription");
         return;
      }
      setShowModal(true);
      subscriptionStore.fetchSubscriptionDetails(orderNumber).then((subscription) => {
         setShowModal(false);
         void cartStore.startEditSubscriptionMode(subscription);
         redirectTo("/checkout/cart");
      });
   };

   return (
      <>
         {showModal && <Loading modal label="Henter abonnementsdetaljer..." />}
         <ShortcutBox>
            <h4>Mine abonnement</h4>
            <p>Se oversikt over dine abonnement, eller velg et abonnement for å endre alle fremtidige leveringer.</p>
            <Link to="/customer/subscriptions">
               <Button variant="secondary" className="mb-2" onClick={() => {}}>
                  Alle abonnement
               </Button>
            </Link>
            <Dropdown onSelect={startEdit}>
               <Dropdown.Toggle className="px-3" size="sm" variant="secondary">
                  Endre abonnement
               </Dropdown.Toggle>
               <Dropdown.Menu>
                  {subscriptionStore.subscriptionsList.data.map((sub) => (
                     <Dropdown.Item eventKey={sub.orderNumber} key={sub.orderNumber}>
                        {sub.name}
                        <div className="smallest text-muted">#{sub.orderNumber}</div>
                     </Dropdown.Item>
                  ))}
               </Dropdown.Menu>
            </Dropdown>
         </ShortcutBox>
      </>
   );
};

export default view(ShortcutChangeSubscription);
