import { errorToString } from "./Utils";
export var LogLevel;
(function (LogLevel) {
    LogLevel[LogLevel["Debug"] = 4] = "Debug";
    LogLevel[LogLevel["Info"] = 3] = "Info";
    LogLevel[LogLevel["Warn"] = 2] = "Warn";
    LogLevel[LogLevel["Error"] = 1] = "Error";
    LogLevel[LogLevel["Off"] = -1] = "Off";
})(LogLevel || (LogLevel = {}));
var LoggerWrapper = /** @class */ (function () {
    function LoggerWrapper(logger, hooks) {
        this.logger = logger;
        this.hooks = hooks;
    }
    Object.defineProperty(LoggerWrapper.prototype, "level", {
        get: function () { var _a; return (_a = this.logger.level) !== null && _a !== void 0 ? _a : LogLevel.Warn; },
        enumerable: false,
        configurable: true
    });
    LoggerWrapper.prototype.log = function (message) {
        this.info(message);
    };
    LoggerWrapper.prototype.debug = function (message) {
        if (this.isLogLevelEnabled(LogLevel.Debug)) {
            this.logger.debug(message);
        }
    };
    LoggerWrapper.prototype.info = function (message) {
        if (this.isLogLevelEnabled(LogLevel.Info)) {
            this.logger.info(message);
        }
    };
    LoggerWrapper.prototype.warn = function (message) {
        if (this.isLogLevelEnabled(LogLevel.Warn)) {
            this.logger.warn(message);
        }
    };
    LoggerWrapper.prototype.error = function (message, err) {
        var _a;
        if (this.isLogLevelEnabled(LogLevel.Error)) {
            var logMessage = err
                ? message + '\n' + errorToString(err, true)
                : message;
            this.logger.error(logMessage);
        }
        (_a = this.hooks) === null || _a === void 0 ? void 0 : _a.emit("clientError", message, err);
    };
    LoggerWrapper.prototype.isLogLevelEnabled = function (logLevel) {
        return this.level >= logLevel;
    };
    return LoggerWrapper;
}());
export { LoggerWrapper };
var ConfigCatConsoleLogger = /** @class */ (function () {
    /**
     * Create an instance of ConfigCatConsoleLogger
     */
    function ConfigCatConsoleLogger(level) {
        if (level === void 0) { level = LogLevel.Warn; }
        this.level = level;
        this.SOURCE = "ConfigCat";
    }
    /** @inheritdoc */
    ConfigCatConsoleLogger.prototype.log = function (message) {
        this.info(message);
    };
    /** @inheritdoc */
    ConfigCatConsoleLogger.prototype.debug = function (message) {
        console.info(this.SOURCE + " - DEBUG - " + message);
    };
    /** @inheritdoc */
    ConfigCatConsoleLogger.prototype.info = function (message) {
        console.info(this.SOURCE + " - INFO - " + message);
    };
    /** @inheritdoc */
    ConfigCatConsoleLogger.prototype.warn = function (message) {
        console.warn(this.SOURCE + " - WARN - " + message);
    };
    /** @inheritdoc */
    ConfigCatConsoleLogger.prototype.error = function (message) {
        console.error(this.SOURCE + " - ERROR - " + message);
    };
    return ConfigCatConsoleLogger;
}());
export { ConfigCatConsoleLogger };
