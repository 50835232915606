import { view } from "@risingstack/react-easy-state";
import cn from "classnames";
import { FormEvent, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";

import { isErrorOccured, isWaitingForData } from "../../../common/utils/asyncDataUtils";

import authStore from "../../../stores/auth/authStore";
import uiStore, { LOGIN_MODE } from "../../../stores/uiStore";

import Button from "../../Button";
import ErrorMessage from "../../ErrorMessage";
import Loading from "../../Loading";
import PasswordField from "../../PasswordField";

type InputOptProps = {
   email: string;
};

const InputOtp = ({ email }: InputOptProps) => {
   const [otp, setOtp] = useState<string>("");
   const [invalidOtp, setInvalidOtp] = useState<boolean>(true);

   const [newPassword, setNewPassword] = useState<string>("");
   const [invalidPassword, setInvalidPassword] = useState<boolean>(false);
   const resetSuccess = authStore.resetPasswordProgress.data;

   useEffect(() => {
      setInvalidOtp(otp.length !== 6 || isNaN(Number(otp)));
      setInvalidPassword(false);
   }, [otp]);

   useEffect(() => {
      if (resetSuccess) {
         uiStore.switchLoginMode(LOGIN_MODE.RESET_PASSWORD_SUCCESS);
      }
   }, [resetSuccess]);

   const onSubmit = async (event: FormEvent) => {
      event.preventDefault();
      setInvalidPassword(false);
      if (newPassword.length < 8) {
         setInvalidPassword(true);
      } else {
         await authStore.attemptResetPassword(otp, newPassword);
      }
   };

   return (
      <>
         <p className="text-center mb-3">Du har fått tilsendt engangskode over e-post. Fyll inn koden og oppgi nytt passord.</p>
         <Form noValidate onSubmit={onSubmit} className="d-flex flex-column">
            <Form.Group className="mb-2">
               <Form.Label className="small">Engangskode (6 tegn)</Form.Label>
               <Form.Control
                  className="tine-input-field"
                  type="text"
                  value={otp}
                  onChange={(event) => {
                     setOtp(event.target.value);
                  }}
                  autoFocus
               />
            </Form.Group>
            <Form.Group className="mb-2">
               <Form.Control hidden type="email" value={email} name="username" autoComplete="username" />
               <Form.Label className="small">Nytt passord</Form.Label>
               <PasswordField
                  className={cn({ "is-invalid": invalidPassword })}
                  type="password"
                  value={newPassword}
                  onChange={(event) => {
                     setNewPassword(event.target.value);
                  }}
                  allowClearText={true}
                  strengthBar={true}
                  isInvalid={invalidPassword}
                  disabled={invalidOtp || isWaitingForData(authStore.resetPasswordProgress)}
               />
               <Form.Control.Feedback type="invalid">Passordet må inneholde minst 8 tegn.</Form.Control.Feedback>
            </Form.Group>
            {isErrorOccured(authStore.resetPasswordProgress) && !invalidPassword && (
               <ErrorMessage className="mb-2 small" message="Noe gikk galt ved endring av passord. Prøv igjen senere." />
            )}
            <Button
               variant="primary"
               size="sm"
               className="tine-primary-button mb-4 mt-3 align-self-center w-fit"
               type="submit"
               disabled={invalidOtp || isWaitingForData(authStore.resetPasswordProgress) || newPassword.length < 8}
            >
               {isWaitingForData(authStore.resetPasswordProgress) ? <Loading visible simple className="fw-100" /> : "Bekreft"}
            </Button>
         </Form>
      </>
   );
};

export default view(InputOtp);
