import isNull from "lodash-es/isNull";

import { apiClient } from "../../common/apiClient";
import { M3Order, M3OrderError, M3OrderListed, OrderFilter, OrderFilterQueryParams } from "../../common/types/m3Types";
import { DeliveryNotesInfo } from "../../common/types/productOrderTypes";
import { formatM3Date } from "../../common/utils/dateUtils";

import theme from "../../themes/theme";
import authStore from "../auth/authStore";

export const convertOrderFilterToQueryParams = (filters: OrderFilter) => {
   const filterQueryParams: OrderFilterQueryParams = {
      typeOfOrder: "K"
   };

   if (filters.orderNumber.trim().length > 0) {
      // If searching for order number we don't care about the other filters
      filterQueryParams.orderNumber = filters.orderNumber.trim();
   } else {
      if (filters.orderName.trim().length > 0) {
         filterQueryParams.orderName = filters.orderName.trim();
      }
      if (!isNull(filters.dateRange.from)) {
         filterQueryParams.fromDate = formatM3Date(filters.dateRange.from);
      }
      if (!isNull(filters.dateRange.to)) {
         filterQueryParams.toDate = formatM3Date(filters.dateRange.to);
      }
      if (!isNull(filters.dateRange.from) && isNull(filters.dateRange.to)) {
         // If only one date is selected in the date range, use this date as both start and end date
         filterQueryParams.toDate = formatM3Date(filters.dateRange.from);
      }
      if (filters.status !== "") {
         const fromTo = filters.status.split("/");
         filterQueryParams.fromStatus = fromTo[0];
         filterQueryParams.toStatus = fromTo[1];
      }
   }

   return filterQueryParams;
};

export const fetchOrderListFromM3 = async (customerNumber: string, filters: OrderFilter): Promise<M3OrderListed[]> => {
   const queryParams = convertOrderFilterToQueryParams(filters);
   return await apiClient(
      `${process.env.API_HOST}/api/${theme.tipApiPrefix}tip/API/customerOrderList`,
      authStore.getSessionToken()
   )
      .query({ customerNumber, ...queryParams })
      .get()
      .json();
};

export const fetchOrderDetailsFromM3 = async (
   companyNumber: string,
   customerNumber: string,
   orderNumber: string
): Promise<M3Order | M3OrderError> => {
   return await apiClient(`${process.env.API_HOST}/api/${theme.tipApiPrefix}tip/API/customerOrder`, authStore.getSessionToken())
      .query({ companyNumber, customerNumber, orderNumber })
      .get()
      .json();
};

export const fetchDeliveryNotes = async (orderNumber: string, customerNumber: string): Promise<DeliveryNotesInfo> => {
   return await apiClient(
      `${process.env.API_HOST}/api/invoices/customer/${customerNumber}/deliverynote/${orderNumber}`,
      authStore.getSessionToken()
   )
      .get()
      .json();
};
