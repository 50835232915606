import { view } from "@risingstack/react-easy-state";
import { useMemo, useState } from "react";
import { Col } from "react-bootstrap";

import { RecipeType } from "../../../common/types/recipeTypes";

import IngredientGroup from "./IngredientGroup";
import IngredientsPortionsControl from "./IngredientsPortionsControl";

type IngredientsProps = {
   recipe: RecipeType;
};

const Ingredients = ({ recipe }: IngredientsProps) => {
   const { portions, multipliable } = recipe;
   const [multiplier, setMultiplier] = useState<number>(1);
   const ingredientGroups = recipe.ingredientGroups;
   const showPortionsControl = !!portions && !!multipliable;
   const currentPortions = useMemo(() => (showPortionsControl ? Math.floor(portions * multiplier) : portions || 4), [multiplier]);

   const handlePortionsControlChange = (newPortions: number) => {
      console.log({ from: "handlePortionsControlChange", newPortions });
      const multiplier = newPortions / (portions || 4);
      setMultiplier(multiplier);
   };

   const portionsText = portions ? (portions === 1 ? "1 porsjon" : `${portions} porsjoner`) : "";

   return (
      <Col className="recipe-ingredients">
         <h2 className="mb-4">Ingredienser</h2>
         {showPortionsControl && <IngredientsPortionsControl portions={currentPortions} onChange={handlePortionsControlChange} />}
         {portions && !showPortionsControl && <p>{portionsText}</p>}
         {ingredientGroups.map((ingredientGroup, i) => (
            <IngredientGroup key={`${recipe.recipeId}-${i}`} ingredientGroup={ingredientGroup} multiplier={multiplier} />
         ))}
      </Col>
   );
};

export default view(Ingredients);
