import { store } from "@risingstack/react-easy-state";
import defaultTo from "lodash-es/defaultTo";

import { apiClient } from "../../common/apiClient";
import { ConfigCatKeys, getConfigCatValue } from "../../common/configCatClient";
import { ConfigCatUserData, CustomerFeature, FEATURE_NAME } from "../../common/types/featureTypes";

import theme from "../../themes/theme";
import authStore from "../auth/authStore";

type FeatureStore = {
   customerFeatures: FEATURE_NAME[];
   barcodeScannerEnabled: boolean;
   claimsEnabled: boolean;
   pickupOrderAvailable: boolean;
   notificationsEnabled: boolean;
   recommendationType: "AI1" | "AI2";

   fetchEnabledConfigCatFeatures: (customerNumber?: string) => Promise<void>;
   fetchEnabledCustomerFeatures: (customerNumber: string) => Promise<void>;
   clearCustomerFeatures: () => void;
   hasCustomerFeature: (feature: FEATURE_NAME) => boolean;
};

export const featuresStore: FeatureStore = store({
   customerFeatures: [],
   barcodeScannerEnabled: false,
   claimsEnabled: false,
   pickupOrderAvailable: false,
   notificationsEnabled: false,
   recommendationType: "AI2",

   fetchEnabledConfigCatFeatures: async (customerNumber?: string) => {
      const userData: ConfigCatUserData = {
         identifier: defaultTo(customerNumber, "Anonymous")
      };

      featuresStore.barcodeScannerEnabled = await getConfigCatValue(ConfigCatKeys.BARCODESCANNER_AVAILABLE, userData);
      featuresStore.claimsEnabled = await getConfigCatValue(ConfigCatKeys.CLAIMS_AVAILABLE, userData);
      featuresStore.pickupOrderAvailable = await getConfigCatValue(ConfigCatKeys.PICKUP_ORDER_AVAILABLE, userData);
      featuresStore.notificationsEnabled = await getConfigCatValue(ConfigCatKeys.NOTIFICATIONS_AVAILABLE, userData);
      featuresStore.recommendationType = await getConfigCatValue(ConfigCatKeys.RECOMMENDATION_TYPE, userData);
   },

   fetchEnabledCustomerFeatures: async (customerNumber) => {
      const optionalStoreId = theme.storeId === "tinehandel" ? "" : `/${theme.storeId}`;
      const url = `${process.env.API_HOST}/api${optionalStoreId}/features/${customerNumber}.json`;
      const resp: CustomerFeature[] = await apiClient(url, authStore.getSessionToken()).get().json();

      featuresStore.customerFeatures = resp.map((feature) => feature.name);
      console.log("Features found: ", featuresStore.customerFeatures);
   },

   clearCustomerFeatures: () => {
      featuresStore.customerFeatures = [];
   },

   hasCustomerFeature: (featureName) => {
      return featuresStore.customerFeatures.includes(featureName);
   }
} satisfies FeatureStore);

export default featuresStore;
