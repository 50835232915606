var InMemoryCache = /** @class */ (function () {
    function InMemoryCache() {
        this.cache = {};
    }
    InMemoryCache.prototype.set = function (key, config) {
        this.cache[key] = config;
    };
    InMemoryCache.prototype.get = function (key) {
        var _a;
        return (_a = this.cache[key]) !== null && _a !== void 0 ? _a : null;
    };
    return InMemoryCache;
}());
export { InMemoryCache };
