import { view } from "@risingstack/react-easy-state";
import { Entry } from "contentful";

import { TypeAutomaticGeneratedProductListSkeleton } from "../../common/types/contentful";

import authStore from "../../stores/auth/authStore";
import favoriteStore from "../../stores/favoriteStore";

import ErrorMessage from "../ErrorMessage";
import FavoritesAndSubscriptionsWidget from "../FavoritesAndSubscriptionsWidget";
import ProductList from "../ProductList";
import Recommendations from "../Recommendations";

type CmsAutomaticProductListProps = {
   content: Entry<TypeAutomaticGeneratedProductListSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "nb_NO">;
};

const CmsAutomaticProductList = ({ content }: CmsAutomaticProductListProps) => {
   const title = content.fields.title;
   const numberOfProducts = content.fields.numberOfProducts;
   const type = content.fields.type;

   if (!authStore.isLoggedIn()) {
      return null;
   }

   switch (type) {
      case "User Favorites":
         return (
            <ProductList
               maxCol={numberOfProducts}
               skus={favoriteStore.allSkus.slice(0, numberOfProducts)}
               buttonUrl="/customer/favorites"
               listName="Mine Favoritter (CMS Block)"
               heading={title}
            />
         );

      case "User Favorites And Subscriptions":
         return <FavoritesAndSubscriptionsWidget heading={title} />;

      case "Recommendations":
         return <Recommendations heading={null} count={numberOfProducts} />;

      case "Recommendations - AI1":
         return <Recommendations heading={null} count={numberOfProducts} skus={"AI1"} />;

      case "Recommendations - AI2":
         return <Recommendations heading={null} count={numberOfProducts} skus={"AI2"} />;

      case "Often Bought By You":
      default:
         return <ErrorMessage message={`Det opppsto en uventet feil under visning av innhold ${content.sys.id} `} />;
   }
};

export default view(CmsAutomaticProductList);
