import { StepGroupType } from "../../../common/types/recipeTypes";

import StepGroup from "./StepGroup";
import StepTip from "./StepTip";

type InstructionsProps = {
   stepGroups: StepGroupType[];
   tip?: string | null;
};

const Instructions = ({ stepGroups, tip }: InstructionsProps) => {
   return (
      <>
         <h2 className="mb-4">Fremgangmåte</h2>
         {stepGroups.map((stepGroup, i) => (
            <div key={`step-group-${i}`}>
               <StepGroup stepGroup={stepGroup} />
            </div>
         ))}
         <>
            {!!tip && (
               <div>
                  <StepTip tip={tip} />
               </div>
            )}
         </>
      </>
   );
};

export default Instructions;
