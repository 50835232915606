// @ts-ignore - needed because TypeScript does not support loading SVGs as images
import desktopLogoUrl from "url:/src/img/themes/tine/logo_blue.svg";
// @ts-ignore - needed because TypeScript does not support loading SVGs as images
import mobileLogoUrl from "url:/src/img/themes/tine/logo_white.svg";

import { SupportedLocaleType } from "../common/types/languageTypes";
import { SiteConfig } from "../common/types/themeTypes";

const CONTENTFUL_ENV = process.env.CONTENTFUL_ENV ?? "";
const ALGOLIA_APPID = "4RRKWA9QAI";
const ALGOLIA_SEARCHKEY = "b13d7842783d41922109815b23b958a3";
const algoliaEnvironment = process.env.ENV_NAME === "prod" ? "prod" : "preprod";

const tineSiteConfig: SiteConfig = {
   storeId: "tinehandel",
   siteName: "TINE Handel",
   favIcon: "favicon-tetra-pak-96.png",
   m3CompanyNumber: "100",
   m3DivisionNumber: "102",
   desktopLogoUrl: desktopLogoUrl.toString(),
   mobileLogoUrl: mobileLogoUrl.toString(),
   productDropdownEnabled: true,
   logoRatio: 0.72,
   filterAttributes: () => ({
      brand: "Merkevare",
      taste: "Smak",
      size: "Størrelse",
      certifications: "Sertifisering"
   }),
   enhanceProduct: (product) => product,
   filterRefreshOnChange: false,
   tipApiPrefix: "",
   deliveryFee: {
      enabled: true
   },
   productAvailability: {
      enabled: true
   },
   newCustomerForm: "https://kundeskjema.tine.no/",
   deliveryNotes: {
      visible: true
   },
   searchConfig: {
      algoliaConfig: {
         appId: ALGOLIA_APPID,
         searchApiKey: ALGOLIA_SEARCHKEY
      },
      searchDomains: [
         {
            type: "product",
            name: "produkter",
            displayName: (locale: SupportedLocaleType) => (locale === "en-US" ? "Products" : "Produkter"),
            homePath: (_locale) => "/produkter.html",
            indexName: `products_${algoliaEnvironment}`
         },
         {
            type: "v2_article",
            name: "inspirasjon",
            displayName: (locale: SupportedLocaleType) => (locale === "en-US" ? "Inspiration" : "Inspirasjon"),
            homePath: (_locale) => "/inspirasjon",
            indexName: `contentful_${CONTENTFUL_ENV}`
         },
         {
            type: "recipe",
            name: "oppskrifter",
            displayName: (locale: SupportedLocaleType) => (locale === "en-US" ? "Recipes" : "Oppskrifter"),
            homePath: (_locale) => "/oppskrifter",
            indexName: `contentful_${CONTENTFUL_ENV}`
         }
      ]
   },
   contentImages: {
      cloudinaryMapping: "content_images"
   }
};

export default tineSiteConfig;
