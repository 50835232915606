var getGlobalObject = (function () {
    var value;
    return function () {
        if (!value) {
            value =
                typeof globalThis === "object" && globalThis ? globalThis :
                    typeof self === "object" && self ? self :
                        typeof window === "object" && window ? window :
                            typeof global === "object" && global ? global :
                                typeof Function === "function" ? (Function('return this')()) :
                                    null;
            if (!value) {
                throw new Error("Global object could not be determined.");
            }
        }
        return value;
    };
})();
export function setupPolyfills() {
    // Object.values
    if (typeof Object.values === "undefined") {
        Object.values = ObjectValuesPolyfill;
    }
    // Object.entries
    if (typeof Object.entries === "undefined") {
        Object.entries = ObjectEntriesPolyfill;
    }
    // Object.fromEntries
    if (typeof Object.fromEntries === "undefined") {
        Object.fromEntries = ObjectFromEntriesPolyfill;
    }
    // WeakRef
    if (typeof WeakRef === "undefined") {
        // There's no way to correctly polyfill WeakRef (https://stackoverflow.com/a/69971312/8656352),
        // so we just polyfill its API (which means falling back on strong references in this case).
        getGlobalObject().WeakRef = getWeakRefStub();
    }
}
export function ObjectValuesPolyfill(o) {
    var result = [];
    for (var _i = 0, _a = Object.keys(o); _i < _a.length; _i++) {
        var key = _a[_i];
        result.push(o[key]);
    }
    return result;
}
export function ObjectEntriesPolyfill(o) {
    var result = [];
    for (var _i = 0, _a = Object.keys(o); _i < _a.length; _i++) {
        var key = _a[_i];
        result.push([key, o[key]]);
    }
    return result;
}
export function ObjectFromEntriesPolyfill(entries) {
    var _a;
    var result = {};
    if (Array.isArray(entries)) {
        for (var _i = 0, entries_1 = entries; _i < entries_1.length; _i++) {
            var _b = entries_1[_i], key = _b[0], value = _b[1];
            result[key] = value;
        }
    }
    else if (typeof Symbol !== "undefined" && (entries === null || entries === void 0 ? void 0 : entries[Symbol.iterator])) {
        var iterator = entries[Symbol.iterator]();
        var element = void 0, done = void 0;
        while ((_a = iterator.next(), element = _a.value, done = _a.done, !done)) {
            var key = element[0], value = element[1];
            result[key] = value;
        }
    }
    else {
        throw new Error('Object.fromEntries() requires a single iterable argument');
    }
    return result;
}
export function getWeakRefStub() {
    var WeakRef = function (target) {
        this.target = target;
    };
    WeakRef.prototype.deref = function () {
        return this.target;
    };
    WeakRef.isFallback = true;
    return WeakRef;
}
export var isWeakRefAvailable = function () { return typeof WeakRef === "function" && !WeakRef.hasOwnProperty("isFallback"); };
