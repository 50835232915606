import { __extends } from "tslib";
export var FetchStatus;
(function (FetchStatus) {
    FetchStatus[FetchStatus["Fetched"] = 0] = "Fetched";
    FetchStatus[FetchStatus["NotModified"] = 1] = "NotModified";
    FetchStatus[FetchStatus["Errored"] = 2] = "Errored";
})(FetchStatus || (FetchStatus = {}));
var FetchResult = /** @class */ (function () {
    function FetchResult(status, responseBody, eTag, errorMessage, errorException) {
        this.status = status;
        this.responseBody = responseBody;
        this.eTag = eTag;
        this.errorMessage = errorMessage;
        this.errorException = errorException;
    }
    FetchResult.success = function (responseBody, eTag) {
        return new FetchResult(FetchStatus.Fetched, responseBody, eTag);
    };
    FetchResult.notModified = function () {
        return new FetchResult(FetchStatus.NotModified, "");
    };
    FetchResult.error = function (errorMessage, errorException) {
        return new FetchResult(FetchStatus.Errored, "", void 0, errorMessage !== null && errorMessage !== void 0 ? errorMessage : "Unknown error.", errorException);
    };
    return FetchResult;
}());
export { FetchResult };
var FetchError = /** @class */ (function (_super) {
    __extends(FetchError, _super);
    function FetchError(cause) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var _this = this;
        var message;
        switch (cause) {
            case "abort":
                message = "Request was aborted.";
                break;
            case "timeout":
                var timeoutMs = args[0];
                message = "Request timed out. Timeout value: ".concat(timeoutMs, "ms");
                break;
            case "failure":
                var err = args[0];
                message = "Request failed due to a network or protocol error.";
                if (err) {
                    message += " " + (err instanceof Error ? err.message : err + "");
                }
                break;
        }
        _this = _super.call(this, message) || this;
        _this.cause = cause;
        // NOTE: due to a known issue in the TS compiler, instanceof is broken when subclassing Error and targeting ES5 or earlier
        // (see https://github.com/microsoft/TypeScript/issues/13965).
        // Thus, we need to manually fix the prototype chain as recommended in the TS docs
        // (see https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work)
        if (!(_this instanceof FetchError)) {
            (Object.setPrototypeOf || (function (o, proto) { return o["__proto__"] = proto; }))(_this, FetchError.prototype);
        }
        _this.args = args;
        return _this;
    }
    return FetchError;
}(Error));
export { FetchError };
