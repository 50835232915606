import { LightBulbIcon } from "@heroicons/react/24/outline";

type StepTipProps = {
   tip?: string | null;
};

const StepTip = ({ tip }: StepTipProps) => {
   if (tip) {
      const cleanedTip = tip.replace(/(<([^>]+)>)/gi, "");
      return (
         <div className="recipe-step-tip my-3">
            <LightBulbIcon />
            <p className="p-2 m-0">{cleanedTip}</p>
         </div>
      );
   }
   return <></>;
};

export default StepTip;
