import { isNumber, isString } from "lodash-es";

import { SourceSetItem } from "../types/mediaTypes";

export type ImageSpec = {
   minWidth?: number;
   maxWidth?: number;
   width: number;
   height: number;
};

/**
 * Generate a source set that gives several smaller sizes with the same aspect ratio
 * as the size used for xxl screens, including a fallback for browsers that is not
 * able to parse source set.
 * @param xxlWidth
 * @param xxlHeight
 */
export const generateSizesFromMaxSize = (xxlWidth: number, xxlHeight: number): ImageSpec[] => {
   const result: ImageSpec[] = [];
   const aspectRatio = xxlHeight / xxlWidth;
   const fullWidth = 1250;
   const breakpointMobile = 995;
   const breakpointLowest = 580;

   // Size for xxl desktop users
   result.push({ minWidth: fullWidth, width: xxlWidth, height: xxlHeight });

   // This is usually images transition into full width regardless of layout
   result.push({
      maxWidth: fullWidth,
      minWidth: breakpointLowest,
      width: Math.ceil(xxlWidth * (breakpointMobile / fullWidth)),
      height: Math.ceil(xxlHeight * (breakpointMobile / fullWidth))
   });

   // Fallback for small browsers under 500 px width
   result.push({ width: breakpointLowest, height: Math.ceil(breakpointLowest * aspectRatio) });

   return result;
};
export const createMediaQuery = (imageSpec: ImageSpec): string => {
   const queryParts = [];
   if (isNumber(imageSpec.minWidth)) {
      queryParts.push(`(min-width: ${imageSpec.minWidth}px)`);
   }
   if (isNumber(imageSpec.maxWidth)) {
      queryParts.push(`(max-width: ${imageSpec.maxWidth}px)`);
   }
   return queryParts.join(" and ");
};

export const createSourceSet = (
   imageSpecs: ImageSpec[],
   urlFactory: { (width: number, height: number): string }
): SourceSetItem[] =>
   imageSpecs
      .map((imageSpec) => ({
         width: imageSpec.width,
         height: imageSpec.height,
         media: createMediaQuery(imageSpec),
         url: urlFactory(imageSpec.width, imageSpec.height)
      }))
      .filter((item) => isString(item.url));
