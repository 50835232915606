import { view } from "@risingstack/react-easy-state";
import { useEffect } from "react";

import { FlyingProductAnimationState } from "../common/types/productTypes";
import { productImageUrl } from "../common/utils";

import uiStore from "../stores/uiStore";

type FlyingProductProps = {
   plane: FlyingProductAnimationState;
};

const FlyingProduct = ({ plane }: FlyingProductProps) => {
   useEffect(() => {
      // console.log("Plane " + plane.animationId + " taking off");
      setTimeout(() => {
         uiStore.takeOff(plane.animationId);
      }, 200);
   }, []);

   return (
      <img
         alt="Produkt på vei til handlekurven"
         src={productImageUrl(plane.sku, plane.sourceWidth)}
         className="flyer"
         style={{
            top: plane.y,
            left: plane.x,
            height: plane.height
         }}
      />
   );
};

export default view(FlyingProduct);
