import { view } from "@risingstack/react-easy-state";

import authStore from "../../stores/auth/authStore";
import barcodeStore from "../../stores/barcodeStore";
import featuresStore from "../../stores/features/featuresStore";

import { ScanBarcodeIcon } from "../icons/ScanBarcodeIcon";

type BarcodeScannerButtonProps = {
   [key: string]: any;
};

const BarcodeScannerButton = (props: BarcodeScannerButtonProps) => {
   if (!featuresStore.barcodeScannerEnabled || !authStore.isLoggedIn()) {
      return null;
   }

   return (
      <div {...props}>
         <button className="border-0 bg-transparent" onClick={barcodeStore.showModal}>
            <ScanBarcodeIcon />
         </button>
      </div>
   );
};

export default view(BarcodeScannerButton);
