import { view } from "@risingstack/react-easy-state";
import cn from "classnames";
import isString from "lodash-es/isString";
import { MouseEventHandler, ReactElement } from "react";

type InfoLineProps = {
   label?: string | null;
   labelClassName?: string;
   value: string | number | null | undefined | ReactElement;
   valueClassName?: string;
   className?: string;
   align?: "start" | "end" | "center" | "between" | "around";
   valign?: "start" | "end" | "center" | "between" | "around";
   margin?: boolean;
   rowOnMobile?: boolean;
   onClick?: MouseEventHandler<HTMLDivElement>;
};

/**
 * The InfoLine element displays a row of data with a label and a value.
 * On desktop the label is shown on the same line as the value.
 * On mobile the label is shown above the line with the value because the label and value often take too much horisontal space.
 */
const InfoLine = ({
   label,
   labelClassName = "",
   value,
   valueClassName = "",
   className = "",
   align = "between",
   valign = "center",
   margin = false,
   rowOnMobile = false,
   onClick = () => {}
}: InfoLineProps): ReactElement => {
   const classNames = cn(`d-flex justify-content-${align}`, className, {
      [`flex-column flex-wrap align-items-start flex-lg-row align-items-lg-${valign}`]: !rowOnMobile,
      "mb-2": margin
   });

   return (
      <div className={classNames} onClick={onClick}>
         <div className={`pe-lg-1 ${labelClassName}`}>{isString(label) ? label + ":" : ""}</div>
         <div className={`ps-lg-1 text-lg-end ${valueClassName} ${isString(value) ? "fw-bold" : ""}`}>{value ?? ""}</div>
      </div>
   );
};

export default view(InfoLine);
