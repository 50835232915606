import { StarIcon as StarIconOutline } from "@heroicons/react/24/outline";
import { StarIcon as StarIconSolid } from "@heroicons/react/24/solid";
import { ReactNode } from "react";

import { RecipeDifficultyType } from "../../../common/types/recipeTypes";
import { difficultyTexts } from "../../../common/utils/cmsStoreUtils";
import useSupportedLocale from "../../../common/utils/useSupportedLocale";

type RecipeDifficultyProps = {
   difficulty: RecipeDifficultyType;
};

const icons: { [difficulty in RecipeDifficultyType]: ReactNode } = {
   easy: (
      <div className="d-flex recipe-difficulty-icons">
         <StarIconSolid />
         <StarIconOutline />
         <StarIconOutline />
      </div>
   ),
   medium: (
      <div className="d-flex recipe-difficulty-icons">
         <StarIconSolid />
         <StarIconSolid />
         <StarIconOutline />
      </div>
   ),
   difficult: (
      <div className="d-flex recipe-difficulty-icons">
         <StarIconSolid />
         <StarIconSolid />
         <StarIconSolid />
      </div>
   )
};

const RecipeDifficulty = ({ difficulty }: RecipeDifficultyProps) => {
   const locale = useSupportedLocale();
   const text = difficultyTexts[locale][difficulty];
   return (
      <div className="recipe-difficulty d-flex">
         {icons[difficulty]}
         <div className="pl-5">{text}</div>
      </div>
   );
};

export default RecipeDifficulty;
