import { Breadcrumb, Spinner } from "react-bootstrap";
import { Link, useLocation, useParams } from "wouter";

import contentStore from "../../stores/cms/contentStore";

const ContentBreadcrumb = () => {
   const { fullUrl } = useParams();
   if (!fullUrl) {
      return <Spinner />;
   }

   const urlData = contentStore.urlData.data.find((data) => data.slug === fullUrl || data.fullUrl === fullUrl);
   const splittedSlugList = urlData?.fullUrl.split("/") ?? fullUrl.split("/");

   return (
      <Breadcrumb>
         {splittedSlugList.map((slug, index) => {
            const isLastItem = splittedSlugList.length === index + 1;
            const urlData = contentStore.urlData.data.find((urlData) => urlData.slug === slug);

            if (isLastItem) {
               return (
                  <Breadcrumb.Item className="small" key={"current-" + slug} active>
                     {urlData?.title}
                  </Breadcrumb.Item>
               );
            }
            return (
               <li className="breadcrumb-item small" key={"parent-" + slug}>
                  <Link to={"/" + urlData?.fullUrl}>{urlData?.title}</Link>
               </li>
            );
         })}
      </Breadcrumb>
   );
};

export default ContentBreadcrumb;
