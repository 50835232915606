import { view } from "@risingstack/react-easy-state";

import theme from "../../themes/theme";
import ButtonPlain from "../ButtonPlain";

const NewCustomerButton = () => {
   return (
      <ButtonPlain>
         <a href={theme.newCustomerForm}>Bli kunde</a>
      </ButtonPlain>
   );
};

export default view(NewCustomerButton);
