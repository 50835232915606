import { Col, Row } from "react-bootstrap";

import { RecipeType } from "../../../common/types/recipeTypes";

import Ingredients from "./Ingredients";
import Instructions from "./Instructions";

type IngredientsAndInstructionsProps = {
   recipe: RecipeType;
};

const IngredientsAndInstructions = ({ recipe }: IngredientsAndInstructionsProps) => (
   <Row>
      <Col xs={12} md={4} className="mt-5">
         <Ingredients recipe={recipe} />
      </Col>
      <Col className="mt-5">
         <Instructions stepGroups={recipe.stepGroups} tip={recipe.tip} />
      </Col>
   </Row>
);
export default IngredientsAndInstructions;
