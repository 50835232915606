// @ts-ignore
import logoUrl from "url:/src/img/themes/diplomis/logo.png?width=67";

import { SupportedLocaleType } from "../common/types/languageTypes";
import { AccessoryType, CustomerType, Product } from "../common/types/productTypes";
import { SiteConfig } from "../common/types/themeTypes";

const CONTENTFUL_ENV = process.env.CONTENTFUL_ENV ?? "";
const ALGOLIA_APPID = "KJ28FN3CEH";
const ALGOLIA_SEARCHKEY = "5e181b299b6ea57f88b628c867a2f1b9";
const algoliaEnvironment = process.env.ENV_NAME === "prod" ? "prod" : "preprod";

type ProductEnhancement = {
   customerType?: CustomerType;
   accessoryType?: AccessoryType;
};
/**
 * Parses the response bodies into suitable data structure for use in stores. Diplomis does not up pickup alternatives
 */

const diplomisSiteConfig: SiteConfig = {
   storeId: "diplomis",
   siteName: "Diplomis Handel",
   favIcon: "lolipop-icon.svg",
   m3CompanyNumber: "500",
   m3DivisionNumber: "100",
   desktopLogoUrl: logoUrl,
   mobileLogoUrl: logoUrl,
   logoRatio: 0.71,
   productDropdownEnabled: false,
   filterAttributes: (variant) =>
      variant === "tilbehor" ? { customerType: "Type", accessoryType: "Kategori" } : { brand: "Merkevare" },
   filterRefreshOnChange: true,
   tipApiPrefix: "di-",
   enhanceProduct: (product: Product): Product => {
      const parentCategoryCode = product.parentCategory?.externalReference;
      const categoryCode = product.category?.externalReference;
      let productEnhancement: ProductEnhancement = {};
      if (parentCategoryCode === "036") {
         productEnhancement.customerType = "Dagligvare";
      } else {
         productEnhancement.customerType = "Isbar";
      }
      if (categoryCode === "0351") {
         productEnhancement.accessoryType = "Saus";
      } else if (categoryCode === "0352") {
         productEnhancement.accessoryType = "Strøssel";
      } else if (categoryCode === "0354") {
         productEnhancement.accessoryType = "Kjeks";
      } else if (["F61", "099"].includes(parentCategoryCode || "")) {
         productEnhancement.accessoryType = "Softisdeler";
      } else if (["035", "036"].includes(parentCategoryCode || "")) {
         productEnhancement.accessoryType = "Annet tilbehør";
      }

      return { ...product, ...productEnhancement };
   },
   deliveryFee: {
      enabled: false
   },
   productAvailability: {
      enabled: true
   },
   newCustomerForm: "https://kundeskjema.diplom-is.no/",
   deliveryNotes: {
      visible: false
   },
   contentImages: {
      cloudinaryMapping: "diplomis_images"
   },
   searchConfig: {
      algoliaConfig: {
         appId: ALGOLIA_APPID,
         searchApiKey: ALGOLIA_SEARCHKEY
      },
      searchDomains: [
         {
            type: "product",
            name: "produkter",
            displayName: (locale: SupportedLocaleType) => (locale === "en-US" ? "Products" : "Produkter"),
            homePath: (_locale) => "/produkter.html",
            indexName: `products_${algoliaEnvironment}`
         },
         {
            type: "v2_article",
            name: "artikler",
            displayName: (locale: SupportedLocaleType) => (locale === "en-US" ? "Articles" : "Artikler"),
            homePath: (_locale) => "/artikler",
            indexName: `contentful_${CONTENTFUL_ENV}`
         }
      ]
   }
};

export default diplomisSiteConfig;
