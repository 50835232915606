import { store } from "@risingstack/react-easy-state";
import find from "lodash-es/find";
import has from "lodash-es/has";
import includes from "lodash-es/includes";
import isEqual from "lodash-es/isEqual";
import isNil from "lodash-es/isNil";
import isUndefined from "lodash-es/isUndefined";
import keyBy from "lodash-es/keyBy";
import keys from "lodash-es/keys";
import pull from "lodash-es/pull";
import uniq from "lodash-es/uniq";
import unset from "lodash-es/unset";
import values from "lodash-es/values";

import { apiClient } from "../common/apiClient";

import theme from "../themes/theme";
import authStore from "./auth/authStore";
import toastStore from "./toastStore";

export type FavoriteList = {
   id: string;
   listName: string;
   items: Record<string, number>;
   sequence: string[];
};

type FavoriteStore = {
   favorites: Record<string, FavoriteList>;
   allSkus: string[];
   activeList: FavoriteList["items"];
   uncommitedList: FavoriteList["items"];
   isFavorite(sku: string): boolean;
   addFavorite(sku: string, listId?: string, qty?: number): void;
   addMultipleFavorites(skus: string[], listId: string, items: FavoriteList["items"]): void;
   removeFavorite(sku: string, listId?: string): void;
   clearFavorites(): void;
   syncAllSkus(): void;
   getFavoriteListById(id: string): FavoriteList | undefined;
   getFavoriteListByName(name: string): FavoriteList | undefined;
   createNewListInStorage(listName: string): Promise<FavoriteList>;
   deleteList(listId: string): Promise<void>;
   loadFavorites(): Promise<void>;
   saveFavorites(listId: string, performSync?: boolean): Promise<void>;
};

const isAbleToAddFavorite = (list: FavoriteList | undefined) => {
   if (!authStore.isLoggedIn()) {
      console.warn("Attempting to add favorite without being logged in");
      return false;
   }
   if (!list) {
      console.warn("Unable to find list to add favorite to");
      return false;
   }
   return true;
};

const isFavoriteInList = (sku: string, listId: string) => {
   const list = favoriteStore.getFavoriteListById(listId);
   return !!list && includes(list.sequence, sku);
};

const getUrl = (customerNumber: string) => {
   const inconsistentPathPart = theme.storeId === "tinehandel" ? "" : `/nextgen/${theme.storeId}`;
   return `${process.env.API_HOST}/api${inconsistentPathPart}/favorites/${customerNumber}`;
};

const favoriteStore: FavoriteStore = store({
   favorites: {},
   allSkus: [],
   activeList: {},
   uncommitedList: {},

   isFavorite: (sku) => {
      return includes(favoriteStore.allSkus, sku);
   },

   addFavorite: (sku, listId, qty = 1) => {
      const favoriteLists = values(favoriteStore.favorites);
      const list = isNil(listId) ? values(favoriteLists)[0] : favoriteStore.getFavoriteListById(listId);
      if (isAbleToAddFavorite(list) && !!list) {
         list.items[sku] = qty;
         list.sequence.push(sku);
         void favoriteStore.saveFavorites(list.id);
      } else if (!listId && !favoriteLists.length) {
         favoriteStore.createNewListInStorage("Mine favoritter").then((list) => {
            list.items[sku] = qty;
            list.sequence.push(sku);
            favoriteStore.saveFavorites(list.id);
         });
      }
   },

   addMultipleFavorites: (skus, listId, items) => {
      const list = isNil(listId) ? values(favoriteStore.favorites)[0] : favoriteStore.getFavoriteListById(listId);
      if (isAbleToAddFavorite(list) && !!list) {
         skus.forEach((sku) => {
            if (!isFavoriteInList(sku, listId)) {
               const newItem = { [sku]: items[sku] };
               list.items = { ...list.items, ...newItem };
               list.sequence.push(sku);
            } else {
               list.items[sku] = list.items[sku] + items[sku];
            }
         });
         favoriteStore.saveFavorites(list.id);
      }
   },

   removeFavorite: (sku, listId) => {
      if (!authStore.isLoggedIn()) {
         console.warn("Attempting to remove favorite without being logged in");
         return;
      }

      if (!isNil(listId)) {
         // If listId is specified, remove SKU from specific list only
         const list = favoriteStore.getFavoriteListById(listId);
         if (!list) {
            console.warn("Unable to find list to remove favorite from");
            return;
         }
         unset(list.items, sku);
         pull(list.sequence, sku);
         void favoriteStore.saveFavorites(list.id, true);
      } else {
         // If no listId is specified, remove it from all lists
         values(favoriteStore.favorites).forEach((list) => {
            if (has(list.items, sku)) {
               unset(list.items, sku);
               pull(list.sequence, sku);
               void favoriteStore.saveFavorites(list.id, false);
            }
         });

         // Manual sync since we might have changed several lists
         favoriteStore.syncAllSkus();
      }
   },

   clearFavorites: () => {
      favoriteStore.favorites = {};
      favoriteStore.syncAllSkus();
   },

   syncAllSkus: () => {
      const allSkus = values(favoriteStore.favorites).flatMap((fl) => keys(fl.items));
      favoriteStore.allSkus = uniq(allSkus);
   },

   getFavoriteListById: (id) => find(favoriteStore.favorites, { id }),

   getFavoriteListByName: (name) => {
      const favoriteList = values(favoriteStore.favorites);
      return favoriteList.find((favorite) => favorite.listName === name);
   },

   createNewListInStorage: (listName) => {
      const customerNumber = authStore.currentCompany;
      if (isNil(customerNumber)) {
         console.warn("Attempting to create list before customer number is available", authStore.user);
         return Promise.reject("Not logged in, unable to create new list");
      }

      return apiClient(getUrl(customerNumber), authStore.getSessionToken())
         .query({ listName })
         .post()
         .json((list) => {
            favoriteStore.favorites[list.id] = list;
            console.log("Created new list: ", listName);
            return list;
         });
   },

   deleteList: (listId) => {
      const customerNumber = authStore.currentCompany;
      if (isNil(customerNumber)) {
         console.warn("Attempting to delete favorites before customer number is available", authStore.user);
         return Promise.reject("Not logged in, unabel to delete list");
      }

      return apiClient(`${getUrl(customerNumber)}/${listId}`, authStore.getSessionToken())
         .delete()
         .text(() => {
            unset(favoriteStore.favorites, listId);
            favoriteStore.syncAllSkus();
         });
   },

   loadFavorites: async () => {
      const customerNumber = authStore.currentCompany;
      if (isNil(customerNumber)) {
         console.warn("Attempting to load favorites before customer number is available", authStore.user);
         return Promise.reject();
      }
      console.log("Fetching favorites for customer number " + customerNumber);
      return apiClient(getUrl(customerNumber), authStore.getSessionToken())
         .get()
         .json((res) => {
            favoriteStore.favorites = keyBy(res, "id");
            favoriteStore.syncAllSkus();
            console.log("Favorites loaded: ", favoriteStore.favorites);
         })
         .catch((res) => {
            console.error("Error during loading of favorites", res);
            toastStore.addError("Feil under lasting av favoritter", "Det oppsto en uforutsett feil under lasting av favoritter.");
         });
   },

   saveFavorites: async (listId, performSync = true) => {
      const customerNumber = authStore.currentCompany;
      if (isNil(customerNumber)) {
         console.warn("Attempting to save favorites before customer number is available", authStore.user);
         return Promise.reject("Not logged in, unable to save favorites");
      }

      const list = favoriteStore.getFavoriteListById(listId);
      if (isUndefined(list)) {
         console.warn("Unable to find favorite list with id " + listId);
         return Promise.reject();
      }

      if (performSync) {
         favoriteStore.syncAllSkus();
      }

      return apiClient(`${getUrl(customerNumber)}/${listId}`, authStore.getSessionToken())
         .put(list)
         .json((res) => {
            list.listName = res.listName;
            const itemsChanged = !isEqual(list.items, res.items);
            if (itemsChanged) {
               list.items = res.items;
               if (performSync) {
                  favoriteStore.syncAllSkus();
               }
            }
         })
         .catch((res) => {
            console.error("Unable to save favorites", res);
            toastStore.addError(
               "Feil under lagring",
               "Vi greide dessverre ikke å lagre endringen i favorittlister. Prøv igjen senere."
            );
         });
   }
});

export default favoriteStore;
