import { ClockIcon } from "@heroicons/react/24/outline";
import { view } from "@risingstack/react-easy-state";
import isNil from "lodash-es/isNil";
import { OverlayTrigger, Popover } from "react-bootstrap";

import { translateDayLabel } from "../../common/utils";
import { formatDate, formatDateTime } from "../../common/utils/dateUtils";

import cartStore from "../../stores/cartStore";
import deliveryDatesStore from "../../stores/deliveryDates/deliveryDatesStore";

import InfoLine from "../../components/InfoLine";

const MobileDeliveryInfo = () => {
   const currentDelivery = deliveryDatesStore.getCurrentDelivery();
   if (isNil(currentDelivery)) {
      return null;
   }
   const nextDelivery = deliveryDatesStore.getNextDeliveryDateForOrderType(cartStore.orderType)?.date;

   const dayLabel = translateDayLabel(cartStore.orderType);

   const deliveryInfo = (
      <Popover className="delivery-info-popover">
         <Popover.Body>
            <InfoLine margin label={dayLabel} value={formatDate(currentDelivery.date, true, false, true)} />
            <InfoLine margin label="Bestillingsfrist" value={formatDateTime(currentDelivery.deadline, true, true)} />
            <InfoLine margin label="Første mulige levering" value={formatDate(nextDelivery, true, false, true)} />
         </Popover.Body>
      </Popover>
   );

   return (
      <OverlayTrigger trigger="click" placement="bottom" rootClose={true} overlay={deliveryInfo}>
         <div className="mx-2">
            <ClockIcon />
         </div>
      </OverlayTrigger>
   );
};

export default view(MobileDeliveryInfo);
