import { useContentfulInspectorMode } from "@contentful/live-preview/dist/react";
import { Entry } from "contentful";
import { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";

import { TypeImageWithFocusSkeleton, TypeV2_articleSkeleton } from "../../common/types/contentful";

import theme from "../../themes/theme";
import ContactFormPageContext from "./ContactFormPageContext";
import HomePageContext from "./HomePageContext";
import ImageWithFocus from "./ImageWithFocus";
import RichTextRenderer from "./RichTextRenderer";
import { cmsTextColumnProps } from "./RichTextRendererHelpers";

type ArticleProps = {
   id: string;
   article: Entry<TypeV2_articleSkeleton, undefined, "nb_no">;
};

const Article = ({ article, id }: ArticleProps) => {
   const inspectorProps = useContentfulInspectorMode();
   const headerImage = article.fields.headerImage as Entry<TypeImageWithFocusSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>;

   // Defaults to false, unless a parent is wraps this component with <BodyOnlyContext.Provider value={true} />
   const isOnArticlePage = !useContext(HomePageContext) && !useContext(ContactFormPageContext);
   const seoMetaDescription = article.fields.metaDescription || article.fields.preface;
   const title = article.fields.metaTitle || `${article.fields.title} - ${theme.siteName}`;

   return (
      <div className="cmsArticle">
         {isOnArticlePage && (
            <>
               <Helmet>
                  <title>{title}</title>
                  {!!seoMetaDescription && <meta name="description" content={seoMetaDescription} />}
               </Helmet>
               <div className="headerContainer">
                  <div className="imageContainer">
                     {headerImage && (
                        <ImageWithFocus
                           width={1250}
                           ratio={"21x9"}
                           key={JSON.stringify(headerImage)}
                           entryId={headerImage.sys.id}
                           placement="aboveFold"
                           className="rounded"
                           {...inspectorProps({
                              entryId: id,
                              fieldId: "headerImage"
                           })}
                           imageFromParent={headerImage}
                        />
                     )}
                  </div>
                  <Row className="titleContainer">
                     <Col lg={{ span: 8, offset: 2 }} className="titleContainerColumn bg-body">
                        <h1
                           className="pt-5 title px-2"
                           {...inspectorProps({
                              entryId: id,
                              fieldId: "title"
                           })}
                        >
                           {article.fields.title}
                        </h1>
                     </Col>
                  </Row>
               </div>

               <Col md={cmsTextColumnProps["half"].md} lg={cmsTextColumnProps["half"].lg}>
                  <p
                     className="centerText preface py-4 px-2 mb-5"
                     {...inspectorProps({
                        entryId: id,
                        fieldId: "preface"
                     })}
                  >
                     {article.fields.preface}
                  </p>
               </Col>
            </>
         )}

         <RichTextRenderer textColumnWidth="twoThirds" document={article.fields.body} key={JSON.stringify(article.fields.body)} />
      </div>
   );
};

export default Article;
