import { HeartIcon, TrashIcon } from "@heroicons/react/24/outline";
import { view } from "@risingstack/react-easy-state";
import clamp from "lodash-es/clamp";
import defaultTo from "lodash-es/defaultTo";
import isUndefined from "lodash-es/isUndefined";
import { useState } from "react";
import { Link } from "wouter";

import { sendRemoveFromCart } from "../../common/tracking";
import { CartItem, Product } from "../../common/types/productTypes";
import { formatPrice, formatUnit, inRangeInclusive } from "../../common/utils";

import cartStore from "../../stores/cartStore";
import deliveryFeeStore from "../../stores/deliveryFeeStore";
import productStore from "../../stores/product/productStore";
import toastStore from "../../stores/toastStore";

import AddToFavoriteModal from "../../account/favorites/AddToFavoriteModal";
import { AmountPickerExternal } from "../../components/AmountPicker";
import ButtonWithIcon from "../../components/ButtonWithIcon";
import LeadTimeWarning from "../../components/LeadTimeWarning";
import ProductImage from "../../components/ProductImage";

type RecyclingChangeProps = {
   product: Product;
};

const RecyclingChange = view(({ product }: RecyclingChangeProps) => {
   if (product.recyclingChargeBaseUnit === 0) {
      return null;
   }

   const conversion = product.unit === "D" ? product.conversionFactor : 1;
   const recyclingCharge = product.recyclingChargeBaseUnit * conversion;

   return <small>+ {formatPrice(recyclingCharge)} pant</small>;
});

type MiniCartItemProps = {
   item: CartItem;
   lastItem: boolean;
   onCartChanged: () => void;
};

const MiniCartItem = view(({ item, lastItem, onCartChanged }: MiniCartItemProps) => {
   const product = productStore.resolveSku(item.sku);

   const handleChangeAmount = (amount: number) => {
      if (cartStore.isEditing()) {
         const validRange = cartStore.getValidQtyRange(item.sku);
         item.qty = clamp(amount, validRange.min, validRange.max);

         if (!inRangeInclusive(amount, validRange)) {
            toastStore.addError(
               "Begrensninger på ordren",
               "Ordren som du redigerer har begrensninger som gjør at du ikke kan endre antallet på denne varen til " +
                  amount +
                  "."
            );
         }
      } else {
         item.qty = amount;
      }
      cartStore.saveCartToServer();
      deliveryFeeStore.recalculateDeliveryCharge();
      onCartChanged();
   };

   if (isUndefined(product)) {
      console.warn("Customer has unavailable product in cart with sku " + item.sku);
      cartStore.removeFromCart(item.sku);
      return null;
   }

   const removeFromCart = () => {
      sendRemoveFromCart(product, item.qty);
      cartStore.removeFromCart(item.sku);
      onCartChanged();
   };

   const borderStyle = !lastItem ? "border-bottom " : "";
   const backgroundColor =
      cartStore.isEditing() && (cartStore.hasQtyChangedDuringEdit(item.sku) || cartStore.isAddedDuringEdit(item.sku))
         ? "bg-glow"
         : "";

   return (
      <>
         {/* Desktop */}
         <div className={"d-none d-lg-flex position-relative py-4 " + borderStyle + backgroundColor}>
            <div className="px-2">
               <ProductImage product={product} size={48} />
            </div>
            <div className="flex-fill">
               <div className="fs-4 mb-2 me-5">
                  <Link to={productStore.getUrl(product)}>{product.name}</Link>
               </div>
               <p className="small mb-2">Art. nr: {item.sku}</p>
               <div className="small fw-bold mb-0">
                  {formatPrice(product.price)} pr {formatUnit(product.unit)}
               </div>
               <RecyclingChange product={product} />
               <LeadTimeWarning product={product} />
               <div className="d-flex mt-3 justify-content-between me-lg-4">
                  <div>
                     <AmountPickerExternal qty={item.qty} onChange={handleChangeAmount} />
                  </div>
                  <div className="fw-bolder">{formatPrice(defaultTo(product.price, 0) * item.qty)}</div>
               </div>
            </div>
            <div className="position-absolute top-right end-0" role="button" onClick={removeFromCart}>
               <TrashIcon />
            </div>
         </div>

         {/* Mobile */}
         <div className={"d-flex flex-column d-lg-none py-3 " + borderStyle + backgroundColor}>
            <div className="d-flex mb-3 align-items-center">
               <div className="me-2">
                  <ProductImage product={product} size={48} />
               </div>
               <div>{product.name}</div>
            </div>
            <div className="d-flex flex-wrap justify-content-between align-items-end mb-3">
               <div className="small">
                  <span className="fw-bold">{formatPrice(product.price)}</span>{" "}
                  <span className="smaller">per {formatUnit(product.unit)}</span>
               </div>
               <div className="mt-2 fw-bolder">{formatPrice(defaultTo(product.price, 0) * item.qty)}</div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
               <div>
                  <AmountPickerExternal qty={item.qty} onChange={handleChangeAmount} />
               </div>
               <div role="button" onClick={removeFromCart}>
                  <TrashIcon />
               </div>
            </div>
         </div>
      </>
   );
});

type MiniCartOrderLines = {
   items: CartItem[];
};

const MiniCartOrderLines = ({ items }: MiniCartOrderLines) => {
   const qtySum = items.reduce((p, i) => p + i.qty, 0);

   const [favoriteModalOpen, setFavoriteModalOpen] = useState(false);
   const addToFavoriteText = "Lagre handlekurven som favorittliste";
   const favoriteAddedText = "Lagret som favorittliste";
   const [favoriteListAdded, setFavoriteListAdded] = useState(false);

   return (
      <>
         <div className="d-flex justify-content-between smallest px-1 px-lg-2 pb-3 border-bottom">
            <div>
               {qtySum} produkter | {items.length} varelinjer
            </div>
            <div className="text-end">
               <Link
                  className="indicate-link"
                  to={"/checkout/cart"}
                  onClick={() => {
                     cartStore.hideMiniCart();
                  }}
               >
                  Endre handlekurven
               </Link>
            </div>
         </div>
         {items.map((ol, i) => (
            <MiniCartItem
               key={ol.sku}
               item={ol}
               lastItem={i === items.length - 1}
               onCartChanged={() => setFavoriteListAdded(false)}
            />
         ))}
         <>
            <div className={`py-4 border-top ${favoriteListAdded ? "favorite marked" : "favorite"}`}>
               <ButtonWithIcon
                  trackingEvent="minicart_add_to_favorite"
                  onClick={() => setFavoriteModalOpen(true)}
                  icon={<HeartIcon />}
               >
                  {favoriteListAdded ? <>{favoriteAddedText}</> : <>{addToFavoriteText}</>}
               </ButtonWithIcon>
            </div>
            <AddToFavoriteModal
               isOpen={favoriteModalOpen}
               onCloseModal={() => setFavoriteModalOpen(false)}
               favoriteListAdded={() => {
                  setFavoriteListAdded(true);
               }}
            />
         </>
      </>
   );
};

export default view(MiniCartOrderLines);
