import { Entry } from "contentful";
import { concat, intersection, uniq } from "lodash-es";
import isString from "lodash-es/isString";

import { TypeSegmentSkeleton } from "../../common/types/contentful";

import { ContentVisibility, CustomerSegment } from "../../stores/cms/segmentStore";

type ArticleEntry = Entry<TypeSegmentSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string> | undefined;

export const mergeArrays = <T>(array1: T[] | undefined, array2: T[] | undefined) =>
   uniq(concat(array1, array2)).filter((element): element is T => !!element);

export const convertEntriesToSegment = (entries: ArticleEntry[]): CustomerSegment =>
   entries.reduce(
      (result: CustomerSegment, entry: ArticleEntry) => {
         return entry
            ? {
                 customerNumber: mergeArrays(result.customerNumber, entry.fields.customerNumbers),
                 chains: mergeArrays(result.chains, entry.fields.chains),
                 customerGroups: mergeArrays(result.customerGroups, entry.fields.customerGroups)
              }
            : result;
      },
      { customerNumber: [], chains: [], customerGroups: [] }
   );

export const isOverlappingArrays = <T>(array1: T[], array2: T[]) =>
   intersection(array1, array2).filter((element): element is T => !!element).length > 0;

export const isSegmentOverlapping = (
   { customerNumber, chains, customerGroups }: CustomerSegment,
   currentSegment: CustomerSegment
) =>
   isOverlappingArrays(customerNumber, currentSegment.customerNumber) ||
   isOverlappingArrays(chains, currentSegment.chains) ||
   isOverlappingArrays(customerGroups, currentSegment.customerGroups);

// Takes a value formatted as "121|Barnehager" and keeps only the id. If
// the value is not formatted as "id|name" it returns the value as is.
export const keepIdOnly = (segment: string) => {
   if (!isString(segment) || !segment.includes("|")) {
      return segment;
   }
   return segment.split("|")[0];
};

export const getEntryVisibility = (
   currentSegment: CustomerSegment,
   entrySegment: {
      include?: CustomerSegment;
      exclude?: CustomerSegment;
   },
   isLoggedIn: boolean
): ContentVisibility => {
   const exclude = entrySegment.exclude;
   const include = entrySegment.include;

   if (!isLoggedIn && (exclude || include)) {
      return "requiresLogin";
   }

   if (isLoggedIn) {
      if (exclude) {
         const isExcludeOverlap = isSegmentOverlapping(exclude, currentSegment);
         if (isExcludeOverlap) {
            return "hidden";
         }
      }
      if (include) {
         const isIncludeOverlap = isSegmentOverlapping(include, currentSegment);
         return isIncludeOverlap ? "visible" : "hidden";
      }
   }

   return "visible";
};
