import { ShoppingCartIcon as ShoppingCartOutline } from "@heroicons/react/24/outline";
import { ShoppingCartIcon as ShoppingCartSolid } from "@heroicons/react/24/solid";
import { view } from "@risingstack/react-easy-state";
import { Offcanvas } from "react-bootstrap";

import authStore from "../../stores/auth/authStore";
import cartStore from "../../stores/cartStore";

import MiniCartContent from "./MiniCartContent";
import MiniCartDates from "./MiniCartDates";
import MiniCartGoToCart from "./MiniCartGoToCart";

const MiniCart = () => {
   const itemCount = cartStore.items.reduce((s, i) => s + i.qty, 0);
   const showNumber = authStore.isLoggedIn() ? " d-block" : " d-none";

   return (
      <>
         <button
            className={`mini-cart d-flex justify-content-end ${itemCount > 0 ? "mini-cart-full" : ""}`}
            id="mini-cart"
            onClick={cartStore.showMiniCart}
         >
            <div className="px-2 mini-cart-icon">{itemCount === 0 ? <ShoppingCartOutline /> : <ShoppingCartSolid />}</div>
            <div className={"align-self-center pe-2" + showNumber}>
               <div className="cart-count fw-bold px-3 px-lg-4 py-1 border border-dark rounded-pill">{itemCount}</div>
            </div>
         </button>

         <Offcanvas show={cartStore.miniCartVisible} placement="end" onHide={cartStore.hideMiniCart}>
            <Offcanvas.Header closeButton />
            <Offcanvas.Body>
               {authStore.isLoggedIn() && <MiniCartDates />}
               <MiniCartContent />
            </Offcanvas.Body>
            <Offcanvas.Header className="p-0">
               <MiniCartGoToCart />
            </Offcanvas.Header>
         </Offcanvas>
      </>
   );
};

export default view(MiniCart);
