import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import { view } from "@risingstack/react-easy-state";
import isNil from "lodash-es/isNil";
import isNumber from "lodash-es/isNumber";
import isString from "lodash-es/isString";
import { useCallback, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";

type AmountPickerControlledProps = {
   qty: number;
   changeFn(qty: number | string, inc: boolean): void;
   className?: string | null;
   min?: number;
   max?: number;
};

const AmountPickerControlled = view(({ qty, changeFn, className, min, max }: AmountPickerControlledProps) => {
   const handleFocus = (evt: React.FocusEvent<HTMLInputElement>) => {
      evt.target.select();
   };
   const canDecrement = typeof min !== "number" || qty > min;
   const canIncrement = typeof max !== "number" || qty < max;
   return (
      <div className={`amount-picker border rounded-pill p-1 mx-auto ${className ?? ""}`}>
         <div
            className={`amount-btn border align-self-center rounded-circle dec ${
               !canDecrement ? "text-muted amount-btn--disabled" : "border-dark"
            }`}
            onClick={() => canDecrement && changeFn(-1, true)}
         >
            <MinusIcon strokeWidth={1} />
         </div>
         <div
            className={`amount-btn border align-self-center rounded-circle inc  ${
               !canIncrement ? "text-muted amount-btn--disabled" : "border-dark"
            }`}
            onClick={() => canIncrement && changeFn(1, true)}
         >
            <PlusIcon strokeWidth={1} />
         </div>
         <Form.Control
            className="qty border-0 rounded-pill bg-white"
            aria-label="Antall"
            value={qty}
            onChange={(e) => changeFn(e.target.value, false)}
            onFocus={handleFocus}
         />
      </div>
   );
});

type AmountPickerExternalProps = {
   qty: number;
   onChange(qty: number): void;
   className?: string | null;
   min?: number;
   max?: number;
};

const AmountPickerExternal = view(({ qty, onChange, className = null, min, max }: AmountPickerExternalProps) => {
   const changeFn = useCallback(
      (v: number | string, inc: boolean): void => {
         const minimum = typeof min === "number" ? min : 1;
         const maximum = typeof max === "number" ? max : Number.MAX_SAFE_INTEGER;
         const amount = isString(v) ? parseInt(v) : v;
         if (!inc) {
            if (!isNumber(amount) || isNaN(amount) || amount < minimum || amount > maximum) {
               return;
            }
            onChange(amount);
         } else if (qty + amount >= minimum && qty + amount <= maximum) {
            onChange(qty + amount);
         }
      },
      [qty]
   );

   return <AmountPickerControlled qty={qty} changeFn={changeFn} className={className} min={min} max={max} />;
});

type AmountPickerProps = {
   sku: string;
   onChange?(qty: number): void;
   startQty?: number;
   className?: string | null;
   min?: number;
   max?: number;
};

const AmountPicker = view(({ sku, onChange = () => {}, startQty = 1, className = null, min, max }: AmountPickerProps) => {
   const [qty, setQty] = useState<number>(startQty);

   const updateLocalQty = (newQty: number) => {
      setQty(newQty);
   };

   useEffect(() => {
      setQty(1);
   }, [sku]);

   useEffect(() => {
      onChange(qty);
   }, [qty]);

   return <AmountPickerExternal onChange={updateLocalQty} qty={qty} className={className} min={min} max={max} />;
});

export default AmountPicker;
export { AmountPickerControlled, AmountPickerExternal };
