import { Table } from "react-bootstrap";

import { IngredientGroupType } from "../../../common/types/recipeTypes";

import IngredientLine from "./IngredientLine";

type IngredientGroupProps = {
   ingredientGroup: IngredientGroupType;
   multiplier?: number;
};

const IngredientGroup = ({ ingredientGroup, multiplier = 1 }: IngredientGroupProps) => {
   const { name, ingredientLines } = ingredientGroup;

   return (
      <div className="ingredient-group mb-3">
         {!!name && <h4>{name}</h4>}
         <Table borderless={true} className="ingredient-lines mb-4">
            <tbody>
               {ingredientLines.map((ingredientLine, i) => (
                  <IngredientLine
                     key={`${ingredientLine.ingredient.singular}-${i}`}
                     ingredientLine={ingredientLine}
                     multiplier={multiplier}
                  />
               ))}
            </tbody>
         </Table>
      </div>
   );
};

export default IngredientGroup;
