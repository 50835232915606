import { view } from "@risingstack/react-easy-state";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Locale } from "react-date-object";

import cartStore from "../stores/cartStore";
import deliveryDatesStore from "../stores/deliveryDates/deliveryDatesStore";

import DatePicker from "./DatePicker";

type DeliveryDateButtonProps = {
   render: (
      value: string,
      openCalendar: () => void,
      handleValueChange: (e: React.ChangeEvent) => void,
      locale: Locale,
      separator: string
   ) => React.ReactNode;
};

const DeliveryDateButton = ({ render }: DeliveryDateButtonProps) => {
   const handleChange = (date: Date) => {
      deliveryDatesStore.changeDeliveryDate(date);
   };

   return (
      <DatePicker
         render={render}
         minDate={new Date().setDate(1)}
         value={cartStore.deliveryDateForPicker()[0]}
         onChange={handleChange}
         mapDays={deliveryDatesStore.getCalendarStyleForDate}
      >
         <Row className="smaller pb-3 mx-1 mx-lg-0 text-start text-lg-center d-block d-lg-flex">
            <Col xs={12} lg={4} className="mb-2 mb-lg-0">
               <div className="example-day picked-day" />
               Leveringsdag
            </Col>
            <Col xs={12} lg={4} className="mb-2 mb-lg-0">
               <div className="example-day delivery-date" />
               Kan velges
            </Col>
            <Col xs={12} lg={4}>
               <div className="example-day cancelled" />
               Kansellert
            </Col>
         </Row>
      </DatePicker>
   );
};

export default view(DeliveryDateButton);
