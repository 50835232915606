import { view } from "@risingstack/react-easy-state";
import { Modal } from "react-bootstrap";

import authStore from "../../stores/auth/authStore";
import uiStore from "../../stores/uiStore";

import Button from "../Button";

const hideModal = () => {
   authStore.showForcedLogoutModal = false;
};

const toLogin = () => {
   authStore.showForcedLogoutModal = false;
   uiStore.showLoginModal();
};

const ForcedLogoutModal = () => (
   <Modal show={authStore.showForcedLogoutModal} onHide={hideModal}>
      <Modal.Header closeButton>
         <Modal.Title>Du har blitt logget ut...</Modal.Title>
      </Modal.Header>
      <Modal.Body>
         <p>Fordi du har vært borte en stund, så har vi for sikkerhets skyld logget deg ut av løsningen.</p>
         <p> Du kan så klart logge inn igjen og fortsette handleturen din om du trenger det.</p>
      </Modal.Body>
      <Modal.Footer>
         <Button onClick={toLogin}>Til innlogging</Button>
         <Button variant="primary" onClick={hideModal}>
            Lukk
         </Button>
      </Modal.Footer>
   </Modal>
);

export default view(ForcedLogoutModal);
