import { view } from "@risingstack/react-easy-state";
import { HTMLProps } from "react";

import notificationsStore from "../../stores/notifications/notificationsStore";

interface NotificationBadgeProps extends HTMLProps<HTMLDivElement> {}

const NotificationsBadge = (props: NotificationBadgeProps) => (
   <>
      {notificationsStore.notifications.data.length > 0 && (
         <div className={`${props.className ?? ""} notifications-badge`}>{notificationsStore.notifications.data.length}</div>
      )}
   </>
);

export default view(NotificationsBadge);
