import { view } from "@risingstack/react-easy-state";

import { isDefaultData, isWaitingForData } from "../../common/utils/asyncDataUtils";

import subscriptionStore from "../../stores/subscriptionStore";

import Loading from "../Loading";
import ShortcutChangeSubscription from "./ShortcutChangeSubscription";
import ShortcutFavoritesToCart from "./ShortcutFavoritesToCart";
import ShortcutNewSubscription from "./ShortcutNewSubscription";

const ShortcutBoxes = () => {
   if (isDefaultData(subscriptionStore.subscriptionsList) || isWaitingForData(subscriptionStore.subscriptionsList)) {
      return <Loading label="Laster informasjon om abonnement" />;
   }

   const hasSubscriptions = subscriptionStore.subscriptionsList.data.length > 0;

   return (
      <>
         <div className="d-flex flex-column h-100">
            <h3 className="mb-4">Snarveier</h3>
            <div className="d-flex flex-column flex-md-row align-items-stretch mb-2">
               {hasSubscriptions ? <ShortcutChangeSubscription /> : <ShortcutNewSubscription />}
               <ShortcutFavoritesToCart />
            </div>
         </div>
      </>
   );
};

export default view(ShortcutBoxes);
