import { view } from "@risingstack/react-easy-state";
import { PropsWithChildren } from "react";
import BootstrapButton, { ButtonProps } from "react-bootstrap/Button";

import { sendButtonClickTracking } from "../common/tracking";

type ButtonWithIconProps = {
   icon: React.ReactNode;
   iconWithBorder?: boolean;
   iconClassName?: string;
   trackingEvent?: string;
   onClick?: () => void;
};

const ButtonWithIcon = ({
   variant = "secondary",
   size = "sm",
   className = "",
   icon,
   iconClassName = "",
   children,
   trackingEvent,
   onClick,
   iconWithBorder = true,
   ...rest
}: PropsWithChildren<ButtonProps> & ButtonWithIconProps) => {
   return (
      <BootstrapButton
         variant={variant}
         size={size}
         onClick={() => {
            onClick && onClick();
            !!trackingEvent && sendButtonClickTracking(trackingEvent);
         }}
         className={`d-inline-flex align-items-center border-0 bg-transparent ${className}`}
         {...rest}
      >
         <div className={" p-1 me-1 " + iconClassName + (iconWithBorder ? "bg-secondary border rounded-circle " : "")}>
            {icon}
         </div>
         {children}
      </BootstrapButton>
   );
};

export default view(ButtonWithIcon);
