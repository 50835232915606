import { RecipeType } from "../../../common/types/recipeTypes";

import ProductList from "../../ProductList";

type RecipeProductListProps = {
   recipe: RecipeType;
};

const RecipeProductList = ({ recipe }: RecipeProductListProps) => {
   const { ingredientGroups } = recipe;

   const skus = ingredientGroups
      .flatMap((ingredientGroup) => ingredientGroup.ingredientLines.map((ingredientLine) => ingredientLine.ingredient))
      .filter((ingredient) => ingredient.articleNo)
      .map((ingredient) => ingredient.articleNo || "");

   const uniqueSkus = [...new Set(skus)];

   if (uniqueSkus.length) {
      return (
         <div>
            <ProductList skus={uniqueSkus} listName="Tine-produkter i oppskrift" heading={"Du vil trenge"} />
         </div>
      );
   } else {
      return null;
   }
};

export default RecipeProductList;
