import { autoEffect, store } from "@risingstack/react-easy-state";
import { set } from "lodash-es";
import find from "lodash-es/find";
import includes from "lodash-es/includes";
import intersection from "lodash-es/intersection";
import isArray from "lodash-es/isArray";
import isNil from "lodash-es/isNil";
import isUndefined from "lodash-es/isUndefined";
import pull from "lodash-es/pull";
import sortBy from "lodash-es/sortBy";
import unset from "lodash-es/unset";

import { apiClient } from "../../common/apiClient";
import { BackendIssueError } from "../../common/errors";
import { FEATURE_NAME } from "../../common/types/featureTypes";
import { ORDERTYPE } from "../../common/types/productOrderTypes";
import { AssortmentEntry, Filters, LegalFilterKey, Product, ProductAvailability } from "../../common/types/productTypes";
import { AlgoliaProductHit } from "../../common/types/searchTypes";
import { formatM3Date } from "../../common/utils/dateUtils";
import { getAssortmentFromStorage, setAssortmentInStorage } from "../../common/utils/storageUtils";

import theme from "../../themes/theme";
import authStore from "../auth/authStore";
import cartStore from "../cartStore";
import categoryStore from "../categories/categoryStore";
import deliveryDatesStore from "../deliveryDates/deliveryDatesStore";
import featuresStore from "../features/featuresStore";
import uiStore from "../uiStore";
import { mapBadges, mapLabels, matchesFilter } from "./productUtils";

type AssortmentCache = { [customerNumber: string]: { [orderType in ORDERTYPE]: { [date: string]: AssortmentEntry[] } } };

type ProductStore = {
   products: Product[];
   productsLoaded: boolean;
   guestAssortment: string[];
   assortment: string[];
   assortmentCache: AssortmentCache;
   favorites: string[];
   activeFilters: Filters;
   filterChecksum: number;
   stockLevels: Record<string, number | null>;

   updateFilterChecksum(): void;
   addFilterValue(field: LegalFilterKey, value: string): void;
   removeFilterValue(field: LegalFilterKey, value: string): void;
   isFilterActive(field: LegalFilterKey, value: string): boolean;
   filterWithActiveFilters(products: Product[]): Product[];
   filter(products: Product[], filters: Filters): Product[];
   clearFilter(): void;
   checkStockForSku(sku: string): Promise<void>;
   inAssortment(sku: string): boolean;
   resolveSkus(skus: string[], onlyInAssortment?: boolean): Product[];
   resolveSku(sku: string | null | undefined): Product | undefined;
   findProductsByEAN(ean: string): Product[];
   findProductByUrlKey(urlKey: string): Product | undefined;
   fillStore(): Promise<void[]>;
   loadGuestAssortment(): void;
   getUrl(product: Product | AlgoliaProductHit): string;
   getAssortment(customerNumber: string | null | undefined, date: Date | null | undefined, orderType: ORDERTYPE): Promise<void>;
   setNewAssortment(assortment: AssortmentEntry[]): void;
};

const enhanceProduct = (product: Product): Product => {
   const enhancedProduct = theme.enhanceProduct(product);
   return { ...enhancedProduct, badges: mapBadges(product), labels: mapLabels(product) };
};

const productStore: ProductStore = store({
   products: [],
   productsLoaded: false,
   guestAssortment: [],
   assortment: getAssortmentFromStorage(),
   assortmentCache: {},
   favorites: [],
   activeFilters: {},
   filterChecksum: 0,
   stockLevels: {},

   updateFilterChecksum: () => {
      productStore.filterChecksum = new Date().getMilliseconds();
   },

   addFilterValue: (field, value) => {
      console.log(`Adding filter for [${field}] to show value [${value}]`);

      if (isUndefined(productStore.activeFilters[field])) {
         productStore.activeFilters[field] = [];
      }

      productStore.activeFilters[field]!.push(value);
      productStore.updateFilterChecksum();
   },

   removeFilterValue: (field, value) => {
      console.log(`Removing filter for [${field}] with value [${value}]`);
      if (isUndefined(productStore.activeFilters[field])) {
         return;
      }

      pull(productStore.activeFilters[field]!, value);
      if (productStore.activeFilters[field]!.length === 0) {
         unset(productStore.activeFilters, field);
      }
      productStore.updateFilterChecksum();
   },

   isFilterActive: (field, value) => {
      if (isUndefined(productStore.activeFilters[field])) {
         return false;
      }
      return includes(productStore.activeFilters[field], value);
   },

   filterWithActiveFilters: (products) => {
      return products.filter((p) => matchesFilter(p, productStore.activeFilters));
   },

   filter: (products, filters) => {
      return products.filter((p) => matchesFilter(p, filters));
   },

   clearFilter: () => {
      productStore.activeFilters = {};
      productStore.updateFilterChecksum();
   },

   checkStockForSku: async (sku) => {
      if (!isUndefined(productStore.stockLevels[sku])) {
         console.log("Already cached. Not going to do stock check for SKU " + sku);
         return;
      }

      productStore.stockLevels[sku] = null;

      const params = {
         customerNumber: authStore.currentCompany,
         m3OrderType: cartStore.orderType,
         requestedDeliveryDate: formatM3Date(deliveryDatesStore.getCurrentDelivery()?.date),
         products: sku + "*10"
      };

      try {
         const resp: ProductAvailability[] = await apiClient(
            `${process.env.API_HOST}/api/${theme.tipApiPrefix}tip/API/productAvailability`,
            authStore.getSessionToken()
         )
            .query(params)
            .get()
            .json();

         if (!isNil(resp[0]?.availableQuantity)) {
            productStore.stockLevels[sku] = resp[0].availableQuantity;
         }
      } catch (err) {
         // Set it to 15 to avoid showing errors on page if the call fails, lets call it an optimistic failure
         productStore.stockLevels[sku] = 15;
      }
   },

   inAssortment: (sku) => {
      return includes(productStore.assortment, sku);
   },

   resolveSkus: (skus, onlyInAssortment = true) => {
      if (!isArray(skus) || skus.length === 0) {
         return [];
      }

      const visibleRequestedSkus = onlyInAssortment ? intersection(skus, productStore.assortment) : skus;
      const products = productStore.products.filter((p) => includes(visibleRequestedSkus, p.sku));
      return sortBy(products, (p) => skus.indexOf(p.sku));
   },

   resolveSku: (sku) => {
      if (isNil(sku)) {
         return undefined;
      }
      const p = find(productStore.products, { sku });
      if (!isUndefined(p) && !productStore.inAssortment(p.sku)) {
         return undefined;
      }
      return p;
   },

   findProductsByEAN: (ean) => {
      return productStore.products
         .filter((p) => productStore.inAssortment(p.sku))
         .filter((p) => p.eans.some((e) => e.code === ean));
   },

   findProductByUrlKey: (urlKey) => {
      return productStore.products.find((p) => p.url_key === urlKey);
   },

   fillStore: () => {
      const productDataUrl = `${process.env.STATICDATA_HOST}/products.${process.env.ENV_NAME}_${uiStore.dataVersion}.json`;
      console.log("Fetching products from " + productDataUrl);

      const productDataPromise = apiClient(productDataUrl)
         .get()
         .json((res: Product[]) => {
            productStore.products = res.map((p): Product => enhanceProduct(p));
            productStore.productsLoaded = true;
            console.log("Products loaded: " + productStore.products.length);
         });

      const guestAssortmentUrl = `${process.env.STATICDATA_HOST}/guest-assortment.${process.env.ENV_NAME}_${uiStore.dataVersion}.json`;
      console.log("Fetching guest assortment from " + guestAssortmentUrl);

      const guestAssortmentPromise = apiClient(guestAssortmentUrl)
         .get()
         .json((res: string[]) => {
            productStore.guestAssortment = res;
            console.log("Guest assortment loaded: " + productStore.guestAssortment.length);
         });

      // If anyone waits for this, they have to wait until both promises are resolved.
      return Promise.all([guestAssortmentPromise, productDataPromise]);
   },

   // We fake the object format for each product since we are just resetting old logged in info
   loadGuestAssortment: () => {
      const fakeAssortmentResult: AssortmentEntry[] = productStore.guestAssortment.map((sku) => ({
         itemId: sku,
         inAgreementAssortment: false,
         dpakPrice: null,
         dpakPriceBeforeDiscount: null,
         comparisonPrice: null,
         comparisonPriceUnit: null
      }));
      productStore.setNewAssortment(fakeAssortmentResult);
   },

   getUrl: (product) => {
      return "/produkt/" + product.url_key + ".html";
   },

   getAssortment: async (customerNumber, date, orderType) => {
      if (isNil(customerNumber)) {
         return Promise.reject("No customer number given for assortment");
      }
      if (isNil(date)) {
         return Promise.reject("No date specified for assortment");
      }
      if (isNil(orderType)) {
         return Promise.reject("No order type specified for assortment");
      }

      console.log("Fetching assortment for " + customerNumber + " on " + date + " for order type " + orderType);

      let assortment: AssortmentEntry[];
      const m3Date = formatM3Date(date);
      const cache = productStore.assortmentCache;
      const cachedAssortment = cache[customerNumber]?.[orderType]?.[m3Date];
      if (cachedAssortment) {
         productStore.setNewAssortment(cachedAssortment);
         return;
      }
      try {
         assortment = await apiClient(`${process.env.API_HOST}/api/${theme.tipApiPrefix}assortment`, authStore.getSessionToken())
            .query({
               CONO: theme.m3CompanyNumber,
               CUNO: customerNumber,
               DIVI: theme.m3DivisionNumber,
               UNIT: "BOTH",
               ORTP: orderType,
               DATE: m3Date
            })
            .get()
            .json();

         if ("error" in assortment) {
            throw new BackendIssueError("Unable to load assortment, error from backend");
         }
      } catch (err) {
         console.warn("Unable to fetch assortment - " + err);
         throw err;
      }
      set(cache, [customerNumber, orderType, m3Date], assortment);
      console.log(`Loaded assortment info with ${assortment.length} items`);
      productStore.setNewAssortment(assortment);
   },

   setNewAssortment: (assortment: AssortmentEntry[]) => {
      const canBuySingleItems = featuresStore.hasCustomerFeature(FEATURE_NAME.canBuySingleItems);

      productStore.assortment = assortment.map((p) => p.itemId);
      assortment.forEach((ap) => {
         const p = productStore.resolveSku(ap.itemId);
         if (!isUndefined(p)) {
            if (p.buyableInBaseUnit && canBuySingleItems) {
               p.price = ap.fpakPrice ?? null;
               p.unit = p.baseUnit;
            } else {
               p.price = ap.dpakPrice;
               p.unit = p.salesUnit;
            }
            p.priceBeforeDiscount = ap.dpakPriceBeforeDiscount;
            p.inAgreementAssortment = ap.inAgreementAssortment;
            p.comparisonPrice = ap.comparisonPrice;
            p.comparisonUnit = ap.comparisonPriceUnit;

            p.labels = mapLabels(p);
         }
      });

      categoryStore.hideEmptyCategories();
   }
});

autoEffect(() => setAssortmentInStorage(productStore.assortment));

autoEffect(() => console.log("Assortment changed:", productStore.assortment));

export default productStore;
