import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { view } from "@risingstack/react-easy-state";
import isNil from "lodash-es/isNil";

import { Product } from "../common/types/productTypes";

import productStore from "../stores/product/productStore";

type LeadTimeWarningProps = {
   sku?: string;
   product?: Product;
   className?: string;
};

const LeadTimeWarning = ({ sku, product, className = "" }: LeadTimeWarningProps) => {
   const productData = product ? product : productStore.resolveSku(sku);
   if (productData === undefined) {
      return null;
   }
   const hasLeadTime = !isNil(productData.leadTime);
   const isMiniTank = productData.miniTank;
   if (!hasLeadTime && !isMiniTank) {
      return null;
   }

   const desc = isMiniTank
      ? "En minitank kan ikke reduseres i antall eller slettes etter innsending av ordre."
      : "Dette produktet er ikke mulig å levere på kort varsel.";
   return (
      <div className={`bg-info d-flex my-2 text-info-dark ${className}`}>
         <div className="p-3 d-flex justify-content-center">
            <InformationCircleIcon />
         </div>
         <div className="flex-fill py-2">
            <div>
               <small>{desc}</small>
            </div>
            {hasLeadTime ? (
               <div>
                  <small>
                     Leveringstid: <strong>{productData.leadTime} dager</strong>
                  </small>
               </div>
            ) : null}
         </div>
      </div>
   );
};

export default view(LeadTimeWarning);
