import { RecipeStepType } from "../../../common/types/recipeTypes";

import StepTip from "./StepTip";
import StepVideo from "./StepVideo";

type StepProps = {
   step: RecipeStepType;
   noList?: boolean;
};

const Step = ({ step, noList = false }: StepProps) => {
   const { instruction, video, tip } = step;
   const stepBody = (
      <>
         {instruction}
         <StepTip tip={tip} />
         {!!video && (
            <div className="py-3">
               <StepVideo video={video} />
            </div>
         )}
      </>
   );

   if (noList) {
      return <div>{stepBody}</div>;
   }
   return <li className="p-1">{stepBody}</li>;
};

export default Step;
