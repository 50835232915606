import { RolloutPercentageItem, RolloutRule } from "./ProjectConfig";
import * as semver from "./Semver";
import { sha1 } from "./Sha1";
import { errorToString, getTimestampAsDate, isUndefined } from "./Utils";
/** Object for variation evaluation */
var User = /** @class */ (function () {
    function User(identifier, email, country, custom) {
        /** Optional dictionary for custom attributes of the User for advanced targeting rule definitions. e.g. User role, Subscription type */
        this.custom = {};
        this.identifier = identifier;
        this.email = email;
        this.country = country;
        this.custom = custom || {};
    }
    return User;
}());
export { User };
var RolloutEvaluator = /** @class */ (function () {
    function RolloutEvaluator(logger) {
        this.logger = logger;
    }
    RolloutEvaluator.prototype.Evaluate = function (setting, key, defaultValue, user, remoteConfig) {
        this.logger.debug("RolloutEvaluator.Evaluate() called.");
        var eLog = new EvaluateLogger();
        eLog.User = user;
        eLog.KeyName = key;
        eLog.ReturnValue = defaultValue;
        var result;
        try {
            if (user) {
                // evaluate comparison-based rules
                result = this.EvaluateRules(setting.rolloutRules, user, eLog);
                if (result !== null) {
                    eLog.ReturnValue = result.value;
                    return evaluationDetailsFromEvaluateResult(key, result, getTimestampAsDate(remoteConfig), user);
                }
                // evaluate percentage-based rules
                result = this.EvaluatePercentageRules(setting.rolloutPercentageItems, key, user);
                if (setting.rolloutPercentageItems && setting.rolloutPercentageItems.length > 0) {
                    eLog.OpAppendLine("Evaluating % options => " + (!result ? "user not targeted" : "user targeted"));
                }
                if (result !== null) {
                    eLog.ReturnValue = result.value;
                    return evaluationDetailsFromEvaluateResult(key, result, getTimestampAsDate(remoteConfig), user);
                }
            }
            else {
                if ((setting.rolloutRules && setting.rolloutRules.length > 0) ||
                    (setting.rolloutPercentageItems && setting.rolloutPercentageItems.length > 0)) {
                    var s = "Evaluating getValue('" + key + "'). ";
                    s += "UserObject missing! You should pass a UserObject to getValue(), in order to make targeting work properly. ";
                    s += "Read more: https://configcat.com/docs/advanced/user-object";
                    this.logger.warn(s);
                }
            }
            // regular evaluate
            result = {
                value: setting.value,
                variationId: setting.variationId
            };
            eLog.ReturnValue = result.value;
            return evaluationDetailsFromEvaluateResult(key, result, getTimestampAsDate(remoteConfig), user);
        }
        finally {
            this.logger.info(eLog.GetLog());
        }
    };
    RolloutEvaluator.prototype.EvaluateRules = function (rolloutRules, user, eLog) {
        this.logger.debug("RolloutEvaluator.EvaluateRules() called.");
        if (rolloutRules && rolloutRules.length > 0) {
            var _loop_1 = function (i) {
                var rule = rolloutRules[i];
                var comparisonAttribute = this_1.GetUserAttribute(user, rule.comparisonAttribute);
                var comparator = rule.comparator;
                var comparisonValue = rule.comparisonValue;
                var log = "Evaluating rule: '" + comparisonAttribute + "' " + this_1.RuleToString(comparator) + " '" + comparisonValue + "' => ";
                if (!comparisonAttribute) {
                    log += "NO MATCH (Attribute is not defined on the user object)";
                    eLog.OpAppendLine(log);
                    return "continue";
                }
                var result = {
                    value: rule.value,
                    variationId: rule.variationId,
                    matchedRule: rule
                };
                switch (comparator) {
                    case 0: // is one of
                        var cvs = comparisonValue.split(",");
                        for (var ci = 0; ci < cvs.length; ci++) {
                            if (cvs[ci].trim() === comparisonAttribute) {
                                log += "MATCH";
                                eLog.OpAppendLine(log);
                                return { value: result };
                            }
                        }
                        log += "no match";
                        break;
                    case 1: // is not one of
                        if (!comparisonValue.split(",").some(function (e) {
                            if (e.trim() === comparisonAttribute) {
                                return true;
                            }
                            return false;
                        })) {
                            log += "MATCH";
                            eLog.OpAppendLine(log);
                            return { value: result };
                        }
                        log += "no match";
                        break;
                    case 2: // contains
                        if (comparisonAttribute.indexOf(comparisonValue) !== -1) {
                            log += "MATCH";
                            eLog.OpAppendLine(log);
                            return { value: result };
                        }
                        log += "no match";
                        break;
                    case 3: // not contains
                        if (comparisonAttribute.indexOf(comparisonValue) === -1) {
                            log += "MATCH";
                            eLog.OpAppendLine(log);
                            return { value: result };
                        }
                        log += "no match";
                        break;
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                        if (this_1.EvaluateSemver(comparisonAttribute, comparisonValue, comparator)) {
                            log += "MATCH";
                            eLog.OpAppendLine(log);
                            return { value: result };
                        }
                        log += "no match";
                        break;
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                        if (this_1.EvaluateNumber(comparisonAttribute, comparisonValue, comparator)) {
                            log += "MATCH";
                            eLog.OpAppendLine(log);
                            return { value: result };
                        }
                        log += "no match";
                        break;
                    case 16: // is one of (sensitive)
                        var values = comparisonValue.split(",");
                        for (var ci = 0; ci < values.length; ci++) {
                            if (values[ci].trim() === sha1(comparisonAttribute)) {
                                log += "MATCH";
                                eLog.OpAppendLine(log);
                                return { value: result };
                            }
                        }
                        log += "no match";
                        break;
                    case 17: // is not one of (sensitive)
                        if (!comparisonValue.split(",").some(function (e) {
                            if (e.trim() === sha1(comparisonAttribute)) {
                                return true;
                            }
                            return false;
                        })) {
                            log += "MATCH";
                            eLog.OpAppendLine(log);
                            return { value: result };
                        }
                        log += "no match";
                        break;
                    default:
                        break;
                }
                eLog.OpAppendLine(log);
            };
            var this_1 = this;
            for (var i = 0; i < rolloutRules.length; i++) {
                var state_1 = _loop_1(i);
                if (typeof state_1 === "object")
                    return state_1.value;
            }
        }
        return null;
    };
    RolloutEvaluator.prototype.EvaluatePercentageRules = function (rolloutPercentageItems, key, user) {
        this.logger.debug("RolloutEvaluator.EvaluateVariations() called.");
        if (rolloutPercentageItems && rolloutPercentageItems.length > 0) {
            var hashCandidate = key + ((user.identifier === null || user.identifier === void 0) ? '' : user.identifier);
            var hashValue = sha1(hashCandidate).substring(0, 7);
            var hashScale = parseInt(hashValue, 16) % 100;
            var bucket = 0;
            for (var i = 0; i < rolloutPercentageItems.length; i++) {
                var percentageRule = rolloutPercentageItems[i];
                bucket += +percentageRule.percentage;
                if (hashScale < bucket) {
                    return {
                        value: percentageRule.value,
                        variationId: percentageRule.variationId,
                        matchedRule: percentageRule
                    };
                }
            }
        }
        return null;
    };
    RolloutEvaluator.prototype.EvaluateNumber = function (v1, v2, comparator) {
        this.logger.debug("RolloutEvaluator.EvaluateNumber() called.");
        var n1, n2;
        if (v1 && !Number.isNaN(Number.parseFloat(v1.replace(',', '.')))) {
            n1 = Number.parseFloat(v1.replace(',', '.'));
        }
        else {
            return false;
        }
        if (v2 && !Number.isNaN(Number.parseFloat(v2.replace(',', '.')))) {
            n2 = Number.parseFloat(v2.replace(',', '.'));
        }
        else {
            return false;
        }
        switch (comparator) {
            case 10:
                return n1 == n2;
            case 11:
                return n1 != n2;
            case 12:
                return n1 < n2;
            case 13:
                return n1 <= n2;
            case 14:
                return n1 > n2;
            case 15:
                return n1 >= n2;
            default:
                break;
        }
        return false;
    };
    RolloutEvaluator.prototype.EvaluateSemver = function (v1, v2, comparator) {
        this.logger.debug("RolloutEvaluator.EvaluateSemver() called.");
        if (semver.valid(v1) == null || isUndefined(v2)) {
            return false;
        }
        v2 = v2.trim();
        switch (comparator) {
            case 4:
                // in
                var sv = v2.split(",");
                var found = false;
                for (var ci = 0; ci < sv.length; ci++) {
                    if (!sv[ci] || isUndefined(sv[ci]) || sv[ci].trim() === "") {
                        continue;
                    }
                    if (semver.valid(sv[ci].trim()) == null) {
                        return false;
                    }
                    if (!found) {
                        found = semver.looseeq(v1, sv[ci].trim());
                    }
                }
                return found;
            case 5:
                // not in
                return !v2.split(",").some(function (e) {
                    if (!e || isUndefined(e) || e.trim() === "") {
                        return false;
                    }
                    e = semver.valid(e.trim());
                    if (e == null) {
                        return false;
                    }
                    return semver.eq(v1, e);
                });
            case 6:
                if (semver.valid(v2) == null) {
                    return false;
                }
                return semver.lt(v1, v2);
            case 7:
                if (semver.valid(v2) == null) {
                    return false;
                }
                return semver.lte(v1, v2);
            case 8:
                if (semver.valid(v2) == null) {
                    return false;
                }
                return semver.gt(v1, v2);
            case 9:
                if (semver.valid(v2) == null) {
                    return false;
                }
                return semver.gte(v1, v2);
            default:
                break;
        }
        return false;
    };
    RolloutEvaluator.prototype.GetUserAttribute = function (user, attribute) {
        switch (attribute) {
            case "Identifier":
                return user.identifier;
            case "Email":
                return user.email;
            case "Country":
                return user.country;
            default:
                return (user.custom || {})[attribute];
        }
    };
    RolloutEvaluator.prototype.RuleToString = function (rule) {
        switch (rule) {
            case 0:
                return "IS ONE OF";
            case 1:
                return "IS NOT ONE OF";
            case 2:
                return "CONTAINS";
            case 3:
                return "DOES NOT CONTAIN";
            case 4:
                return "IS ONE OF (SemVer)";
            case 5:
                return "IS NOT ONE OF (SemVer)";
            case 6:
                return "< (SemVer)";
            case 7:
                return "<= (SemVer)";
            case 8:
                return "> (SemVer)";
            case 9:
                return ">= (SemVer)";
            case 10:
                return "= (Number)";
            case 11:
                return "!= (Number)";
            case 12:
                return "< (Number)";
            case 13:
                return "<= (Number)";
            case 14:
                return "> (Number)";
            case 15:
                return ">= (Number)";
            case 16:
                return "IS ONE OF (Sensitive)";
            case 17:
                return "IS NOT ONE OF (Sensitive)";
            default:
                return rule;
        }
    };
    return RolloutEvaluator;
}());
export { RolloutEvaluator };
var EvaluateLogger = /** @class */ (function () {
    function EvaluateLogger() {
        this.Operations = "";
    }
    EvaluateLogger.prototype.OpAppendLine = function (s) {
        this.Operations += " " + s + "\n";
    };
    EvaluateLogger.prototype.GetLog = function () {
        return "Evaluate '" + this.KeyName + "'"
            + "\n User : " + JSON.stringify(this.User)
            + "\n" + this.Operations
            + " Returning value : " + this.ReturnValue;
    };
    return EvaluateLogger;
}());
/* Helper functions */
function evaluationDetailsFromEvaluateResult(key, evaluateResult, fetchTime, user) {
    return {
        key: key,
        value: evaluateResult.value,
        variationId: evaluateResult.variationId,
        fetchTime: fetchTime,
        user: user,
        isDefaultValue: false,
        matchedEvaluationRule: evaluateResult.matchedRule instanceof RolloutRule ? evaluateResult.matchedRule : void 0,
        matchedEvaluationPercentageRule: evaluateResult.matchedRule instanceof RolloutPercentageItem ? evaluateResult.matchedRule : void 0,
    };
}
export function evaluationDetailsFromDefaultValue(key, defaultValue, fetchTime, user, errorMessage, errorException) {
    return {
        key: key,
        value: defaultValue,
        fetchTime: fetchTime,
        user: user,
        isDefaultValue: true,
        errorMessage: errorMessage,
        errorException: errorException
    };
}
export function evaluationDetailsFromDefaultVariationId(key, defaultVariationId, fetchTime, user, errorMessage, errorException) {
    return {
        key: key,
        value: null,
        variationId: defaultVariationId,
        fetchTime: fetchTime,
        user: user,
        isDefaultValue: true,
        errorMessage: errorMessage,
        errorException: errorException
    };
}
export function evaluate(evaluator, settings, key, defaultValue, user, remoteConfig, logger) {
    var errorMessage;
    if (!settings) {
        errorMessage = "config.json is not present. Returning default value: '".concat(defaultValue, "'.");
        logger.error(errorMessage);
        return evaluationDetailsFromDefaultValue(key, defaultValue, getTimestampAsDate(remoteConfig), user, errorMessage);
    }
    var setting = settings[key];
    if (!setting) {
        errorMessage = "Evaluating '".concat(key, "' failed (key was not found in config.json). Returning default value: '").concat(defaultValue, "'. These are the available keys: ").concat(keysToString(settings), ".");
        logger.error(errorMessage);
        return evaluationDetailsFromDefaultValue(key, defaultValue, getTimestampAsDate(remoteConfig), user, errorMessage);
    }
    ensureAllowedDefaultValue(defaultValue);
    var evaluationDetails = evaluator.Evaluate(setting, key, defaultValue, user, remoteConfig);
    if (defaultValue !== null && defaultValue !== void 0 && typeof defaultValue !== typeof evaluationDetails.value) {
        throw new Error("The type of a setting must match the type of the given default value.\nThe setting's type was ".concat(typeof defaultValue, ", the given default value's type was ").concat(typeof evaluationDetails.value, ".\nPlease pass a corresponding default value type."));
    }
    return evaluationDetails;
}
export function evaluateVariationId(evaluator, settings, key, defaultVariationId, user, remoteConfig, logger) {
    var errorMessage;
    if (!settings) {
        errorMessage = "config.json is not present. Returning default variationId: '".concat(defaultVariationId, "'.");
        logger.error(errorMessage);
        return evaluationDetailsFromDefaultVariationId(key, defaultVariationId, getTimestampAsDate(remoteConfig), user, errorMessage);
    }
    var setting = settings[key];
    if (!setting) {
        errorMessage = "Evaluating '".concat(key, "' failed (key was not found in config.json). Returning default variationId: '").concat(defaultVariationId, "'. These are the available keys: ").concat(keysToString(settings), ".");
        logger.error(errorMessage);
        return evaluationDetailsFromDefaultVariationId(key, defaultVariationId, getTimestampAsDate(remoteConfig), user, errorMessage);
    }
    return evaluator.Evaluate(setting, key, null, user, remoteConfig, defaultVariationId);
}
function evaluateAllCore(evaluator, settings, user, remoteConfig, logger, getDetailsForError) {
    var errors;
    if (!checkSettingsAvailable(settings, logger, ", returning empty array")) {
        return [[], errors];
    }
    var evaluationDetailsArray = [];
    var index = 0;
    for (var _i = 0, _a = Object.entries(settings); _i < _a.length; _i++) {
        var _b = _a[_i], key = _b[0], setting = _b[1];
        var evaluationDetails = void 0;
        try {
            evaluationDetails = evaluator.Evaluate(setting, key, null, user, remoteConfig);
        }
        catch (err) {
            errors !== null && errors !== void 0 ? errors : (errors = []);
            errors.push(err);
            evaluationDetails = getDetailsForError(key, getTimestampAsDate(remoteConfig), user, err);
        }
        evaluationDetailsArray[index++] = evaluationDetails;
    }
    return [evaluationDetailsArray, errors];
}
export function evaluateAll(evaluator, settings, user, remoteConfig, logger) {
    return evaluateAllCore(evaluator, settings, user, remoteConfig, logger, function (key, fetchTime, user, err) { return evaluationDetailsFromDefaultValue(key, null, fetchTime, user, errorToString(err), err); });
}
export function evaluateAllVariationIds(evaluator, settings, user, remoteConfig, logger) {
    return evaluateAllCore(evaluator, settings, user, remoteConfig, logger, function (key, fetchTime, user, err) { return evaluationDetailsFromDefaultVariationId(key, null, fetchTime, user, errorToString(err), err); });
}
export function checkSettingsAvailable(settings, logger, appendix) {
    if (appendix === void 0) { appendix = ""; }
    if (!settings) {
        logger.error("config.json is not present".concat(appendix));
        return false;
    }
    return true;
}
export function ensureAllowedDefaultValue(value) {
    if (value === null || value === void 0) {
        return;
    }
    var type = typeof value;
    if (type === "boolean" || type === "number" || type === "string") {
        return;
    }
    throw new Error("The default value must be boolean, number, string, null or undefined.");
}
function keysToString(settings) {
    return Object.keys(settings).join();
}
