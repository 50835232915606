import { documentToReactComponents, NodeRenderer, Options, RenderNode } from "@contentful/rich-text-react-renderer";
import { BLOCKS, Document, INLINES } from "@contentful/rich-text-types";
import { ArrowDownTrayIcon, ArrowTopRightOnSquareIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import { useContext } from "react";
import { Link } from "wouter";

import contentStore from "../../stores/cms/contentStore";
import { ContentEntryType } from "../../stores/cms/contentUtils";

import CmsEmbeddedEntry from "./CmsEmbeddedEntry";
import ContactFormPageContext from "./ContactFormPageContext";
import HomePageContext from "./HomePageContext";
import { columnWidthType, RichTextColumn, RichTextListColumn, TextTags } from "./RichTextRendererHelpers";

type RichTextRendererProps = {
   document: Document;
   textColumnWidth?: columnWidthType;
};

const RichTextRenderer = ({ document, textColumnWidth }: RichTextRendererProps) => {
   const textNodeRenderer =
      (tag: TextTags): NodeRenderer =>
      (node, children) => {
         const isListTag = tag === "ul" || tag === "ol";
         const Tag = tag;
         return (
            <RichTextColumn columnWidth={textColumnWidth} tag={tag}>
               {isListTag ? (
                  <RichTextListColumn node={node} customRenderNodes={assetAndEntryRenderNodes} />
               ) : (
                  <Tag>{children}</Tag>
               )}
            </RichTextColumn>
         );
      };

   const textRenderNodes: RenderNode = {
      [BLOCKS.PARAGRAPH]: textNodeRenderer("p"),
      [BLOCKS.HEADING_2]: textNodeRenderer("h2"),
      [BLOCKS.HEADING_3]: textNodeRenderer("h3"),
      [BLOCKS.HEADING_4]: textNodeRenderer("h4"),
      [BLOCKS.HEADING_5]: textNodeRenderer("h5"),
      [BLOCKS.HEADING_6]: textNodeRenderer("h6"),
      [BLOCKS.UL_LIST]: textNodeRenderer("ul"),
      [BLOCKS.OL_LIST]: textNodeRenderer("ol")
   };

   const assetAndEntryRenderNodes: RenderNode = {
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
         if (!node.data.target.fields) {
            return (
               <>
                  <InformationCircleIcon /> Innholdet eksisterer ikke
               </>
            );
         }
         const nodeId = node.data.target.sys.id;
         const nodeType = node.data.target.sys.contentType.sys.id as ContentEntryType;
         const isInArticle = !useContext(HomePageContext) && !useContext(ContactFormPageContext);

         return (
            <div className={isInArticle ? "contentBlock" : "contentBlockLessMargin"}>
               <CmsEmbeddedEntry entryId={nodeId} content={node.data.target} type={nodeType} />
            </div>
         );
      },
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
         const url = node.data.target.fields.file.url;
         const assetTitle = node.data.target.fields.title;
         return (
            <a
               className="underline-before-hover"
               href={url}
               target="_blank"
               rel="noopener noreferrer"
               aria-label={"Gå til " + assetTitle}
            >
               {children}
               <ArrowDownTrayIcon height={18} />
            </a>
         );
      },
      [INLINES.HYPERLINK]: (node, children) => {
         const url = node.data.uri;
         if (url.startsWith("/")) {
            return (
               <Link to={url} className="underline-before-hover">
                  {children}
               </Link>
            );
         }
         return (
            <a
               className="underline-before-hover"
               href={url}
               target="_blank"
               rel="noopener noreferrer"
               aria-label={"Gå til " + url}
            >
               {children}
               <ArrowTopRightOnSquareIcon height={18} />
            </a>
         );
      },
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
         const nodeId = node.data.target.sys.id;
         const existingContent = contentStore.urlData.data.find((data) => data.id === nodeId);
         if (existingContent) {
            return (
               <Link to={"/" + existingContent.fullUrl} className="underline-before-hover">
                  {children}
               </Link>
            );
         }
         return <>{children}</>;
      }
   };

   const customRenderOptions: Options = {
      renderNode: {
         ...textRenderNodes,
         ...assetAndEntryRenderNodes
      }
   };
   return <>{documentToReactComponents(document, customRenderOptions)}</>;
};

export default RichTextRenderer;
