import { view } from "@risingstack/react-easy-state";
import cn from "classnames";
import { KeyboardEventHandler, PropsWithChildren } from "react";

type ButtonPlainProps = {
   onClick?(): void;
   isBlue?: boolean;
   className?: string;
};

const ButtonPlain = ({ onClick = () => {}, isBlue = false, className = "", children }: PropsWithChildren<ButtonPlainProps>) => {
   const handleKeyPress: KeyboardEventHandler<HTMLDivElement> = (event) => {
      if (event.key === "Enter" || event.key === " ") {
         onClick();
      }
   };

   return (
      <div
         role="button"
         tabIndex={0}
         className={cn(`tine-plain-button${isBlue ? "-blue" : ""} w-fit h-fit`, className)}
         onClick={onClick}
         onKeyDown={handleKeyPress}
      >
         {children}
      </div>
   );
};

export default view(ButtonPlain);
