import { FocusEvent, KeyboardEvent, ReactNode, useEffect, useRef, useState } from "react";
import { Locale } from "react-date-object";
import RmdpDatePicker, { DatePickerRef as _DatePickerRef, DateObject } from "react-multi-date-picker";

import { CalendarClickEvent, CalendarStyle } from "../common/types/deliveryTypes";
import useMediaQuery from "../common/useMediaQuery";
import { desktopBreakpoint, monthNames, weekDayNames } from "../common/utils";
import { formatDate, formatDatesTuple } from "../common/utils/dateUtils";

export type DatePickerRef = _DatePickerRef & {
   isOpen: boolean;
};

type DateOrDates = [Date, Date] | Date;

type DatePickerRender = (
   value: string,
   openCalendar: () => void,
   handleValueChange: (e: React.ChangeEvent) => void,
   locale: Locale,
   separator: string
) => ReactNode;

type DatePickerProps<D extends DateOrDates> = {
   pickerRef?: React.MutableRefObject<DatePickerRef | null>;
   className?: string;
   onChange: (value: D) => void;
   value: D;
   render?: DatePickerRender;
   children?: ReactNode;
   numberOfMonths?: number;
   minDate?: number;
   mapDays?: (event: CalendarClickEvent) => CalendarStyle;
};

const DatePicker = <D extends DateOrDates>({
   pickerRef,
   className,
   onChange,
   value,
   render,
   children = null,
   numberOfMonths,
   minDate,
   mapDays
}: DatePickerProps<D>) => {
   const ref = useRef<HTMLDivElement>(null);
   const datePickerRef = useRef<DatePickerRef>(null);
   const isDesktop = useMediaQuery(desktopBreakpoint);
   const [currentValue, setCurrentValue] = useState<D>(value);

   useEffect(() => {
      if (datePickerRef.current && pickerRef) {
         pickerRef.current = datePickerRef.current;
      }
   }, []);

   useEffect(() => {
      setCurrentValue(value);
   }, [value]);

   const months = typeof numberOfMonths === "number" || isDesktop ? 2 : 1;
   const isMulti = Array.isArray(value);

   const handleChange = (date: DateObject[] | DateObject) => {
      if (Array.isArray(date)) {
         if (date.length > 1) {
            onChange([date[0].toDate(), date[1].toDate()] as D);
            datePickerRef.current?.closeCalendar();
         }
      } else if (date) {
         onChange((date as DateObject).toDate() as D);
         datePickerRef.current?.closeCalendar();
      }
   };
   const handleBlur = (e: FocusEvent<HTMLElement>) => {
      const { currentTarget, relatedTarget, target } = e;
      if (datePickerRef?.current?.isOpen) {
         if (relatedTarget && currentTarget && currentTarget !== target) {
            if (!currentTarget.contains(relatedTarget)) {
               datePickerRef.current.closeCalendar();
            }
         }
      }
   };
   const handleKeydown = (e: KeyboardEvent<HTMLElement>) => {
      if (e.key === "Escape" && datePickerRef.current) {
         datePickerRef?.current?.closeCalendar();
      }
   };
   const defaultRender: DatePickerRender = (_currentValue, openCalendar) => {
      const format: (value: any) => string =
         value instanceof Date ? formatDate : Array.isArray(value) && value.length > 1 ? formatDatesTuple : () => "";
      const handleFocus = () => {
         openCalendar();
      };
      return (
         <div className="rmdp-container ">
            <input type="text" className="form-control" value={format(value)} onFocus={handleFocus} readOnly />
         </div>
      );
   };
   const handleClose = () => {
      // this clears the datapicker state.  Useful when the user picks a start date then closes the picker.
      // We want the range defined in value to appear next time the picker is opened.
      if (Array.isArray(value)) {
         setCurrentValue([...value] as D);
      }
      return true;
   };
   return (
      <div ref={ref} onBlur={handleBlur} onKeyDown={handleKeydown}>
         <RmdpDatePicker
            ref={datePickerRef}
            range={isMulti}
            format="DD.MM.YY"
            className={className || "datepicker-divider"}
            render={render || defaultRender}
            numberOfMonths={months}
            weekDays={weekDayNames}
            weekStartDayIndex={1}
            months={monthNames}
            minDate={minDate}
            calendarPosition="bottom-center"
            inputClass="form-control"
            value={currentValue}
            onClose={handleClose}
            onChange={handleChange}
            mapDays={mapDays}
         >
            {children}
         </RmdpDatePicker>
      </div>
   );
};

export const SingleDatePicker = DatePicker<Date>;
export const MultiDatePicker = DatePicker<[Date, Date]>;

export default DatePicker;
