import { StepGroupType } from "../../../common/types/recipeTypes";

import Step from "./Step";

type StepGroupProps = {
   stepGroup: StepGroupType;
};

const StepGroup = ({ stepGroup }: StepGroupProps) => {
   const { steps, name } = stepGroup;
   if (steps.length === 1) {
      return (
         <div className="recipe-step-group">
            {!!name && <h3>{name}</h3>}
            <div className="mb-3">
               <Step step={steps[0]} noList={true} />
            </div>
         </div>
      );
   }
   return (
      <div className="recipe-step-group">
         {!!name && <h3>{name}</h3>}
         <ol>
            {steps.map((step) => (
               <Step key={step.instruction} step={step} />
            ))}
         </ol>
      </div>
   );
};

export default StepGroup;
