import { view } from "@risingstack/react-easy-state";
import { useTranslation, withTranslation } from "react-i18next";

import { getKeyPlural } from "../common/i18n";

import uiStore from "../stores/uiStore";

type TranslationTextProps = {
   textKey: string;
   count?: number;
   interpolation?: {
      [name: string]: string;
   };
   defaultText?: string;
};

const TranslationText = ({ textKey, count, interpolation, defaultText }: TranslationTextProps) => {
   const { t } = useTranslation();
   if (uiStore.showTranslationKeys) {
      return <>{`${textKey}`}</>;
   }
   if (uiStore.showDefaultTranslationText) {
      return <>{defaultText || "No default text provided"}</>;
   } else {
      if (count !== undefined) {
         const key = getKeyPlural(textKey, count);
         const translation = t(key, { count: count, ...interpolation });
         return <>{translation}</>;
      } else {
         const translation = t(textKey, interpolation);
         return <>{translation}</>;
      }
   }
};

export default view(TranslationText);
