import YouTube, { YouTubeEvent, YouTubeProps } from "react-youtube";

import { AspectRatioType } from "../common/types/mediaTypes";

type YouTubeVideoProps = {
   id: string;
   ratio?: AspectRatioType;
   title?: string;
   autoPlay?: boolean;
} & YouTubeProps;

const YouTubeVideo = ({ id, title = "", ratio = "16x9", autoPlay = false, ...rest }: YouTubeVideoProps) => {
   const handleReady: YouTubeProps["onReady"] = (event) => {
      if (autoPlay) {
         event.target.playVideo();
      }
      if (rest.onReady) {
         rest.onReady(event);
      }
   };
   const containerClass = `youtube-container youtube-container__ratio${ratio}`;
   const options = { width: "100%", height: "100%" };
   return (
      <div className={containerClass}>
         <YouTube {...rest} className="youtube-video" videoId={id} title={title} opts={options} onReady={handleReady} />
      </div>
   );
};

export default YouTubeVideo;
