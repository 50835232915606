import { view } from "@risingstack/react-easy-state";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import authStore from "../../stores/auth/authStore";

import BarcodeScannerButton from "../../components/barcodes/BarcodeScannerButton";
import LoginButton from "../../components/login/LoginButton";
import NewCustomerButton from "../../components/login/NewCustomerButton";
import PreprodInfo from "../../components/PreprodInfo";
import MiniCart from "../minicart/MiniCart";
import MobileCategories from "./MobileCategories";
import MobileDeliveryInfo from "./MobileDeliveryInfo";

const BurgerMenuAndCart = () => (
   <Row className="align-items-center w-100 mobile-bar my-2">
      <Col xs={3} className="ps-4">
         <MobileCategories />
      </Col>
      <Col xs={9} className="pe-4 d-flex justify-content-end align-items-center">
         {process.env.ENV_NAME == "preprod24" && <PreprodInfo />}
         {authStore.isLoggedIn() ? (
            <>
               <BarcodeScannerButton className="mx-2" />
               <MobileDeliveryInfo />
               <MiniCart />
            </>
         ) : (
            <>
               <LoginButton />
               <NewCustomerButton />
            </>
         )}
      </Col>
   </Row>
);

export default view(BurgerMenuAndCart);
