import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Document } from "@contentful/rich-text-types";
import { ExclamationCircleIcon, InformationCircleIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import { view } from "@risingstack/react-easy-state";
import { Fragment } from "react";

import { FEATURE_NAME } from "../common/types/featureTypes";

import contentStore from "../stores/cms/contentStore";
import featuresStore from "../stores/features/featuresStore";

type WarningProps = {
   warning: {
      internalName: string;
      theme: string;
      visibleFor: FEATURE_NAME | "everyone";
      message: Document;
   };
};

const Warning = view(({ warning }: WarningProps) => {
   let Icon;
   switch (warning.theme) {
      case "Warning":
         Icon = ExclamationCircleIcon;
         break;
      case "Information":
         Icon = InformationCircleIcon;
         break;
      case "Important":
         Icon = QuestionMarkCircleIcon;
         break;
      default:
         Icon = Fragment;
   }
   const Message = () => documentToReactComponents(warning.message);

   return (
      <div className={`py-2 warning theme-${warning.theme.toLowerCase()}`}>
         <div className="mx-auto d-flex align-items-center">
            <div className="me-2">
               <Icon width={20} />
            </div>
            <Message />
         </div>
      </div>
   );
});

const Warnings = () => {
   const warnings = Object.values(contentStore.fetchedContent)
      .filter(({ type }) => type === "warning")
      .map((content) => content.entry.data)
      .filter((warning) => warning.visibleFor === "everyone" || featuresStore.hasCustomerFeature(warning.visibleFor));

   if (warnings.length === 0) {
      return null;
   }

   return (
      <div className="warnings">
         {warnings.map((warning, index) => (
            <Warning warning={warning} key={`warning-${index}`} />
         ))}
      </div>
   );
};

export default view(Warnings);
