import { Bars3Icon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { view } from "@risingstack/react-easy-state";
import isNil from "lodash-es/isNil";
import { MouseEventHandler } from "react";
import { Offcanvas } from "react-bootstrap";
import { Link, useLocation } from "wouter";

import { CategoryInfo } from "../../common/types/categoryTypes";
import { FEATURE_NAME } from "../../common/types/featureTypes";
import { titleCase } from "../../common/utils";

import authStore from "../../stores/auth/authStore";
import loginState from "../../stores/auth/loginState";
import categoryStore from "../../stores/categories/categoryStore";
import contentStore from "../../stores/cms/contentStore";
import featuresStore from "../../stores/features/featuresStore";

import NotificationsBadge from "../../account/notifications/NotificationsBadge";
import Loading from "../../components/Loading";
import NavLink from "../../components/NavLink";
import theme from "../../themes/theme";

const hasChildren = (cat: CategoryInfo) => !isNil(cat["children"]) && cat.children.length > 0;

const drillDownTree = (menuItems: CategoryInfo[], path: string[]): CategoryInfo | undefined => {
   // Make sure this doesn't crash if called without data
   if (path.length === 0) {
      return undefined;
   }

   const [lookForKey, ...rest] = path;
   const foundItem = menuItems.find((mi) => mi.url_key === lookForKey);

   // No more path to process, we found the item.
   if (rest.length === 0) {
      return foundItem;
   }

   if (isNil(foundItem)) {
      return undefined;
   }

   return drillDownTree(foundItem.children, rest);
};

const RootMenu = view(() => {
   const path = categoryStore.mobile.path;

   return (
      <ul className="list-unstyled">
         {categoryStore.categories.map((cat) => (
            <li className="fw-bold mb-3" key={cat.id} onClick={() => path.push(cat.url_key)}>
               {titleCase(cat.name)} {hasChildren(cat) && <ChevronRightIcon />}
            </li>
         ))}
         {contentStore.mainMenuItems.map((menuItem) => (
            <li className="fw-bold mb-3" key={menuItem.id}>
               <Link to={menuItem.url} onClick={() => categoryStore.showMenu(false)}>
                  {titleCase(menuItem.title)}
               </Link>
            </li>
         ))}
      </ul>
   );
});

const SubMenu = view(() => {
   const path = categoryStore.mobile.path;
   const selectedItem = drillDownTree(categoryStore.categories, path);
   const [_, redirectTo] = useLocation();

   const handleClick = (cat: CategoryInfo) => {
      if (!hasChildren(cat)) {
         categoryStore.showMenu(false);
         redirectTo(cat.fullUrl);
      } else {
         path.push(cat.url_key);
      }
   };

   const goBack = () => {
      categoryStore.mobile.path.pop();
   };

   if (isNil(selectedItem)) {
      return <div>Det oppsto den feil under lasting av kategoriene.</div>;
   }

   const visibleItems = selectedItem.children.filter((c: CategoryInfo) => c.visible);

   return (
      <div>
         <h2 className="d-flex align-items-center mb-3" onClick={goBack}>
            <ChevronLeftIcon className="me-3" /> {titleCase(selectedItem.name)}
         </h2>
         <Link className="indicate-link" onClick={() => categoryStore.showMenu(false)} to={"/" + path.join("/") + ".html"}>
            Se alle produkter i {titleCase(selectedItem.name)}
         </Link>

         <ul className="list-unstyled mt-3">
            {visibleItems.map((cat: CategoryInfo) => (
               <li
                  className="fw-bold mb-3 d-flex align-items-center justify-content-between"
                  key={cat.id}
                  onClick={() => handleClick(cat)}
               >
                  {titleCase(cat.name)} {hasChildren(cat) && <ChevronRightIcon />}
               </li>
            ))}
         </ul>
      </div>
   );
});

const MobileCategories = () => {
   const [_, redirectTo] = useLocation();
   if (categoryStore.categories.length === 0) {
      return <Loading />;
   }

   const hideMenu: MouseEventHandler = () => {
      categoryStore.showMenu(false);
   };

   const logout = () => {
      redirectTo("/");
      loginState.transitionTo("NOT_LOGGED_IN");
   };

   return (
      <>
         <Bars3Icon onClick={() => categoryStore.showMenu(true)} />
         <Offcanvas
            show={categoryStore.mobile.visible}
            className="p-3 mobile-menu"
            placement="start"
            onHide={() => categoryStore.showMenu(false)}
         >
            <Offcanvas.Header closeButton>
               <img alt="Logo" width={56} height={Math.round(56 * theme.logoRatio)} className="logo" src={theme.desktopLogoUrl} />
            </Offcanvas.Header>
            <Offcanvas.Body>
               {categoryStore.mobile.path.length === 0 ? <RootMenu /> : <SubMenu />}
               <hr />
               <ul className="list-unstyled">
                  <li className="mb-2">
                     <NavLink onClick={hideMenu} to="/customer/account">
                        Brukere og innlogging
                     </NavLink>
                  </li>
                  <li className="mb-2">
                     <NavLink onClick={hideMenu} to={"/customer/deliveries"}>
                        Leveringsdager
                     </NavLink>
                  </li>
                  <li className="mb-2">
                     <NavLink onClick={hideMenu} to="/customer/orders">
                        Ordreoversikt
                     </NavLink>
                  </li>
                  <li className="mb-2">
                     <NavLink onClick={hideMenu} to="/customer/favorites">
                        Favorittlister
                     </NavLink>
                  </li>
                  {featuresStore.hasCustomerFeature(FEATURE_NAME.subscriptionOrderAvailable) && (
                     <li className="mb-2">
                        <NavLink onClick={hideMenu} to={"/customer/subscriptions"}>
                           Abonnement
                        </NavLink>
                     </li>
                  )}
                  {featuresStore.hasCustomerFeature(FEATURE_NAME.invoicePaymentAvailable) && (
                     <li className="mb-4">
                        <NavLink onClick={hideMenu} to={"/customer/invoices"}>
                           Fakturaer
                        </NavLink>
                     </li>
                  )}
                  {featuresStore.claimsEnabled && (
                     <li className="mb-2">
                        <NavLink onClick={hideMenu} to="/customer/claims">
                           Reklamasjoner
                        </NavLink>
                     </li>
                  )}
                  {featuresStore.notificationsEnabled && (
                     <li className="mb-2 d-flex">
                        <NavLink onClick={hideMenu} to="/customer/notifications">
                           Varslinger
                        </NavLink>
                        <NotificationsBadge />
                     </li>
                  )}
                  {authStore.isLoggedIn() && (
                     <li className="mb-2">
                        <div role="button" onClick={logout}>
                           Logg ut
                        </div>
                     </li>
                  )}
               </ul>
               {authStore.isLoggedIn() && (
                  <>
                     <hr />
                     <div className="small">
                        Logget inn som
                        <br />
                        {authStore.currentCompany} {authStore.getCurrentCompany()?.name ?? ""}
                     </div>
                  </>
               )}
            </Offcanvas.Body>
         </Offcanvas>
      </>
   );
};

export default view(MobileCategories);
