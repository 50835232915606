import { view } from "@risingstack/react-easy-state";
import { Link } from "wouter";

import SearchField from "../../components/search/SearchField";
import theme from "../../themes/theme";

const MobileSearchBar = () => {
   const logoWidth = 50;

   return (
      <div className="categories w-100 d-flex align-items-center">
         <div className="ps-3 p-2 d-flex align-items-center">
            <Link to="/">
               <img
                  alt="Logo"
                  width={logoWidth}
                  height={Math.round(logoWidth * theme.logoRatio)}
                  className="logo"
                  src={theme.mobileLogoUrl}
               />
            </Link>
         </div>
         <div className="pe-3 p-2 flex-fill">
            <SearchField />
         </div>
      </div>
   );
};

export default view(MobileSearchBar);
