import { __awaiter, __extends, __generator } from "tslib";
import { ConfigServiceBase } from "./ConfigServiceBase";
import { ProjectConfig } from "./ProjectConfig";
import { delay } from "./Utils";
var AutoPollConfigService = /** @class */ (function (_super) {
    __extends(AutoPollConfigService, _super);
    function AutoPollConfigService(configFetcher, options) {
        var _this = _super.call(this, configFetcher, options) || this;
        _this.signalInitialization = (void 0); // the initial value is only for keeping the TS compiler happy
        if (options.maxInitWaitTimeSeconds > 0) {
            _this.initialized = false;
            // This promise will be resolved when
            // 1. the cache contains a valid config at startup (see startRefreshWorker) or
            // 2. config.json is downloaded the first time (see onConfigUpdated) or
            // 3. maxInitWaitTimeSeconds has passed (see the setTimeout call below).
            _this.initialization = new Promise(function (resolve) { return _this.signalInitialization = function () {
                _this.initialized = true;
                resolve();
            }; });
            _this.initialization.then(function () { return !_this.disposed && options.hooks.emit("clientReady"); });
            setTimeout(function () { return _this.signalInitialization(); }, options.maxInitWaitTimeSeconds * 1000);
        }
        else {
            _this.initialized = true;
            _this.initialization = Promise.resolve();
            options.hooks.emit("clientReady");
        }
        if (!options.offline) {
            _this.startRefreshWorker(options.pollIntervalSeconds * 1000);
        }
        return _this;
    }
    AutoPollConfigService.prototype.waitForInitializationAsync = function () {
        return __awaiter(this, void 0, void 0, function () {
            var cancelDelay, result;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.race([
                            (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.initialization];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/, true];
                                }
                            }); }); })(),
                            delay(this.options.maxInitWaitTimeSeconds * 1000, function (cancel) { return cancelDelay = cancel; })
                        ])];
                    case 1:
                        result = _a.sent();
                        cancelDelay();
                        return [2 /*return*/, !!result];
                }
            });
        });
    };
    AutoPollConfigService.prototype.getConfig = function () {
        return __awaiter(this, void 0, void 0, function () {
            function logSuccess(logger) {
                logger.debug("AutoPollConfigService.getConfig() - returning value from cache.");
            }
            var cacheConfig;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.options.logger.debug("AutoPollConfigService.getConfig() called.");
                        cacheConfig = null;
                        if (!(!this.isOffline && !this.initialized)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.options.cache.get(this.options.getCacheKey())];
                    case 1:
                        cacheConfig = _a.sent();
                        if (!ProjectConfig.isExpired(cacheConfig, this.options.pollIntervalSeconds * 1000)) {
                            logSuccess(this.options.logger);
                            return [2 /*return*/, cacheConfig];
                        }
                        this.options.logger.debug("AutoPollConfigService.getConfig() - cache is empty or expired, waiting for initialization.");
                        return [4 /*yield*/, this.waitForInitializationAsync()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [4 /*yield*/, this.options.cache.get(this.options.getCacheKey())];
                    case 4:
                        cacheConfig = _a.sent();
                        if (!ProjectConfig.isExpired(cacheConfig, this.options.pollIntervalSeconds * 1000)) {
                            logSuccess(this.options.logger);
                        }
                        else {
                            this.options.logger.debug("AutoPollConfigService.getConfig() - cache is empty or expired.");
                        }
                        return [2 /*return*/, cacheConfig];
                }
            });
        });
    };
    AutoPollConfigService.prototype.refreshConfigAsync = function () {
        this.options.logger.debug("AutoPollConfigService.refreshConfigAsync() called.");
        return _super.prototype.refreshConfigAsync.call(this);
    };
    AutoPollConfigService.prototype.dispose = function () {
        this.options.logger.debug("AutoPollConfigService.dispose() called.");
        _super.prototype.dispose.call(this);
        if (this.timerId) {
            this.stopRefreshWorker();
        }
    };
    AutoPollConfigService.prototype.onConfigUpdated = function (newConfig) {
        _super.prototype.onConfigUpdated.call(this, newConfig);
        this.signalInitialization();
    };
    AutoPollConfigService.prototype.onConfigChanged = function (newConfig) {
        _super.prototype.onConfigChanged.call(this, newConfig);
        this.options.configChanged();
    };
    AutoPollConfigService.prototype.setOnlineCore = function () {
        this.startRefreshWorker(this.options.pollIntervalSeconds * 1000);
    };
    AutoPollConfigService.prototype.setOfflineCore = function () {
        this.stopRefreshWorker();
    };
    AutoPollConfigService.prototype.startRefreshWorker = function (delayMs) {
        return __awaiter(this, void 0, void 0, function () {
            var latestConfig;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.options.logger.debug("AutoPollConfigService.startRefreshWorker() called.");
                        return [4 /*yield*/, this.options.cache.get(this.options.getCacheKey())];
                    case 1:
                        latestConfig = _a.sent();
                        if (!ProjectConfig.isExpired(latestConfig, this.options.pollIntervalSeconds * 1000)) return [3 /*break*/, 4];
                        if (!!this.isOfflineExactly) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.refreshConfigCoreAsync(latestConfig)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        this.signalInitialization();
                        _a.label = 5;
                    case 5:
                        this.options.logger.debug("AutoPollConfigService.startRefreshWorker() - calling refreshWorkerLogic()'s setTimeout.");
                        this.timerId = setTimeout(function (d) { return _this.refreshWorkerLogic(d); }, delayMs, delayMs);
                        return [2 /*return*/];
                }
            });
        });
    };
    AutoPollConfigService.prototype.stopRefreshWorker = function () {
        this.options.logger.debug("AutoPollConfigService.stopRefreshWorker() - clearing setTimeout.");
        clearTimeout(this.timerId);
    };
    AutoPollConfigService.prototype.refreshWorkerLogic = function (delayMs) {
        return __awaiter(this, void 0, void 0, function () {
            var latestConfig;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.disposed) {
                            this.options.logger.debug("AutoPollConfigService.refreshWorkerLogic() - called on a disposed client.");
                            return [2 /*return*/];
                        }
                        this.options.logger.debug("AutoPollConfigService.refreshWorkerLogic() - called.");
                        if (!!this.isOffline) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.options.cache.get(this.options.getCacheKey())];
                    case 1:
                        latestConfig = _a.sent();
                        return [4 /*yield*/, this.refreshConfigCoreAsync(latestConfig)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        this.options.logger.debug("AutoPollConfigService.refreshWorkerLogic() - calling refreshWorkerLogic()'s setTimeout.");
                        this.timerId = setTimeout(function (d) { return _this.refreshWorkerLogic(d); }, delayMs, delayMs);
                        return [2 /*return*/];
                }
            });
        });
    };
    return AutoPollConfigService;
}(ConfigServiceBase));
export { AutoPollConfigService };
