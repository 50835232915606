import { view } from "@risingstack/react-easy-state";
import isNil from "lodash-es/isNil";
import Row from "react-bootstrap/Row";
import { Link } from "wouter";

import { Product, ProductTileCallbacks } from "../common/types/productTypes";

import productStore from "../stores/product/productStore";

import Button from "./Button";
import ProductTile from "./ProductTile";

const MAXIMUM_PRODUCTS_PER_ROW = 6;

type ProductListProps = {
   heading?: string | null;
   products?: Product[];
   skus?: string[];
   count?: number;
   maxCol?: number;
   buttonText?: string;
   buttonUrl?: string;
   listName: string;
   callbacks?: ProductTileCallbacks;
};

const getXlBreak = (maxCol?: number) => {
   if (!!maxCol) {
      //Maximum of columns cannot exceed 6
      if (maxCol <= MAXIMUM_PRODUCTS_PER_ROW) {
         return maxCol;
      }
      //Check the number is divisible by 6
      if (maxCol % MAXIMUM_PRODUCTS_PER_ROW === 0) {
         return MAXIMUM_PRODUCTS_PER_ROW;
      }
   }
   //If the requirements above is not met, return 4
   return 4;
};

const ProductList = ({
   heading,
   products,
   skus = [],
   count,
   maxCol,
   buttonText,
   buttonUrl,
   listName,
   callbacks = {}
}: ProductListProps) => {
   const resolvedProducts: Product[] = !isNil(products) ? products : productStore.resolveSkus(skus);
   if (resolvedProducts.length === 0) {
      console.log("No products resolved: ", skus, resolvedProducts);
      return null;
   }

   const visibleSlice = !isNil(count) ? resolvedProducts.slice(0, count) : resolvedProducts;
   const lgBreak = getXlBreak(maxCol);
   const mdBreak = Math.max(2, Math.round(lgBreak / 2));
   const smBreak = Math.max(2, Math.round(lgBreak / 3));

   return (
      <div>
         {!isNil(heading) && (
            <div className="d-flex mb-3 justify-content-between align-items-center">
               <div>
                  <h2>{heading}</h2>
               </div>
               {buttonUrl && buttonText && (
                  <div>
                     <Link to={buttonUrl}>
                        <Button>{buttonText}</Button>
                     </Link>
                  </div>
               )}
            </div>
         )}
         <Row xs={2} sm={smBreak} md={mdBreak} lg={lgBreak} className="g-1">
            {visibleSlice.map((product, i) => (
               <ProductTile key={product.sku} product={product} callbacks={callbacks} listName={listName} position={i + 1} />
            ))}
         </Row>
      </div>
   );
};

export default view(ProductList);
