import { ShoppingCartIcon } from "@heroicons/react/24/outline";
import { view } from "@risingstack/react-easy-state";
import debounce from "lodash-es/debounce";
import isFunction from "lodash-es/isFunction";
import isNil from "lodash-es/isNil";
import React from "react";
import Button from "react-bootstrap/Button";

import { sendAddToCart } from "../common/tracking";
import { Product, ProductTileCallbacks } from "../common/types/productTypes";

import authStore from "../stores/auth/authStore";
import cartStore from "../stores/cartStore";
import uiStore from "../stores/uiStore";

type AddToCartButtonProps = {
   product: Product;
   qty: number;
   callbacks: ProductTileCallbacks;
   listName: string;
   position: number;
   imageRefFn: () => React.RefObject<HTMLImageElement> | null;
   noPadding?: boolean;
};

const AddToCartButton = ({
   product,
   qty,
   callbacks,
   listName,
   position,
   imageRefFn,
   noPadding = false
}: AddToCartButtonProps) => {
   const addToCart = debounce(
      () => {
         if (authStore.isLoggedIn()) {
            const imageRef = imageRefFn();
            cartStore
               .addToCart(product.sku, qty)
               .then(() => {
                  // If the add to cart action is successful
                  sendAddToCart(product, qty, listName);
                  if (!isNil(imageRef)) {
                     uiStore.addFlyingProduct(product.sku, imageRef);
                  }

                  // Allow other consumers to insert their own callback
                  if (!isNil(callbacks) && isFunction(callbacks["addToCart"])) {
                     callbacks["addToCart"](product, listName, position);
                  }
               })
               .catch((err) => {
                  console.warn("An unexpected error occured", err);
               });
         }
      },
      800,
      { leading: true, trailing: false }
   );

   return (
      <Button className={`add-to-cart icon w-100 ${noPadding ? "" : "ms-lg-2"}`} aria-label="Add to cart" onClick={addToCart}>
         <ShoppingCartIcon />
      </Button>
   );
};

export default view(AddToCartButton);
