import { __extends } from "tslib";
import { InMemoryCache } from "./Cache";
import { ConfigCatConsoleLogger, LoggerWrapper } from "./ConfigCatLogger";
import { DefaultEventEmitter } from "./DefaultEventEmitter";
import { Hooks } from "./Hooks";
export var PollingMode;
(function (PollingMode) {
    PollingMode[PollingMode["AutoPoll"] = 0] = "AutoPoll";
    PollingMode[PollingMode["LazyLoad"] = 1] = "LazyLoad";
    PollingMode[PollingMode["ManualPoll"] = 2] = "ManualPoll";
})(PollingMode || (PollingMode = {}));
/** Control the location of the config.json files containing your feature flags and settings within the ConfigCat CDN. */
export var DataGovernance;
(function (DataGovernance) {
    /** Select this if your feature flags are published to all global CDN nodes. */
    DataGovernance[DataGovernance["Global"] = 0] = "Global";
    /** Select this if your feature flags are published to CDN nodes only in the EU. */
    DataGovernance[DataGovernance["EuOnly"] = 1] = "EuOnly";
})(DataGovernance || (DataGovernance = {}));
var OptionsBase = /** @class */ (function () {
    function OptionsBase(apiKey, clientVersion, options, defaultCache, eventEmitterFactory) {
        var _a, _b, _c;
        this.configFileName = "config_v5";
        this.requestTimeoutMs = 30000;
        this.baseUrlOverriden = false;
        this.proxy = "";
        this.offline = false;
        if (!apiKey) {
            throw new Error("Invalid 'apiKey' value");
        }
        if (!defaultCache) {
            defaultCache = new InMemoryCache();
        }
        this.apiKey = apiKey;
        this.clientVersion = clientVersion;
        this.dataGovernance = (_a = options === null || options === void 0 ? void 0 : options.dataGovernance) !== null && _a !== void 0 ? _a : DataGovernance.Global;
        this.cache = defaultCache;
        switch (this.dataGovernance) {
            case DataGovernance.EuOnly:
                this.baseUrl = "https://cdn-eu.configcat.com";
                break;
            default:
                this.baseUrl = "https://cdn-global.configcat.com";
                break;
        }
        var eventEmitter = (_b = eventEmitterFactory === null || eventEmitterFactory === void 0 ? void 0 : eventEmitterFactory()) !== null && _b !== void 0 ? _b : new DefaultEventEmitter();
        this.hooks = new Hooks(eventEmitter);
        var logger;
        if (options) {
            logger = options.logger;
            if (options.requestTimeoutMs) {
                if (options.requestTimeoutMs < 0) {
                    throw new Error("Invalid 'requestTimeoutMs' value");
                }
                this.requestTimeoutMs = options.requestTimeoutMs;
            }
            if (options.baseUrl) {
                this.baseUrl = options.baseUrl;
                this.baseUrlOverriden = true;
            }
            if (options.proxy) {
                this.proxy = options.proxy;
            }
            if (options.cache) {
                this.cache = options.cache;
            }
            if (options.flagOverrides) {
                this.flagOverrides = options.flagOverrides;
            }
            if (options.defaultUser) {
                this.defaultUser = options.defaultUser;
            }
            if (options.offline) {
                this.offline = options.offline;
            }
            (_c = options.setupHooks) === null || _c === void 0 ? void 0 : _c.call(options, this.hooks);
        }
        this.logger = new LoggerWrapper(logger !== null && logger !== void 0 ? logger : new ConfigCatConsoleLogger(), this.hooks);
    }
    OptionsBase.prototype.getUrl = function () {
        return this.baseUrl + "/configuration-files/" + this.apiKey + "/" + this.configFileName + ".json" + "?sdk=" + this.clientVersion;
    };
    OptionsBase.prototype.getCacheKey = function () {
        return "js_" + this.configFileName + "_" + this.apiKey;
    };
    return OptionsBase;
}());
export { OptionsBase };
var AutoPollOptions = /** @class */ (function (_super) {
    __extends(AutoPollOptions, _super);
    function AutoPollOptions(apiKey, sdkType, sdkVersion, options, defaultCache, eventEmitterFactory) {
        var _this = _super.call(this, apiKey, sdkType + "/a-" + sdkVersion, options, defaultCache, eventEmitterFactory) || this;
        /** The client's poll interval in seconds. Default: 60 seconds. */
        _this.pollIntervalSeconds = 60;
        /** You can subscribe to configuration changes with this callback.
         * @deprecated This property is obsolete and will be removed from the public API in a future major version. Please use the 'options.setupHooks = hooks => hooks.on("configChanged", ...)' format instead.
        */
        _this.configChanged = function () { };
        /** Maximum waiting time between the client initialization and the first config acquisition in seconds. */
        _this.maxInitWaitTimeSeconds = 5;
        if (options) {
            if (options.pollIntervalSeconds !== void 0 && options.pollIntervalSeconds !== null) {
                _this.pollIntervalSeconds = options.pollIntervalSeconds;
            }
            if (options.configChanged) {
                _this.configChanged = options.configChanged;
            }
            if (options.maxInitWaitTimeSeconds !== void 0 && options.maxInitWaitTimeSeconds !== null) {
                _this.maxInitWaitTimeSeconds = options.maxInitWaitTimeSeconds;
            }
        }
        if (!(_this.pollIntervalSeconds >= 1 && (typeof _this.pollIntervalSeconds === 'number'))) {
            throw new Error("Invalid 'pollIntervalSeconds' value");
        }
        if (!(_this.maxInitWaitTimeSeconds >= 0 && (typeof _this.maxInitWaitTimeSeconds === 'number'))) {
            throw new Error("Invalid 'maxInitWaitTimeSeconds' value");
        }
        return _this;
    }
    return AutoPollOptions;
}(OptionsBase));
export { AutoPollOptions };
var ManualPollOptions = /** @class */ (function (_super) {
    __extends(ManualPollOptions, _super);
    function ManualPollOptions(apiKey, sdkType, sdkVersion, options, defaultCache, eventEmitterFactory) {
        return _super.call(this, apiKey, sdkType + "/m-" + sdkVersion, options, defaultCache, eventEmitterFactory) || this;
    }
    return ManualPollOptions;
}(OptionsBase));
export { ManualPollOptions };
var LazyLoadOptions = /** @class */ (function (_super) {
    __extends(LazyLoadOptions, _super);
    function LazyLoadOptions(apiKey, sdkType, sdkVersion, options, defaultCache, eventEmitterFactory) {
        var _this = _super.call(this, apiKey, sdkType + "/l-" + sdkVersion, options, defaultCache, eventEmitterFactory) || this;
        /** The cache TTL. */
        _this.cacheTimeToLiveSeconds = 60;
        if (options) {
            if (options.cacheTimeToLiveSeconds) {
                _this.cacheTimeToLiveSeconds = options.cacheTimeToLiveSeconds;
            }
        }
        if (!_this.cacheTimeToLiveSeconds || _this.cacheTimeToLiveSeconds < 1) {
            throw new Error("Invalid 'cacheTimeToLiveSeconds' value. Value must be greater than zero.");
        }
        return _this;
    }
    return LazyLoadOptions;
}(OptionsBase));
export { LazyLoadOptions };
