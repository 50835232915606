import { store, view } from "@risingstack/react-easy-state";
import cn from "classnames";
import isFunction from "lodash-es/isFunction";
import { useRef } from "react";
import Col from "react-bootstrap/Col";
import { useInView } from "react-intersection-observer";
import { Link } from "wouter";

import { queueImpression, sendProductClick } from "../common/tracking";
import { PageType, Product, ProductTileCallbacks } from "../common/types/productTypes";
import { formatPrice, productImageUrl } from "../common/utils";

import authStore from "../stores/auth/authStore";
import productStore from "../stores/product/productStore";
import { hasDiscount } from "../stores/product/productUtils";

import AddToCartButton from "./AddToCartButton";
import AmountPicker from "./AmountPicker";
import Badges from "./Badges";
import Favorite from "./Favorite";
import Labels from "./Labels";
import PricePerItem from "./PricePerItem";

type ProductTileProps = {
   product: Product;
   listName: string;
   position: number;
   callbacks?: ProductTileCallbacks;
};

type InternalStateStore = {
   qty: number;
   hovering: boolean;
   hasReportedVisibility: boolean;
};

const ProductTile = ({ product, listName, position, callbacks = {} }: ProductTileProps) => {
   const imageRef = useRef<HTMLImageElement>(null);
   const tileRef = useRef<HTMLDivElement>(null);
   const tileState: InternalStateStore = store({
      qty: 1,
      hovering: false,
      hasReportedVisibility: false
   } satisfies InternalStateStore);

   const trackImpression = (isVisible: boolean) => {
      if (isVisible && !tileState.hasReportedVisibility) {
         queueImpression(product, listName, position);
         // Allow other consumers to insert their own callback
         if (isFunction(callbacks["impression"])) {
            callbacks["impression"](product, listName, position);
         }
         tileState.hasReportedVisibility = true;
      }
   };

   const { ref } = useInView({
      triggerOnce: true,
      onChange: trackImpression
   });

   const checkIfWarn = () => {
      tileState.hovering = !authStore.isLoggedIn();
   };

   const removeWarning = () => {
      tileState.hovering = false;
   };

   const trackClick = () => {
      sendProductClick(product, listName, position);
      // Allow other consumers to insert their own callback
      if (isFunction(callbacks["click"])) {
         callbacks["click"](product, listName, position);
      }
   };

   const warn = !authStore.isLoggedIn() && tileState.hovering;

   return (
      <Col ref={ref}>
         <div
            ref={tileRef}
            className={`product-tile ${tileState.hovering ? "hovering" : ""}`}
            onMouseEnter={checkIfWarn}
            onMouseLeave={removeWarning}
         >
            <div className="position-relative">
               <Favorite sku={product.sku} />
               <Badges badges={product.badges} page={PageType.ProductListPage} />
               <Labels labels={product.labels} className="d-none d-sm-flex" page={PageType.ProductListPage} sku={product.sku} />
               {/* @ts-ignore */}
               <Link to={productStore.getUrl(product)} onClick={trackClick} ref={imageRef}>
                  <img
                     role="button"
                     alt={`Bilde av ${product.name}`}
                     loading="lazy"
                     className="image w-60 mx-auto d-block my-3 ratio ratio-1x1"
                     src={productImageUrl(product.sku, 210)}
                  />
               </Link>
            </div>
            <div className="name mb-1">
               <Link to={productStore.getUrl(product)} onClick={trackClick}>
                  {product.name}
               </Link>
            </div>
            {authStore.isLoggedIn() ? (
               <>
                  {hasDiscount(product) && (
                     <div className="mt-1 text-end">
                        <strong className="text-decoration-line-through opacity-50">
                           {formatPrice(product.priceBeforeDiscount)}
                        </strong>
                     </div>
                  )}
                  <div className={cn("mb-1 fw-bold text-end", { "text-info-orange": hasDiscount(product) })}>
                     {formatPrice(product.price)} {product.unit}
                  </div>

                  {/* Desktop Version */}
                  <div className="small-details d-none d-lg-flex justify-content-between">
                     <div className="sku mb-2">Art.nr.: {product.sku}</div>
                     <div className={cn("mb-2 smallest text-end", { "text-info-orange": hasDiscount(product) })}>
                        <PricePerItem className="mb-2 smallest text-end" product={product} />
                        {formatPrice(product.comparisonPrice)} per {product.comparisonUnit?.toLowerCase()}
                     </div>
                  </div>

                  {/* Mobile Version */}
                  <div className="small-details d-lg-none">
                     <PricePerItem
                        className={cn("mb-1 smallest text-end", { "text-info-orange": hasDiscount(product) })}
                        product={product}
                     />
                     <div className={cn("mb-2 smallest text-end", { "text-info-orange": hasDiscount(product) })}>
                        {formatPrice(product.comparisonPrice)} per {product.comparisonUnit?.toLowerCase()}
                     </div>
                     <div className="mb-3 smallest text-end">Art.nr.: {product.sku}</div>
                  </div>
               </>
            ) : (
               <div className="sku mb-3">Art.nr.: {product.sku}</div>
            )}
            <div className="amountAndAddToCart">
               <div className="amount">
                  <AmountPicker
                     sku={product.sku}
                     onChange={(qty) => {
                        tileState.qty = qty;
                     }}
                  />
               </div>
               <div className="addToCart">
                  <AddToCartButton
                     product={product}
                     qty={tileState.qty}
                     imageRefFn={() => imageRef}
                     callbacks={callbacks}
                     listName={listName}
                     position={position}
                     noPadding
                  />
               </div>
            </div>
            <div className={`line-unavailable smallest py-2 ${warn ? "visible" : "invisible"}`}>
               Du må logge inn for å legge produkter i favorittliste eller handlekurv.
            </div>
         </div>
      </Col>
   );
};

export default view(ProductTile);
