import { __awaiter, __extends, __generator } from "tslib";
import { ConfigServiceBase } from "./ConfigServiceBase";
var ManualPollConfigService = /** @class */ (function (_super) {
    __extends(ManualPollConfigService, _super);
    function ManualPollConfigService(configFetcher, options) {
        var _this = _super.call(this, configFetcher, options) || this;
        options.hooks.emit("clientReady");
        return _this;
    }
    ManualPollConfigService.prototype.getConfig = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.options.logger.debug("ManualPollService.getConfig() called.");
                        return [4 /*yield*/, this.options.cache.get(this.options.getCacheKey())];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ManualPollConfigService.prototype.refreshConfigAsync = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.options.logger.debug("ManualPollService.refreshConfigAsync() called.");
                return [2 /*return*/, _super.prototype.refreshConfigAsync.call(this)];
            });
        });
    };
    return ManualPollConfigService;
}(ConfigServiceBase));
export { ManualPollConfigService };
