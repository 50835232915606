import { documentToReactComponents, Options, RenderNode } from "@contentful/rich-text-react-renderer";
import { Block, BLOCKS, Document, Inline } from "@contentful/rich-text-types";
import { ReactNode } from "react";
import { Col, Row } from "react-bootstrap";

export type TextTags = "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "ul" | "ol";

export const cmsTextColumnProps = {
   full: { md: { span: 8, offset: 2 }, lg: { span: 12, offset: 0 } },
   twoThirds: { md: { span: 8, offset: 2 }, lg: { span: 8, offset: 2 } },
   half: { md: { span: 8, offset: 2 }, lg: { span: 6, offset: 3 } },
   absoluteFull: { md: { span: 12, offset: 0 }, lg: { span: 12, offset: 0 } }
};

export type columnWidthType = "full" | "twoThirds" | "half" | "absoluteFull";

export const RichTextColumn = ({
   children,
   columnWidth,
   tag
}: {
   children: ReactNode;
   columnWidth?: columnWidthType;
   tag: TextTags;
}) => {
   const width = columnWidth ?? "full";
   return (
      <Row>
         <Col md={cmsTextColumnProps[width].md} lg={cmsTextColumnProps[width].lg}>
            {children}
         </Col>
      </Row>
   );
};

/**
 * To avoid extra rows and cols between bulletpoints and text,
 * create a new text renderer with no additional grid for child elements
 */
export const RichTextListColumn = ({ node, customRenderNodes }: { node: Block | Inline; customRenderNodes: RenderNode }) => {
   const newDocument: Document = {
      nodeType: BLOCKS.DOCUMENT,
      content: [
         {
            nodeType: node.nodeType === "ordered-list" ? BLOCKS.OL_LIST : BLOCKS.UL_LIST,
            content: node.content,
            data: {}
         }
      ],
      data: {}
   };
   const customRenderOptions: Options = {
      renderNode: {
         ...customRenderNodes
      }
   };
   return <>{documentToReactComponents(newDocument, customRenderOptions)}</>;
};
