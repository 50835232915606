import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { view } from "@risingstack/react-easy-state";
import cn from "classnames";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

interface LoadingProps {
   simple?: boolean;
   modal?: boolean;
   label?: string | null;
   className?: string;
   visible?: boolean;
   small?: boolean;
}

const Loading = ({
   simple = false,
   modal = false,
   label = null,
   className = "",
   visible = false,
   small = false
}: LoadingProps) => {
   const [isVisible, setVisible] = useState(visible);

   useEffect(() => {
      if (visible) {
         return;
      }

      const timerId = setTimeout(() => {
         setVisible(true);
      }, 150);

      return () => {
         clearTimeout(timerId);
      };
   }, [visible]);

   if (!isVisible) {
      return null;
   }

   if (simple) {
      return (
         <div className={`spinner ${className}`}>
            <ArrowPathIcon className={cn("spin", { "small-spinner": small })} />
            {label && <span>{label}</span>}
         </div>
      );
   }

   if (modal) {
      return (
         <Modal show={true}>
            <Modal.Header>
               <Modal.Title>Vennligst vent</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <ArrowPathIcon className="spin me-2" /> {label && <span>{label}</span>}
            </Modal.Body>
         </Modal>
      );
   }

   return (
      <Row className="justify-content-center">
         <Col sm={label ? 12 : 1} className="text-center">
            <ArrowPathIcon className="spin" />
            {label && <span>{label}</span>}
         </Col>
      </Row>
   );
};

export default view(Loading);
