import Bugsnag from "@bugsnag/js";
import { view } from "@risingstack/react-easy-state";
import React, { Suspense, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { Helmet } from "react-helmet";
import { Route, Switch } from "wouter";
import useLocation from "wouter/use-location";

import "@contentful/live-preview/style.css";
import "react-loading-skeleton/dist/skeleton.css";

import { ensureError, TokenExpiryError } from "./common/errors";
import useMediaQuery from "./common/useMediaQuery";
import { desktopBreakpoint } from "./common/utils";

import authStore from "./stores/auth/authStore";
import barcodeStore from "./stores/barcodeStore";
import categoryStore from "./stores/categories/categoryStore";
import contentStore from "./stores/cms/contentStore";
import { PREVIEW_BASE_PATH } from "./stores/cms/contentUtils";
import deliveryDatesStore from "./stores/deliveryDates/deliveryDatesStore";
import featuresStore from "./stores/features/featuresStore";
import productStore from "./stores/product/productStore";
import uiStore from "./stores/uiStore";

import ChangeDeliveryDateModal from "./components/ChangeDeliveryDateModal";
import FlyingProduct from "./components/FlyingProduct";
import Loading from "./components/Loading";
import ForcedLogoutModal from "./components/login/ForcedLogoutModal";
import LoginModal from "./components/login/LoginModal";
import RedirectPunchout from "./components/RedirectPunchout";
import ScrollToTop from "./components/ScrollToTop";
import SessionChecker from "./components/SessionChecker";
import SwitchCompanyModal from "./components/SwitchCompanyModal";
import ToastDisplay from "./components/ToastDisplay";
import Warnings from "./components/Warnings";
import Footer from "./footer/Footer";
import DesktopHeader from "./header/desktop/DesktopHeader";
import LoginProgress from "./header/LoginProgress";
import MobileHeader from "./header/mobile/MobileHeader";
import theme from "./themes/theme";

const BarcodeScanner = React.lazy(() => import("./components/barcodes/BarcodeScanner"));
const Cart = React.lazy(() => import("./pages/cart/Cart"));
const CustomerRoutes = React.lazy(() => import("./account/CustomerRoutes"));
const ProductListPage = React.lazy(() => import("./pages/ProductListPage"));
const Home = React.lazy(() => import("./pages/Home"));
const SearchResult = React.lazy(() => import("./pages/SearchResult"));
const ProductDetailsPage = React.lazy(() => import("./pages/pdp/ProductDetailsPage"));
const OrderConfirmation = React.lazy(() => import("./pages/OrderConfirmation"));
const SubscriptionConfirmation = React.lazy(() => import("./pages/SubscriptionConfirmation"));
const PickupConfirmation = React.lazy(() => import("./pages/PickupConfirmation"));
const CmsContentPreview = React.lazy(() => import("./components/cms/CmsContentPreview"));
const CmsContent = React.lazy(() => import("./components/cms/CmsContent"));
const ContactFormPage = React.lazy(() => import("./pages/ContactFormPage"));

const App = () => {
   const [location, redirectTo] = useLocation();

   useEffect(() => {
      async function fetchData() {
         await uiStore.ensureDataVersionAvailable();
         await featuresStore.fetchEnabledConfigCatFeatures();

         try {
            await contentStore.fillStore(location);
            void deliveryDatesStore.fetchHolidays();

            await productStore.fillStore();
            await categoryStore.fillStore();
         } catch (err) {
            const actualError: Error = ensureError(err);
            // If we end up here, likelihood is that we attempted to load an old data file from the CDN.
            // We will attempt to check versions on CDN and then refresh the window to fetch new data.
            await uiStore.handleMissingDataFileError(actualError);
         }

         try {
            const isImpersonating = authStore.useSessionFromQuery();
            if (isImpersonating) {
               redirectTo("/");
            }

            productStore.loadGuestAssortment();
            await authStore.verifySession(null);
         } catch (err) {
            if (!(err instanceof TokenExpiryError)) {
               const wrappedError: Error = ensureError(err);
               Bugsnag.notify(wrappedError, (e) => {
                  e.context = "Session Check during startup";
               });
               console.info("Not already logged in, reason: ", wrappedError);
            }
         }
      }

      void fetchData();

      window.addEventListener("keydown", uiStore.handleShortcutKeypress);
      return () => {
         window.removeEventListener("keydown", uiStore.handleShortcutKeypress);
      };
   }, []);

   const isDesktop = useMediaQuery(desktopBreakpoint);

   return (
      <>
         <Helmet>
            <title>{theme.siteName}</title>
         </Helmet>
         <Warnings />
         <Container as="header" fluid className="header bg-white px-0">
            <ScrollToTop />
            {isDesktop ? <DesktopHeader /> : <MobileHeader />}
         </Container>
         <Container as="main" className="main px-sm-3 px-md-5 py-4">
            <Suspense fallback={<Loading />}>
               <Switch>
                  <Route path="/" component={Home} />
                  <Route path="/produkter/:cat2/:cat3">{(p) => <ProductListPage {...p} />}</Route>
                  <Route path="/produkter/:cat2">{(p) => <ProductListPage {...p} />}</Route>
                  <Route path="/produkter.html">{(p) => <ProductListPage {...p} />}</Route>
                  <Route path="/customer/:subkey*" component={CustomerRoutes} />
                  <Route path="/checkout/cart" component={Cart} />
                  <Route path="/checkout/confirmation/subscription" component={SubscriptionConfirmation} />
                  <Route path="/checkout/confirmation/pickup" component={PickupConfirmation} />
                  <Route path="/checkout/confirmation" component={OrderConfirmation} />
                  <Route path="/search/:type?" component={SearchResult} />
                  <Route path="/produkt/:slug">{(p) => <ProductDetailsPage {...p} />}</Route>
                  <Route path="/kontakt" component={ContactFormPage} />
                  <Route path="/punchout/customer/login">
                     <RedirectPunchout target="magento" />
                  </Route>
                  <Route path="/tine_esa/cc/zPunchout.jsp">
                     <RedirectPunchout target="esales" />
                  </Route>
                  <Route path={`/${PREVIEW_BASE_PATH}/:fullUrl*`} component={CmsContentPreview} />
                  <Route path="/:fullUrl*" component={CmsContent} />
               </Switch>
            </Suspense>
            <div id="megamenu-root" />
            <div id="quicksearch-root" />
            <ToastDisplay />
            {uiStore.planes.map((p) => (
               <FlyingProduct key={p.animationId} plane={p} />
            ))}
            {featuresStore.barcodeScannerEnabled && barcodeStore.modalVisible && (
               <Suspense fallback={<Loading modal />}>
                  <BarcodeScanner />
               </Suspense>
            )}
            <LoginModal />
            <LoginProgress />
            <ForcedLogoutModal />
            <ChangeDeliveryDateModal />
            <SwitchCompanyModal />
         </Container>
         <Container as={"footer"} fluid className="footer">
            <Footer />
         </Container>
         <SessionChecker onChange={(vis) => authStore.verifyStillLoggedIn(vis)} />
      </>
   );
};

export default view(App);
