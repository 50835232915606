import { store } from "@risingstack/react-easy-state";
import find from "lodash-es/find";
import includes from "lodash-es/includes";
import isNil from "lodash-es/isNil";
import remove from "lodash-es/remove";
import { RefObject } from "react";
import { v4 as uuid } from "uuid";

import { apiClient } from "../common/apiClient";
import i18n from "../common/i18n";
import { SupportedLocaleType } from "../common/types/languageTypes";
import { FlyingProductAnimationState } from "../common/types/productTypes";
import {
   getDataVersionFromStorage,
   getHasRefreshedFromStorage,
   setDataVersionInStorage,
   setHasRefreshedInStorage
} from "../common/utils/storageUtils";

import authStore from "./auth/authStore";
import loginState from "./auth/loginState";
import productStore from "./product/productStore";
import toastStore from "./toastStore";

const landingPadId = "mini-cart";

export const enum LOGIN_MODE {
   USERNAME = "username",
   SMS = "sms",
   RESET_PASSWORD_EMAIL = "resetPasswordEmail",
   RESET_PASSWORD_OTP = "resetPasswordOtp",
   RESET_PASSWORD_SUCCESS = "resetPasswordSuccess"
}

type UIStore = {
   planes: FlyingProductAnimationState[];
   dataVersion: string | null;
   newDataAvailable: boolean;
   locale: SupportedLocaleType;
   accessLevel: number;
   loginMode: LOGIN_MODE;
   //loginModalVisible: boolean;
   showTranslationKeys: boolean;
   showDefaultTranslationText: boolean;
   showLanguageSwitcher: boolean;

   ensureDataVersionAvailable(): Promise<void>;
   checkDataVersion(): Promise<void>;
   handleMissingDataFileError(err: Error): Promise<void>;
   takeOff(animationId: string): void;
   addFlyingProduct(sku: string, fromRef: RefObject<HTMLImageElement>): void;
   handleShortcutKeypress(e: KeyboardEvent): void;
   switchLoginMode(newMode: LOGIN_MODE): void;
   showLoginModal(): void;
   hideLoginModal(): void;
};

const uiStore: UIStore = store({
   planes: [],
   dataVersion: getDataVersionFromStorage(),
   locale: i18n.language?.startsWith("en") ? "en-US" : "nb-NO",
   newDataAvailable: false,
   accessLevel: 0,
   loginMode: LOGIN_MODE.USERNAME,
   //loginModalVisible: false,
   showTranslationKeys: false,
   showDefaultTranslationText: true,
   showLanguageSwitcher: false,

   ensureDataVersionAvailable: async () => {
      if (uiStore.dataVersion === null) {
         // If we don't know a valid data version, just fetch it so its stored for next time.
         // No need to schedule an update check this session.
         return uiStore.checkDataVersion();
      }

      // If we already know a valid data version, we use that and schedule an update check
      // in 10 seconds once everything has settled.
      setTimeout(() => uiStore.checkDataVersion(), 10000);
   },

   checkDataVersion: async () => {
      const dataVersionUrl = `${process.env.STATICDATA_HOST}/currentVersion.${process.env.ENV_NAME}.txt`;
      const latestDataVersion = await apiClient(dataVersionUrl).get().text();

      if (uiStore.dataVersion !== latestDataVersion) {
         console.log("Static data available is version " + latestDataVersion);

         if (uiStore.dataVersion === null) {
            uiStore.dataVersion = latestDataVersion;
            setDataVersionInStorage(uiStore.dataVersion);
         } else {
            // If we detect that new data is available, we can remove the
            // data version stored in session storage. This ensures that the
            // next time the user visits the site, we will fetch the latest data.
            uiStore.newDataAvailable = true;
            setDataVersionInStorage(null);
         }
      }
   },

   handleMissingDataFileError: async (err) => {
      console.warn("An error occured while fetching data files!", err);

      // Check session storage to see if we have already tried to refresh, to avoid refresh loops
      const alreadyRefreshed = getHasRefreshedFromStorage();

      if (includes(err.message, "BlobNotFound") && !alreadyRefreshed) {
         setHasRefreshedInStorage(true);
         await uiStore.checkDataVersion();
         window.location.reload();
      } else {
         throw err;
      }
   },

   takeOff: (animationId) => {
      const targetEl = document.getElementById(landingPadId);
      if (isNil(targetEl)) {
         return;
      }
      const rect = targetEl.getBoundingClientRect();

      const plane = find(uiStore.planes, { animationId });
      if (isNil(plane)) {
         return;
      }

      plane.x = rect.left + window.scrollX;
      plane.y = rect.top + window.scrollY;
      plane.height = targetEl.clientHeight;

      setTimeout(() => {
         remove(uiStore.planes, { animationId });
      }, 1000);
   },

   addFlyingProduct: (sku, fromRef) => {
      if (fromRef.current === null) {
         console.warn("Attempted to fly image from undefined reference");
         return;
      }

      const el = fromRef.current;
      if (isNil(el)) {
         console.warn("Unable to find current image for sku " + sku + " in ref: " + JSON.stringify(fromRef));
         return;
      }

      const rect = el.getBoundingClientRect();
      const animationId = uuid();

      uiStore.planes.push({
         animationId,
         sku,
         x: rect.left + window.scrollX,
         y: rect.top + window.scrollY,
         sourceWidth: el.naturalWidth,
         height: el.clientHeight
      });
   },

   handleShortcutKeypress: (e) => {
      const isFunctionKeysHeld = e.altKey && e.ctrlKey && e.shiftKey;

      if (!isFunctionKeysHeld) {
         // The rest of this function should only run if user is holdeing
         // alt + ctrl + shift, so return if this is not the case.
         return;
      }

      if (e.code === "KeyO") {
         uiStore.showTranslationKeys = !uiStore.showTranslationKeys;
      }

      if (e.code === "KeyK" && uiStore.accessLevel === 0) {
         uiStore.accessLevel = 1;
      } else if (e.code === "KeyS" && uiStore.accessLevel === 1) {
         uiStore.accessLevel = 2;
         if (loginState.is("LOGGED_IN")) {
            loginState.transitionTo("NOT_LOGGED_IN");
         }
         productStore.assortment = productStore.products.map((p) => p.sku);
         toastStore.addSuccess("Du har nå KS-tilgang", "Du har blitt logget ut, men kan nå søke på alle produkter");
      }
   },

   switchLoginMode: (newMode) => {
      uiStore.loginMode = newMode;
   },

   showLoginModal: () => {
      loginState.transitionTo("LOGIN_MODAL");
   },

   hideLoginModal: () => {
      loginState.transitionTo("NOT_LOGGED_IN");
      authStore.codeChallengeId = null;
      uiStore.switchLoginMode(LOGIN_MODE.USERNAME);
   }
});

export default uiStore;
